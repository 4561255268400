import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";

export default function AllPsychologistTable() {
  let [allPsychologist, setAllPsyChologist] = useState([]);

  const fetchPsychologist = async () => {
    const response = await fetch(
      "https://api.thepsycure.com/psychologists"
      // , {
      //     method: "GET",
      //     headers: {
      //         authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      //     },
      // }
    );
    const json = await response.json();

    if (response.ok) {
      let sortedData = json.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setAllPsyChologist(sortedData);
    }
  };

  useEffect(() => {
    fetchPsychologist();
  }, []);

  const columns = [
    { id: "name", label: "Name", minWidth: 170, align: "center" },
    { id: "email", label: "Email", minWidth: 150, align: "center" },
    {
      id: "phone",
      label: "Phone",
      minWidth: 170,
      align: "center",
    },
    {
      id: "details",
      label: "Details",
      minWidth: 170,
      align: "center",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 170,
      align: "center",
    },
  ];

  function createData(id, name, email, phone, details, status) {
    return { id, name, email, phone, details, status };
  }

  const rows = [];
  allPsychologist.forEach((psychologist) =>
    rows.push(
      createData(
        psychologist._id,
        psychologist.name,
        psychologist.email,
        psychologist.phone,
        <Button style={{ backgroundColor: "green", color: "#fff" }}>
          <NavLink
            style={{ textDecoration: "none", color: "white" }}
            to={`/admin-dashboard/psychologist/${psychologist._id}`}
          >
            Preview
          </NavLink>{" "}
        </Button>,
        psychologist.approved ? (
          <p
            style={{
              background: "#31C75A",
              padding: "3px",
              display: "inline",
              color: "#fff",
            }}
          >
            Approved
          </p>
        ) : (
          <p
            style={{
              background: "red",
              display: "inline",
              padding: "3px",
              color: "#fff",
            }}
          >
            Pending
          </p>
        )
      )
    )
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div
      style={{
        boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.25)",
        padding: "2rem",
        borderRadius: ".8rem",
      }}
    >
      <TableContainer className="table-container" sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: 600 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
