import React, { useEffect, useState } from "react";
import { Container } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import "./CalenderView.css";

import IndividualEdit from "./IndividualEdit/IndividualEdit";

import Footer from "../../../Shared/Footer/Footer";

const CalenderView = () => {
  const { user } = useAuth();
  const [psychologist, setPsychologist] = useState([]);
  const [psychologistTimes, setPsychologistTimes] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetch(`https://api.thepsycure.com/psychologists/${user?.email}`)
      .then((res) => res.json())
      .then((data) => {
        setPsychologist(data);
        setPsychologistTimes(data?.availableDateTimes);
      });
  }, [user, open]);

  return (
    <>
      <Container>
        <div style={{ textAlign: "center" }}>
          <h2>My Available Date and Time</h2>
          <small>
            To update available date and time, please click on the edit icon
          </small>
        </div>

        <div className="main-box">
          {/* {psychologistTimes?.length ? psychologistTimes?.map((item) => (
                    <div className='align-box'>
                        <div className='date-box'>
                            <CalendarMonthIcon sx={{ color: '#31C75A' }} />  <p>Date: {item?.date}</p>
                        </div>
                        <div>
                            {item?.times?.map((time) => (
                                <div>
                                    <div className='time-box'>
                                        <p>Start Time: <br />{time?.startTime}</p>
                                        <p>End Time:<br /> {time?.endTime}</p>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                )) : <></>} */}
          <IndividualEdit details={psychologist} setDetails={setPsychologist} />
          {/* <EditModal
                    handleClose={handleClose}
                    open={open}
                ></EditModal> */}
        </div>
      </Container>
      {/* <DashboardFooter /> */}
      <Footer />
    </>
  );
};

export default CalenderView;
