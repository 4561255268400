
import { useState } from "react"
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase.config";
import { useEffect } from "react";

export const useForgotPassword = () => {
    let [error, setError] = useState("");
    let [success, setSuccess] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setError("");
            setSuccess("");
        }, 3000)
    }, [success, error])

    const sendPasswordReset = async (email) => {
        setError(null)
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setSuccess("sending reset password link to your email account")
            })
            .catch((err) => {
                setError("please write down your email into email box")
            })
    };

    return { sendPasswordReset, error, success }
}