
import { Container } from "@mui/system";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../../Shared/Footer/Footer";

import service1 from "../../../assets/images/service1.png";


import counseling2 from "../../../assets/images/counseling2.jpg";
import counseling3 from "../../../assets/images/counseling3.jpg";
import counseling4 from "../../../assets/images/counseling4.jpg";
import counseling5 from "../../../assets/images/counseling5.jpg";
import counseling6 from "../../../assets/images/counseling6.jpg";
import { Link } from "react-router-dom";
import Header from "../../../Shared/Header/Header";
import ReactGA from "react-ga4";

const data = [
  {
    id: 1,
    title: "INDIVIDUAL COUNSELING",
    content:
      "Do you think you need someone to talk about your griefs and stresses? Do you think, even though socializing is one of the most common tasks among human beings, still, some talks and tears can’t be shared with all? Those secret conversations, secret pains, and secret stresses can be shared and healed if you decide to talk with a counselor or therapist. Individual counseling is for those who have extreme emotions, any recent trauma, workplace problems, strained family issues, any kind of substance abuse, or are constantly bothered by a thought. For 45 to 60 minutes, a patient can express any thoughts, strengths, and weaknesses to solve behavioral patterns or to solve any conflicts. Individual counseling can help one to heal and to start a new edition of one’s life.",
    category: "Individual Counseling",
    price: {
      student: 599,
      regular: 1000,
      premium: 1500,
    },
    img: service1,
  },
  {
    id: 2,
    title: "COUPLE COUNSELING",
    content:
      "Two people with different characteristics and personalities get together by marriage or just for love. But time goes by and they start to get detached or they start to see their differences. Many of the couple’s issues and conflicts are based on mental distance and communication gaps. Couple counseling exists to help them to talk about their problems and extreme emotional issues. Sharing with therapists can help them to express themselves and to solve their problems in logical methods. On 50 to 70 minutes sessions and with a minimum gap, couples can get an opportunity to talk their heart out. ",
    category: "Couple Counseling",
    price: {
      regular: 1500,
      premium: 1500,
    },
    img: counseling2,
  },
  {
    id: 3,
    title: "FAMILY COUNSELING",
    content:
      "We all start this life with a family, The family is the most important connection that humans have with one another.  The goal of family Counseling is to help family members improve communication, To solve family problems, we need to understand and handle special family situations (for example, death, serious physical or mental illness, or child and adolescent issues) and create a better functioning home environment. Family Counseling lasts at least one hour during which the expert will listen carefully, and provide valuable advice.",
    category: "Family & Couple Therapy",
    price: {
      regular: 1500,
      premium: 1500,
    },
    img: counseling3,
  },
  {
    id: 4,
    title: "GROUP Counseling",
    content:
      "Do you know the benefits of group Counseling? Group Counseling offers the chance for group members to share their similar situations for mutual growth. People are born into a group, grow as part of a group, and develop through a group. Group Counseling therefore is used to help members meet their personal needs, solve problems of interpersonal relationships and accomplish their goals. On 50 to 70 minutes sessions and with a minimum gap, the group can get an opportunity to talk their heart out. Psycure organization will give them a place to share their thoughts and opinions, and encourage interpersonal interaction.",
    category: "Group Counseling",
    price: {
      regular: 6000,
      premium: 8000,
    },
    img: counseling4,
  },
  {
    id: 5,
    title: "CHILD Counseling",
    content:
      "Child Counseling is a psychological therapy that focuses on children, teens, and adolescents with one or more mental conditions. Youths experiencing trauma due to dysfunctional or stressful home environments may also seek child counseling. Children face some common mental issues like anxiety, depression, and grief. Counseling for such reasons is most beneficial. The main goal of child Counseling is to simplify the concerns so that children could understand and cope better in life. For most cases, it takes around 6 to 8 sessions, where each session lasts around 1 hour, to reap noticeable changes. Reach out to Psycure today if your children need any psychological guidance.",
    category: "Child & Adolescent",
    price: {
      student: 1000,
      regular: 1000,
      premium: 1500,
    },
    img: counseling5,
  },
  {
    id: 6,
    title: "CAREER Counseling",
    content:
      "If you are unsure of what you want out of your education, your career, and your life, then seeking career Counseling would be a wise decision. The career counsellor will help you organize and make sense of your thoughts and feelings pertaining to career and educational choices. Factors affecting your career development and assess your interests, capabilities, and values. They will guide you to locate your own resources to determine the next steps and create a realistic plan to achieve your goals. Mostly career Counseling takes about 3 to 4 sessions, 45 minutes each. So, do not hesitate to contact Psycure for a career Counseling session today.",
    category: "",
    price: {
      student: 1000,
      regular: 1000,
      premium: 1500,
    },
    img: counseling6,
  },
];

export default function ServiceReadMore() {
  const { title } = useParams({});

  const navigate = useNavigate("");
  const actualService = data.filter(
    (service) => service.title.toLowerCase() == title.toLowerCase()
  );

  // const handleNavigate = () => {
  //     navigate("/booksession");
  // }
  useEffect(() => {
  
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Counselling type: ${title}`,
    });
  }, [title]);

  return (
    <>
      <Header />
      <div className="read_more">
        {/* <Grid item xs={12} md={12} > */}
        <Container style={{ marginTop: "10rem", textAlign: "center" }}>
          <h2>{actualService[0]?.title}</h2>
          <img src={actualService[0]?.img} style={{ maxWidth: "50%" }} />
          <div style={{ textAlign: "left" }}>
            <p style={{ textAlign: "justify" }}>{actualService[0]?.content}</p>
            {actualService[0]?.price?.student ? (
              <p>
                <span style={{ fontWeight: "700" }}>Student Price:</span>{" "}
                {actualService[0]?.price?.student} BDT
              </p>
            ) : null}
            {actualService[0]?.price?.regular ? (
              <p>
                <span style={{ fontWeight: "700" }}>Regular Price:</span>{" "}
                {actualService[0]?.price?.regular} BDT
              </p>
            ) : null}
            {actualService[0]?.price?.premium ? (
              <p>
                <span style={{ fontWeight: "700" }}>Premium Price:</span>{" "}
                {actualService[0]?.price?.premium} BDT
              </p>
            ) : null}
          </div>
          <Link to={`/booksession/${actualService[0]?.category}`}>
            <button>Book Appointment</button>
          </Link>
        </Container>
        <Footer />
        {/* </Grid> */}
      </div>
    </>
  );
}
