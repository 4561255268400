import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams, NavLink } from "react-router-dom";
import "./BlogLearnMore.css";

import spinner from "../../../assets/images/spinner-loop.gif";
import useAuth from "../../../hooks/useAuth";
import Facebook from "../../../Shared/Facebook/Facebook";
import Linkedin from "../../../Shared/Linkedin/Linkedin";
import Twitter from "../../../Shared/Twitter/Twitter";

import Footer from "../../../Shared/Footer/Footer";
import Header from "../../../Shared/Header/Header";

import ReactGA from "react-ga4";

const BlogLearnMore = () => {
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "green",
      background: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
      background: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#67523D",
        borderRadius: "50px",
        background: "none",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
        background: "none",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
        background: "none",
      },
    },
  });

  const { user } = useAuth();

  const { blogtitle } = useParams({});

  const [selected, setSelected] = useState({});
  let [blogerDetails, setBlogerDetails] = useState({});
  const [relatedBlog, setRelatedBlog] = useState([]);
  const [blogShow, setBlogShow] = useState(3);

  const [ip, setIp] = useState();
  useEffect(() => {
    const fetchIp = async () => {
      await fetch("https://api.ipify.org/?format=json")
        .then((response) => response.json())
        .then((data) => setIp(data.ip));
    };
    fetchIp();
  }, []);
  useEffect(() => {
    const updateReadTime = async () => {
      await fetch("https://api.thepsycure.com/put-readtime", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: blogtitle, ip: ip }),
      });
    };
    updateReadTime();
  }, [ip]);
  useEffect(() => {
    // fetch(`https://api.thepsycure.com/blog-detail/${id}`)
    // fetch(`https://api.thepsycure.com/blog-detail/${blogtitle}`)
    fetch(`https://api.thepsycure.com/blog-details/${blogtitle}`)
      .then((res) => res.json())
      .then((blogs) => {
        setSelected(blogs);
      });
  }, [blogtitle]);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `${selected?.blogTitle}`,
    });
  }, [selected]);

  useEffect(() => {
    fetch(`https://api.thepsycure.com/users`)
      .then((res) => res.json())
      .then((data) => {
        const foundBloger = data?.filter(
          (authenticateUser) =>
            authenticateUser?.email === selected?.authorEmail
        );
        setBlogerDetails(foundBloger[0]);
      });
  }, [selected]);

  useEffect(() => {
    fetch(`https://api.thepsycure.com/blogs`)
      .then((res) => res.json())
      .then((blogs) => {
        console.log(blogs);
        let authorOtherBlog = blogs?.blogs?.filter(
          (blog) => blog?.authorEmail === selected?.authorEmail
        );
        setRelatedBlog(authorOtherBlog);
      });
  }, [selected]);

  return (
    <div>
      <Header />
      <Container>
        <Grid container spacing={2} sx={{ mt: 10 }}>
          <Grid item xs={12} md={8}>
            {!selected?.blogTitle ? (
              <div
                style={{
                  height: "40vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={spinner}
                  alt="spinner"
                  style={{ width: "500px", height: "400px" }}
                />
              </div>
            ) : (
              <Card style={{ marginTop: "5rem", boxShadow: "none" }}>
                <CardMedia
                  component="img"
                  height="400"
                  style={{ objectFit: "contain" }}
                  image={selected?.blogImage}
                  alt={selected?.blogImageCaption}
                />
                <Container style={{ marginTop: "5rem" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{ fontSize: "36px", fontWeight: "600" }}
                  >
                    {selected?.blogTitle}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selected?.blogContent,
                      }}
                    />
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      gap: "2rem",
                      alignItems: "center",
                    }}
                  >
                    <h2>Share </h2>
                    <div style={{ display: "flex", gap: "2rem" }}>
                      <Facebook
                        url={`https://thepsycure.com/${blogtitle}`}
                        qoute="Psycure Blog"
                      />
                      <Linkedin
                        url={`https://thepsycure.com/${blogtitle}`}
                        qoute="Psycure Blog"
                      />
                      <Twitter
                        url={`https://thepsycure.com/${blogtitle}`}
                        qoute="Psycure Blog"
                      />
                    </div>
                    <div style={{ marginLeft: "30%" }}>
                      <p
                        style={{
                          fontSize: "15px",
                          color: "gray",
                          textAlign: "right",
                          // marginRight: "8px",

                          fontWeight: "normal",
                        }}
                      >
                        Read:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {selected?.readTime}
                        </span>{" "}
                        times
                      </p>
                    </div>
                  </div>
                </Container>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} md={4} sx={{ mt: 10 }}>
            {/* relatedBlog?.length ? ( */}
            <div className="bloger">
              <img
                src={selected?.authorImage}
                alt={selected?.author}
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "50%",
                  marginBottom: "15px",
                }}
              />
              <div className="bloger_details">
                <h2>{selected?.author}</h2>
                <p>{blogerDetails?.bio}</p>
              </div>
            </div>
            {/* ) : (
              <NavLink
                to="/blog"
                style={{ textDecoration: "none", color: "currentcolor" }}
              >
                <h2>Click here to go back Blog Page</h2>
              </NavLink>
            ) */}

            {relatedBlog?.length ? (
              <div className="other_blog">
                <h2>Other Blogs</h2>
                {relatedBlog?.slice(0, blogShow).map((blog) => {
                  // console.log(blog);
                  let blogTitle = blog?.blogTitle?.split(" ").join("-");
                  return (
                    <NavLink
                      to={`/${blog?._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        sx={{ maxWidth: 345 }}
                        style={{ marginBottom: "2rem" }}
                      >
                        <CardMedia
                          component="img"
                          height="150"
                          image={blog?.blogImage}
                          alt="green iguana"
                          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {blog?.blogTitle}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: blog?.blogContent.slice(0, 100),
                              }}
                            />
                          </Typography>
                        </CardContent>
                        <CardActions
                          style={{
                            marginBottom: "1rem",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <NavLink
                            to={`/${blog?._id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              size="small"
                              style={{
                                backgroundColor: "#31C75A",
                                padding: "5px 15px",
                                fontSize: "14px",
                                textTransform: "capitalize",
                                borderRadius: "8px",
                                color: "#fff",
                              }}
                              contain
                            >
                              {" "}
                              Read More
                            </Button>
                          </NavLink>
                          {blog?.authorImage ? (
                            <CardMedia
                              component="img"
                              height="50"
                              width="50"
                              image={blog?.authorImage}
                              alt={blog?.author}
                              style={{
                                width: "80px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "50%",
                                paddingLeft: "1rem",
                                paddingRight: "1rem",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </CardActions>
                      </Card>
                    </NavLink>
                  );
                })}
                {relatedBlog?.length > blogShow ? (
                  <Button
                    onClick={() => setBlogShow((prevNum) => prevNum + 3)}
                    size="small"
                    style={{
                      backgroundColor: "#31C75A",
                      padding: "5px 15px",
                      fontSize: "14px",
                      textTransform: "capitalize",
                      borderRadius: "8px",
                      color: "#fff",
                    }}
                    contain
                  >
                    Load More
                  </Button>
                ) : null}
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default BlogLearnMore;
