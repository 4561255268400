import React, { useEffect, useLayoutEffect, useState } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Container } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import SuccessAlert from "../../../Shared/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../../Shared/ErrorAlert/ErrorAlert";
import spinner from "../../..//assets/images/spinner-loop.gif";

import Footer from "../../../Shared/Footer/Footer";

const EditPatientInfo = () => {
  const { user } = useAuth();
  const [patientDetails, setPatientDetails] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(false);

  let [userDetails, setUserDetails] = useState({
    name: patientDetails?.name,
    email: patientDetails?.email,
    location: patientDetails?.location,
    phone: patientDetails?.phone,
    age: patientDetails?.patientInfo?.age,
    gender: patientDetails?.patientInfo?.gender,
  });

  useLayoutEffect(() => {
    fetch("https://api.thepsycure.com/users")
      .then((res) => res.json())
      .then((data) => {
        const foundPsychologist = data?.filter(
          (detail) => detail?.email === user?.email
        );
        setPatientDetails(foundPsychologist[0]);
      });
  }, [user.email]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userProfile = {
      name: userDetails?.name,
      location: userDetails?.location,
      phone: userDetails?.phone,
      age: userDetails?.age,
      gender: userDetails?.gender,
    };

    fetch(`https://api.thepsycure.com/userProfile/${user?.email}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(userProfile),
    })
      .then((res) => res.json())
      .then((data) => {
        setIsSuccess(true);
        setError(false);
      })
      .catch((err) => {
        setIsSuccess(false);
        setError(true);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(false);
      setError(false);
    }, 3000);
  }, [isSuccess, error]);
  return (
    <>
      <div style={{ minHeight: "75vh" }}>
        {patientDetails?._id ? (
          <Container
            style={{
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              textAlign: "center",
              borderRadius: "15px",
            }}
            sx={{ alignItems: "center" }}
          >
            <Box
              className="EditProfile"
              style={{ padding: "2rem 5rem  5rem 5rem" }}
            >
              <Box id="BookSession">
                <h2
                  style={{ textAlign: "center", marginLeft: "1rem" }}
                  variant="h4"
                  gutterBottom
                  component="div"
                >
                  Edit Your Information
                </h2>
                <hr
                  style={{
                    display: "block",
                    width: "10%",
                    height: "3px",
                    backgroundColor: "#31C75A",
                    border: 0,
                  }}
                />
              </Box>
              <Box>
                <form className="mode" onSubmit={handleSubmit}>
                  <Box>
                    <TextField
                      required
                      sx={{ width: "97.5%", m: 2 }}
                      id="outlined-required"
                      label="Full Name"
                      defaultValue={patientDetails?.name}
                      onChange={(e) =>
                        setUserDetails({ ...userDetails, name: e.target.value })
                      }
                    />
                  </Box>

                  <Box>
                    <TextField
                      required
                      sx={{ width: "97.5%", m: 2 }}
                      id="outlined-required"
                      label="Email"
                      defaultValue={patientDetails?.email}
                    />
                  </Box>

                  <Box
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                    }}
                  >
                    <TextField
                      required
                      sx={{ width: "95%", m: 2 }}
                      id="outlined-required"
                      label="Location"
                      defaultValue={patientDetails?.location}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          location: e.target.value,
                        })
                      }
                    />

                    <TextField
                      required
                      sx={{ width: "95%", m: 2 }}
                      id="outlined-required"
                      label="phone"
                      type="number"
                      defaultValue={patientDetails?.phone}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          phone: e.target.value,
                        })
                      }
                    />

                    {/* {
                                            patientDetails?.patientInfo?.age ? <p>Age: {patientDetails?.patientInfo?.age}</p> : <TextField
                                                required
                                                sx={{ width: '95%', m: 2 }}
                                                id="outlined-required"
                                                label="Age"
                                                type="number"
                                                defaultValue={patientDetails?.patientInfo?.age}
                                                onChange={e => setUserDetails({ ...userDetails, age: e.target.value })}
                                            />
                                        } */}

                    <TextField
                      required
                      sx={{ width: "95%", m: 2 }}
                      id="outlined-required"
                      label="Age"
                      type="number"
                      defaultValue={patientDetails?.patientInfo?.age}
                      onChange={(e) =>
                        setUserDetails({ ...userDetails, age: e.target.value })
                      }
                    />
                  </Box>

                  <Box
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                    }}
                  >
                    {/* <TextField
                                            required
                                            sx={{ width: '95%', m: 2 }}
                                            id="outlined-required"
                                            label="Age"
                                            type="number"
                                            defaultValue={patientDetails?.age}
                                            onChange={e => setUserDetails({ ...userDetails, age: e.target.value })}
                                        /> */}

                    {/* <TextField
                                            required
                                            sx={{ width: '95%', m: 2 }}
                                            id="outlined-required"
                                            label="Gender"
                                            defaultValue={patientDetails?.gender}
                                            onChange={e => setUserDetails({ ...userDetails, gender: e.target.value })}
                                        /> */}
                  </Box>

                  <Box className="btn_container">
                    <Button
                      style={{ backgroundColor: "#31C75A", width: "20%" }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
                {isSuccess ? (
                  <SuccessAlert>Profile updated successfully</SuccessAlert>
                ) : (
                  ""
                )}
                {error ? (
                  <ErrorAlert>Could not updated your profile</ErrorAlert>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Container>
        ) : (
          <div
            style={{
              height: "40vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={spinner}
              alt="spinner"
              style={{ width: "500px", height: "400px" }}
            />
          </div>
        )}
      </div>
      {/* <DashboardFooter /> */}
      <Footer />
    </>
  );
};

export default EditPatientInfo;
