import { Box, Button, Container, TextField } from '@material-ui/core';
import React from 'react';
import checkedIcon from './../../assets/images/checkedIdon.png'
import deleteIcon from './../../assets/images/Delete.png'
import AddIcon from './../../assets/images/Add.png'
import Header from '../../Shared/Header/Header';

const AvailableDate = () => {
    const handleSubmit = data => {
    }
    return (
        <>
            <Header />
            <Box>
                <Container sx={{
                    flexGrow: 1,

                }} style={{
                    background: '#FAFAFA',
                    boxShadow: ' 0px 24px 48px rgba(0, 0, 0, 0.25)',
                    borderRadius: '40px',

                }} >
                    <Box style={{
                        padding: '5rem', marginTop: '8rem',

                    }}>
                        <Box>
                            <Box>
                                <h2 style={{ textAlign: 'center' }} variant="h4" gutterBottom component="div">
                                    Select Available Date
                                    <br />
                                    This Week
                                </h2>
                                <hr style={{ display: 'block', width: '10%', height: '3px', backgroundColor: '#31C75A', border: 0 }} />
                            </Box>
                            <Box style={{

                                borderRadius: '20px', border: '1px solid #E0E0E0'
                            }}>
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Box style={{ display: 'flex' }}>
                                        <img src={checkedIcon} style={{ width: 'auto', height: 'auto', padding: '20px' }} alt="" />
                                        <h4>Mon</h4>
                                    </Box>
                                    <Box style={{ display: 'flex' }}>
                                        <TextField
                                            style={{ margin: '1rem' }}
                                            id="standard-basic"
                                            label="Your Name"
                                            name='name'
                                            type='text'
                                            variant="outlined" />

                                        <TextField
                                            style={{ margin: '1rem' }}
                                            id="standard-basic"
                                            label="Your Name"
                                            name='name'
                                            type='text'
                                            variant="outlined" />
                                    </Box>
                                    <Box style={{ display: 'flex' }}>

                                        <Button>
                                            <img src={deleteIcon} style={{ width: 'auto', height: 'auto', padding: '20px' }} alt="" />
                                        </Button>
                                        <Button>
                                            <img src={AddIcon} style={{ width: 'auto', height: 'auto', padding: '20px' }} alt="" />
                                        </Button>


                                    </Box>
                                </Box>
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Box style={{ display: 'flex' }}>
                                        <img src={checkedIcon} style={{ width: 'auto', height: 'auto', padding: '20px' }} alt="" />
                                        <h4>Mon</h4>
                                    </Box>
                                    <Box style={{ display: 'flex' }}>
                                        <TextField
                                            style={{ margin: '1rem' }}
                                            id="standard-basic"
                                            label="Your Name"
                                            name='name'
                                            type='text'
                                            variant="outlined" />

                                        <TextField
                                            style={{ margin: '1rem' }}
                                            id="standard-basic"
                                            label="Your Name"
                                            name='name'
                                            type='text'
                                            variant="outlined" />
                                    </Box>
                                    <Box style={{ display: 'flex' }}>

                                        <Button>
                                            <img src={deleteIcon} style={{ width: 'auto', height: 'auto', padding: '20px' }} alt="" />
                                        </Button>
                                        <Button>
                                            <img src={AddIcon} style={{ width: 'auto', height: 'auto', padding: '20px' }} alt="" />
                                        </Button>


                                    </Box>
                                </Box>

                            </Box>


                        </Box>

                    </Box >

                </Container >
            </Box >
        </>
    );
};

export default AvailableDate;