export const signinPageEvent = () => {
    window?.fbq('track', 'Sign-In');
}

export const bookSessionPageEvent = () => {
    window?.fbq('track', 'Book-Session');
}

export const servicesPageEvent = () => {
    window?.fbq('track', 'Services');
}

export const journalPageEvent = () => {
    window?.fbq('track', 'Journal');
}

export const dashboardPageEvent = () => {
    window?.fbq('track', 'Dashboard');
}

export const psychologistSigninPageEvent = () => {
    window?.fbq('track', 'psychologist-signin');
}