import React, { useState } from 'react'
import { Box, Button, TextField } from '@mui/material';

export default function AddChatUser() {
    let [error, setError] = useState("");
    let [success, setSuccess] = useState("");
    let [userDetail, setUserDetail] = useState({
        name: "",
        email: "",
        password: ""
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('https://api-laravel.thepsycure.com/register', {
            method: 'POST',
            body: JSON.stringify(userDetail),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const json = await response.json()
        if (!response.ok) {
            setError('Could not upload your blog. Please try again');
        }
        if (response.ok) {
            setUserDetail({
                name: "",
                email: "",
                password: ""
            })
            setSuccess('Congratulations, Blog Added');
        }
    }
    return (
        <div>
            <h2 style={{ textAlign: "center" }}>Add Chat User</h2>
            <form className="mode" onSubmit={handleSubmit}>


                <Box >

                    <TextField
                        required
                        sx={{ width: '95%', m: 2 }}
                        id="outlined-required"
                        label="Name"
                        defaultValue={userDetail?.name}
                        onChange={e => setUserDetail({ ...userDetail, name: e.target.value })}
                    />

                </Box>

                <Box style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>

                    <TextField
                        required
                        sx={{ width: '95%', m: 2 }}
                        id="outlined-required"
                        label="Email"
                        defaultValue={userDetail?.email}
                        onChange={e => setUserDetail({ ...userDetail, email: e.target.value })}
                    />
                    <TextField
                        required
                        sx={{ width: '95%', m: 2 }}
                        id="outlined-required"
                        label="Chat Password"
                        defaultValue={userDetail?.password}
                        onChange={e => setUserDetail({ ...userDetail, password: e.target.value })}
                    />
                </Box>

                <Box style={{ textAlign: "center" }}>

                    <Button style={{ backgroundColor: "#31C75A", color:"#fff", maxWidth: "300px", margin: "0 auto" }} type="submit" variant="cpontained">Submit</Button>

                </Box>

            </form>
            {/* <AllPro /> */}
        </div>
    )
}
