import {  Paper } from '@mui/material'
import React from 'react'

export default function FreequencyPage({ setPage, homework, setHomeWork }) {
    const allFreequency = [
        {
            id: 1,
            name: "Daily"
        },
        {
            id: 2,
            name: "Weekly"
        },
        // // {
        //     id: 3,
        //     name: "Monthly"
        // },
    ]
    const handleFreequency = (data) => {
        setPage(2);
        setHomeWork({ ...homework, frequency: data.name })
    }
    return (
        <Paper elevation={12} sx={{p:3, borderRadius: '1.5rem',  minHeight: '25rem' }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignContent: 'center', gap: '0.5rem' }}>
            <h3 style={{ textAlign: 'center' }}>Choose Frequency</h3>
            {
                allFreequency.map(freequency => (
                    <div key={freequency.id} onClick={() => handleFreequency(freequency)} style={{ color: 'white', backgroundColor: '#31C75A', padding: '1.6rem', borderRadius: '1rem', marginBottom: '1rem', width: "150px", textAlign: "center", cursor: 'pointer' }}>{freequency.name}</div>
                ))
            }
        </Paper>
    )
}
