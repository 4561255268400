import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import ErrorAlert from "../../../Shared/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../../Shared/SuccessAlert/SuccessAlert";

export default function JournalPosting({ setMood, mood, feelingEmoji }) {
  let [success, setSuccess] = useState("");
  let [error, setError] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    setMood({ ...mood, email: user?.email });
  }, [success, error]);

  const handleMoodSubmit = async () => {
    if (mood.feeling == "") {
      setError("Please select your mood");
      setSuccess("");
      return;
    }

    const response = await fetch("https://api.thepsycure.com/moods", {
      method: "POST",
      body: JSON.stringify(mood),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      setError("Something Went Wrong");
      setSuccess("");
    }
    const json = await response.json();
    if (response.ok) {
      let toDate = new Date().toISOString().slice(0, 10);
      let toTime = new Date().toLocaleString().slice(10);
      let concatDateTime = `${toDate},${toTime}`;
      setMood({
        feeling: "",
        mind: "",
        date: concatDateTime,
      });
      setSuccess("Mood Journal Submitted");
      setError("");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess("");
      setError("");
    }, 3000);
  }, [success, error]);

  return (
    <Grid item xs={12} md={6}>
      <Box
        style={{
          boxShadow: "0px 12px 22px rgba(0, 0, 0, 0.10)",
          padding: "2rem",
          borderRadius: ".8rem",
        }}
      >
        <Typography variant="h6" align="center" sx={{ mb: 2 }}>
          How are you feeling?
        </Typography>
        <Grid container spacing={4} sx={{ mx: "auto" }} align="center">
          {feelingEmoji.map((feeling) => (
            <Grid item md={2}>
              <img
                onClick={() => setMood({ ...mood, feeling: feeling.title })}
                style={{
                  background:
                    mood.feeling == feeling.title ? "#ddd" : "transparent",
                  borderRadius: "50%",
                }}
                src={feeling.img}
                alt={feeling.title}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        style={{
          boxShadow: "0px 12px 22px rgba(0, 0, 0, 0.10)",
          padding: "2rem",
          borderRadius: ".8rem",
          margin: "2rem 0",
        }}
      >
        <Typography variant="h6" align="center" sx={{ mb: 2 }}>
          What is on your mind?
        </Typography>
        <textarea
          onChange={(e) => setMood({ ...mood, mind: e.target.value })}
          value={mood.mind}
        />
      </Box>
      <Box>
        <Button
          onClick={handleMoodSubmit}
          style={{
            background:
              "linear-gradient(97.88deg, #31C75A -4.13%, #63E455 102.47%)",
            color: "#fff",
            borderRadius: "100px",
            padding: ".5rem 2.5rem",
            display: "block",
            textAlign: "center",
          }}
        >
          Submit
        </Button>
      </Box>
      {success !== "" ? <SuccessAlert>{success}</SuccessAlert> : ""}
      {error !== "" ? <ErrorAlert>{error}</ErrorAlert> : ""}
    </Grid>
  );
}
