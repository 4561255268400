import React, { useEffect, useState } from "react";
import Header from "../../../Shared/Header/Header";
import { Container, Divider, Paper, Grid } from "@mui/material";

import useAuth from "../../../hooks/useAuth";
import "../../Payment/PaySecondPage/PaySecondPage.css";
import ticket from "../../../assets/images/ticket-discount.png";

const WorkshopPayment = ({ currentWorkshop }) => {
  const [isMobile, setIsMobile] = useState(false);
  let [promoCode, setPromoCode] = useState("");
  let [allPromo, setAllPromo] = useState([]);
  let [amount, setAmount] = useState(0);
  let [discountPrice, setDiscountPrice] = useState(0);
  let [error, setError] = useState("");
  let [success, setSuccess] = useState("");
  let [selectedPromo, setSelectedPromo] = useState({});
  const mediaQuery = window.matchMedia("(max-width:768px)");
  const { user } = useAuth();
  // Function to handle media query changes
  const handleMediaQueryChange = (event) => {
    setIsMobile(event.matches);
  };
  useEffect(() => {
    mediaQuery.addListener(handleMediaQueryChange);

    // Initial check for the media query
    setIsMobile(mediaQuery.matches);

    // Cleanup the event listener when the component unmounts
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, [mediaQuery]);
  useEffect(() => {
    fetch("https://api.thepsycure.com/workshop-promo")
      .then((res) => res.json())
      .then((data) => {
        setAllPromo(data);
      });
  }, []);
  useEffect(() => {
    setAmount(currentWorkshop?.workshopFee);
  }, [currentWorkshop]);
  // handle promo
  const [promoState, setPromoState] = useState(false);
  const handlePromoAppy = () => {
    console.log(selectedPromo);
    if (promoCode) {
      let filteredPromo = allPromo?.filter(
        (promo) => promo?.name === promoCode
      );
      setSelectedPromo(filteredPromo[0]);
      if (filteredPromo[0]?.percentage) {
        setError("");
        let discountAmount =
          amount * (Number(filteredPromo[0]?.percentage) / 100);
        if (discountAmount > Number(filteredPromo[0]?.maxDiscount)) {
          setDiscountPrice(Number(filteredPromo[0]?.maxDiscount));
          setPromoState(true);
        } else {
          setDiscountPrice(discountAmount);
          setPromoState(true);
        }
        setSuccess(
          "Successfully applied your promo code. Now you can pay with discount price"
        );
      } else {
        setDiscountPrice(0);
        setError("The promo code is invalid");
        setSuccess("");
      }
    } else {
      setError("Please enter your promo code");
      setSuccess("");
    }
  };

  return (
    <>
      <Header />
      <div
        style={{
          paddingBottom: "30px",
        }}
      >
        <Container>
          <Grid sx={{ pt: 20, mb: 5 }} container spacing={8}>
            <Grid item md={8} sm={6}>
              {/* <br /><br /><br /><br /> */}
              <h3 className="session-header">Your Session</h3>
              <Paper className="payment-table">{/* <PayTable /> */}</Paper>
              <div className="individual-counseling">
                <div className="order-1-parent">
                  <div className="order-1">
                    <div
                      className="image-session"
                      style={{
                        width: `${isMobile ? "63.5px" : "30%"}`,
                      }}
                    >
                      <img
                        className="psychologist-image"
                        src={currentWorkshop?.workshopImage}
                        height={isMobile ? "63.68px" : ""}
                        width={isMobile ? "63.5px" : "100%"}
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div className="individual-counseling-text">
                      <p style={{ fontSize: "12px" }}>
                        Workshop On {currentWorkshop?.worskshopTitle}
                      </p>
                      <div className="size">
                        <span className="size-time">Place:</span>
                        <span className="size-start-time">
                          {currentWorkshop?.eventType}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{ textWrap: "nowrap" }}
                      className="individual-counseling-price"
                    >
                      <p>Event Fee: {currentWorkshop?.workshopFee}</p>
                    </div>
                  </div>
                </div>
                <div
                  className="session-type"
                  style={{ marginLeft: `${!isMobile ? "30%" : ""}` }}
                >
                  <div className="session-type-text">
                    <span className="session-gray">Session Type:</span>
                    <span className="individual-session">Workshop</span>
                  </div>
                </div>
              </div>
              <div className="promo-code-things">
                <label for="promo_code" className="promo-code-label">
                  Promo Code
                </label>
                <div class="input-container">
                  <span class="promo-icon">
                    <img
                      src={ticket}
                      alt=""
                      height="24px"
                      width="24px"
                      srcset=""
                    />
                  </span>

                  <input
                    type="text"
                    name="promo_code"
                    id="promo_code"
                    placeholder="Promo Code"
                    class="promo-input"
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                  <button onClick={handlePromoAppy} class="apply-button">
                    Apply Promo
                  </button>
                </div>
              </div>
              <Divider
                style={{
                  width: isMobile ? "350px" : "440px",
                  color: "#E3E8EF",
                }}
              ></Divider>
              <div
                className="session-details"
                style={{
                  marginBottom: "100px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="session-fees"
                >
                  <p className="session-details-each">Session Fees</p>
                  {!promoState && <p>৳{currentWorkshop?.workshopFee}</p>}
                  {promoState && (
                    <p className="session-values-each">
                      ৳{amount - discountPrice}
                    </p>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="gateway-details"
                >
                  <p className="session-details-each">Gateway Fee</p>
                  <p className="session-values-each">Free</p>
                </div>
                {discountPrice > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="discount-details"
                  >
                    <p className="session-details-each">
                      Discount:({selectedPromo?.percentage}%)
                    </p>
                    <p className="session-values-each">
                      ৳{discountPrice.toFixed(2)}
                    </p>
                  </div>
                )}

                <Divider
                  style={{
                    width: isMobile ? "350px" : "440px",
                    color: "#E3E8EF",
                  }}
                ></Divider>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="subtotal-details"
                >
                  <p className="subtotal-details-text">Subtotal</p>
                  {!promoState && (
                    <p className="subtotal-details-price">
                      ৳{currentWorkshop?.workshopFee}
                    </p>
                  )}
                  {promoState && <p>৳{amount - discountPrice}</p>}
                </div>
              </div>
              {/*  <Divider
                style={{
                  width: isMobile ? "350px" : "440px",
                  color: "#E3E8EF",
                }}
              ></Divider> */}
              {/* <div
                className="session-details"
                style={{
                  marginBottom: "100px",
                }}
              >
                <Divider
                  style={{
                    width: isMobile ? "350px" : "440px",
                    color: "#E3E8EF",
                  }}
                ></Divider>
              </div> */}
            </Grid>
            <Grid item md={4} sm={6}>
              {!isMobile && <p className="payment-info">Payment Information</p>}

              <div
                className="payment-form-parent"

                /*  style={{
                display: isMobile && "flex",
                justifyContent: isMobile && "center",
                justifyItems: isMobile && "center",
              }} */
              >
                <form
                  action="https://api.thepsycure.com/workshop-payment"
                  method="POST"
                  style={{
                    margin: "0 0",
                  }}
                  className="payment_form"
                >
                  <div
                    className="input_container billing-info"
                    style={{ marginBottom: "1rem" }}
                  >
                    <label className="phone-label" for="email">
                      Email
                    </label>
                    <input
                      required
                      type="email"
                      className="phone-input"
                      name="cus_email"
                      id="email"
                      placeholder="Ex. example@mail.com"
                    />
                  </div>
                  {/* workshop id */}
                  <div
                    className="input_container billing-info"
                    style={{ marginBottom: "1rem", display: "none" }}
                  >
                    <label className="phone-label" for="id">
                      Id
                    </label>
                    <input
                      required
                      type="text"
                      className="phone-input"
                      name="id"
                      id="id"
                      readOnly
                      value={currentWorkshop?._id}
                    />
                  </div>
                  {/* promo code*/}
                  {promoState && (
                    <div
                      className="input_container billing-info"
                      style={{ marginBottom: "1rem", display: "none" }}
                    >
                      <label className="phone-label" for="id">
                        Promo Code
                      </label>
                      <input
                        required
                        type="text"
                        className="phone-input"
                        name="promo_code"
                        id="promo_code"
                        readOnly
                        value={promoCode}
                      />
                    </div>
                  )}
                  {/* participant name */}
                  <div
                    className="input_container billing-info"
                    style={{ marginBottom: "1rem" }}
                  >
                    <label className="phone-label">Participant Name</label>
                    <input
                      placeholder="Ex. John"
                      className="phone-input"
                      name="cus_name"
                      required
                    />
                  </div>
                  {/*age*/}
                  {/*  <div
                    className="input_container billing-info"
                    style={{ marginBottom: "1rem" }}
                  >
                    <label className="phone-label">Participant Age</label>
                    <input
                      placeholder="Ex. 06"
                      type="number"
                      className="phone-input"
                      name="age"
                      id="age"
                      required
                    />
                  </div> */}
                  {/* institution */}
                  <div
                    className="input_container billing-info"
                    style={{ marginBottom: "1rem" }}
                  >
                    <label className="phone-label">
                      Participant Institution / Organization
                    </label>
                    <input
                      placeholder="Ex. ABC Int. High School"
                      className="phone-input"
                      name="institute"
                      id="institute"
                      required
                    />
                  </div>
                  {/* phone */}
                  <div
                    className="input_container billing-info"
                    style={{ marginBottom: "1rem" }}
                  >
                    <label className="phone-label" for="phone">
                      Phone
                    </label>
                    <input
                      placeholder="Ex. 0184542125"
                      className="phone-input"
                      required
                      id="phone"
                      name="cus_phone"
                    />
                  </div>
                  <div
                    className="input_container billing-info"
                    style={{ marginBottom: "1rem", display: "none" }}
                  >
                    <label className="phone-label" for="amount">
                      Phone
                    </label>
                    <input
                      placeholder="Ex. 0184542125"
                      className="phone-input"
                      required
                      type="hidden"
                      value={
                        !promoState
                          ? currentWorkshop?.workshopFee
                          : amount - discountPrice
                      }
                      id="amount"
                      name="amount"
                    />
                  </div>
                  {/* address */}

                  {!promoState && (
                    <button type="submit" className="pay-button">
                      Pay ৳{currentWorkshop?.workshopFee}
                    </button>
                  )}
                  {promoState && (
                    <button type="submit" className="pay-button">
                      Pay ৳{amount - discountPrice}
                    </button>
                  )}
                  {error && <p style={{ color: "red" }}>{error}</p>}
                  {success && <p style={{ color: "green" }}>{success}</p>}
                </form>
              </div>
              <br />
              <br />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default WorkshopPayment;
