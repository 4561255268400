import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";


import { Button } from "@mui/material";
import styled from "styled-components";
import TextField from "@mui/material/TextField";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import Radio from "@material-ui/core/Radio";
import { green } from "@material-ui/core/colors";

import { withStyles } from "@material-ui/core/styles";
import SuccessAlert from "../../Shared/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../Shared/ErrorAlert/ErrorAlert";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: #31c75a;
    border-color: #31c75a;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #31c75a;
    }
  }
`;

const GreenRadio = withStyles({
  root: {
    color: green[400],
    borderRadius: "0px",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function GetInTouch() {
  let [error, setError] = useState("");
  let [success, setSuccess] = useState("");
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Corporate mental health`,
    });
  }, []);
  let [info, setInfo] = useState({
    firstName: "",
    lastName: "",
    businessEmail: "",
    contactNumber: "",
    companyName: "",
    companySize: "",
    companyBased: "",
    interested: "",
    howToSupport: "",
    createdAt: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const today = new Date().toISOString().slice(0, 10);

    if (
      info.firstName !== "" &&
      info.lastName !== "" &&
      info.businessEmail !== "" &&
      info.contactNumber !== "" &&
      info.companyName !== "" &&
      info.companySize !== "" &&
      info.companyBased !== "" &&
      info.interested !== "" &&
      info.howToSupport !== ""
    ) {
      const response = await fetch("https://api.thepsycure.com/add-servey", {
        method: "POST",
        body: JSON.stringify({ ...info, createdAt: today }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      if (!response.ok) {
        setError("Could not upload your form. Please try again");
      }
      if (response.ok) {
        setInfo({
          firstName: "",
          lastName: "",
          businessEmail: "",
          contactNumber: "",
          companyName: "",
          companySize: "",
          companyBased: "",
          interested: "",
          howToSupport: "",
          createdAt: "",
        });
        setSuccess("Thanks for filling up this form");
      }
    }
  };

  return (
    <>
      <Header />
      <Container sx={{ my: 15 }}>
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Grid item xs={12} md={4}>
                        <img style={{ width: '100%', height: 'auto' }} src={GoalImg} alt="Contact-img" />
                    </Grid> */}
            <Grid item xs={12} md={8}>
              <h2>
                Get in touch with <span>our team</span>
              </h2>
              <p>Contact Details</p>

              <Grid
                container
                sx={{
                  flexGrow: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} md={6}>
                  <WhiteBorderTextField
                    style={{ backgroundColor: "white", marginTop: "10px" }}
                    sx={{ width: "95%", m: 1 }}
                    id="standard-basic"
                    label="First Name"
                    name="name"
                    type="text"
                    required
                    value={info.firstName}
                    onChange={(e) =>
                      setInfo({ ...info, firstName: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WhiteBorderTextField
                    style={{ backgroundColor: "white", marginTop: "10px" }}
                    sx={{ width: "95%", m: 1 }}
                    id="standard-basic"
                    label="Last Name"
                    name="name"
                    type="text"
                    required
                    value={info.lastName}
                    onChange={(e) =>
                      setInfo({ ...info, lastName: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WhiteBorderTextField
                    style={{ backgroundColor: "white", marginTop: "10px" }}
                    sx={{ width: "95%", m: 1 }}
                    id="standard-basic"
                    label="Business Email"
                    name="name"
                    type="email"
                    required
                    value={info.businessEmail}
                    onChange={(e) =>
                      setInfo({ ...info, businessEmail: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WhiteBorderTextField
                    style={{ backgroundColor: "white", marginTop: "10px" }}
                    sx={{ width: "95%", m: 1 }}
                    id="standard-basic"
                    label="Contact Number"
                    name="Contact Number"
                    type="email"
                    required
                    value={info.contactNumber}
                    onChange={(e) =>
                      setInfo({ ...info, contactNumber: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WhiteBorderTextField
                    style={{ backgroundColor: "white", marginTop: "10px" }}
                    sx={{ width: "95%", m: 1 }}
                    id="standard-basic"
                    label="Company Name"
                    name="name"
                    type="email"
                    required
                    value={info.companyName}
                    onChange={(e) =>
                      setInfo({ ...info, companyName: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WhiteBorderTextField
                    style={{ backgroundColor: "white", marginTop: "10px" }}
                    sx={{ width: "95%", m: 1 }}
                    id="standard-basic"
                    label="Company Size"
                    name="name"
                    type="number"
                    required
                    value={info.companySize}
                    onChange={(e) =>
                      setInfo({ ...info, companySize: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WhiteBorderTextField
                    style={{ backgroundColor: "white", marginTop: "10px" }}
                    sx={{ width: "95%", m: 1 }}
                    id="standard-basic"
                    label="Country where comapany in based"
                    name="name"
                    type="text"
                    required
                    value={info.companyBased}
                    onChange={(e) =>
                      setInfo({ ...info, companyBased: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </Grid>

              <p style={{ marginTop: "30px" }}>What are you interested in ?</p>
              <Grid item xs={12} md={6}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="preference"
                    name="preference"
                    value={info.interested}
                    onChange={(e) =>
                      setInfo({ ...info, interested: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="Mental Health Content"
                      control={<GreenRadio />}
                      label="Mental Health Content"
                    />
                    <FormControlLabel
                      value="1-1 Counselling Support"
                      control={<GreenRadio />}
                      label="1-1 Counselling Support"
                    />
                    <FormControlLabel
                      value="Workshop/ Training"
                      control={<GreenRadio />}
                      label="Workshop/ Training"
                    />
                    <FormControlLabel
                      value="Others"
                      control={<GreenRadio />}
                      label="Others"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <textarea
                  className="textarea"
                  placeholder="Tell us how you think psycure can support your team?"
                  value={info.howToSupport}
                  onChange={(e) =>
                    setInfo({ ...info, howToSupport: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  style={{
                    backgroundColor: "#31C75A",
                    padding: "8px",
                    fontSize: "14px",
                    textTransform: "capitalize",
                    borderRadius: "8px",
                    width: "100%",
                    color: "#fff",
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {success && <SuccessAlert>{success}</SuccessAlert>}
          {error && <ErrorAlert>{error}</ErrorAlert>}
        </Box>
      </Container>
    </>
  );
}
