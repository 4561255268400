import { useContext } from "react"
import { AuthContext } from "../contexts/AuthContext"

const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuthContext must be inside an AuthContextProvider")
    }

    return context
}
export default useAuth;