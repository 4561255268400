import { Box, Button, Container, Grid } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6F1EC",
    color: "#000",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E6F1EC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(blogTitle, author, email, id, date) {
  return { blogTitle, author, email, id, date };
}

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useLayoutEffect(() => {
    fetch("https://api.thepsycure.com/blogs-all")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        let sortedData = data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setIsLoading(false);
        setBlogs(sortedData);
      });
  }, []);

  const rows = [];

  blogs.forEach((blog) =>
    rows.push(
      createData(
        blog.blogTitle,
        blog.author,
        blog.authorEmail,
        blog._id,
        blog.date
      )
    )
  );

  const handelDelete = (id) => {
    const proceed = window.confirm("Are you sure you want to DELETE this Blog");
    if (proceed) {
      const url = `https://api.thepsycure.com/delete-blog/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount) {
            const remaining = blogs?.filter((blog) => blog._id !== id);
            setBlogs(remaining);
          }
        });
    }
  };

  return (
    <>
      <Container>
        <Grid item xs={12} md={6} component={Paper}>
          <Box>
            <h2
              style={{ textAlign: "center" }}
              variant="h4"
              gutterBottom
              component="div"
            >
              All Blogs
            </h2>
            <hr
              style={{
                display: "block",
                width: "10%",
                height: "3px",
                backgroundColor: "#31C75A",
                border: 0,
              }}
            />
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 300 }} aria-label="customized table">
              <TableHead style={{ marginBottom: "2rem" }}>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <strong>Blog Title</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Author</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Author Email</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Date</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Delete</strong>
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell className="table_center">
                        {row.blogTitle}
                      </StyledTableCell>
                      <StyledTableCell className="table_center">
                        {row.author}
                      </StyledTableCell>
                      <StyledTableCell className="table_center">
                        {row.email}
                      </StyledTableCell>
                      <StyledTableCell className="table_center">
                        {row.date}
                      </StyledTableCell>

                      <StyledTableCell className="table_center">
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#ff0000" }}
                          sx={{ borderRadius: "25px" }}
                          onClick={() => handelDelete(row.id)}
                        >
                          {" "}
                          <DeleteForeverIcon />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Container>
    </>
  );
};

export default AllBlogs;
