import {
  
  Divider,

  Grid,
  
  Paper,
 
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";

import "./PaySecondPage.css";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../Shared/Header/Header";
// import { pricing } from '../Data';
import ticket from "../../../assets/images/ticket-discount.png";
import ReactGA from "react-ga4";

const PaySecondPage = ({ selectedStartTime, item, isMobile }) => {
  console.log(item);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Selected psychologist for payment: ${item?.docEmail}`,
    });
  }, [item]);
  let { id } = useParams();
  const navigate = useNavigate("");
  const { user } = useAuth();
  let [amount, setAmount] = useState(0);
  let [discountPrice, setDiscountPrice] = useState(0);

  let [pricing, setPricing] = useState({});
  let [allPromo, setAllPromo] = useState([]);
  let [promoCode, setPromoCode] = useState("");
  let [error, setError] = useState("");
  let [success, setSuccess] = useState("");
  let [selectedPromo, setSelectedPromo] = useState({});
  let LocalStoragedata = item;
  console.log(LocalStoragedata);
  let selectedPsychologist = item?.selectedPsychologists;

  useEffect(() => {
    fetch("https://api.thepsycure.com/pricing-list")
      .then((res) => res.json())
      .then((data) => {
        // setPricing(data[0]);
      });
    fetch("https://api.thepsycure.com/promo")
      .then((res) => res.json())
      .then((data) => {
        setAllPromo(data);
      });
  }, []);
  const [promoState, setPromoState] = useState(false);
  const handlePromoAppy = () => {
    console.log(selectedPromo);
    if (promoCode) {
      let filteredPromo = allPromo?.filter(
        (promo) => promo?.name === promoCode
      );
      setSelectedPromo(filteredPromo[0]);
      if (filteredPromo[0]?.percentage) {
        setError("");
        let discountAmount =
          amount * (Number(filteredPromo[0]?.percentage) / 100);
        if (discountAmount > Number(filteredPromo[0]?.maxDiscount)) {
          setDiscountPrice(Number(filteredPromo[0]?.maxDiscount));
          setPromoState(true);
        } else {
          setDiscountPrice(discountAmount);
          setPromoState(true);
        }
        setSuccess(
          "Successfully applied your promo code. Now you can pay with discount price"
        );
      } else {
        setDiscountPrice(0);
        setError("The promo code is invalid");
        setSuccess("");
      }
    } else {
      setError("Please enter your promo code");
      setSuccess("");
    }
  };

  // useEffect(() => {}, [promoCode]);

  useEffect(() => {
    localStorage.setItem("paymentAmount", amount - discountPrice);
  }, [amount, discountPrice]);

  // Getting data from localstorage

  useEffect(() => {
    setPricing(LocalStoragedata?.selectedPsychologists?.amount);
    setAmount(LocalStoragedata?.selectedPsychologists?.amount);
  }, [LocalStoragedata]);
  console.log(isMobile);
  return (
    <>
      <Header />
      <div
        style={{
          paddingBottom: "30px",
        }}
      >
        <Container>
          <Grid sx={{ pt: 20, mb: 5 }} container spacing={8}>
            <Grid item md={8} sm={6}>
              {/* <br /><br /><br /><br /> */}
              <h3 className="session-header">Your Session</h3>
              <Paper className="payment-table">{/* <PayTable /> */}</Paper>
              <div className="individual-counseling">
                <div className="order-1-parent">
                  <div className="order-1">
                    <div className="image-session">
                      <img
                        className="psychologist-image"
                        src={selectedPsychologist?.image}
                        height={isMobile ? "63.68px" : "80px"}
                        width={isMobile ? "63.5px" : "80px"}
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div className="individual-counseling-text">
                      <p>
                        Individual Counselling With {selectedPsychologist?.name}
                      </p>
                      <div className="size">
                        <span className="size-time">Time:</span>
                        <span className="size-start-time">
                          {LocalStoragedata?.startTime}
                        </span>
                      </div>
                    </div>
                    <div className="individual-counseling-price">
                      <p>{selectedPsychologist?.amount}</p>
                    </div>
                  </div>
                </div>
                <div className="session-type">
                  <div className="session-type-text">
                    <span className="session-gray">Session Type: </span>
                    <span className="individual-session">
                      Individual Counseling
                    </span>
                  </div>
                </div>
              </div>
              <div className="promo-code-things">
                <label for="promo_code" className="promo-code-label">
                  Promo Code
                </label>
                <div class="input-container">
                  <span class="promo-icon">
                    <img
                      src={ticket}
                      alt=""
                      height="24px"
                      width="24px"
                      srcset=""
                    />
                  </span>

                  <input
                    type="text"
                    name="promo_code"
                    id="promo_code"
                    placeholder="Promo Code"
                    class="promo-input"
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                  <button onClick={handlePromoAppy} class="apply-button">
                    Apply Promo
                  </button>
                </div>
              </div>
              <Divider
                style={{
                  width: isMobile ? "350px" : "440px",
                  color: "#E3E8EF",
                }}
              ></Divider>
              <div
                className="session-details"
                style={{
                  marginBottom: "100px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="session-fees"
                >
                  <p className="session-details-each">Session Fees</p>
                  {!promoState && (
                    <p>৳{LocalStoragedata?.selectedPsychologists?.amount}</p>
                  )}
                  {promoState && (
                    <p className="session-values-each">
                      ৳{amount - discountPrice}
                    </p>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="gateway-details"
                >
                  <p className="session-details-each">Gateway Fee</p>
                  <p className="session-values-each">Free</p>
                </div>
                {discountPrice > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="discount-details"
                  >
                    <p className="session-details-each">
                      Discount:({selectedPromo?.percentage}%)
                    </p>
                    <p className="session-values-each">
                      ৳{discountPrice.toFixed(2)}
                    </p>
                  </div>
                )}

                <Divider
                  style={{
                    width: isMobile ? "350px" : "440px",
                    color: "#E3E8EF",
                  }}
                ></Divider>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="subtotal-details"
                >
                  <p className="subtotal-details-text">Subtotal</p>
                  {!promoState && (
                    <p className="subtotal-details-price">
                      ৳{LocalStoragedata?.selectedPsychologists?.amount}
                    </p>
                  )}
                  {promoState && <p>৳{amount - discountPrice}</p>}
                </div>
              </div>

              {/* <form action="https://api.thepsycure.com/payment" onSubmit={purchase} method="post" >
                            <input type="submit" value="Pay" style={{ width: "100%", outline: 0, border: 0, color: 'white', padding: "1rem 2rem", backgroundColor: '#31C75A', borderRadius: '25px', fontSize: '18px' }} />
                        </form> */}
            </Grid>
            <Grid item md={4} sm={6}>
              {!isMobile && <p className="payment-info">Payment Information</p>}

              <div
                className="payment-form-parent"
                /*  style={{
                  display: isMobile && "flex",
                  justifyContent: isMobile && "center",
                  justifyItems: isMobile && "center",
                }} */
              >
                <form
                  action="https://api.thepsycure.com/payment"
                  method="post"
                  className="payment_form"
                >
                  <div className="input_container" style={{ display: "none" }}>
                    <label for="name">Name</label>
                    <input
                      required
                      name="cus_name"
                      id="name"
                      value={user?.displayName}
                    />
                  </div>
                  <div className="input_container" style={{ display: "none" }}>
                    <label for="email">Email</label>
                    <input
                      required
                      type="email"
                      name="cus_email"
                      id="email"
                      value={user?.email}
                    />
                  </div>
                  <div
                    className="input_container billing-info"
                    style={{ marginBottom: "1rem" }}
                  >
                    <label className="phone-label" for="phone">
                      Phone
                    </label>
                    <input
                      placeholder="Ex. 0184542125"
                      className="phone-input"
                      required
                      id="phone"
                      name="cus_phone"
                    />
                  </div>
                  {/* address */}
                  <div
                    className="input_container billing-info"
                    style={{ marginBottom: "1rem" }}
                  >
                    <label className="phone-label">Billing Address</label>
                    <input
                      placeholder="Dhaka,Bangladesh"
                      className="phone-input"
                      required
                    />
                  </div>
                  {/* zipcode city */}
                  {/* <div className="zipcode-city">
                  <div
                    className="zip-container"
                    style={{ marginBottom: "1rem" }}
                  >
                    <label className="phone-label">Zip Code</label> <br />
                    <input
                      placeholder="Ex. 1202"
                      className="zip-input"
                      required
                    />
                  </div>
                  <div
                    className="city-container"
                    style={{ marginBottom: "1rem" }}
                  >
                    <label className="phone-label">City</label> <br />
                    <input placeholder="Dhaka" className="zip-input" required />
                  </div>
                </div> */}

                  <input
                    type="hidden"
                    value={amount - discountPrice}
                    name="amount"
                  />

                  {!promoState && (
                    <button type="submit" className="pay-button">
                      Pay ৳{LocalStoragedata?.selectedPsychologists?.amount}
                    </button>
                  )}
                  {promoState && (
                    <button type="submit" className="pay-button">
                      Pay ৳{amount - discountPrice}
                    </button>
                  )}
                  {error && <p style={{ color: "red" }}>{error}</p>}
                  {success && <p style={{ color: "green" }}>{success}</p>}
                </form>
              </div>
              <br />
              <br />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default PaySecondPage;
