import React from 'react'
import TitleIcon from '@mui/icons-material/Title';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';

import { Paper } from '@mui/material';

export default function TaskElement({ setPage, setSelectElement, homework }) {
    const allElements = [
        {
            id: 1,
            name: "Title",
            icon: <TitleIcon />,
            bg: "#d3d3d3"
        },
        {
            id: 2,
            name: "Description",
            icon: <DescriptionIcon />,
            bg: "#d3d3d3"
        },
        {
            id: 3,
            name: "Image",
            icon: <ImageIcon />,
            bg: "#d3d3d3"
        },
        // {
        //     id: 4,
        //     name: "Checkbox",
        //     icon: <CheckBoxIcon />,
        //     bg: "#d3d3d3"
        // },
    ]

    const handleElement = (data) => {
        setPage(4);
        setSelectElement(data.name);
    }

    return (
        <Paper elevation={12} sx={{ p: 5, borderRadius: '1.5rem' }}>
            <h2 style={{ textAlign: 'center' }}>Homework Builder</h2>
            <div className='elementContainer'>
                {
                    allElements.map(element => (
                        <div key={element.id} onClick={() => handleElement(element)} style={{ backgroundColor: (homework.title !== "" && element.name == "Title") || (homework.description !== "" && element.name == "Description") || (homework.image !== "" && element.name == "Image") || (homework.checkbox !== "" && element.name == "Checkbox") ? "#ddd" : "#E6F1EC", textAlign: 'center', padding: '2rem 0rem', cursor: 'pointer', borderRadius: '1rem' }}>
                            <div>
                                {element.icon}
                            </div>
                            {element.name}
                        </div>
                    ))
                }
            </div>
        </Paper>
    )
}
