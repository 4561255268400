import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import img1 from "../../../assets/images/why_choose_us_first_pic.svg"
import img2 from "../../../assets/images/chooseus2.webp"
import img3 from "../../../assets/images/chooseus3.webp"
import { useTheme } from '@material-ui/core/styles';


export default function ChooseUs() {
    const theme = useTheme();
    return (
        <Container sx={{ mb: 0,
            [theme.breakpoints.down('xs')]: {
              mb: 5,
            }, }}>
            <h2  className='headin'>
                Why Choose Us
            </h2>
            <hr style={{ display: 'block', width: '10%', height: '3px', backgroundColor: '#31C75A', border: 0 }} />

            <Grid sx={{ pt: 5,  }} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} sm={12} md={4}>
                    <div style={{ textAlign: "center" }}>

                        <div >
                            <img src={img1} style={{ width: "70%", height: "100%" }} alt="img" />
                        </div>
                        <Typography variant="p" style={{ textAlign: "center", fontWeight: "500", fontSize: "1.4rem" }}>
                            Experienced licensed psychologists
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <div style={{ textAlign: "center" }}>

                        <div >
                            <img src={img2} style={{ width: "70%", height: "100%" }} alt="img" />
                        </div>
                        <Typography variant="p" style={{ textAlign: "center", fontWeight: "500", fontSize: "1.4rem" }}>
                            Avail service anywhere <br/> anytime
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <div style={{ textAlign: "center" }}>

                        <div >
                            <img src={img3} style={{ width: "70%", height: "100%" }} alt="img" />
                        </div>
                        <Typography variant="p" style={{ textAlign: "center", fontWeight: "500", fontSize: "1.4rem" }}>
                            Complete confidentially of client information
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}
