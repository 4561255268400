import { Button } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './WaitForApproval.css'
import Header from '../../../Shared/Header/Header';
const WaitForApproval = () => {
    return (
        <div>
            <Header />
            <div className='WaitForApproval'>
                <div id="notfound">
                    <div class="notfound">
                        <div class="notfound-404">
                            {/* <h3>Oops! Page not found</h3> */}
                            <h1><span>We are reviewing your details</span></h1>
                        </div>
                        <h2>Thank you for registering!</h2>

                        <NavLink to='/' style={{ textDecoration: 'none', }} ><Button variant='contained' style={{ backgroundColor: '#31C75A', }} sx={{ borderRadius: '25px', px: 3 }} >Go Back</Button></NavLink>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default WaitForApproval;