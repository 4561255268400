import {  LinkedinShareButton, LinkedinIcon } from 'react-share';
const Linkedin = ({ url, quotes, hashtag }) => {
    return (
        <>

            <LinkedinShareButton
                url={url}     //eg. https://www.example.com
                quotes={quotes}  //"Your Quotes"
                // hashtag={hashtag} // #hashTag
            >
                <LinkedinIcon  style={{width: "40px", height: "40px"}}/>
            </LinkedinShareButton>

        </>
    )
}

export default Linkedin;