import { Alert, AlertTitle, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";


export default function SuccessfulSignup({ greet }) {
  const navigate = useNavigate("");

  useEffect(() => {
    setTimeout(() => {
      navigate("/Dashboard");
    }, 1000);
  }, []);

  return (
    <Container>
      <Alert style={{ marginTop: "5rem" }} severity="success">
        <AlertTitle>Congratulations</AlertTitle>
        User {greet} — <strong>successfully!</strong>
      </Alert>
    </Container>
  );
}
