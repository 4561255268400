import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import { Container } from "@mui/system";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import "../SignUp/SignUp.css";
import { useSignup } from "../../hooks/useSignup";
import useToken from "../../hooks/useToken";
import SuccessfulSignupPsychologist from "./SuccessfulSignupPsychologist";
import googleIcon from "./../../assets/images/googleIcon.png";

import Footer from "../../Shared/Footer/Footer";
import { useSignInWithGoogle } from "../../hooks/useSignInWithGoogle";
import styled from "styled-components";
import LoginImg from "../../assets/images/sign_up_boy.svg";
import Paper from "@mui/material/Paper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: #31c75a;
    border-color: #31c75a;
  }
  & .MuiOutlinedInput-root { 
    &.Mui-focused fieldset {
      border-color: #31c75a;
    }
  }
`;

export default function PsychologistSignUp() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Psychologist signup`,
    });
  }, []);
  const [uploaderImage, setUploaderImage] = useState("");
  const [loginData, setLoginData] = useState({});
  let [issue, setIssue] = useState(null);
  const [emailValidate, setEmailValidate] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const navigate = useNavigate("/psychologist-signup");
  const { user } = useAuth();
  const [token] = useToken(user);
  const { signUp, error, isLoading } = useSignup();
  const {
    handleSignInWithGoogle,
    handleSignOutWithGoogle,
    error: googleError,
  } = useSignInWithGoogle("psychologist");

  function CheckName(inputtxt) {
    var name = /^([a-zA-Z0-9\s,'-*]).{0,100}$/;
    if (inputtxt.match(name)) {
      setIssue(false);
      setEmailVerified(true);
      return true;
    } else {
      setIssue("Name length cannot be less than 1 chracter");
      setEmailVerified(false);
      return false;
    }
  }

  function CheckPassword(inputtxt) {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (inputtxt.match(passw)) {
      setIssue(false);
      setEmailVerified(true);
      return true;
    } else {
      setIssue(
        "Please set a strong password(atleast 7 characters, Uppercase, Lowercase, Symbols, Numbers)"
      );
      setEmailVerified(false);
    }
  }

  function ValidateEmail(email) {
    if (
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailValidate(true);
      setEmailVerified(true);
      setIssue(false);
      return true;
    }
    setEmailValidate(false);
    setEmailVerified(false);
    setIssue("Please enter a valid email address");
    return false;
  }

  const handleOnBlur = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();

    if (loginData.password !== loginData.password2) {
      setIssue("Your password did not match");
      return;
    }
    setIssue(null);
    signUp(
      loginData.email,
      loginData.password,
      loginData.name,
      navigate,
      "psychologist"
    );
    e.preventDefault();
  };

  return !user?.email ? (
    <>
      <Header />
      <Container sx={{ my: 15 }}>
        <>
          <h2
            style={{ textAlign: "center" }}
            variant="h4"
            gutterBottom
            component="div"
          >
            Sign Up as a Psychologist
          </h2>
          <hr
            style={{
              display: "block",
              width: "10%",
              height: "3px",
              backgroundColor: "#31C75A",
              border: 0,
            }}
          />
          <Box style={{ marginTop: "2rem" }}>
            <Grid
              container
              spacing={2}
              sx={{
                flexGrow: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* ======>FIRST PART<======== */}
              <Grid item xs={12} md={6}>
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={LoginImg}
                  alt="Contact-img"
                />
              </Grid>
              {/* ======>SECOND PART<======== */}
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={12}
                  style={{ backgroundColor: "#F4F8F5" }}
                  sx={{ textAlign: "center", py: 5, borderRadius: 5 }}
                >
                  <form onSubmit={handleLoginSubmit}>
                    <h2>Sign Up as a Psychologist</h2>
                    <WhiteBorderTextField
                      sx={{ width: "75%", m: 1 }}
                      id="standard-basic"
                      label="Your Name"
                      name="name"
                      type="text"
                      required
                      onChange={(e) => {
                        CheckName(e.target.value);
                        e.target.value.length > 99 &&
                          setIssue(
                            "Maximum length exceeded. Name length cannot be more than 100 chracters"
                          );
                      }}
                      onBlur={handleOnBlur}
                      inputProps={{ maxLength: 100 }}
                      variant="outlined"
                    />

                    <WhiteBorderTextField
                      sx={{ width: "75%", m: 1 }}
                      id="standard-basic"
                      label="Your Email"
                      name="email"
                      type="email"
                      required
                      onChange={(e) => ValidateEmail(e.target.value)}
                      onBlur={handleOnBlur}
                      variant="outlined"
                    />
                    <div className="pass_div">
                      <WhiteBorderTextField
                        sx={{ width: "75%", m: 1 }}
                        id="standard-password-input"
                        label="Your Password"
                        type={showPass ? "text" : "password"}
                        name="password"
                        required
                        onChange={(e) => CheckPassword(e.target.value)}
                        onBlur={handleOnBlur}
                        autoComplete="current-password"
                        variant="outlined"
                      />
                      {showPass ? (
                        <RemoveRedEyeIcon
                          onClick={() => setShowPass(!showPass)}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() => setShowPass(!showPass)}
                        />
                      )}
                    </div>
                    <div className="pass_div">
                      <WhiteBorderTextField
                        sx={{ width: "75%", m: 1 }}
                        id="standard-password-input"
                        label="Re-type Your Password"
                        type={showConfirmPass ? "text" : "password"}
                        name="password2"
                        required
                        onBlur={handleOnBlur}
                        autoComplete="current-password"
                        variant="outlined"
                      />
                      {showConfirmPass ? (
                        <RemoveRedEyeIcon
                          onClick={() => setShowConfirmPass(!showConfirmPass)}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() => setShowConfirmPass(!showConfirmPass)}
                        />
                      )}
                    </div>
                    <br />

                    {!emailVerified || issue ? (
                      <small style={{ color: "red" }}>{issue}</small>
                    ) : (
                      <></>
                    )}
                    <br />
                    {error && <small style={{ color: "red" }}> {error} </small>}
                    <br />

                    {isLoading && (
                      <Button
                        style={{ marginTop: "1rem" }}
                        className="signin-btn"
                        variant="contained"
                      >
                        Processing
                      </Button>
                    )}
                    {!isLoading && (
                      <Button
                        style={{ marginTop: "1rem" }}
                        className="signin-btn"
                        type="submit"
                        variant="contained"
                      >
                        Sign Up
                      </Button>
                    )}

                    <div>
                      <p style={{ margin: "10px 0" }}>
                        Already Registered? Please{" "}
                        <Link
                          to="/psychologist-signin"
                          style={{
                            color: "#31c75a",
                            textTransform: "uppercase",
                          }}
                        >
                          signin
                        </Link>
                      </p>

                      <br />

                      <Link
                        style={{ textDecoration: "none", color: "#000" }}
                        to="/psychologist-signin"
                      >
                        {" "}
                        <Button
                          sx={{
                            color: "#000",
                            borderRadius: "50px",
                            padding: "14px 80px",
                          }}
                          color="inherit"
                          variant="outlined"
                        >
                          SIGN IN
                        </Button>
                      </Link>
                    </div>

                    {!user?.email ? (
                      <div
                        className="google_login"
                        onClick={() => handleSignInWithGoogle("psychologist")}
                      >
                        <img src={googleIcon} alt="icon" />
                        <p>Sign In With Google</p>
                      </div>
                    ) : (
                      <div
                        className="google_login"
                        onClick={() => handleSignOutWithGoogle()}
                      >
                        <img src={googleIcon} alt="icon" />
                        <p>Sign Out From Google</p>
                      </div>
                    )}
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </>
      </Container>
      <Footer />
    </>
  ) : (
    <SuccessfulSignupPsychologist greet="created" />
  );
}
