import { Container, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import SessionImg from "../../assets/images/dashboard/session.png";
import useAuth from "../../hooks/useAuth";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6F1EC",
    color: "#000",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E6F1EC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(workshopTitle, cus_name, card_number, card_type, bank_txn) {
  return { workshopTitle, cus_name, card_number, card_type, bank_txn };
}

const highlightGreen = {
  display: "inline",
  backgroundColor: "#31C75A",
  color: "#fff",
  padding: "10px",
  borderRadius: ".5rem",
};
const highlightYellow = {
  display: "inline",
  backgroundColor: "yellow",
  color: "#000",
  padding: "10px",
  borderRadius: ".5rem",
};

export default function WorkshopUserRegistered() {
  const { user } = useAuth();
  console.log(user?.email);
  const [userWorkShop, setUserWorkShop] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  let today = new Date().toISOString().slice(0, 10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetch(`https://api.thepsycure.com/paidWorkshop`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        let sortedData = data.sort(
          (a, b) => new Date(b.pay_time) - new Date(a.pay_time)
        );
        setUserWorkShop(sortedData);
      });
  }, [user]);

  console.log(userWorkShop);

  const rows = [];

  userWorkShop.forEach((workshop) =>
    rows.push(
      createData(
        workshop?.worskshopTitle,
        workshop?.cus_name,
        workshop?.card_number,

        workshop?.card_type,
        workshop?.bank_txn
      )
    )
  );

  return (
    <>
      <Container>
        <h1 className="title">Workshop History</h1>
        <Grid item xs={12} className="table-container" component={Paper}>
          <TableContainer sx={{ maxHeight: 440, backgroundColor: "#E6F1EC" }}>
            <Table aria-label="customized table">
              <TableHead style={{ marginBottom: "2rem" }}>
                <TableRow style={{ textAlign: "center" }}>
                  {/* <StyledTableCell></StyledTableCell> */}
                  <StyledTableCell>
                    <strong>Workshop Name</strong>
                  </StyledTableCell>
                  {/* <StyledTableCell><strong>Category</strong></StyledTableCell> */}
                  <StyledTableCell>
                    <strong>Registered user name</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Account Number</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Payment Method</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Transaction ID</strong>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <>
                      <StyledTableRow key={row.name}>
                        {/* <StyledTableCell >

                                        </StyledTableCell> */}
                        <StyledTableCell className="table_center">
                          {row?.workshopTitle}
                        </StyledTableCell>
                        {/* <StyledTableCell className='table_center'>{row?.Category?.split(",")[0]}</StyledTableCell> */}
                        <StyledTableCell className="table_center">
                          {row?.cus_name}
                        </StyledTableCell>
                        <StyledTableCell className="table_center">
                          {row?.card_number}
                        </StyledTableCell>

                        <StyledTableCell className="table_center">
                          {row?.card_type}
                        </StyledTableCell>
                        <StyledTableCell className="table_center">
                          {row?.bank_txn}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        <div className="img_container">
          <img src={SessionImg} />
        </div>
      </Container>
      {/* <DashboardFooter /> */}
    </>
  );
}
