import React, { useEffect, useState } from "react";
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
 
} from "agora-rtc-react";
import PersonIcon from "@mui/icons-material/Person";
import { useParams, useNavigate } from "react-router-dom";

import VideocamOffIcon from "@mui/icons-material/VideocamOff";

import VideocamIcon from "@mui/icons-material/Videocam";

import ChatIcon from "@mui/icons-material/Chat";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicOffIcon from "@mui/icons-material/MicOff";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";

import classes from "./VideoPage.module.css";
import { Button } from "@material-ui/core";
import { Grid } from "@mui/material";
import Messaging from "./Messaging";
import useAuth from "../../hooks/useAuth";
import img1 from "../../assets/images/workshop.png";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const config = {
  mode: "rtc",
  codec: "vp8",
};

const appId = "699e8064f15244958ea8a401d6eac09c"; //ENTER APP ID HERE
// const appId = "0a3d17bd06044c6d81116739f0854229"; //ENTER APP ID HERE
const token = null;

const App = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Video page`,
    });
  }, []);
  const [inCall, setInCall] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [session, setSession] = useState({});
  const { user } = useAuth("");

  const [reJoin, setRejoin] = useState(false);

  return (
    <div className={classes.container}>
      {/* <h1 style={{ textAlign: "center" }}>Psycure Session</h1> */}
      {inCall ? (
        <VideoCall
          setInCall={setInCall}
          channelName={channelName}
          inCall={inCall}
          session={session}
          email={user?.email}
          setRejoin={setRejoin}
        />
      ) : (
        <ChannelForm
          setInCall={setInCall}
          setChannelName={setChannelName}
          setSession={setSession}
          session={session}
          reJoin={reJoin}
          setRejoin={setRejoin}
        />
      )}
    </div>
  );
};

// the create methods in the wrapper return a hook
// the create method should be called outside the parent component
// this hook can be used the get the client/stream in any component
const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const VideoCall = (props) => {
  const { setInCall, channelName, inCall, email, session, setRejoin } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate("");
  const [isLoading, setIsLoading] = useState(false);

  const [remoteuserVideo, setRemoteUserVideo] = useState(false);
  const [remoteuserAudio, setRemoteUserAudio] = useState(true);

  const [msgForVideo, setMsgForVideo] = useState("");
  const [msgForAudio, setMsgForAudio] = useState("");

  // using the hook to get access to the client object
  const client = useClient();

  // ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const handleShow = () => {
    setShowMessage(!showMessage);
  };

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name) => {
      setIsLoading(true);

      console.log("users", users);

      client.on("user-published", async (user, mediaType) => {
        if (users.length < 1) {
          await client.subscribe(user, mediaType);
          if (mediaType === "video") {
            setUsers((prevUsers) => {
              return [...prevUsers, user];
            });
          }
          if (mediaType === "audio") {
            user.audioTrack?.play();
          }
        }
      });

      client.on("user-unpublished", (user, type) => {
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      client.on("user-left", (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
        // client.leave();
        // client.removeAllListeners();

        // we close the tracks to perform cleanup
        // tracks[0].close();
        // tracks[1].close();
        // setRejoin(true);
        // navigate(`/Dashboard/review/${session._id}`);
      });

      await client.join(appId, name, token, null);

      // await client.
      // tracks[1].setEnabled(false)
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setIsLoading(false);
      setStart(true);
    };

    if (ready && tracks) {
      init(channelName);
    }
  }, [channelName, client, ready, tracks]);

  return isLoading ? (
    <div className={classes.video_container_double_blank}></div>
  ) : (
    <div className={classes.video_container_double}>
      {start && tracks && (
        <Videos
          remoteuserAudio={remoteuserAudio}
          remoteuserVideo={remoteuserVideo}
          users={users}
          tracks={tracks}
          showMessage={showMessage}
          totalUser={client?._users}
        />
      )}
      {ready && tracks && (
        <Controls
          setMsgForAudio={setMsgForAudio}
          setMsgForVideo={setMsgForVideo}
          msgForVideo={msgForVideo}
          tracks={tracks}
          users={users}
          setStart={setStart}
          setInCall={setInCall}
          setShowMessage={handleShow}
          showMessage={showMessage}
          session={session}
          setUsers={setUsers}
        />
      )}
      {start && tracks && (
        <Messaging
          setRemoteUserAudio={setRemoteUserAudio}
          setRemoteUserVideo={setRemoteUserVideo}
          setMsgForVideo={setMsgForVideo}
          msgForVideo={msgForVideo}
          channelName={channelName}
          users={users}
          inCall={inCall}
          email={email}
          showMessage={showMessage}
        />
      )}
    </div>
  );
};

const Videos = (props) => {
  const { totalUser, users, tracks, showMessage } = props;
  let remoteUi = null;

  console.log("tracks", tracks);

  if (totalUser?.length <= 0) {
    remoteUi = <div className={classes.vid}>No one joined yet</div>;
  } else if (totalUser?.length > 0 && users.length > 0) {
    remoteUi = (
      <AgoraVideoPlayer
        className={classes.vid}
        videoTrack={users[0]?.videoTrack}
        key={users[0]?.uid}
      />
    );
  } else if (totalUser?.length > 0 && users.length <= 0) {
    remoteUi = (
      <div className={classes.vid}>
        <PersonIcon style={{ width: "100px", height: "100px" }} />
      </div>
    );
  }
  return (
    <div className={!showMessage ? classes.relative : classes.relative_two}>
      <Grid id="videos" className={classes.videos} spacing={3} container>
        <Grid
          className={classes.video}
          justifyContent="center"
          item
          md={12}
          xs={12}
        >
          {remoteUi}
        </Grid>
      </Grid>
      <div className={classes.patient}>
        <AgoraVideoPlayer className={classes.myself} videoTrack={tracks[1]} />
      </div>
    </div>
  );
};

export const Controls = (props) => {
  const client = useClient();
  const {
    tracks,
    users,
    setStart,
    setInCall,
    setShowMessage,
    showMessage,
    session,
    setMsgForVideo,
    msgForVideo,
    setMsgForAudio,
    setUsers,
  } = props;
  const [trackState, setTrackState] = useState({ video: false, audio: true });
  const navigate = useNavigate("");

  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      if (trackState.audio) {
        setMsgForAudio("PERMISSION_AUDIO_DISABLE");
      }
      if (!trackState.video) {
        setMsgForAudio("PERMISSION_AUDIO_ENABLE");
      }
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      // tracks[1].video.disable = false;

      if (trackState.video) {
        setMsgForVideo("PERMISSION_VIDEO_DISABLE");
      }
      if (!trackState.video) {
        setMsgForVideo("PERMISSION_VIDEO_ENABLE");
      }
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  useEffect(() => {
    mute("video");
    // if (tracks[1]?._id) {
    // tracks[1].setEnabled(!trackState.video)
    // setTrackState({ video: false, audio: true })
    // }
  }, []);

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();

    // we close the tracks to perform cleanup
    tracks[0].close();
    tracks[1].close();
    setTrackState({ video: false, audio: false });
    setStart(false);
    setInCall(false);
    window.location.reload();

    // navigate(`/Dashboard/review/${session._id}`);
  };

  return (
    <>
      <div style={{ margin: "0", padding: "0", display: "none" }}>
        <Header />
      </div>

      <div className={classes.controls}>
        <p
          className={trackState.audio ? "on" : ""}
          style={{ backgroundColor: !trackState.audio ? "#EA5044" : "" }}
          onClick={() => mute("audio")}
        >
          {trackState.audio ? <KeyboardVoiceIcon /> : <MicOffIcon />}
        </p>
        <p
          className={trackState.video ? "on" : ""}
          style={{ backgroundColor: !trackState.video ? "#EA5044" : "" }}
          onClick={() => mute("video")}
        >
          {trackState.video ? <VideocamIcon /> : <VideocamOffIcon />}
        </p>
        <p
          onClick={setShowMessage}
          style={{ backgroundColor: showMessage ? "#31C75A" : "" }}
        >
          {showMessage ? <ModeCommentIcon /> : <ChatIcon />}
        </p>
        {
          <p
            onClick={() => leaveChannel()}
            style={{ backgroundColor: "#EA5044" }}
          >
            <CallEndIcon />
          </p>
        }
      </div>
    </>
  );
};

const ChannelForm = (props) => {
  const { id } = useParams({});
  const { setInCall, setChannelName, setSession, session, reJoin, setRejoin } =
    props;
  const navigate = useNavigate();

  localStorage.setItem("sessionId", id);

  useEffect(() => {
    fetch(`https://api.thepsycure.com/session`)
      .then((res) => res.json())
      .then((result) => {
        const currentSession = result.find((data) => data._id === id);
        setSession(currentSession);
      });
  }, []);

  setChannelName(id);

  /* Link validation according to Date-time */

  const timeConverter = (time) => {
    let hours = time.split(":")[0];
    let minutes = time.split(":")[1];
    let meridian;
    if (hours > 12) {
      meridian = "PM";
      // hours -= 12;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
    } else {
      meridian = "PM";
    }

    return time.concat(" ", meridian);
  };

  const startTimeWithBuffer = (time) => {
    let hours = Number(time?.split(":")[0]);
    let minutes = Number(time?.split(":")[1]?.split(" ")[0]);

    // Minute minutes buffer time Start
    if (minutes < 10 && minutes > 0) {
      hours = Number(hours) - 1;
      minutes = 50;
    } else {
      minutes = Number(minutes) - 10;
    }
    // Minute minutes buffer time end

    let meridian;
    if (hours > 12) {
      meridian = "PM";
    } else if (hours < 12) {
      meridian = "AM";
    } else {
      meridian = "PM";
    }

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return hours + ":" + minutes + " " + meridian;
  };

  const today = new Date().toISOString().slice(0, 10);
  var now = new Date();
  var time = now.getHours() + ":" + now.getMinutes();

  var currentTime = timeConverter(time);

  const verified = session?.date === today;
  const verifiedPassed = session?.date < today;

  const verifiedTime =
    startTimeWithBuffer(session?.startTime) <= currentTime &&
    session?.endTime >= currentTime;

  const reviewDateConverter = (sessionDate) => {
    let day = Number(sessionDate?.split("-")[2]);
    let month = Number(sessionDate?.split("-")[1]);
    let year = Number(sessionDate?.split("-")[0]);

    if (day === 30 || day === 31) {
      day = 1;
      if (month !== 12) {
        month += 1;
      } else {
        month = 1;
        year = year + 1;
      }
    } else {
      day += 1;
    }

    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    console.log(
      "testing",
      reviewDateConverter(session?.date) >= session?.date &&
        session?.endTime < currentTime,
      session?.date,
      (session?.date && reviewDateConverter(session?.date) == today) ||
        (session?.date &&
          reviewDateConverter(session?.date) >= session?.date &&
          session?.endTime < currentTime)
    );

    if (
      (session?.date && reviewDateConverter(session?.date) == today) ||
      (session?.date &&
        reviewDateConverter(session?.date) >= session?.date &&
        session?.endTime < currentTime)
    ) {
      navigate(`/Dashboard/review/${session._id}`);
    }
  }, [verifiedPassed, session]);

  return (
    <>
      <Header />
      <form className="join" style={{ textAlign: "center", padding: "50px 0" }}>
        <h4>Join the session to consult with your booked psychologist</h4>
        {verified && verifiedTime ? (
          <>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setInCall(true);
              }}
              style={{
                backgroundColor: "#31C75A",
                marginTop: "1rem",
                padding: "10px 20px",
                borderRadius: "10px",
                color: "white",
                textAlign: "center",
              }}
            >
              Join
            </Button>
          </>
        ) : verifiedPassed ? (
          <>
            <Button
              style={{
                backgroundColor: "#31C75A",
                marginTop: "1rem",
                padding: "10px 20px",
                borderRadius: "10px",
                color: "white",
                textAlign: "center",
              }}
            >
              Your session has been completed
            </Button>
            <div className="img_container">
              <img src={img1} />
            </div>
          </>
        ) : (
          <>
            <Button
              style={{
                backgroundColor: "#31C75A",
                marginTop: "1rem",
                padding: "10px 20px",
                borderRadius: "10px",
                color: "white",
                textAlign: "center",
              }}
            >
              Join button will be visible in your session time
            </Button>
            <div className="img_container">
              <img src={img1} />
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default App;
