import { Box, Modal } from "@mui/material";
import React from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "60%",
};
const mediaItems = [
  {
    id: 1,
    title: "‘Unwind Mind’: Capturing the many shades of mental health",
    description: "Pyscure’s mental health awareness exhibition wraps up today",
    type: "news",
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Fdaily%20star%20image.jpg?alt=media",
    link: "https://www.thedailystar.net/entertainment/theatre-arts/news/unwind-mind-capturing-the-many-shades-mental-health-3124726",
    logo: "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Fdaily%20star%20logo.svg?alt=media&token=dea3c349-06ef-4325-a464-923af5f9c7d8",
    height: 100,
    width: 300,
  },
  {
    id: 2,
    title: "মানসিক স্বাস্থ্য সচেতনতায় শিল্প প্রদর্শনী | Unwind Mind 2022",
    description:
      "নিজের সঙ্গে নিজের দ্বন্ধ শুরু হয়। ফলে, মনের ভারসাম্য হারানো, জীবনের ছন্দপতন বিপর‌্যস্ত করে দেয় মানুষকে। সে সব নিয়েই সাইকিওর এর প্রদর্শনী।",
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Funwind%20mind%202022.jpg?alt=media",
    link: "#",
    type: "video",
    logo: "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Ftbs%20logo.svg?alt=media&token=36df6684-e973-4dad-87e4-a2c88e671c24",
    height: 100,
    width: 150,
  },
];

const MediaShowcase = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="media-showcase-container">
      <h2 className="showcase-title">Featured Media Coverage</h2>
      <div className="media-grid">
        {mediaItems.map((item) => (
          <div className="media-item" key={item.id}>
            <div
              className="media-img"
              style={{ backgroundImage: `url(${item.imgUrl})` }}
            ></div>
            <div className="media-info">
              <h3>{item.title}</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h4>Covered by:</h4>
                <img
                  src={item?.logo}
                  alt=""
                  height={item?.height}
                  width={item?.width}
                  className="media-logo"
                />
              </div>
              <p>{item.description}</p>
              {item?.type == "news" && (
                <a
                  href={item.link}
                  rel="noreferrer"
                  target="_blank"
                  className="media-link"
                >
                  Read more
                </a>
              )}
              {item?.type == "video" && (
                <a className="media-link" onClick={handleOpen}>
                  View
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/4mqlANx2haM?si=3BpQX-hklCrR_bPM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Box>
      </Modal>
    </div>
  );
};

export default MediaShowcase;
