import React, { useState } from "react";

import { signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import useAuth from "./useAuth";
import { auth } from "../firebase.config";
import { useNavigate } from "react-router-dom";

export const useSignInWithGoogle = (user, patientInfo) => {
  let [error, setError] = useState(false);
  const { dispatch } = useAuth();
  const navigate = useNavigate("");

  const handleSignInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    setError(false);
    signInWithPopup(auth, provider)
      .then((result) => {
        if (
          result?.user?.metadata?.creationTime ===
            result?.user?.metadata?.lastSignInTime &&
          user !== "patient"
        ) {
          let userDetails = {
            name: result?.user?.displayName,
            email: result?.user?.email,
            patientInfo: null,
            role: user === "psychologist" ? "psychologist" : "bloger",
            createdAt: new Date().toISOString().slice(0, 10),
          };

          if (userDetails?.role) {
            fetch(`https://api.thepsycure.com/users`, {
              method: "POST",
              body: JSON.stringify(userDetails),
              headers: {
                "Content-Type": "application/json",
              },
            })
              .then((res) => res.json())
              .then((data) => {
                let d = data;
              });
          }
        }

        // result?.user?.email
        dispatch({
          type: "LOGIN",
          payload: {
            ...result.user,
            type:
              user === "patient"
                ? "patient"
                : user === "psychologist"
                ? "psychologist"
                : "bloger",
          },
        });
        if (user === "patient") {
          navigate("/signup");
        }
        if (user === "psychologist") {
          navigate("/psychologist-signup");
        }
        if (user === "bloger") {
          navigate("/bloger-signup");
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const handleSignOutWithGoogle = () => {
    signOut(auth)
      .then(() => {
        dispatch({ type: "LOGOUT" });
      })
      .catch((err) => {
        let error = "Your email or password was incorrect";
      });
  };

  return { error, handleSignInWithGoogle, handleSignOutWithGoogle };
};
