import {
  Box,
  Button,
  Container,
  Grid,
  
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import {
  
  FormControl,
  
  Rating,
 
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import "./BookSession.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import useAuth from "../../hooks/useAuth";
import Footer from "../../Shared/Footer/Footer";
import SuccessAlert from "../../Shared/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../Shared/ErrorAlert/ErrorAlert";
import spinner from "../../assets/images/spinner-loop.gif";
import PickersDay, {

  pickersDayClasses,
} from "@mui/lab/PickersDay";
import style from "./styles.module.css";
import { bookSessionPageEvent } from "../../utils/FacebookPixelEvent";
import Header from "../../Shared/Header/Header";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import verifiedImage from "../../assets/images/verified.png";
import PaySecondPage from "../Payment/PaySecondPage/PaySecondPage";
import ReactGA from "react-ga4";

const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
  return (
    <PickersDay
      {...pickersDayProps}
      sx={{
        [`&&.${pickersDayClasses.selected}`]: {
          backgroundColor: "rgb(23, 216, 136)",
          borderRadius: "50%",
          border: "1px solid #fff",
          color: "#fff !important",
        },
      }}
    />
  );
};

//   MuiButtonBase-root MuiPickersDay-root MuiPickersDay-dayWithMargin css-m42gyj-MuiButtonBase-root-MuiPickersDay-root

const BookSession = () => {
  // the value of the search field
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Book session`,
    });
  }, []);
  const [name, setName] = useState("");
  const [psychologists, setPsychologists] = useState([]);
  const [foundResults, setFoundResults] = useState(psychologists);
  const [selectedPsychologists, setSelectedPsychologists] = useState("");
  const [value, setValue] = React.useState("");
  const [selectedStartTime, setSelectedStartTime] = React.useState("");
  const [selectedEndTime, setSelectedEndTime] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [date, setDate] = useState("");
  const [allSession, setAllSession] = useState("");
  const [userDetail, setUserDetail] = useState({});
  const [availability, setAvailability] = useState();
  const [checkingDate, setCheckingDate] = useState();
  const [category, setCategory] = useState({
    category: "",
    counselingType: "",
  });

  // const classes = useStyles();
  const [item, setParsedItem] = useState();
  let [error, setError] = useState(null);
  let [success, setSuccess] = useState("");
  const { user } = useAuth();

  const bookingEndRef = useRef(null);

  const { height, width } = useWindowDimensions();

  const scrollToBottom = () => {
    bookingEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedStartTime, value]);

  const { paramCategory } = useParams();

  let navigate = useNavigate();

  useLayoutEffect(() => {
    function updateScreen(time) {
      // Make visual updates here.
      if (psychologists.length === 0) {
        setIsLoading(true);
        fetch("https://api.thepsycure.com/psychologists")
          .then((res) => res.json())
          .then((data) => {
            const approvedPsychologist = data
              ?.filter((psychologist) => psychologist?.approved === true)
              .sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));
            setPsychologists(approvedPsychologist);
            setFoundResults(approvedPsychologist);
            setIsLoading(false);
          });
      }
    }

    requestAnimationFrame(updateScreen);
  }, [psychologists]);

  /* ======== Filter by Categories ====== */

  // the search result
  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = psychologists
        ?.filter((psychologist) => {
          return (
            psychologist.name.toLowerCase().includes(keyword.toLowerCase()) &&
            psychologist?.approved
          );
          // Use the toLowerCase() method to make it case-insensitive
        })
        .sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));
      setFoundResults(results);
    } else {
      let sorted = psychologists
        ?.filter((psychologist) => psychologist?.approved === true)
        ?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));
      setFoundResults(sorted);
      // If the text field is empty, show all users
    }

    setName(keyword);
  };

  const handleSelectValue = (selectedPsychologists) => {
    setSelectedPsychologists(selectedPsychologists);
  };

  /* =======Save Session URL======== */
  // const handleLinkChange = (e) => {
  //     setLink({ [e.target.name]: e.target.value });
  // }

  useEffect(() => {
    bookSessionPageEvent();
  }, []);

  useEffect(() => {
    fetch("https://api.thepsycure.com/session")
      .then((res) => res.json())
      .then((data) => {
        const filteredByDate = data.filter((d) => d.date == date.slice(0, 10));

        setAllSession(filteredByDate);
      });
  }, [date]);

  let [weeklyLoading, setWeeklyLoading] = useState(false);

  useEffect(() => {
    async function finding() {
      setWeeklyLoading(true);
      let result = [];
      for (let i = 0; i < foundResults.length; i++) {
        let psychologist = foundResults[i];
        let response = await fetch(
          `https://api.thepsycure.com/doctorSession/${psychologist?.email}`
        );
        let data = await response.json();
        let filtered = data?.filter((d) => d.date >= checkingDate);

        if (
          psychologist?.availableDateTimes?.map((avl) => avl.times).flat()
            .length > filtered.length
        ) {
          result.push(psychologist);
        }
      }
      return result;
    }

    if (availability == 7) {
      finding().then((data) => {
        let sorted = data
          .filter((psychologist) => psychologist?.approved === true)
          .sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));
        setFoundResults(sorted);
        setWeeklyLoading(false);
      });
    }
  }, [availability]);

  const handleValue = (newValue) => {
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const selectedDate = new Date(newValue.toISOString().slice(0, 10));
    setValue(weekday[selectedDate.getDay()]);
    setDate(newValue.toISOString());
  
    setSelectedStartTime("");
    setSelectedEndTime("");
  };

  let psychologistArr = psychologists
    .map((psychologist) => psychologist?.category)
    .flat();
  const uniqueCategories = psychologistArr.filter(
    (v, i, a) => a.indexOf(v) === i
  );

  let psychologistCouselingTypes = psychologists
    .map((psychologist) => psychologist?.counselingType)
    .flat();
  const uniqueCounselingTypes = psychologistCouselingTypes.filter(
    (v, i, a) => a.indexOf(v) === i
  );

  const weekday = [0, 1, 2, 3, 4, 5, 6];
  const availableDate = selectedPsychologists?.availableDateTimes?.map(
    (times) => {
      if (times.date === "Sunday") {
        return 0;
      } else if (times.date === "Monday") {
        return 1;
      } else if (times.date === "Tuesday") {
        return 2;
      } else if (times.date === "Wednesday") {
        return 3;
      } else if (times.date === "Thursday") {
        return 4;
      } else if (times.date === "Friday") {
        return 5;
      } else if (times.date === "Saturday") {
        return 6;
      }
    }
  );
  const unAvailableDate = weekday.filter(
    (day) => !availableDate?.includes(day)
  );

  function isWeekendDay(date) {
    return unAvailableDate.includes(date.getDay());

    // let style = {};
    // if (unAvailableDate.includes(date.getDay())) {
    //     style = { backgroundColor: '#f0f8ff' };
    // }
    // return <div style={style}>{date.day}</div>;
  }

  const filteredSession = allSession.length
    ? allSession?.filter(
        (session) =>
          session?.selectedPsychologists?.email === selectedPsychologists?.email
      )
    : [];

  const { displayName, email, uid, emailVerified, createdAt, apiKey } = user;

  useEffect(() => {
    fetch("https://api.thepsycure.com/users")
      .then((res) => res.json())
      .then((users) => {
        const actualUserDetail = users.filter((user) => user?.email == email);

        setUserDetail(actualUserDetail[0]);
      });
  }, [email]);

  const handleSubmit = async (e) => {
    let sessionDetails = {
      patientEmail: email,
      docEmail: selectedPsychologists?.email,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      date: date.slice(0, 10),
      day: value,
      status: "incomplete",
      selectedPsychologists,
      userDetails: userDetail,
    };

    localStorage.setItem("selectedDetails", JSON.stringify(sessionDetails));

    navigate("/payment-next");
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
      setError(null);
    }, 3000);
  }, [success, error]);

  const handleSelectedTime = (time) => {
    setSelectedStartTime(time.startTime);
    setSelectedEndTime(time.endTime);
    let sessionDetails = {
      patientEmail: user?.email,
      docEmail: selectedPsychologists?.email,
      startTime: time.startTime,
      endTime: time.endTime,
      date: date.slice(0, 10),
      day: value,
      status: "incomplete",
      selectedPsychologists,
      userDetails: userDetail,
    };

    localStorage.setItem("selectedDetails", JSON.stringify(sessionDetails));
    setParsedItem(JSON.parse(localStorage?.getItem("selectedDetails")));
  };

  function renderDayInPicker(date) {
    return availableDate.includes(date.getDay());
  }

  const handleChange = (e) => {
    const selectCategory = e.target.value;
    setCategory({ ...category, category: selectCategory });
  };

  const handleCounselingChange = (e) => {
    const selectCounseling = e.target.value;
    setCategory({ ...category, counselingType: selectCounseling });
  };

  useEffect(() => {
    const fetchByCategory = async (e) => {
      let response = await fetch(
        `https://api.thepsycure.com/psychologists/filter`,
        {
          method: "POST",
          body: JSON.stringify(category),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (!response.ok) {
      }
      if (response.ok) {
        let sorted = json
          ?.filter((psychologist) => psychologist?.approved === true)
          ?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));
        setFoundResults(sorted);
      }
    };
    fetchByCategory();
  }, [category]);

  useEffect(() => {
    if (paramCategory) {
      setTimeout(() => {
        setCategory({ ...category, category: paramCategory });
      }, 1000);
    }
  }, [paramCategory]);

  // https://api.thepsycure.com/doctorSession/xyz10@gmail.com

  const values = foundResults?.map((psychologist) => {
    let totalReview, likedReview;
    totalReview = psychologist.reviews.length;
    likedReview = psychologist.reviews?.filter(
      (review) => review.rating === "like"
    ).length;

    return { totalReview, likedReview };
  });
  const [timeSelectionButton, setTimeSelectionButton] = useState(false);

  const { pathname } = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const mediaQuery = window.matchMedia("(max-width:768px)");
  // Function to handle media query changes
  const handleMediaQueryChange = (event) => {
    setIsMobile(event.matches);
  };

  // Attach event listener for media query changes
  useEffect(() => {
    mediaQuery.addListener(handleMediaQueryChange);

    // Initial check for the media query
    setIsMobile(mediaQuery.matches);

    // Cleanup the event listener when the component unmounts
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, [mediaQuery]);
  return (
    <>
      {pathname !== "/Dashboard/choose-your-psychologist" ? <Header /> : null}

      <div style={{ marginBottom: "7rem" }}>
        <Container sx={{ flexGrow: 1 }}>
          <Box className="SelectPsychotherapist">
            <h2
              style={{ textAlign: "center", marginTop: "30px" }}
              variant="h4"
              gutterBottom
              component="div"
            >
              Select Psychologist
            </h2>
            <hr
              style={{
                display: "block",
                width: "10%",
                height: "3px",
                backgroundColor: "#31C75A",
                border: 0,
                marginBottom: "50px",
              }}
            />
          </Box>
          <div className="searching_container">
            <div>
              <Box style={{ textAlign: "center", widht: "100%" }}>
                {/* <p>Search by Name</p> */}
                <input
                  value={name}
                  onChange={filter}
                  type="Search"
                  placeholder="Search by Name"
                  style={{
                    width: "90%",
                    borderRadius: "25px",
                    padding: "10px 15px",
                    outline: "none",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
              </Box>
            </div>
            <div className="category_filter">
              <FormControl fullWidth>
                <div className="flex_center">
                  <p>Sort by Category</p>
                  <select
                    className="select"
                    value={category?.category}
                    onChange={handleChange}
                  >
                    <option value="all">All</option>
                    {uniqueCategories?.map(
                      (categories) =>
                        categories && (
                          <option
                            value={categories}
                            style={{ textTransform: "capitalize" }}
                          >
                            {categories}
                          </option>
                        )
                    )}
                  </select>
                </div>
              </FormControl>
            </div>
            <div className="category_filter">
              <FormControl fullWidth>
                <div className="flex_center">
                  <p>Sort by Time</p>
                  <select
                    className="select"
                    value={availability}
                    onChange={(e) => {
                      setAvailability(e.target.value);
                      setCheckingDate(new Date().toISOString().slice(0, 10));
                    }}
                  >
                    <option value="">All</option>
                    <option value="7">weekly</option>
                  </select>
                </div>
              </FormControl>
            </div>
            <div className="category_filter">
              <FormControl fullWidth style={{ display: "flex" }}>
                <div className="flex_center">
                  <p>Sort by Price</p>
                  <select
                    className="select"
                    value={category?.counselingType}
                    onChange={handleCounselingChange}
                  >
                    <option value="all">All</option>
                    {uniqueCounselingTypes?.map(
                      (counseling) =>
                        counseling && (
                          <option
                            value={counseling}
                            style={{ textTransform: "capitalize" }}
                          >
                            {counseling}
                          </option>
                        )
                    )}
                  </select>
                </div>
              </FormControl>
            </div>
          </div>
          {/* card section  */}

          {(isLoading || weeklyLoading) && (
            <div
              style={{
                height: "40vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={spinner}
                alt="spinner"
                style={{ width: "500px", height: "400px" }}
              />
            </div>
          )}

          <div className="all_psychologist">
            {foundResults.map((psychologist, index) => (
              <Link
                style={{ textDecoration: "none" }}
                to={
                  pathname == "/Dashboard/choose-your-psychologist"
                    ? `/Dashboard/choose-your-psychologist/${psychologist.name
                        .split(" ")
                        .join("-")}`
                    : `/psychologists/details/${psychologist.name
                        .split(" ")
                        .join("-")}`
                }
              >
                <div className="group-37417">
                  <div
                    className="rectangle-59"
                    style={{
                      padding: "0px",
                      height: " 440px",
                    }}
                  >
                    <div
                      className="image-main"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="psychologist-image-square"
                        style={{
                          /*  display: "flex",
                      justifyContent: "center", */
                          marginTop: "10px",
                          height: "151px",
                          width: "151px",
                          borderRadius: "75.5px",

                          /* top: "230px",
                      left: "121px",
                      /* backgroundImage: `url(${psychologist?.image})`,
                      backgroundSize: "cover", */
                          // backgroundRepeat: "cover",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "75.5px",
                            width: "100%",
                            height: "100%",
                            // position: "relative",
                          }}
                          src={psychologist?.image}
                          alt=""
                          srcset=""
                        />
                        <img
                          className="verified-image"
                          src={verifiedImage}
                          style={{
                            height: "44px",
                            width: "44px",
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <p className="psychologist-name">{psychologist?.name}</p>
                    </div>
                    <div
                      className="review"
                      style={{
                        marginTop: "2px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Rating
                        className="rating-system"
                        size="large"
                        name="read-only"
                        value={
                          (((values[index].likedReview /
                            values[index].totalReview) *
                            100) /
                            100) *
                            5 ==
                          5
                            ? 5
                            : 4.5
                        }
                        sx={{
                          color: "black",
                        }}
                        precision={0.5}
                        readOnly
                      />
                    </div>
                    <div className="pricing">
                      <p className="pricing-text">{psychologist?.amount}৳</p>
                    </div>
                    <div className="details">
                      <div className="details-text">
                        {psychologist?.yourself.slice(0, 10)}
                        <span className="faded-text">
                          {psychologist?.yourself.slice(10, 70)}
                        </span>
                      </div>
                    </div>
                    <div className="group-btn">
                      <HashLink
                        to="#BookSession"
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="booking-button-custom"
                          // variant="contained"
                          onClick={() => handleSelectValue(psychologist)}
                        >
                          <p> Book Session</p>
                        </div>
                      </HashLink>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          {width > 996 ? <div ref={bookingEndRef}></div> : null}

          {/* calender  */}

          {selectedPsychologists ? (
            <>
              <Box id="BookSession" sx={{ paddingTop: "5rem" }}>
                <h2
                  style={{ textAlign: "center" }}
                  variant="h4"
                  gutterBottom
                  component="div"
                >
                  Book Session
                </h2>
                <hr
                  style={{
                    display: "block",
                    width: "10%",
                    height: "3px",
                    backgroundColor: "#31C75A",
                    border: 0,
                  }}
                />
              </Box>

              {/* {
                        selectedTime.length ? <form style={{ textAlign: 'center' }}>
                            <h4>Save your session URL</h4>
                            <span>Session URL: </span>
                            <input
                                style={{ width: '40%', padding: '10px 20px', borderRadius: '15px', outline: 'none', border: '1px solid green' }}
                                type="text" name="link"
                                defaultValue={link?.link}
                                disabled
                            />

                        </form> : <></>
                    } */}
              {/* <Grid container spacing={2} style={{ marginTop: '2rem', display: 'flex', justifyContent: 'center' }} > */}
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ margin: ".5rem" }}
                  className={style.outlinedToday}
                >
                  <Box
                    style={{
                      boxShadow:
                        "0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)",
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}
                    sx={{
                      alignItems: "center",
                      borderRadius: "30px",
                      height: "100%",
                    }}
                    ref={
                      value && !selectedStartTime && width < 996
                        ? bookingEndRef
                        : null
                    }
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        openTo="day"
                        // disabled
                        minDate={new Date()}
                        shouldDisableDate={isWeekendDay}
                        renderDay={renderWeekPickerDay}
                        // value={value}
                        onChange={handleValue}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>
                </div>
                {value && !selectedStartTime && width < 996 ? (
                  <div ref={bookingEndRef}></div>
                ) : null}

                <div style={{ margin: ".5rem" }}>
                  <Box
                    style={{ textAlign: "center", borderRadius: "15px" }}
                    sx={{ alignItems: "center" }}
                  >
                    {value && (
                      <Box>
                        {/* {
                                            today > value && <Alert severity="warning">Check today's later dates!</Alert>
                                        } */}

                        {selectedPsychologists?.availableDateTimes?.map(
                          (pd) =>
                            pd.date == value && (
                              <>
                                <div className="time-button-parent">
                                  <div
                                    className="time-button"
                                    onClick={() =>
                                      setTimeSelectionButton(
                                        !timeSelectionButton
                                      )
                                    }
                                  >
                                    <button>Time</button>
                                    {timeSelectionButton ? (
                                      <span>⯆</span>
                                    ) : (
                                      <span>⯅</span>
                                    )}
                                  </div>
                                </div>
                                <div className="custom-box-parent">
                                  <div
                                    className="custom-box"
                                    style={{
                                      display: timeSelectionButton
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    {selectedPsychologists?.availableDateTimes.map(
                                      (adt) =>
                                        adt.date === value &&
                                        adt.times.map((time) =>
                                          // checking filtered session date and time matching or not
                                          // if matching this means already booked this session
                                          (filteredSession[0]?.startTime ==
                                            time?.startTime &&
                                            filteredSession[0]?.endTime ==
                                              time?.endTime) ||
                                          (filteredSession[1]?.startTime ==
                                            time?.startTime &&
                                            filteredSession[1]?.endTime ==
                                              time?.endTime) ||
                                          (filteredSession[2]?.startTime ==
                                            time?.startTime &&
                                            filteredSession[2]?.endTime ==
                                              time?.endTime) ||
                                          (filteredSession[3]?.startTime ==
                                            time?.startTime &&
                                            filteredSession[3]?.endTime ==
                                              time?.endTime) ? (
                                            <div style={{ padding: "10px 0" }}>
                                              <Button
                                                style={{
                                                  background: "#31C75A",
                                                  color: "#fff",
                                                }}
                                              >
                                                Already Booked
                                              </Button>
                                            </div>
                                          ) : (
                                            <Box>
                                              <Button
                                                onClick={() =>
                                                  handleSelectedTime(time)
                                                }
                                                className="select-time"
                                                style={{
                                                  width: "90%",
                                                  margin: ".5rem",
                                                  fontSize: "14px",
                                                  background:
                                                    time.startTime ===
                                                    selectedStartTime
                                                      ? "#36CA5A"
                                                      : "transparent",
                                                  color:
                                                    time.startTime ===
                                                    selectedStartTime
                                                      ? "#fff"
                                                      : "#F2F2F2",
                                                }}
                                                variant="contained"
                                                color="success"
                                              >
                                                {time.startTime} -{" "}
                                                {time.endTime}
                                              </Button>
                                            </Box>
                                          )
                                        )
                                    )}
                                  </div>
                                </div>
                              </>
                            )
                        )}
                      </Box>
                    )}
                  </Box>
                </div>
                {value && selectedStartTime && width < 996 ? (
                  <div ref={bookingEndRef}></div>
                ) : null}
              </Grid>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "2rem 0 5rem 0",
                }}
              >
                {selectedPsychologists?.availableDateTimes?.map(
                  (qtm) =>
                    qtm.date == value && (
                      <>
                        {value && selectedStartTime && (
                          <PaySecondPage
                            selectedStartTime={selectedStartTime}
                            item={item}
                            isMobile={isMobile}
                          ></PaySecondPage>
                        )}
                      </>
                    )
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {success ? (
            <SuccessAlert>Successfully Booked your session.</SuccessAlert>
          ) : (
            ""
          )}
          {error ? <ErrorAlert>{error}</ErrorAlert> : ""}
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default BookSession;
