import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect } from "react";
import Footer from "../../Shared/Footer/Footer";
import Murad_ansary from "../../assets/images/Teams/Murad_ansary.png";
import Fabia_Fairoze from "../../assets/images/Teams/Fabia_Fairoze.png";
import Mahin_Abrar_Rahman from "../../assets/images/Teams/Mahin_Abrar_Rahman.png";
import arik_Aziz from "../../assets/images/Teams/arik_Aziz.png";
import Barrister_Quazi_Maruful_Alam from "../../assets/images/Teams/Barrister_Quazi_Maruful_Alam.png";
import anik from "../../assets/images/Teams/anik.jpg";
import Mahjabeen_Sarwaar_Chowdhury from "../../assets/images/Teams/Mahjabeen_Sarwaar_Chowdhury.png";
import tanim from "../../assets/images/Teams/Tanim-web.png";
import Asim_Nabil from "../../assets/images/Teams/Asim_Nabil.png";
import Asifa_Hossain from "../../assets/images/Teams/asifa.jpg";
import Manal_Mahbub_Khan from "../../assets/images/Teams/Manal_Mahbub_Khan.png";
import Atiya_Sadaf from "../../assets/images/Teams/Atiya_Sadaf.png";
import Raisha_Mehzabeen from "../../assets/images/Teams/Raisha Mehzabeen.png";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const teamData = [
  {
    id: 1,
    name: "Murad Ansary",
    designation: "Co-Founder & CEO",
    img: Murad_ansary,
  },
  {
    id: 2,
    name: "Fabia Firoz",
    designation: "Chairperson",
    img: Fabia_Fairoze,
  },
  {
    id: 3,
    name: "Mahin Abrar Rahman",
    designation: "Co-Founder​",
    img: Mahin_Abrar_Rahman,
  },
  {
    id: 4,
    name: "Tarik Aziz",
    designation: "Co-Founder",
    img: arik_Aziz,
  },
  {
    id: 12,
    name: "Asifa Hossain",
    designation: "Senior Officer, Clinical Operation",
    img: Asifa_Hossain,
  },
  {
    id: 5,
    name: "Barrister Quazi Maruful Alam",
    designation: "Legal Director",
    img: Barrister_Quazi_Maruful_Alam,
  },
  {
    id: 6,
    name: "Shahariar Anik",
    designation: "Product Manager",
    img: anik,
  },
  /* {
    id: 7,
    name: "Kazi Rashadul Islam Rony",
    designation: "Assistant Program Manager",
    img: Kazi_Rashadul_Islam_Rony,
  },
  {
    id: 8,
    name: "Fouzia Kanij Khan",
    designation: "Lead Designer",
    img: Fouzia_Kanij_Khan,
  }, */
  {
    id: 9,
    name: "Mahjabeen Sarwaar Chowdhury",
    designation: "Lead Content",
    img: Mahjabeen_Sarwaar_Chowdhury,
  },
  {
    id: 10,
    name: "Tanim Istiak",
    designation: "Software Engineer",
    img: tanim,
  },
  {
    id: 11,
    name: "Asim Nabil",
    designation: "Research Associate",
    img: Asim_Nabil,
  },

  {
    id: 13,
    name: "Manal Mahbub Khan",
    designation: "Trainee Psychologist",
    img: Manal_Mahbub_Khan,
  },
  {
    id: 14,
    name: "Atiya Sadaf",
    designation: "Trainee Psychologist",
    img: Atiya_Sadaf,
  },
  {
    id: 15,
    name: "Raisha Mehzabeen",
    designation: "Content Writer",
    img: Raisha_Mehzabeen,
  },
];

export default function Teams() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: "Teams",
    });
  }, []);
  return (
    <>
      <Header />
      <Container sx={{ my: 15 }}>
        <h2 className="headin" style={{ marginTOp: "30px" }}>
          Team
        </h2>
        <hr
          style={{
            display: "block",
            width: "10%",
            marginBottom: "2rem",
            height: "3px",
            backgroundColor: "#31C75A",
            border: 0,
          }}
        />
        <Grid container spacing={2}>
          {teamData.map((team) => (
            <Grid item xs={12} md={4}>
              <div className="person_card">
                <div className="img_container">
                  <img src={team.img} alt={team.name} />
                </div>
                <h3>{team.name}</h3>
                <p>{team.designation}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
