import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import backgroundImage from "./../../assets/images/signinBG.png";
// import signInImage from './../../assets/images/signin image.png'
import signInImage from "./../../assets/images/login.svg";
import { Container } from "@mui/system";
import {
 
  Box,
  CardMedia,
  
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import "../SignUp/SignUp.css";
import { useSignup } from "../../hooks/useSignup";
import useToken from "../../hooks/useToken";
import googleIcon from "./../../assets/images/googleIcon.png";

import Footer from "../../Shared/Footer/Footer";
import { useSignInWithGoogle } from "../../hooks/useSignInWithGoogle";
import SuccessfulSignupBloger from "./SuccessfulSignupBloger";
import styled from "styled-components";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: #31c75a;
    border-color: #31c75a;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #31c75a;
    }
  }
`;

export default function BlogerSignup() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Blogger signup`,
    });
  }, []);
  const [loginData, setLoginData] = useState({});
  let [issue, setIssue] = useState(null);
  const [emailValidate, setEmailValidate] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  const navigate = useNavigate("/bloger-signup");
  const { user } = useAuth();
  const [token] = useToken(user);
  const { signUp, error, isLoading } = useSignup();
  const {
    handleSignInWithGoogle,
    handleSignOutWithGoogle,
    error: googleError,
  } = useSignInWithGoogle("bloger");

  function CheckName(inputtxt) {
    var name = /^([a-zA-Z0-9\s,'-*]).{0,100}$/;
    if (inputtxt.match(name)) {
      setIssue(false);
      setEmailVerified(true);
      return true;
    } else {
      setIssue("Name length cannot be less than 1 chracter");
      setEmailVerified(false);
      return false;
    }
  }

  function CheckPassword(inputtxt) {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (inputtxt.match(passw)) {
      setIssue(false);
      setEmailVerified(true);
      return true;
    } else {
      setIssue(
        "Please set a strong password(atleast 7 characters, Uppercase, Lowercase, Symbols, Numbers)"
      );
      setEmailVerified(false);
    }
  }

  function ValidateEmail(email) {
    if (
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailValidate(true);
      setEmailVerified(true);
      setIssue(false);
      return true;
    }
    setEmailValidate(false);
    setEmailVerified(false);
    setIssue("Please enter a valid email address");
    return false;
  }

  const handleOnBlur = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();

    if (loginData.password !== loginData.password2) {
      setIssue("Your password did not match");
      return;
    }
    setIssue(null);
    signUp(
      loginData.email,
      loginData.password,
      loginData.name,
      navigate,
      "bloger"
    );
    e.preventDefault();
  };

  return !user?.email ? (
    <>
      <Header />
      <Container
        className="main"
        style={{ maxWidth: "1000px" }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Grid
            container
            component="main"
            sx={{
              boxShadow: "0px 4px 48px rgba(0, 0, 0, 0.25)",
              borderRadius: "50px",
            }}
          >
            {/* ======>FIRST PART<======== */}
            <Grid
              className="left-signIn"
              item
              xs={12}
              md={6}
              sx={{
                background: `url(${backgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: "#27583A",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  height: "70vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <CardMedia
                    component="img"
                    alt="sign in"
                    image={signInImage}
                  />
                </Box>
              </Box>
            </Grid>
            {/* ======>SECOND PART<======== */}
            <Grid className="right-signIn" item xs={12} md={6}>
              <form onSubmit={handleLoginSubmit}>
                <h2>Sign Up as a Blogger</h2>
                <WhiteBorderTextField
                  sx={{ width: "75%", m: 1 }}
                  id="standard-basic"
                  label="Your Name"
                  name="name"
                  type="text"
                  required
                  onChange={(e) => {
                    CheckName(e.target.value);
                    e.target.value.length > 99 &&
                      setIssue(
                        "Maximum length exceeded. Name length cannot be more than 100 chracters"
                      );
                  }}
                  onBlur={handleOnBlur}
                  inputProps={{ maxLength: 100 }}
                  variant="outlined"
                />
                <WhiteBorderTextField
                  sx={{ width: "75%", m: 1 }}
                  id="standard-basic"
                  label="Your Email"
                  name="email"
                  type="email"
                  required
                  onChange={(e) => ValidateEmail(e.target.value)}
                  onBlur={handleOnBlur}
                  variant="outlined"
                />
                <WhiteBorderTextField
                  sx={{ width: "75%", m: 1 }}
                  id="standard-password-input"
                  label="Your Password"
                  type="password"
                  name="password"
                  required
                  onChange={(e) => CheckPassword(e.target.value)}
                  onBlur={handleOnBlur}
                  autoComplete="current-password"
                  variant="outlined"
                />
                <WhiteBorderTextField
                  sx={{ width: "75%", m: 1 }}
                  id="standard-password-input"
                  label="Re-type Your Password"
                  type="password"
                  name="password2"
                  required
                  onBlur={handleOnBlur}
                  autoComplete="current-password"
                  variant="outlined"
                />
                {isLoading && (
                  <Button
                    style={{ marginTop: "1rem" }}
                    className="signin-btn"
                    variant="contained"
                  >
                    Processing
                  </Button>
                )}
                {!isLoading && (
                  <Button
                    style={{ marginTop: "1rem" }}
                    className="signin-btn"
                    type="submit"
                    variant="contained"
                  >
                    Sign Up
                  </Button>
                )}
                <div>
                  <p style={{ margin: "10px 0" }}>
                    Already Registered? Please{" "}
                    <Link to="/bloger-signin" style={{ color: "#31c75a" }}>
                      signin
                    </Link>
                  </p>
                  <br />

                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/bloger-signin"
                  >
                    {" "}
                    <Button
                      sx={{
                        color: "#000",
                        borderRadius: "50px",
                        padding: "14px 80px",
                      }}
                      color="inherit"
                      variant="outlined"
                    >
                      SIGN IN
                    </Button>
                  </Link>
                </div>
                {!user?.email ? (
                  <div
                    className="google_login"
                    onClick={() => handleSignInWithGoogle("bloger")}
                  >
                    <img src={googleIcon} alt="icon" />
                    <p>Sign In With Google</p>
                  </div>
                ) : (
                  <div
                    className="google_login"
                    onClick={() => handleSignOutWithGoogle()}
                  >
                    <img src={googleIcon} alt="icon" />
                    <p>Sign Out From Google</p>
                  </div>
                )}
                <br /> <br />
                {!emailVerified || issue ? (
                  <small style={{ color: "coral" }}>{issue}</small>
                ) : (
                  <></>
                )}
                <br />
                {error && <small style={{ color: "coral" }}> {error} </small>}
              </form>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  ) : (
    <SuccessfulSignupBloger greet="created" />
  );
}
