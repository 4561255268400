import { Alert, AlertTitle, Container } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';


export default function SuccessfulSignupBloger({ greet }) {

    const navigate = useNavigate("");

    setTimeout(() => {
        navigate('/bloger-dashboard');
    }, 1000)
    return (

        <Container>
            <Alert style={{ marginTop: "5rem" }} severity="success">
                <AlertTitle>Congratulations</AlertTitle>
                User {greet} — <strong>successfully!</strong>
            </Alert>
        </Container>
    )
}

