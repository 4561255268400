import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom"
import { Button, CardActions, CardContent, Collapse, Container, IconButton } from '@mui/material';
import Card from '@mui/material/Card';

import styled from '@emotion/styled';

import { useTheme } from '@material-ui/core/styles';
import counseling from "../../../assets/images/counseling.png"
import mentalHealth from "../../../assets/images/mentalHealth.png"
import workshop from "../../../assets/images/workshop.png"
import awarness from "../../../assets/images/awarness.png"

import Aos from 'aos'
import "./Services.css"




const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
}));


const Services = () => {

    const navigate = useNavigate("");
    const theme = useTheme();

    const [expanded1, setExpanded1] = React.useState(false);
    const [expanded2, setExpanded2] = React.useState(false);
    const [expanded3, setExpanded3] = React.useState(false);
    const [expanded4, setExpanded4] = React.useState(false);

    const handleExpandClick1 = () => {
        navigate("/service/counseling")
    };
    const handleExpandClick2 = () => {
        navigate("/workshop")
    };
    const handleExpandClick3 = () => {
        navigate("/corporate-mental-health")
        // setExpanded3(!expanded3);
    };
    const handleExpandClick4 = () => {
        navigate("/campaign")
    };

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])



    return (
        <Box id="services" sx={{ flexGrow: 1, my: 10,
            [theme.breakpoints.down('xs')]: {
              my: 10,
            }, }}>
            <Container>
                <div style={{marginBottom: "1.5rem"}}>
                    
                <h2 className='headin' >
                    Services We Provide
                </h2>
                <hr style={{ display: 'block', width: '10%', height: '3px', backgroundColor: '#31C75A', border: 0 }} />
                </div>
                <Grid sx={{ pt: 5 }} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                    <Grid item xs={12} sm={12} md={3} data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500">

                        <Card sx={{ textAlign: 'center', fontFamily: 'Poppins', pt: 5, pb: 2, borderRadius: '25px' }} style={{ boxShadow: '0px 17px 15px 5px #dad6d6' }}>
                            <img
                                component="img"
                                height="194"
                                src={counseling}
                                alt="Paella dish"
                            />
                            <h2 style={{ fontSize: '18px' }}>Counseling</h2>
                            <CardContent style={{padding: "0 16px"}}>
                                <p>
                                Overcome your mental barriers with the help of expert psychologists
                                </p>
                            </CardContent>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', placeContent: 'center' }}>
                                <CardActions disableSpacing>
                                    <ExpandMore
                                        expand={expanded1}
                                        onClick={handleExpandClick1}
                                        aria-expanded={expanded1}
                                        aria-label="show more"
                                    >
                                        <Button style={{ backgroundColor: '#31C75A', color: 'white', padding: '10px 20px', borderRadius: '15px' }} variant="contained">{expanded1 ? 'Read Less' : 'Read More'} </Button>
                                    </ExpandMore>
                                </CardActions>
                            </div>
                            <Collapse in={expanded1} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <p>
                                        Fluoride treatments are typically professional treatments containing a high concentration of fluoride that a dentist or hygienist will apply to a persons teeth to improve health and reduce the risk of cavities.
                                    </p>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>


                    <Grid item xs={12} sm={12} md={3} data-aos="fade-up" data-aos-duration="3000">

                        <Card sx={{ textAlign: 'center', fontFamily: 'Poppins', pt: 5, pb: 2, borderRadius: '25px' }} style={{ boxShadow: '0px 17px 15px 5px #dad6d6' }}>
                            <img
                                component="img"
                                height="194"
                                src={workshop}
                                alt="Paella dish"
                            />
                            <h2 style={{ fontSize: '18px' }}>Workshops</h2>
                            <CardContent style={{padding: "0 16px"}}>
                                <p>
                                Learn how to take care of your mental health with seasoned trainers
                                </p>
                            </CardContent>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', placeContent: 'center' }}>
                                <CardActions disableSpacing>
                                    <ExpandMore
                                        expand={expanded2}
                                        onClick={handleExpandClick2}
                                        aria-expanded={expanded2}
                                        aria-label="show more"
                                    >
                                        <Button style={{ backgroundColor: '#31C75A', color: 'white', padding: '10px 20px', borderRadius: '15px', }} variant="contained">{expanded2 ? 'Read Less' : 'Read More'} </Button>
                                    </ExpandMore>
                                </CardActions>
                            </div>
                            <Collapse in={expanded2} timeout="auto" unmountOnExit>
                                <CardContent style={{padding: "0 16px"}}>
                                    <p>
                                        Fluoride treatments are typically professional treatments containing a high concentration of fluoride that a dentist or hygienist will apply to a persons teeth to improve health and reduce the risk of cavities.
                                    </p>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500">

                        <Card sx={{ textAlign: 'center', fontFamily: 'Poppins', pt: 5, pb: 2, borderRadius: '25px' }} style={{ boxShadow: '0px 17px 15px 5px #dad6d6' }}>
                            <img
                                component="img"
                                height="194"
                                src={mentalHealth}
                                alt="Paella dish"
                            />
                            <h2 style={{ fontSize: '18px' }}>Corporate Mental Health</h2>
                            <CardContent style={{padding: "0 16px"}}>
                                <p>
                                Ensure mental wellbeing of people who drive your organisation
                                </p>
                            </CardContent>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', placeContent: 'center' }}>
                                <CardActions disableSpacing>
                                    <ExpandMore
                                        expand={expanded3}
                                        onClick={handleExpandClick3}
                                        aria-expanded={expanded3}
                                        aria-label="show more"
                                    >
                                        <Button style={{ backgroundColor: '#31C75A', color: 'white', padding: '10px 20px', borderRadius: '15px' }} variant="contained">{expanded3 ? 'Read Less' : 'Read More'} </Button>
                                    </ExpandMore>
                                </CardActions>
                            </div>
                            <Collapse in={expanded3} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <p>
                                        A valuable HR service provided by an organization to its employees, corporate counseling improves job satisfaction and productivity by sharing the tools, resources and knowledge required to increase personal and team effectiveness, communication and overall employee wellbeing in the workplace.
                                    </p>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} data-aos="fade-up" data-aos-duration="3000">

                        <Card sx={{ textAlign: 'center', fontFamily: 'Poppins', pt: 5, pb: 2, borderRadius: '25px' }} style={{ boxShadow: '0px 17px 15px 5px #dad6d6' }}>
                            <img
                                component="img"
                                height="194"
                                src={awarness}
                                alt="Awarness"
                            />
                            <h2 style={{ fontSize: '18px' }}>Awareness Programs</h2>
                            <CardContent style={{padding: "0 16px"}}>
                                <p>
                                Join us in spreading mental health awareness across Bangladesh
                                </p>
                            </CardContent>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', placeContent: 'center' }}>
                                <CardActions disableSpacing>
                                    <ExpandMore
                                        expand={expanded4}
                                        onClick={handleExpandClick4}
                                        aria-expanded={expanded4}
                                        aria-label="show more"
                                    >
                                        <Button style={{ backgroundColor: '#31C75A', color: 'white', padding: '10px 20px', borderRadius: '15px' }} variant="contained">{expanded4 ? 'Read Less' : 'Read More'} </Button>
                                    </ExpandMore>
                                </CardActions>
                            </div>
                            <Collapse in={expanded4} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <p>
                                        Fluoride treatments are typically professional treatments containing a high concentration of fluoride that a dentist or hygienist will apply to a persons teeth to improve health and reduce the risk of cavities.
                                    </p>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Services;