import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate, useParams } from "react-router-dom";

export default function PsychologistPaymentDetails() {
  let { id } = useParams();
  const [psychologist, setPsychologist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetch("https://api.thepsycure.com/psychologists")
      .then((res) => res.json())
      .then((data) => {
        const foundPsychologist = data.filter((detail) => detail._id === id);
        setIsLoading(false);
        setPsychologist(foundPsychologist[0]);
      });
  }, [id]);

  return (
    <div
      style={{
        boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.25)",
        padding: "2rem",
        borderRadius: ".8rem",
      }}
    >
      <TableContainer className="table-container" sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Session Id</TableCell>
              <TableCell>Session Date</TableCell>
              <TableCell>Patient Email</TableCell>
              <TableCell>Doctor Email</TableCell>
              <TableCell>Ammount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {psychologist?.payment
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) =>
                !row?.isDisbursed ? (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell>{row?.id}</TableCell>
                    <TableCell>{row?.date}</TableCell>
                    <TableCell>{row?.patientEmail}</TableCell>
                    <TableCell>{row?.doctorEmail}</TableCell>
                    <TableCell>{row?.ammount}</TableCell>
                  </TableRow>
                ) : (
                  ""
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={psychologist?.payment?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
