import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Box, Container, Typography } from "@mui/material";

export default function FellowShipFaq() {
  return (
    <Container
      sx={{
        margin: "5% 0",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
      }}
      maxWidth="xl"
    >
      <Box>
        <Box>
          <Typography variant="h3" style={{ textAlign: "center" }}>
            FAQ
          </Typography>
        </Box>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            defaultExpanded
          >
            Who is eligible to apply for the fellowship?
          </AccordionSummary>
          <AccordionDetails>
            To be eligible to apply for the Youth Mental Health Fellowship
            program, individuals must be between the ages of 18 and 30
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            How many hours do I need to commit to the program?
          </AccordionSummary>
          <AccordionDetails>
            Participants are expected to commit approximately 6-8 hours per
            week.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Is it mandatory to have a background in mental health?
          </AccordionSummary>
          <AccordionDetails>
            No, it is not mandatory. We welcome individuals from diverse
            backgrounds who have an interest in mental health.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
          >
            What are the selection criteria for participation?
          </AccordionSummary>
          <AccordionDetails>
            Participants will be selected based on their community project plan
            and their interest in the field of mental health.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5-content"
            id="panel5-header"
          >
            Will financial support be provided?
          </AccordionSummary>
          <AccordionDetails>
            No, financial support is not available. However, we can provide
            internet access services as needed.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6-content"
            id="panel6-header"
          >
            What are the responsibilities of participants?
          </AccordionSummary>
          <AccordionDetails>
            Participants are required to conduct 5 workshops as
            part of the program.
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
