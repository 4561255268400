import { Box, Button, Container, Grid, TextField } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import useAuth from "../../hooks/useAuth";
import RichTextEditor from "../Blogs/AddBlog/RichTextEditor";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6F1EC",
    color: "#000",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E6F1EC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(blog, blogTitle, author, authorImage, email, id, date) {
  return { blog, blogTitle, author, authorImage, email, id, date };
}

export default function BlogerDashboard() {
  const { user } = useAuth();

  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let [obj, setObj] = useState({});
  let [blogContent, setBlogContent] = useState();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useLayoutEffect(() => {
    fetch("https://api.thepsycure.com/blogs")
      .then((res) => res.json())
      .then((data) => {
        let usersBlog = data?.filter(
          (blog) => blog?.authorEmail === user?.email
        );
        setIsLoading(false);
        setBlogs(usersBlog);
      });
  }, [obj]);

  const rows = [];

  blogs.forEach((blog) =>
    rows.push(
      createData(
        blog,
        blog.blogTitle,
        blog.author,
        blog?.authorImage,
        blog.authorEmail,
        blog._id,
        blog.date
      )
    )
  );

  const handelDelete = (id) => {
    const proceed = window.confirm("Are you sure you want to DELETE this Blog");
    if (proceed) {
      const url = `https://api.thepsycure.com/delete-blog/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount) {
            const remaining = blogs?.filter((blog) => blog._id !== id);
            setBlogs(remaining);
          }
        });
    }
  };

  const hanldeBlogUpdate = () => {
    let updatedObj = {
      ...obj,
      blogContent,
    };

    fetch(`https://api.thepsycure.com/blog/${obj?._id}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ updatedObj }),
    })
      .then((res) => res.json())
      .then((result) => {
        const data = result;
        setObj({});
      });
  };

  return (
    <>
      <Container>
        {/* < Box>
                    <h2 style={{ textAlign: 'center', marginTop: '-35px' }} variant="h4" gutterBottom component="div">
                        Bloger Dashboard
                    </h2>
                    <hr style={{ display: 'block', width: '10%', height: '3px', backgroundColor: '#31C75A', border: 0 }} />
                </Box > */}
        <Container sx={{ py: 10 }}>
          <Grid item xs={12} md={6} component={Paper}>
            <Box>
              <h2
                style={{ textAlign: "center" }}
                variant="h4"
                gutterBottom
                component="div"
              >
                All Blogs of {user?.displayName}
              </h2>
              <hr
                style={{
                  display: "block",
                  width: "10%",
                  height: "3px",
                  backgroundColor: "#31C75A",
                  border: 0,
                }}
              />
            </Box>
            <TableContainer>
              <Table sx={{ minWidth: 300 }} aria-label="customized table">
                <TableHead style={{ marginBottom: "2rem" }}>
                  <TableRow style={{ textAlign: "center" }}>
                    {/* <StyledTableCell></StyledTableCell> */}
                    <StyledTableCell className="table_center">
                      <strong>Blog Title</strong>
                    </StyledTableCell>
                    <StyledTableCell className="table_center">
                      <strong>Author</strong>
                    </StyledTableCell>
                    <StyledTableCell className="table_center">
                      <strong>Author Email</strong>
                    </StyledTableCell>
                    <StyledTableCell className="table_center">
                      <strong>Author Image</strong>
                    </StyledTableCell>
                    <StyledTableCell className="table_center">
                      <strong>Actions</strong>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row.id}>
                        {/* <StyledTableCell >

                                            </StyledTableCell> */}
                        <StyledTableCell>{row.blogTitle}</StyledTableCell>
                        <StyledTableCell className="table_center">
                          {row.author}
                        </StyledTableCell>
                        <StyledTableCell className="table_center">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell className="table_center">
                          <img
                            src={row.authorImage}
                            alt={row.author}
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                          />
                        </StyledTableCell>

                        <StyledTableCell className="table_center">
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#31C75A",
                              marginRight: "1rem",
                            }}
                            sx={{ borderRadius: "25px" }}
                            onClick={() => setObj(row.blog)}
                          >
                            {" "}
                            <EditIcon />
                          </Button>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#ff0000" }}
                            sx={{ borderRadius: "25px" }}
                            onClick={() => handelDelete(row.id)}
                          >
                            {" "}
                            <DeleteForeverIcon />
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>

          {obj?.blogTitle ? (
            <Box md={5} sx={12}>
              <Box>
                <h2
                  style={{ textAlign: "center" }}
                  variant="h4"
                  gutterBottom
                  component="div"
                >
                  Update Blog
                </h2>
                <hr
                  style={{
                    display: "block",
                    width: "10%",
                    height: "3px",
                    backgroundColor: "#31C75A",
                    border: 0,
                  }}
                />
              </Box>

              <Box>
                <TextField
                  required
                  sx={{
                    width: "100%",
                    marginRight: "1%",
                    marginBottom: "2rem",
                  }}
                  id="outlined-required"
                  label="Blog Title"
                  onChange={(e) =>
                    setObj({ ...obj, blogTitle: e.target.value })
                  }
                  value={obj?.blogTitle}
                />

                {/* <TextField
                                    style={{ width: '24%' }}
                                    id="standard-basic"
                                    onChange={(e) => handleImage(e, "blogImage")}
                                    name='image'
                                    type='file'
                                    variant="outlined" />

                                {blogImage ?


                                    <img style={{ maxWidth: '500px', margin: 'auto', display: 'flex' }} src={blogImage} alt="" />
                                    :
                                    <img style={{ maxWidth: '500px', margin: 'auto', display: 'flex' }} src={placeHolderImage} alt="" />
                                } */}

                {/* {!user?.photoURL && <>
                                    <TextField
                                        style={{ width: '24%' }}
                                        id="standard-basic"
                                        onChange={(e) => handleImage(e, "authorImage")}
                                        name='image'
                                        type='file'
                                        variant="outlined" />
                                    <img style={{ width: '50%', margin: 'auto', display: 'flex' }} src={authorImage} alt="" />
                                </>
                                } */}

                <RichTextEditor
                  value={obj?.blogContent}
                  setBlogContent={setBlogContent}
                />
                <div style={{ textAlign: "center", marginTop: "2rem" }}>
                  <Button
                    style={{ backgroundColor: "#31C75A" }}
                    type="submit"
                    variant="contained"
                    onClick={hanldeBlogUpdate}
                  >
                    Update
                  </Button>
                </div>
              </Box>
              <Box md={6} sx={12}></Box>
            </Box>
          ) : (
            ""
          )}
        </Container>
      </Container>
    </>
  );
}
