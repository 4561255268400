import { PauseOutlined, PlayArrowOutlined } from "@mui/icons-material";
import { Box, Container, Icon, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import "./UnwindMindOverview.css";
const UnwindMindOverView = () => {
  const videoRef = useRef(null); // Use ref to access the video element
  const [isPlaying, setIsPlaying] = useState(false); // Track play/pause state

  const handlePlayPause = () => {
    const video = videoRef.current;

    if (isPlaying) {
      video.pause(); // Pause the video
      setIsPlaying(false);
    } else {
      video.play(); // Play the video
      setIsPlaying(true);
    }
  };
  const videoUrl =
    "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2FFacebook.mp4?alt=media";
  return (
    <div>
      <Container maxWidth="xl">
        <div
          className="parent-video-component"
          style={{ position: "relative" }}
        >
          <video
            ref={videoRef}
            width="100%"
            src={videoUrl}
            loop
            poster="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Fposter.jpg?alt=media"
          />
          <div
            className="play-pause-button"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              transition: "opacity 0.3s ease",
            }}
          >
            <button
              onClick={handlePlayPause}
              style={{ border: "none", background: "none" }}
            >
              {isPlaying ? (
                <PauseOutlined
                  sx={{
                    width: "100px",
                    height: "100px",
                    color: "black",
                  }}
                />
              ) : (
                <PlayArrowOutlined
                  sx={{
                    width: "100px",
                    height: "100px",
                    color: "black",
                    // opacity: "0.5",
                  }}
                />
              )}
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default UnwindMindOverView;
