import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import EventSelection from "./EventSelection";
import Header from "../../Shared/Header/Header";
import SummitHeader from "./SummitHeader";
import RegisterButtonSummit from "./RegisterButtonSummit";
import ImageTextBlock from "./ImageTextBlock";
import SummitHistory from "./SummitHistory";
import SpeakerOrGuest from "./SpeakerOrGuest";

const SummitLandingPage = () => {
  const [selectedSummits, setSelectedSummits] = useState([]);
  const [selectionStatus, setSelectionStatus] = useState(false);
  const summitPairs = [
    { name: "firstEvent", index: 0 },
    { name: "secondEvent", index: 1 },
    { name: "thirdEvent", index: 2 },
    { name: "fourthEvent", index: 3 },
    { name: "fifthEvent", index: 4 },
    { name: "sixthEvent", index: 5 },
  ];
  const handleSelect = (summit) => {
    // console.log(summit);

    setSelectionStatus(!selectionStatus);
  };
  return (
    <>
      <Header />
      <div style={{ margin: "5% 0" }}>
        <Box className="summit-header">
          <SummitHeader />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", margin: "3% 0" }}>
          <RegisterButtonSummit />
        </Box>
        <Box sx={{ width: "90%", margin: "0 auto" }}>
          <ImageTextBlock />
        </Box>

        <Box sx={{ width: "90%", margin: "0 auto" }}>
          <SummitHistory />
        </Box>
        {/* <Box sx={{ width: "90%", margin: "0 auto" }}>
          <SpeakerOrGuest />
        </Box> */}
      </div>
      {/* <EventSelection /> */}
      {/* <Button /> */}
    </>
  );
};

export default SummitLandingPage;
