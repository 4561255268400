import React from "react";
import { campaignData } from "./data";
import Header from "../../Shared/Header/Header";
import { Button, Container, Grid } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Footer from "../../Shared/Footer/Footer";
import ReactGA from "react-ga4";

export default function CampaignDetails() {
  const { id } = useParams({});
  let [currentCampaign, setCurrentCampaign] = useState({});
  let [topCampaign, setTopCampaign] = useState([]);

  useEffect(() => {
    let filteredCampaign = campaignData.filter((campaign) => campaign.id == id);
    let othersCampaign = campaignData.filter((campaign) => campaign.id != id);
    setCurrentCampaign(filteredCampaign[0]);
    setTopCampaign(othersCampaign);
  }, [id]);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `${currentCampaign?.campaignTitle}`,
    });
  }, [currentCampaign]);
  return (
    <>
      <Header />
      <Container style={{ maxWidth: "1400px" }}>
        <Grid sx={{ pt: 15 }} container spacing={8}>
          <Grid item xs={12} md={7}>
            <h2 className="workshop_details_title">
              {currentCampaign?.campaignTitle}
            </h2>
            <img
              src={currentCampaign?.campaignImage}
              style={{ width: "100%" }}
            />
            <div style={{ display: "flex", gap: "2rem" }}>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                Total Participants : {currentCampaign?.totalPerticipant}
              </p>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                Event Reach : {currentCampaign?.eventReact}
              </p>
            </div>
            <div>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                Core Partners :{" "}
              </p>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                {currentCampaign?.corePartners?.map((partner) => (
                  <p
                    style={{
                      padding: "5px 15px",
                      margin: "0",
                      borderRadius: "100px",
                      color: "#fff",
                      backgroundColor: "#27583A",
                    }}
                  >
                    {partner}
                  </p>
                ))}
              </div>
            </div>
            <div>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                Campaign Details:{" "}
              </p>
              <p>{currentCampaign?.campaignDescription}</p>
              <a href={currentCampaign?.campaignLink} target="_blank">
                <Button
                  style={{
                    backgroundColor: "#31C75A",
                    padding: "10px 20px",
                    borderRadius: "100px",
                  }}
                  variant="contained"
                >
                  EVENT LINK
                </Button>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <h2 style={{ marginTop: "7px", marginBottom: 0 }}>Top Campaigns</h2>
            <hr
              style={{
                display: "inline-block",
                width: "30%",
                height: "3px",
                backgroundColor: "#31C75A",
                border: 0,
                textAlign: "left",
              }}
            />
            {topCampaign?.slice(0, 4)?.map((campaign) => (
              <Grid container spacing={2} sx={{ mt: 5 }}>
                <Grid item xs={6}>
                  <img
                    src={campaign?.campaignImage}
                    style={{ width: " 100%", height: "100%" }}
                    alt=""
                  />
                </Grid>
                <Grid item xs={6}>
                  <p className="worshop_heading">{campaign?.campaignTitle}</p>
                  <p className="worshop_description">
                    {campaign?.campaignDescription?.substring(0, 50)}...
                  </p>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/campaign/${campaign?.id}`}
                  >
                    <button className="workshop_btn">KNOW MORE</button>
                  </Link>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
