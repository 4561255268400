import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router";
import useAuth from "../../../hooks/useAuth";

const AdminRoute = ({ children, ...rest }) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [logedInUser, setLogedInUser] = useState(null);

  useEffect(() => {
    if (user?.email) {
      setIsLoading(true);
      fetch(`https://api.thepsycure.com/users/${user?.email}`)
        .then((res) => res.json())
        .then((data) => {
          setLogedInUser(data);
          // localStorage.setItem("userDetails", JSON.stringify(data))
        })
        .finally(() => setIsLoading(false));
    }
  }, [user]);

  const location = useLocation();
  // const userDetails = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem('userDetails') || '{}') : { role: "", email: "" }

  if (isLoading || (logedInUser === null && user?.email)) {
    return (
      <div style={{ paddingTop: "15%", textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  } else {
    if (logedInUser?.role === "psychologist") {
      return <Navigate to="/" state={{ from: location }} />;
    }
    if (logedInUser?.role === "patient") {
      return <Navigate to="/" state={{ from: location }} />;
    }
    if (logedInUser?.role === "bloger") {
      return <Navigate to="/" state={{ from: location }} />;
    }

    if (!logedInUser?.role) {
      return <Navigate to="/admin-signin" state={{ from: location }} />;
    } else if (logedInUser?.email && logedInUser?.role === "admin") {
      return children;
    }
  }
};

export default AdminRoute;
