import { FacebookShareButton, FacebookIcon } from 'react-share';
const Facebook = ({ url, quotes, hashtag }) => {
    return (
        <>

            <FacebookShareButton
                url={url}    
                quotes={quotes} 
            >
                <FacebookIcon style={{width: "40px", height: "40px"}} />
            </FacebookShareButton>

        </>
    )
}

export default Facebook;