import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Header from "../../Shared/Header/Header";
// import EventSelection from "./EventSelection";
import IndividualEvent from "./IndividualEvent";

const SummitRegistrationStudent = () => {
  const formFieldStyle = {
    width: "100%",
    marginBottom: 10,
  };
  const isValidBangladeshiPhoneNumber = (phoneNumber) => {
    // Regular expression to match Bangladeshi phone numbers
    const bangladeshiPhoneNumberRegex = /^(?:\+?88)?01[3-9]\d{8}$/;
    return bangladeshiPhoneNumberRegex.test(phoneNumber);
  };
  const [selectedItems, setSelectedItems] = useState([]);
  const [studentData, setStudentData] = useState({
    fullName: "",
    number: "",
    email: "",
    institution: "",
    department: "",
    level: "",
    summitOption: "entire",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setStudentData((prev) => ({ ...prev, [name]: value }));
  };
  //   console.log(studentData);
  //   console.log(selectedItems);
  const [error, setError] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    if (
      studentData?.summitOption === "individual" &&
      selectedItems.length === 0
    ) {
      setError("Please select at least one workshop");
    }
    const fullData = { ...studentData, events: selectedItems };
  };
  console.log(selectedItems);
  return (
    <Box>
      <Header />
      <Container sx={{ mt: 15, display: "flex", justifyContent: "center" }}>
        <Box>
          <Typography
            variant="h4"
            sx={{ fontWeight: "700", fontFamily: "DMSans, sans-serif" }}
          >
            Mental Health Summit 2024, Bangladesh - Registration Form
          </Typography>
          <form
            action="https://api.thepsycure.com/summit-payment"
            method="post"
            style={{ margin: "5% 0" }}
          >
            {/* full name */}
            <TextField
              id="outlined-basic"
              label="Full Name"
              variant="outlined"
              style={formFieldStyle}
              value={studentData?.fullName}
              name="fullName"
              required
              onChange={handleChange}
            />
            {/* contact number */}
            <TextField
              id="outlined-basic"
              label="Contact Number"
              variant="outlined"
              style={formFieldStyle}
              value={studentData?.number}
              name="number"
              required
              onChange={handleChange}
              //   type="number"
            />
            {/* email name */}
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              style={formFieldStyle}
              value={studentData?.email}
              name="email"
              required
              onChange={handleChange}
            />
            {/* instituion name */}
            <TextField
              id="outlined-basic"
              label="Institution"
              variant="outlined"
              style={formFieldStyle}
              value={studentData?.institution}
              name="institution"
              required
              onChange={handleChange}
            />
            {/* department name */}
            <TextField
              id="outlined-basic"
              label="Department"
              variant="outlined"
              style={formFieldStyle}
              value={studentData?.department}
              name="department"
              required
              onChange={handleChange}
            />
            {/* post or not post */}
            <FormControl variant="outlined" fullWidth required sx={{ my: 3 }}>
              <InputLabel id="demo-simple-select-label">
                Level of Study
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={studentData?.level}
                label="Level of study"
                name="level"
                onChange={handleChange}
                // required
              >
                <MenuItem value="undergraduate">Undergraduate</MenuItem>
                <MenuItem value="postgraduate">Postgraduate</MenuItem>
              </Select>
            </FormControl>
            {/* summit option */}
            <FormControl
              variant="standard"
              fullWidth
              required
              sx={{
                border: "1px solid #c4c4c4",
                padding: 1,
                borderRadius: 1,
                mb: 3,
              }}
            >
              <InputLabel id="demo-simple-select-label" sx={{ padding: 1.5 }}>
                Please select your preferred registration option
              </InputLabel>
              <NativeSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={studentData?.summitOption}
                label="Summit option"
                name="summitOption"
                onChange={handleChange}
              >
                <option value="entire">
                  Entire summit (BDT 1200): This option is for students and
                  includes full access to the two-day summit, along with lunch,
                  snacks, and a souvenir. You may also select up to 4 workshops
                  to attend during the summit.
                </option>
                <option value="individual">
                  Single workshop (BDT 500 per workshop): This option is for
                  students who wish to attend individual workshops. You can
                  select up to 4 workshops.
                </option>
              </NativeSelect>
            </FormControl>
            {/* amount */}
            <input
              type="text"
              name="amount"
              id=""
              style={{ display: "none" }}
              value={
                studentData?.summitOption === "entire"
                  ? 1200
                  : selectedItems?.length * 500
              }
            />
            {/* name */}
            <input
              type="text"
              name="cus_name"
              id=""
              style={{ display: "none" }}
              value={studentData?.fullName}
            />
            {/* email */}
            <input
              type="text"
              name="cus_email"
              id=""
              style={{ display: "none" }}
              value={studentData?.email}
            />
            {/* phone */}
            <input
              type="text"
              name="cus_phone"
              id=""
              style={{ display: "none" }}
              value={studentData?.number}
            />
            {/* institution and department, level and summit option */}
            <input
              type="text"
              name="opt_a"
              id=""
              style={{ display: "none" }}
              value={`${studentData?.institution} | ${studentData?.department} | ${studentData?.level} | ${studentData?.summitOption}`}
            />
            {/* selected events */}
            <input
              type="text"
              name="opt_b"
              id=""
              style={{ display: "none" }}
              value={JSON.stringify(selectedItems)}
            />
            {(studentData?.summitOption === "individual" ||
              studentData?.summitOption === "entire") && (
              <IndividualEvent
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                
              />
            )}
            {error && (
              <Typography color="red" align="center">
                {error}
              </Typography>
            )}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <input
                type="submit"
                value={`Pay ${
                  studentData?.summitOption === "entire"
                    ? "1200"
                    : `${selectedItems?.length * 500}`
                } and Submit`}
                style={{ height: "50px", width: "200px" }}
                className="summit-submit"
              />
            </Box>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default SummitRegistrationStudent;
