import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Paper,
  
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import React, { useLayoutEffect, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Container } from "@material-ui/core";
import { HashLink } from "react-router-hash-link";
import recommandImg from "../../../assets/images/dashboard/recommand.png";
import useAuth from "../../../hooks/useAuth";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useEffect } from "react";
import Footer from "../../../Shared/Footer/Footer";
import style from "./styles.module.css";
import PickersDay, {
 
  pickersDayClasses,
} from "@mui/lab/PickersDay";
import ScrollToTop from "../../ScrollToTop";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
  return (
    <PickersDay
      {...pickersDayProps}
      sx={{
        [`&&.${pickersDayClasses.selected}`]: {
          backgroundColor: "rgb(23, 216, 136)",
          borderRadius: "50%",
          border: "1px solid #fff",
          color: "#fff !important",
        },
      }}
    />
  );
};

export default function Recommend() {
  const [psychologists, setPsychologists] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState("");
  const [recommendDoctor, setRecommendDoctor] = useState({});
  const [value, setValue] = React.useState("");
  const [selectedStartTime, setSelectedStartTime] = React.useState("");
  const [selectedEndTime, setSelectedEndTime] = React.useState("");
  const [userDetail, setUserDetail] = useState({});

  const [date, setDate] = useState("");
  const [selectedPsychologists, setSelectedPsychologists] = useState("");
  const [allSession, setAllSession] = useState("");

  const { user } = useAuth();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  const bookingEndRef = useRef(null);

  const scrollToBottom = () => {
    bookingEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedStartTime, value]);

  const fetchData = () => {
    fetch("https://api.thepsycure.com/psychologists")
      .then((res) => res.json())
      .then((data) => {
        const approvedPsychologist = data?.filter((doctor) => {
          return doctor?.approved === true;
        });
        setPsychologists(approvedPsychologist);
      });
  };

  useLayoutEffect(() => {
    requestAnimationFrame(fetchData);
  }, []);

  useEffect(() => {
    fetch("https://api.thepsycure.com/session")
      .then((res) => res.json())
      .then((data) => {
        const filteredByDate = data.filter((d) => d.date == date.slice(0, 10));

        setAllSession(filteredByDate);
      });
  }, [date]);

  const handleValue = (newValue) => {
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const selectedDate = new Date(newValue.toISOString().slice(0, 10));
    setValue(weekday[selectedDate.getDay()]);
    setDate(newValue.toISOString());

    setSelectedStartTime("");
    setSelectedEndTime("");
  };

  const problems = [
    ...new Set(psychologists?.map((doctor) => doctor?.category).flat()),
  ];

  const handleChange = (e) => {
    setSelectedProblem(e.target.value);
  };

  const handleShow = () => {
    // filtered Psychologist with selectedProblem inlcude
    if (selectedProblem) {
      let showingDoctor = psychologists
        .filter((doctor) => {
          return doctor?.category?.includes(selectedProblem);

          // Sort Psychologist for recommendation by (total number of review / recommendation % ) with selectedProblem inlcude
        })
        .sort((a, b) => {
          return (
            (b.reviews?.length / b?.reviews?.length
              ? (b?.reviews?.filter((review) => review?.rating == "like")
                  .length /
                  b?.reviews.length) *
                100
              : 0) -
            (a.reviews?.length / a?.reviews?.length
              ? (a?.reviews?.filter((review) => review?.rating == "like")
                  .length /
                  a?.reviews.length) *
                100
              : 0)
          );
        });

      setRecommendDoctor(showingDoctor[0]);
    }
  };

  const handleSelectValue = (selectedPsychologists) => {
    setSelectedPsychologists(selectedPsychologists);
  };

  let psychologistArr = psychologists.map(
    (psychologist) => psychologist?.category
  );

  const weekday = [0, 1, 2, 3, 4, 5, 6];
  const availableDate = selectedPsychologists?.availableDateTimes?.map(
    (times) => {
      if (times.date === "Sunday") {
        return 0;
      } else if (times.date === "Monday") {
        return 1;
      } else if (times.date === "Tuesday") {
        return 2;
      } else if (times.date === "Wednesday") {
        return 3;
      } else if (times.date === "Thursday") {
        return 4;
      } else if (times.date === "Friday") {
        return 5;
      } else if (times.date === "Saturday") {
        return 6;
      }
    }
  );
  const unAvailableDate = weekday.filter(
    (day) => !availableDate?.includes(day)
  );

  function isWeekendDay(date) {
    return unAvailableDate.includes(date.getDay());
  }

  const filteredSession = allSession.length
    ? allSession?.filter(
        (session) =>
          session?.selectedPsychologists?.email === selectedPsychologists?.email
      )
    : [];

  const { displayName, email, uid, emailVerified, createdAt, apiKey } = user;

  useEffect(() => {
    fetch("https://api.thepsycure.com/users")
      .then((res) => res.json())
      .then((users) => {
        const actualUserDetail = users.filter((user) => user?.email == email);

        setUserDetail(actualUserDetail[0]);
      });
  }, [email]);

  const handleSubmit = async (e) => {
    let sessionDetails = {
      patientEmail: email,
      docEmail: selectedPsychologists?.email,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      date: date.slice(0, 10),
      day: value,
      status: "incomplete",
      selectedPsychologists,
      userDetails: userDetail,
    };

    localStorage.setItem("selectedDetails", JSON.stringify(sessionDetails));

    navigate("/payment-next");
  };

  const handleSelectedTime = (time) => {
    setSelectedStartTime(time.startTime);
    setSelectedEndTime(time.endTime);
  };

  return (
    <>
      <ScrollToTop />
      <Container>
        <Grid
          container
          spacing={4}
          alignItems="center"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item md={6} style={{ textAlign: "center" }}>
            <h1>Define your problems</h1>
            <p>
              You are not alone. Let us know the problems you are facing. We are
              here for you.
            </p>
            <FormControl fullWidth>
              <InputLabel id="problem">Define your problems</InputLabel>
              <Select
                labelId="problem"
                label="Define your problems"
                style={{ textAlign: "left" }}
                value={selectedProblem}
                onChange={handleChange}
              >
                {problems?.map((category) => (
                  <MenuItem value={category}>{category}</MenuItem>
                ))}
              </Select>
              <div style={{ textAlign: "center" }}>
                <Button
                  style={{
                    background: "#31C75A",
                    color: "#fff",
                    marginTop: "1rem",
                  }}
                  onClick={handleShow}
                >
                  Show Results
                </Button>
              </div>
            </FormControl>
          </Grid>
          <Container>
            <Grid
              container
              spacing={2}
              sx={{ mt: 10 }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {recommendDoctor?.name ? (
                <>
                  {
                    // recommendDoctor.map((recommendDoctor) => (
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "2rem",
                        marginLeft: "32px",
                      }}
                      item
                      xs={12}
                      md={4}
                    >
                      <div className="psychologist-box">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "2rem",
                          }}
                        >
                          <img
                            style={{ width: "100%", objectFit: "contain" }}
                            src={recommendDoctor.image}
                            className=""
                            alt={recommendDoctor.name}
                          />
                        </div>
                        <div>
                          <h2 className="psychologist-name">
                            {recommendDoctor.name}
                          </h2>
                          <h4 className="psychologist-title">
                            {recommendDoctor.designation}
                          </h4>
                        </div>

                        <div className="group-btn">
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/psychologists/details/${recommendDoctor?.name
                              ?.split(" ")
                              .join("-")}`}
                          >
                            <Button
                              fullWidth
                              style={{
                                backgroundColor: "#31C75A",
                                padding: "10px",
                                borderRadius: "15px",
                              }}
                              variant="contained"
                            >
                              Know More
                            </Button>
                          </Link>
                          <HashLink
                            to="#BookSession"
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              fullWidth
                              style={{
                                backgroundColor: "#31C75A",
                                padding: "10px",
                                borderRadius: "15px",
                              }}
                              variant="contained"
                              onClick={() => handleSelectValue(recommendDoctor)}
                            >
                              Book Session
                            </Button>
                          </HashLink>
                        </div>
                      </div>
                    </Grid>
                    // ))
                  }
                </>
              ) : (
                <></>
              )}
            </Grid>

            {selectedProblem && width > 996 ? (
              <div ref={bookingEndRef}></div>
            ) : null}

            {selectedPsychologists ? (
              <>
                <Box id="BookSession" sx={{ padding: "10px" }}>
                  <h2
                    style={{ textAlign: "center" }}
                    variant="h4"
                    gutterBottom
                    component="div"
                  >
                    Book Session
                  </h2>
                  <hr
                    style={{
                      display: "block",
                      width: "10%",
                      height: "3px",
                      backgroundColor: "#31C75A",
                      border: 0,
                    }}
                  />
                </Box>

                <Grid
                  container
                  spacing={2}
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "0",
                  }}
                >
                  <Grid item xs={12} md={2}>
                    <Paper style={{ borderRadius: "25px" }} elevation={12}>
                      <div style={{ textAlign: "center", padding: ".1rem" }}>
                        <h3>Available Days</h3>
                        {selectedPsychologists?.availableDateTimes?.map(
                          (qtm) => (
                            <p>{qtm?.date}</p>
                          )
                        )}
                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} md={4} className={style.outlinedToday}>
                    <Box
                      style={{
                        boxShadow:
                          "0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)",
                        borderRadius: "15px",
                        overflow: "hidden",
                      }}
                      sx={{
                        alignItems: "center",
                        borderRadius: "30px",
                        height: "100%",
                      }}
                      ref={
                        value && !selectedStartTime && width < 996
                          ? bookingEndRef
                          : null
                      }
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                          displayStaticWrapperAs="desktop"
                          openTo="day"
                          // disabled
                          minDate={new Date()}
                          shouldDisableDate={isWeekendDay}
                          value={value}
                          renderDay={renderWeekPickerDay}
                          onChange={handleValue}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Box
                      style={{ textAlign: "center", borderRadius: "15px" }}
                      sx={{ alignItems: "center" }}
                    >
                      {value && (
                        <Box>
                          {selectedPsychologists?.availableDateTimes?.map(
                            (pd) =>
                              pd.date == value && (
                                <>
                                  {selectedPsychologists?.availableDateTimes.map(
                                    (adt) =>
                                      adt.date === value &&
                                      adt.times.map((time) =>
                                        // checking filtered session date and time matching or not
                                        // if matching this means already booked this session
                                        (filteredSession[0]?.startTime ==
                                          time?.startTime &&
                                          filteredSession[0]?.endTime ==
                                            time?.endTime) ||
                                        (filteredSession[1]?.startTime ==
                                          time?.startTime &&
                                          filteredSession[1]?.endTime ==
                                            time?.endTime) ||
                                        (filteredSession[2]?.startTime ==
                                          time?.startTime &&
                                          filteredSession[2]?.endTime ==
                                            time?.endTime) ||
                                        (filteredSession[3]?.startTime ==
                                          time?.startTime &&
                                          filteredSession[3]?.endTime ==
                                            time?.endTime) ? (
                                          <div style={{ padding: "10px 0" }}>
                                            <Button
                                              style={{
                                                background: "#31C75A",
                                                color: "#fff",
                                              }}
                                            >
                                              Already Booked
                                            </Button>
                                          </div>
                                        ) : (
                                          <Box>
                                            <Button
                                              onClick={() =>
                                                handleSelectedTime(time)
                                              }
                                              style={{
                                                width: "90%",
                                                color:
                                                  time.startTime ===
                                                  selectedStartTime
                                                    ? "#fff"
                                                    : "#000",
                                                margin: ".5rem",
                                                border: "2px solid #31C75A",
                                                background:
                                                  time.startTime ===
                                                  selectedStartTime
                                                    ? "#31C75A"
                                                    : "transparent",
                                              }}
                                              variant="contained"
                                              color="success"
                                            >
                                              {time.startTime} - {time.endTime}
                                            </Button>
                                          </Box>
                                        )
                                      )
                                  )}
                                </>
                              )
                          )}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  {value && selectedStartTime && width < 996 ? (
                    <div ref={bookingEndRef}></div>
                  ) : null}

                  <Grid item xs={12} md={3}>
                    <div className="selectedPsychologist-box">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{
                            marginTop: "0.5rem",
                            width: "15rem",
                            height: "15rem",
                            objectFit: "contain",
                          }}
                          src={selectedPsychologists.image}
                          className="selectedPsychologistImg"
                          alt=""
                        />
                      </div>
                      <div>
                        <h2 className="psychologist-name">
                          {selectedPsychologists.name}
                        </h2>
                        <h4 className="psychologist-title">
                          {selectedPsychologists?.designation}
                        </h4>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "2rem",
                  }}
                >
                  {selectedPsychologists?.availableDateTimes?.map(
                    (qtm) =>
                      qtm.date == value && (
                        <>
                          {value && selectedStartTime && (
                            <Button
                              onClick={handleSubmit}
                              style={{
                                backgroundColor: "#31C75A",
                                marginTop: "1rem",
                                padding: "10px 20px",
                                borderRadius: "15px",
                                color: "white",

                                width: "40%",
                              }}
                            >
                              Continue
                            </Button>
                          )}
                        </>
                      )
                  )}
                </div>
              </>
            ) : (
              <></>
            )}

            {!recommendDoctor.name ? (
              <div className="img_container">
                <img src={recommandImg} alt="recomment image" />
              </div>
            ) : (
              ""
            )}
          </Container>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
