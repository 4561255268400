import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import experienceImg from '../../../assets/images/experience-img.png';
import { useTheme } from '@material-ui/core/styles';

export default function Experience() {
    const theme = useTheme();
    return (
        <Container sx={{
            my: 10,
            [theme.breakpoints.down('xs')]: {
                my: 10,
            },
        }}>
            <Box >
                <Grid container spacing={12} sx={{ flexGrow: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} md={6}>
                        <img style={{ width: '100%', height: 'auto' }} src={experienceImg} alt="Psycure logo" />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h2 style={{fontSize: "1.8rem"}}>Join us on the journey of mental wellbeing</h2>
                        <p style={{ textAlign: 'justify', fontSize: "1.2rem" }}>
                            At Psycure, we aim to ensure your complete mental wellness through providing the support that is right for you. </p>
                        <p style={{ textAlign: 'justify', fontSize: "1.2rem" }}>
                            We are also continuously working for removing the stigma associated with mental health and building wellbeing champions. </p>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}