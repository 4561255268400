import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useTheme } from "@material-ui/core/styles";

export default function SummitHistory() {
  const theme = useTheme();
  return (
    <Container
      sx={{
        my: 10,
        [theme.breakpoints.down("xs")]: {
          my: 4,
        },
      }}
    >
      <Typography variant="h4" sx={{ textAlign: "center", fontWeight: "900" }}>
        Mental Health Summit Bangladesh 2024 In Numbers
      </Typography>
      <hr
        style={{
          display: "block",
          width: "10%",
          height: "3px",
          backgroundColor: "#31C75A",
          border: 0,
        }}
      />

      <Grid
        sx={{ pt: 5 }}
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 16 }}
      >
        {/* 500 */}
        <Grid item xs={12} sm={12} md={4}>
          <div style={{ textAlign: "center" }}>
            <p
              style={{
                fontSize: "5rem",
                color: "#31C75A",
                margin: "0px",
                fontWeight: "700",
              }}
            >
              <CountUp
                end={500}
                redraw={true}
                duration={1}
                suffix="+"
                // scrollSpyOnce={true}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </p>
            <Typography
              variant="p"
              style={{
                textAlign: "center",
                marginTop: "0px",
                fontSize: "1.2rem",
              }}
            >
              participants passionate about mental health.
            </Typography>
          </div>
        </Grid>
        {/* second */}
        <Grid item xs={12} sm={12} md={4}>
          <div style={{ textAlign: "center" }}>
            <p
              style={{
                fontSize: "5rem",
                color: "#31C75A",
                margin: "0px",
                fontWeight: "700",
              }}
            >
              <CountUp end={50} redraw={true} duration={1} suffix="+">
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </p>
            <Typography
              variant="p"
              style={{
                textAlign: "center",
                marginTop: "0px",
                fontSize: "1.2rem",
              }}
            >
              speakers sharing valuable insights.
            </Typography>
          </div>
        </Grid>
        {/* third */}
        <Grid item xs={12} sm={12} md={4}>
          <div style={{ textAlign: "center" }}>
            <p
              style={{
                fontSize: "5rem",
                color: "#31C75A",
                margin: "0px",
                fontWeight: "700",
              }}
            >
              <CountUp end={9} redraw={true} duration={1}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </p>
            <Typography
              variant="p"
              style={{
                textAlign: "center",
                marginTop: "0px",
                fontSize: "1.2rem",
              }}
            >
              interactive workshops for practical skill-building.
            </Typography>
          </div>
        </Grid>
        {/* fourth */}
        <Grid item xs={12} sm={12} md={4}>
          <div style={{ textAlign: "center" }}>
            <p
              style={{
                fontSize: "5rem",
                color: "#31C75A",
                margin: "0px",
                fontWeight: "700",
              }}
            >
              <CountUp end={5} redraw={true} duration={1}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </p>
            <Typography
              variant="p"
              style={{
                textAlign: "center",
                marginTop: "0px",
                fontSize: "1.2rem",
              }}
            >
              engaging panel discussions on various topics.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
