import React from 'react';
import HomeworkTable from '../HomeworkTable/HomeworkTable';

const HomeworkContainer = () => {
    return (
        <div>
            <HomeworkTable />
        </div>
    );
};

export default HomeworkContainer;