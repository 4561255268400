import React, { useEffect } from "react";
import Header from "../../Shared/Header/Header";
import Footer from "../../Shared/Footer/Footer";

import UnwindMindBanner from "./UnwindMindBanner";
import UnwindMindOverView from "./UnwindMindOverView";
import UnwindMindProgram from "./UnwindMindProgram";
import UnwindMindRules from "./UnwindMindRules";
import UnWindMindAwards from "./UnWindMindAwards";
import MediaShowcase from "./MediaShowCase";

import UnwindMindJudges from "./UnwindMindJudges";
import { Helmet } from "react-helmet-async";
// import axios from "axios";

const UnwindMind = () => {
  /*  useEffect(() => {
    axios
      .get("https://api.thepsycure.com/unwind-mind-2024")
      .then((data) => console.log(data.data))
      .catch((err) => console.log(err));
  }, []); */
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>The Psycure | Unwind Mind 2024</title>
        <meta
          name="description"
          content="Art washes away from the soul the dust of everyday life."
        />
        <meta property="og:type" content="Unwind Mind" />
        <meta property="og:title" content="Unwind Mind 2024" />
        <meta
          property="og:description"
          content="Art washes away from the soul the dust of everyday life."
        />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Funwind-mind-event.jpg?alt=media"
        />
      </Helmet>
      <Header />
      <div className="fellowship-banner">
        <UnwindMindBanner />
      </div>
      <UnWindMindAwards />
      <UnwindMindOverView />
      <UnwindMindProgram />
      <UnwindMindRules />
      <UnwindMindJudges />
      <MediaShowcase />

      <Footer />
    </>
  );
};

export default UnwindMind;
