import * as React from "react";

import {
  Button,

  CardMedia,

  Grid,
  Tooltip,
  Zoom,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import UserProfileDoctorImage from "./../../assets/images/UserProfileDoctorImage.png";
import videoCallIcon from "./../../assets/images/videoCallIcon.png";
import EastIcon from "@mui/icons-material/East";
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { useState } from "react";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";
// import FolderIcon from '@mui/icons-material/Folder';
import { Link } from "react-router-dom";
import SuccessAlert from "../../Shared/SuccessAlert/SuccessAlert";
import style from "./styles.module.css";

import { useTheme } from "@material-ui/core/styles";
import { dashboardPageEvent } from "../../utils/FacebookPixelEvent";
import happyEmoji from "../../assets/images/MoodJournal/4happy.png";
import Footer from "../../Shared/Footer/Footer";

function UserProfile(props) {
  const [homeworks, setHomeworks] = useState([]);
  // const [myHomeworks, setMyHomeworks] = useState([]);
  const [newStatus, setNewStatus] = useState([]);
  const [mySessions, setMySessions] = useState([]);
  const [completeTask, setCompleteTask] = useState(null);

  const { user } = useAuth();

  const theme = useTheme();

  useEffect(() => {
    dashboardPageEvent();
  }, []);

  // user homework
  useEffect(() => {
    fetch(`https://api.thepsycure.com/homeworks/${user?.email}`)
      .then((res) => res.json())
      .then((data) => {
        let sorted = data
          .filter((homework) => homework.status === "incomplete")
          .sort((a, b) => new Date(a.date) - new Date(b.date));
        setHomeworks(sorted);
      });
  }, [user?.email, newStatus]);

  let [patientHomework, setPatientHomework] = useState([]);

  function showingHomework() {
    let today = new Date().toISOString().slice(0, 10);
    let patientHW = homeworks?.filter(
      (homework) =>
        (new Date(homework?.date) <= new Date(today) &&
          new Date(homework?.lastDate) >= new Date(today) &&
          homework?.homework?.frequency === "Daily" &&
          !homework?.completed?.includes(today)) ||
        (homework?.homework?.frequency === "Weekly" &&
          !homework?.completed?.includes(today))
    );

    setPatientHomework(patientHW);
  }

  useEffect(() => {
    showingHomework();
  }, [homeworks]);

  useEffect(() => {
    const url = `https://api.thepsycure.com/session/${user?.email}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        // const userSession = data.filter(session => session.userDetails?.email === user?.email)
        const sorted = data?.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setMySessions(sorted);
      });
  }, [user?.email]);

  //  upcomming session filtering by date
  const upCommingSession = mySessions
    .filter(
      (session) =>
        new Date(session.date) >=
        new Date(new Date().toISOString().slice(0, 10))
    )
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  //  completed session filtering by date
  const completedSession = mySessions.filter(
    (session) =>
      new Date(session.date) <= new Date(new Date().toISOString().slice(0, 10))
  );

  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleUpdate = (data) => {
    let obj = {
      ...data,
      completed: [...data.completed, new Date().toISOString().slice(0, 10)],
    };

    fetch(`https://api.thepsycure.com/homeworkUpdate/${data?._id}`, {
      method: "PUT",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((result) => {
        setCompleteTask(data);
        setNewStatus(result);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setCompleteTask(null);
    }, 5000);
  }, [completeTask]);

  /* Link validation according to Date-time */

  const timeConverter = (time) => {
    let hours = time.split(":")[0];
    let meridian;
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
    } else {
      meridian = "PM";
    }
    return time.concat(" ", meridian);
  };

  const today = new Date().toISOString().slice(0, 10);
  var now = new Date();
  var time = now.getHours() + ":" + now.getMinutes();

  var currentTime = timeConverter(time);

  // showing greeting based on time
  let currentHours = new Date().getHours();

  let greet;
  if (currentHours < 12) {
    greet = "Good Morning";
  }
  if (currentHours >= 12 && currentHours < 18) {
    greet = "Good Afternoon";
  }

  if (currentHours >= 18 && currentHours < 24) {
    greet = "Good Evening";
  }
  return (
    <>
      <Container sx={{ py: 10 }} className={style.container}>
        <Grid
          spacing={0}
          container
          style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.25)" }}
          sx={{ alignItems: "center", borderRadius: "30px", mb: 10 }}
        >
          <Grid xs={12} md={6}>
            <Box sx={{ padding: "15px 30px" }}>
              <h2>
                Hello,{" "}
                <span style={{ color: "#FFC100" }}>{user?.displayName}</span>
              </h2>
              <p style={{ marginBottom: "2rem" }}>
                {greet}! Hope you are having a great time with Psycure. Don't
                forget to take care of your mental health.
              </p>
              {/* 

                        <div
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more" style={{ textDecoration: 'none', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer' }}>{expanded ? 'Read Less' : 'Read More'}<ArrowRightIcon /></div>


                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                <p>
                                    Fluoride treatments are typically professional treatments containing a high concentration of fluoride that a dentist or hygienist will apply to a persons teeth to improve health and reduce the risk of cavities.
                                </p>
                            </CardContent>
                        </Collapse> */}
            </Box>
          </Grid>

          <Grid xs={12} md={6}>
            <Box>
              <CardMedia
                className={style.img}
                sx={{ width: "90%", marginTop: "-90px" }}
                component="img"
                alt="sign in"
                image={UserProfileDoctorImage}
              />
            </Box>
          </Grid>
        </Grid>

        {/* <Container sx={{ my: 20 }} className={style.spacer}>
                    <BookingModal handleClose={handleClose} open={open}></BookingModal>
                </Container> */}

        {/* mySessions ? */}

        <Grid container spacing={3} sx={{ pb: 3 }}>
          <Grid item md={6} sm={12}>
            {/* <Item>xs=8</Item>
             */}
            <Box
              style={{
                boxShadow: "0px 10px 22px rgba(0, 0, 0, 0.25)",
                padding: "2rem",
                height: "23.7rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              sx={{
                alignItems: "center",
                borderRadius: "30px",
                pt: 2,
                px: 2,
                pb: 5,
              }}
            >
              <h4 style={{ textAlign: "center", color: "black" }}>
                Upcoming Schedule
              </h4>

              {upCommingSession.length ? (
                <>
                  {upCommingSession.slice(0, 1)?.map((session) => (
                    <>
                      {session.date ? (
                        <>
                          <Box style={{ borderRadius: "2rem" }}>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <CardMedia
                                  sx={{
                                    width: "150px",
                                    paddingY: "1rem",
                                    borderRadius: "25px",
                                  }}
                                  component="img"
                                  alt="Psychologist Image"
                                  image={session?.selectedPsychologists?.image}
                                />
                                <Box>
                                  <h4>
                                    {session?.selectedPsychologists?.name}
                                  </h4>
                                  <p>{session?.selectedPsychologists?.title}</p>
                                </Box>

                                <Box>
                                  <Link to={`/video-session/${session?._id}`}>
                                    <Tooltip
                                      title="Join your meeting"
                                      placement="top"
                                      arrow
                                      TransitionComponent={Zoom}
                                    >
                                      <CardMedia
                                        sx={{
                                          width: "100%",
                                          cursor: "pointer",
                                        }}
                                        component="img"
                                        alt="sign in"
                                        image={videoCallIcon}
                                        // onClick={() => handleMeeting(session?._id)}
                                      />
                                    </Tooltip>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ textAlign: "center" }}>
                              <Link
                                to={`/video-session/${session?._id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  fullWidth
                                  style={{
                                    backgroundColor: "#31C75A",
                                    borderRadius: "15px",
                                    padding: "10px",
                                  }}
                                  variant="contained"
                                >
                                  {session?.date} {session?.day}
                                  <EastIcon sx={{ px: 0.5 }} />{" "}
                                  {session?.startTime}-{session?.endTime}
                                </Button>
                              </Link>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ textAlign: "center" }}>
                      <h2>Book a session with a certified psychologist</h2>
                      <NavLink
                        to="/Dashboard/choose-your-psychologist"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#31C75A" }}
                          sx={{ borderRadius: "25px", px: 3 }}
                        >
                          Book Session
                        </Button>
                      </NavLink>
                    </div>
                  </div>
                </>
              )}
            </Box>
          </Grid>

          <Grid item md={6} sm={12} style={{ width: "100%" }}>
            <Box
              style={{
                boxShadow: "0px 10px 22px rgba(0, 0, 0, 0.25)",
                height: "23.7rem",
                overflowY: "scroll",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              sx={{
                alignItems: "center",
                borderRadius: "30px",
                pt: 2,
                px: 2,
                pb: 5,
              }}
            >
              <h4 style={{ textAlign: "center" }}>Today’s Homework</h4>

              {patientHomework.length ? (
                <>
                  {patientHomework?.map((task) => (
                    <Box
                      sx={{
                        alignItems: "center",
                        padding: "13px",
                        background: "#E9FAED",
                        borderRadius: " 20px",
                        marginBottom: "20px",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        {/* <CardMedia
                                                sx={{ width: '45px', height: '45px', mr: 2 }}
                                                component="img"
                                                alt="sign in"
                                                image={breathIcon}
                                            /> */}
                        <Box>
                          {task?.homework?.title ? (
                            <h5 style={{ color: "#31C75A" }}>
                              {task?.homework?.title}
                            </h5>
                          ) : (
                            <></>
                          )}

                          {task?.homework?.description ? (
                            <p style={{ color: "#31C75A" }}>
                              {task?.homework?.description}
                            </p>
                          ) : (
                            <></>
                          )}

                          {task?.homework?.image ? (
                            <a href={task?.homework?.image} target="_blank">
                              <p>Click here for more instruction Instruction</p>
                              {/* <img style={{ width: "150px", height: "60px", objectFit: "cover" }} src={task?.homework?.image} /> */}
                            </a>
                          ) : (
                            //     <Button style={{ backgroundColor: "#31C75A" }} variant="contained" ><FolderIcon></FolderIcon>File</Button>
                            // </a>

                            <></>
                          )}

                          <p style={{ color: "#31C75A" }}>
                            {" "}
                            Assigned by : {task?.docName}
                          </p>
                        </Box>
                      </Box>

                      <Box
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {task.status === "Complete" ? (
                          <Button
                            disabled
                            variant="contained"
                            style={{ backgroundColor: "#31C75A" }}
                            sx={{ borderRadius: "25px", px: 3 }}
                          >
                            Complete{" "}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#31C75A" }}
                            sx={{ borderRadius: "25px", px: 3 }}
                            onClick={() => handleUpdate(task)}
                          >
                            Complete
                          </Button>
                        )}
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ textAlign: "center" }}>
                    <h3>You don't have any homework today</h3>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "1rem",
                      }}
                    >
                      <h4>Just keep smiling </h4>
                      <img
                        src={happyEmoji}
                        alt="happy image"
                        style={{ width: "40px", height: "40px" }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {completeTask ? (
                <SuccessAlert>Homework Completed.</SuccessAlert>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* <DashboardFooter /> */}
      <Footer />
    </>
  );
}

export default UserProfile;
