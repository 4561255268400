import {  Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import angryImg from "../../../assets/images/MoodJournal/1angry.png"
import sadImg from "../../../assets/images/MoodJournal/2sad.png"
import notgoodImg from "../../../assets/images/MoodJournal/3notgood.png"
import happyImg from "../../../assets/images/MoodJournal/4happy.png"
import betterImg from "../../../assets/images/MoodJournal/5better.png"
import ReadingJournal from '../../UserProfile/Journal/ReadingJournal';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#E6F1EC",
        color: "#000",
        textAlign: "center"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#E6F1EC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(date, time, mind, feeling) {
    return { date, time, mind, feeling };
}

export default function AllMoods({ moods, name }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [open, setOpen] = useState(false);

    const [selectedMood, setSelectedMood] = useState({});

    const handleRead = (data) => {
        setSelectedMood(data);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = [];

    moods.forEach(mood => rows.push(createData(mood.date, mood.date, mood.mind, mood.feeling)))

    return (
        <>
            <Grid item xs={12} md={6}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="customized table">
                        <TableHead style={{ marginBottom: "2rem" }}>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell><strong>Patient Name</strong></StyledTableCell>
                                <StyledTableCell><strong>Date</strong></StyledTableCell>
                                <StyledTableCell ><strong>Time</strong></StyledTableCell>
                                <StyledTableCell ><strong>Mood</strong></StyledTableCell>
                                <StyledTableCell ><strong>Journal</strong></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell >

                                    </StyledTableCell>
                                    <StyledTableCell  className="table_center">
                                        {name}
                                    </StyledTableCell>
                                    <StyledTableCell  className="table_center">
                                        {row.date.split(",")[0]}
                                    </StyledTableCell>
                                    <StyledTableCell className="table_center">{row.date.split(",")[1]}</StyledTableCell>


                                    <StyledTableCell  className="table_center"><img src={row.feeling === "angry" && angryImg || row.feeling === "sad" && sadImg || row.feeling === "notgood" && notgoodImg || row.feeling === "happy" && happyImg || row.feeling === "better" && betterImg} alt={row.feeling} /></StyledTableCell>
                                    <StyledTableCell  className="table_center"><Button onClick={() => handleRead(row)} style={{ background: "#31C75A", color: "#fff", borderRadius: "100px" }}>Read</Button></StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
            {
                selectedMood && <ReadingJournal selectedJournal={selectedMood} handleClose={handleClose} open={open} />
            }
        </>
    )
}
