import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import AllPromo from "./AllPromo";

export default function PromoCode() {
  let [error, setError] = useState("");
  let [success, setSuccess] = useState("");
  let [promo, setPromo] = useState({
    name: "",
    percentage: 0,
    maxDiscount: 0,
    validity: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch("https://api.thepsycure.com/add-promo", {
      method: "POST",
      body: JSON.stringify(promo),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      setError("Could not upload your blog. Please try again");
    }
    if (response.ok) {
      setPromo({
        name: "",
        percentage: 0,
        maxDiscount: 0,
        validity: "",
      });
      setSuccess("Congratulations, Blog Added");
    }
  };
  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Add Promo</h2>
      <form className="mode" onSubmit={handleSubmit}>
        <Box style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
          <TextField
            required
            sx={{ width: "95%", m: 2 }}
            id="outlined-required"
            label="Name"
            defaultValue={promo?.name}
            onChange={(e) => setPromo({ ...promo, name: e.target.value })}
          />
          <TextField
            required
            sx={{ width: "95%", m: 2 }}
            id="outlined-required"
            label="Percentage"
            defaultValue={promo?.percentage}
            onChange={(e) => setPromo({ ...promo, percentage: e.target.value })}
          />
        </Box>

        <Box style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
          <TextField
            required
            sx={{ width: "95%", m: 2 }}
            id="outlined-required"
            label="Max Discount"
            defaultValue={promo?.maxDiscount}
            onChange={(e) =>
              setPromo({ ...promo, maxDiscount: e.target.value })
            }
          />
          <TextField
            required
            sx={{ width: "95%", m: 2 }}
            id="outlined-required"
            type="date"
            defaultValue={promo?.validity}
            onChange={(e) => setPromo({ ...promo, validity: e.target.value })}
          />
        </Box>

        <Box style={{ textAlign: "center" }}>
          <Button
            style={{
              backgroundColor: "#31C75A",
              maxWidth: "300px",
              margin: "0 auto",
            }}
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </form>
      <AllPromo />
    </div>
  );
}
