import React, { useEffect, useState } from 'react';
import AppointmentTable from './AppointmentTable/AppointmentTable';

const UpcomingAppointment = ({ sessions }) => {

    const [foundResults, setFoundResults] = useState(sessions);

    const filter = (e) => {
        e.preventDefault();
        const keyword = e.target.value.toLowerCase();

        const results = sessions?.filter((session) => {
            return session?.userDetails?.name?.toLowerCase().includes(keyword);
            // Use the toLowerCase() method to make it case-insensitive
        });

        setFoundResults(results);
        // if (results.length) {
        // } 
    };

    useEffect(() => {
        setFoundResults(sessions);
    }, [sessions])

    return (
        <div>
            <div className='appointment-first'>
                <h3>Upcoming Appointment</h3>
                <form style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }} action="">
                    <input type="search" name="search" placeholder='Type here to search' id="data-filter"

                        onChange={filter}
                    />
                    {/* <SearchIcon style={{ cursor: 'pointer' }} /> */}
                </form>
            </div>
            <AppointmentTable
                foundResults={foundResults}
            />
        </div>
    );
};

export default UpcomingAppointment;