import React, { useEffect } from "react";
import Pricing from "./Pricing";
import Header from "../../Shared/Header/Header";
import Footer from "../../Shared/Footer/Footer";
import ReactGA from "react-ga4";

const GiftCard = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: "Gift card",
    });
  }, []);
  return (
    <>
      <Header></Header>
      <div
        style={{
          margin: "8% 0",
        }}
      >
        <Pricing></Pricing>
      </div>
      <Footer></Footer>
    </>
  );
};

export default GiftCard;
