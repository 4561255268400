import React, { useEffect, useState } from "react";
import Header from "../../../Shared/Header/Header";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const FailedPaymentWorkshop = () => {
  const [countDown, setCountdown] = useState(3);
  const navigate = useNavigate();
  useEffect(() => {
    if (countDown == 0) {
      navigate("/workshop");
    }

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clear the timeout when the component unmounts to avoid memory leaks
    return () => {
      clearInterval(countdownInterval);
    };
  }, [countDown]);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: "Payment failed for workshop",
    });
  }, []);
  return (
    <div>
      <Header></Header>
      <p style={{ margin: "5% 0", textAlign: "center" }}>
        Failed workshop payment. Redirecting to workshop page in {countDown}{" "}
        seconds..
      </p>
    </div>
  );
};

export default FailedPaymentWorkshop;
