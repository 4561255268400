import { Box, Typography } from "@mui/material";
import React from "react";

const SummitHeader = () => {
  return (
    <div>
      <Box
        component="div"
        container
        sx={{
          height: "500px",
          backgroundImage:
            "url('https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/summit%2Fsenate.jpg?alt=media'), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          // backgroundAttachment: "scroll",
          position: "relative",
          backgroundBlendMode: "overlay",
          //   backgroundColor: "black",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "86%",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              textTransform: "capitalize",
              color: "white",
              fontFamily: "DMSans, sans-serif",
              fontWeight: "900",
              // wordWrap: "normal",
            }}
            className="summit-name-text"
          >
            Mental Health Summit 2024, Bangladesh, 22-23 November,
            <br />
            <Typography variant="h5" sx={{ mt: 2 }}>
              Nabab Nawab Ali Chowdhury Senate Bhaban, DU
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              textTransform: "capitalize",
              color: "#36ca5a",
              fontFamily: "DMSans, sans-serif",
              fontWeight: "900",
              margin: "2% 0",
            }}
          >
            Registration Deadline- Nov 12, 2024
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default SummitHeader;
