import React, { useEffect } from "react";
import { Container } from "@mui/system";
import Header from "../../Shared/Header/Header";
import Footer from "../../Shared/Footer/Footer";
import ReactGA from "react-ga4";

const Privacy = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Privacy Policy`,
    });
  }, []);
  return (
    <>
      <Header />
      <Container sx={{ my: 15 }}>
        <h2
          style={{ textAlign: "center" }}
          variant="h4"
          gutterBottom
          component="div"
        >
          Privacy Policy
        </h2>
        <hr
          style={{
            display: "block",
            width: "10%",
            height: "3px",
            backgroundColor: "#31C75A",
            border: 0,
          }}
        />

        <p style={{ textAlign: "justify" }} variant="body1" gutterBottom>
          Thank you for being with Psycure. We are committed to protect your
          personal information and privacy. If you have any questions or queries
          regarding our Privacy Policy, please contact us at
          connect@thepsycure.org
          <br />
          <br />
          We believe that whenever you use our website or our servers, you use
          them with complete confidence in your information and privacy. Your
          personal information and privacy is very important to us. We want to
          make clear to you through this policy what kind of information we
          actually collect, how we use that information and what rights you have
          over these matters.
          <br />
          <br />
          Hopefully, you will read the following policy carefully. Because it is
          very important for you. If there is anything in it that you do not
          agree with or that you think is risky. But you should contact us
          immediately. We also request that you read the following policy
          carefully, as this will give you a clear idea of ​​our activities.
          <br />
          <br />
          what we collect:
          <br />
          <br />
          In order to find out about us, to take an active part in our online
          forums and activities, or to contact us, you must voluntarily provide
          us with some of your personal information when you register on the
          website. We collect all that information.
          <br />
          <br />
          Depending on your affiliation with our website and the type of
          products and services you use, we may collect information from you.
          The information collected may include the following:
          <br />
          <br />
          1. Personal information you provide. Such as: phone number, e-mail
          address, name, address, etc.
          <br />
          2. Information about financial transactions. We collect information
          necessary for the convenience of your purchased product or
          transaction. Such as: credit card number and password associated with
          the medium of financial transaction.
          <br />
          3. Information about social media. You can register on our website if
          you want with the help of your social media (such as: Facebook or
          others) or social media account.
          <br />
          4. Information about social media. You can register on our website if
          you want with the help of your social media (such as: Facebook,
          Twitter or others) or social media account.
          <br />
          <br />
          Automatically data collection:
          <br />
          <br />
          We collect certain information when you visit or use our web pages.
          This information does not help us identify you (e.g. your name, where
          you live). However, it helps to know about the device with which you
          entered the site, the IP address key of the device, the operating
          system, the country where you are located, the language preferences,
          etc. There is also information about when and how you entered our
          site.
          <br />
          <br />
          How we use your information:
          <br />
          <br />
          We use your information for our business interests only with your
          consent and in accordance with our legal obligations.
          <br />
          <br />
          Do we use cookies and other tracking technologies?
          <br />
          <br />
          Yes. Sometimes we use cookies and other tracking technologies to
          gather information.
          <br />
          <br />
          Do we update policies?
          <br />
          <br />
          Yes. We update the policies as required to ensure that there are no
          violations of the existing and relevant laws.
          <br />
          <br />
          Ways to contact us at:
          <br />
          <br />
          If you have any questions or comments, please email us at:
          connect@thepsycure.org
          <br />
          <br />
          Congratulations on being with Psycure at the end of it all.
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default Privacy;
