import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import { Container } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import reviewMan from "../../assets/images/reviewMan.png";
import reviewWomen from "../../assets/images/reviewFemale.png";
import "./PsychologistDashboard.css";
import { ProgressBarLine } from "react-progressbar-line";
import UpcomingAppointment from "./UpcomingAppointment/UpcomingAppointment";
import { useEffect } from "react";
import WaitForApproval from "./WaitForApproval/WaitForApproval";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useTheme } from "@material-ui/core/styles";
import Footer from "../../Shared/Footer/Footer";

const PsychologistDashboard = () => {
  const { user } = useAuth();
  const theme = useTheme();

  const [sessions, setSessions] = React.useState([]);
  const [psychologist, setPsychologist] = React.useState([]);

  let today = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    const url = `https://api.thepsycure.com/doctorSession/${user?.email}`;
    fetch(url)
      .then((res) => res.json())
      .then((psychologistSession) => {
        // const psychologistSession = data?.filter(data => data?.selectedPsychologists?.email === user?.email)
        const sorted = [...psychologistSession]?.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

        setSessions(sorted);
      });
  }, [user?.email]);

  useEffect(() => {
    sessions?.forEach((session) => {
      if (
        new Date(session?.date) < new Date(today) &&
        session?.status !== "complete"
      ) {
        let obj = {
          ...session,
          status: "complete",
        };

        fetch(`https://api.thepsycure.com/session/${session?._id}`, {
          method: "PUT",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify(obj),
        })
          .then((res) => res.json())
          .then((result) => {
            let data = result;
          })
          .catch((err) => {
            let error = err.meesage;
          });

        fetch(
          `https://api.thepsycure.com/psychologists/${session?.selectedPsychologists?.email}`
        )
          .then((res) => res.json())
          .then((selectPsychologist) => {
            let paymentSession = {
              id: session?._id,
              date: session?.date,
              patientEmail: session?.patientEmail,
              doctorEmail: session?.docEmail,
              ammount: session?.amount,
              isDisbursed: false,
            };

            let isSessionHave = selectPsychologist?.payment?.some(
              (element) => element?.id === session?._id
            );

            if (!isSessionHave) {
              let updatedObj = {
                ...selectPsychologist,
                payment: [...selectPsychologist?.payment, paymentSession],
              };
              fetch(
                `https://api.thepsycure.com/psychologist/${selectPsychologist?.email}`,
                {
                  method: "PUT",
                  headers: { "content-type": "application/json" },
                  body: JSON.stringify({ updatedObj }),
                }
              )
                .then((res) => res.json())
                .then((result) => {
                  const data = result;
                });
            }
          });
      }
    });
  }, [sessions]);

  useEffect(() => {
    fetch(`https://api.thepsycure.com/psychologists/${user?.email}`)
      .then((res) => res.json())
      .then((data) => setPsychologist(data));
  }, [user?.email]);

  const upCommingSession = sessions
    .filter(
      (session) =>
        new Date(session.date) >=
        new Date(new Date().toISOString().slice(0, 10))
    )
    .sort((a, b) => new Date(a.date) - new Date(b.date));
  const completedSession = sessions.filter(
    (session) =>
      new Date(session.date) <= new Date(new Date().toISOString().slice(0, 10))
  );

  return (
    <>
      {psychologist?._id ? (
        <>
          {psychologist.approved === true ? (
            <>
              <Container style={{ padding: "30px 0px 0" }}>
                <Box>
                  <h2
                    style={{ textAlign: "center", marginTop: "-35px" }}
                    variant="h4"
                    gutterBottom
                    component="div"
                  >
                    Psychologist Dashboard
                  </h2>
                  <hr
                    style={{
                      display: "block",
                      width: "10%",
                      height: "3px",
                      backgroundColor: "#31C75A",
                      border: 0,
                    }}
                  />
                </Box>
                <Container
                  sx={{
                    py: 10,
                    [theme.breakpoints.down("xs")]: {
                      py: 2,
                    },
                  }}
                >
                  <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {upCommingSession.length ? (
                      <>
                        <Grid item md={5} sx={12}>
                          <Box
                            className="patientBox"
                            style={{
                              boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.25)",
                            }}
                            sx={{
                              alignItems: "center",
                              borderRadius: "30px",
                              pt: 2,
                              px: 2,
                              pb: 5,
                            }}
                          >
                            <h4
                              style={{ textAlign: "center", color: "#31C75A" }}
                            >
                              Patient
                            </h4>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                sx={{ width: "100%" }}
                                src={
                                  upCommingSession[0]?.userDetails?.patientInfo
                                    ?.gender === "male"
                                    ? reviewMan
                                    : reviewWomen
                                }
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "50%",
                                }}
                                alt="Patient Pic"
                              />
                            </div>

                            <h4
                              style={{ textAlign: "center", color: "#31C75A" }}
                            >
                              {upCommingSession[0]?.userDetails?.name}
                              <br />
                            </h4>
                            <Box sx={{ textAlign: "center" }}>
                              <Button
                                fullWidth
                                style={{
                                  backgroundColor: "#31C75A",
                                  borderRadius: "15px",
                                  padding: "10px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                                variant="contained"
                              >
                                <CalendarMonthIcon />{" "}
                                {upCommingSession[0]?.date}{" "}
                                <span>{upCommingSession[0]?.selectedTime}</span>
                              </Button>
                            </Box>

                            <Box
                              sx={{ textAlign: "center", marginTop: "1rem" }}
                            >
                              <Link
                                to={`/video-session/${upCommingSession[0]?._id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  fullWidth
                                  style={{
                                    backgroundColor: "#31C75A",
                                    borderRadius: "15px",
                                    padding: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                  variant="contained"
                                >
                                  Join Session
                                </Button>
                              </Link>
                            </Box>

                            {/* =========> TWO BUTTON<========= */}
                            {/* <div className='meet-btns'>
                                    <Link to={`/psychologist-dashboard/session/${upCommingSession[0]?._id}`}>
                                      <button className='report-btn'>Report</button>
                                    </Link>

                                    <Link to={`/video-session/${upCommingSession[0]?._id}`} style={{ textDecoration: "none" }}>
                                      <p className='join-btn' rel="noopener noreferrer">
                                        Join Session</p>
                                    </Link>

                                  </div> */}
                          </Box>
                        </Grid>

                        {/* =================Recommended Card=================== */}

                        <Grid item md={7} sx={12}>
                          <Box
                            style={{
                              boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.25)",
                            }}
                            sx={{
                              alignItems: "center",
                              borderRadius: "30px",
                              pt: 5,
                              px: 2,
                              pb: 8,
                            }}
                          >
                            <h4
                              style={{ textAlign: "center", color: "#31C75A" }}
                            >
                              Recommendation
                            </h4>
                            <div
                              style={{ marginTop: "10px", textAlign: "center" }}
                            >
                              <h4 className="psychologist-recommend">
                                Recommended By
                              </h4>
                              <div className="bar-line">
                                <ProgressBarLine
                                  value={
                                    psychologist?.reviews?.length
                                      ? (psychologist?.reviews?.filter(
                                          (review) => review?.rating == "like"
                                        ).length /
                                          psychologist?.reviews.length) *
                                        100
                                      : 0
                                  }
                                  min={0}
                                  max={100}
                                  strokeWidth={3}
                                  trailWidth={3}
                                  styles={{
                                    path: {
                                      stroke: "#17b978",
                                    },
                                    trail: {
                                      stroke: "#a7ff83",
                                    },
                                    text: {
                                      fill: "#404040",
                                      textAlign: "center",
                                      fontSize: "18px",
                                    },
                                  }}
                                />
                              </div>
                              {upCommingSession?.slice(0, 2).map((session) => (
                                <div className="patients">
                                  <div>
                                    <img
                                      src={
                                        session?.userDetails?.patientInfo
                                          ?.gender === "male"
                                          ? reviewMan
                                          : reviewWomen
                                      }
                                      alt="patient pic"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <p>
                                      {session?.userDetails?.name} <br />{" "}
                                    </p>
                                  </div>
                                  <div>
                                    <small>{session?.date}</small> <br />
                                    <small>{session?.selectedTime}</small>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Box>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item md={5} sx={12}>
                          <Box
                            style={{
                              boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.25)",
                            }}
                            sx={{
                              alignItems: "center",
                              borderRadius: "30px",
                              pt: 2,
                              px: 2,
                              pb: 5,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ textAlign: "center" }}>
                                <AccountCircleIcon
                                  style={{ fontSize: "5rem", color: "#31C75A" }}
                                ></AccountCircleIcon>
                                <h2> You don't have any Patient yet</h2>
                              </div>
                            </div>
                          </Box>
                        </Grid>

                        {/* =================Recommended Card=================== */}

                        <Grid item md={7} sm={12}>
                          <Box
                            style={{
                              boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.25)",
                            }}
                            sx={{
                              alignItems: "center",
                              borderRadius: "30px",
                              pt: 5,
                              px: 2,
                              pb: 8,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ textAlign: "center" }}>
                                <h3>You don't have any Recommendation</h3>
                                <h4>Just keep Smiling</h4>
                              </div>
                            </div>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Container sx={{ my: 8 }}></Container>
                  <Box
                    container
                    style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.25)" }}
                    sx={{ alignItems: "center", borderRadius: "30px", mb: 10 }}
                  >
                    {sessions.length ? (
                      <UpcomingAppointment sessions={sessions} />
                    ) : (
                      <>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <h2>You don't have any Patient yet</h2>
                          </div>
                        </div>
                      </>
                    )}
                  </Box>
                </Container>

                {/* <Box container style={{ boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.25)" }} sx={{ alignItems: "center", borderRadius: "30px", mb: 10 }}>
                      <UpcomingAppointment
                        sessions={sessions}
                      />
                    </Box> */}
              </Container>
            </>
          ) : (
            <>
              <WaitForApproval></WaitForApproval>
            </>
          )}
        </>
      ) : (
        <>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            {/* <CircularProgress /> */}
            <div>
              <p>
                Please provide your{" "}
                <Link to="/psychologist-details">Details</Link>
              </p>
            </div>
          </Box>
        </>
      )}
      {/* <DashboardFooter /> */}
      <Footer />
    </>
  );
};

export default PsychologistDashboard;
