import React from "react";

import { motion } from "framer-motion";
import "./styles.css";

import mainImage from "../../../assets/images/parallax/character.svg";
import leftImage from "../../../assets/images/parallax/plants 1.svg";
import rightImage from "../../../assets/images/parallax/plants 2.svg";

import { useParallax } from "./useParallax";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";
const ParallaxComponent = () => {
  const {
    primaryX,
    primaryY,
    thirdX,
    thirdY,
    onMouseMoveHandler,
    onMouseLeaveHandler,
  } = useParallax();

  return (
    <section
      style={{ marginTop: "10%", marginBottom: "5%" }}
      className="section-one-parent"
    >
      <div>
        <div className="healing-text-content">
          <h1 className="healing">
            Start your healing journey{" "}
            <span style={{ fontWeight: "bold" }}>now</span>
          </h1>
          <div className="healing-button">
            <Link to="/booksession">
              <button className="healing-button-link">Book Session</button>
            </Link>
            <Link to="/corporate-mental-health">
              <button className="corporate-mental-health">
                Corporate Mental Health
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <motion.div
          className="parallax"
          onMouseMove={onMouseMoveHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          <div className="left-image-div">
            <motion.img
              style={{
                x: primaryX,
                y: primaryY,
              }}
              src={leftImage}
              className="left-image"
            ></motion.img>
          </div>
          <Tilt
            className="parallax-effect-img"
            tiltMaxAngleX={20}
            tiltMaxAngleY={20}
            perspective={800}
            transitionSpeed={1500}
            scale={1.1}
            gyroscope={false}
          >
            <img src={mainImage} className="inner-element" alt="pic" />
          </Tilt>

          <div className="right-image-div">
            <motion.img
              style={{
                x: thirdX,
                y: thirdY,
              }}
              src={rightImage}
              className="right-image"
            ></motion.img>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ParallaxComponent;
