import {
  Box,
  Button,
  Grid,
  Container,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function AdminPsychologistDetails() {
  const [counselling, setCounselling] = useState([
    { id: "1", value: "regular" },
    { id: "2", value: "student" },
    { id: "3", value: "premium" },
    { id: "4", value: "super premium" },
  ]);
  let { id } = useParams();
  const [psychologistDetails, setPsychologistDetails] = useState([]);
  const [pricingList, setPricingList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate("");
  const [editState, setEditState] = useState(false);
  const [counsellingType, setCounsellingType] = useState([]);
  const [selectedCounsellingType, setSelectedCounsellingType] = useState([]);

  const handleProblemChange = (event) => {
    const {
      target: { value },
    } = event;
    setCounsellingType(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useLayoutEffect(() => {
    const fetchPsychologist = async () => {
      await fetch("https://api.thepsycure.com/psychologists")
        .then((res) => res.json())
        .then((data) => {
          const foundPsychologist = data.filter((detail) => detail._id === id);
          setIsLoading(false);
          setPsychologistDetails(foundPsychologist[0]);
        });

      await fetch(`https://api.thepsycure.com/pricing-list`)
        .then((res) => res.json())
        .then((data) => {
          setPricingList(data[0]);
        });
    };
    fetchPsychologist();
  }, [id, editState]);

  const handleApproved = (data) => {
    const approvedData = {
      ...data,
      approved: true,
      counselingType: selectedCounsellingType,
    };

    if (approvedData?.amount) {
      fetch(`https://api.thepsycure.com/psychologists/${data?.email}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(approvedData),
      })
        .then((res) => res.json())
        .then((data) => {
          setError("");
          navigate("/admin-dashboard/psychologist");
        })
        .catch((err) => {
          let error = err.message;
        });
    } else {
      setError("Please select the amount from edit window");
    }
  };
  const handleDisApproved = (data) => {
    const approvedData = {
      ...data,
      approved: false,
      counselingType: selectedCounsellingType,
    };

    if (approvedData?.amount) {
      fetch(`https://api.thepsycure.com/psychologists/${data?.email}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(approvedData),
      })
        .then((res) => res.json())
        .then((data) => {
          setError("");
          navigate("/admin-dashboard/psychologist");
        })
        .catch((err) => {
          let error = err.message;
        });
    }
  };

  useEffect(() => {
    setSelectedCounsellingType(
      counsellingType.map((obj) => counselling[obj - 1].value)
    );
  }, [counsellingType]);

  const {
    name,
    email,
    degree,
    location,
    phone,
    image,
    cv,
    category,
    problems,
    approved,
    availableDateTimes,
    maxCharge,
    minCharge,
  } = psychologistDetails;
  console.log(psychologistDetails);
  const joinProblem = problems?.join(",");

  // for edit states

  const [amount, setAmount] = useState();
  const [newName, setNewName] = useState(name);
  const [newDegree, setNewDegree] = useState(degree);
  const [newPhone, setNewPhone] = useState(phone);
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editedData = {
      ...psychologistDetails,
      amount: amount,
      name: newName ? newName : name,
      degree: newDegree ? newDegree : degree,
      phone: newPhone ? newPhone : phone,
    };
    console.log(editedData);
    await fetch(
      `https://api.thepsycure.com/psychologists/${psychologistDetails?.email}`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(editedData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setError("");
        setEditState(!editState);
      })
      .catch((err) => {
        let error = err.message;
      });
  };

  return (
    <Container>
      <Grid
        container
        spacing={2}
        alignItems="center"
        key={name}
        sx={{ flexGrow: 1 }}
      >
        <Grid item sm={12} justifyContent="center" md={4}>
          <img
            style={{ width: "90%", height: "auto", borderRadius: "15px" }}
            src={`${image}`}
            alt=""
          />
        </Grid>
        {editState ? (
          <Grid
            item
            sm={12}
            md={8}
            style={{
              width: "100%",
              background: "rgba(0, 0, 0, .1)",
              padding: "40px",
              borderRadius: "15px",
            }}
          >
            <div className="form">
              <form onSubmit={handleUpdate}>
                <label htmlFor="name">Name</label>
                <br />
                <input
                  onChange={(e) => setNewName(e.target.value)}
                  style={{ width: "500px", padding: "10px" }}
                  defaultValue={name}
                  className=""
                  type="text"
                  name=""
                  id="name"
                />
                <label htmlFor="degree">Degree</label>
                <br />
                <input
                  onChange={(e) => setNewDegree(e.target.value)}
                  style={{ width: "500px", padding: "10px" }}
                  defaultValue={degree}
                  className=""
                  type="text"
                  name=""
                  id="degree"
                />
                <label htmlFor="amount">Amount</label>
                <br />
                <input
                  onChange={(e) => setAmount(e.target.value)}
                  style={{ width: "500px", padding: "10px" }}
                  defaultValue={psychologistDetails?.amount}
                  required
                  className=""
                  type="number"
                  name=""
                  id="amount"
                />
                <label htmlFor="phone">Phone</label>
                <br />
                <input
                  onChange={(e) => setNewPhone(e.target.value)}
                  style={{ width: "500px", padding: "10px" }}
                  defaultValue={phone}
                  className=""
                  type="number"
                  name=""
                  id="phone"
                />
                <Button
                  style={{
                    backgroundColor: "#31C75A",
                    color: "#fff",
                    width: "500px",
                    marginTop: "10px",
                  }}
                  type="submit"
                >
                  Update
                </Button>
              </form>
            </div>
          </Grid>
        ) : (
          <Grid
            item
            sm={12}
            md={8}
            style={{
              width: "100%",
              background: "rgba(0, 0, 0, .1)",
              padding: "40px",
              borderRadius: "15px",
            }}
          >
            <h3>
              Name:{" "}
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                {name}
              </span>
            </h3>
            <h3>
              Email:{" "}
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                {email}
              </span>
            </h3>
            <h3>
              phone:{" "}
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                {phone}
              </span>
            </h3>
            <h3>
              Location:{" "}
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                {location}
              </span>
            </h3>
            <h3>
              Degree:{" "}
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                {degree}
              </span>
            </h3>
            <h3>
              Expected session charge:{" "}
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                {minCharge}-{maxCharge}
              </span>
            </h3>
            {psychologistDetails?.amount && (
              <h3>
                Selected session charge:{" "}
                <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                  {psychologistDetails?.amount}
                </span>
              </h3>
            )}
            <h3>
              Cv:{" "}
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                <a href={cv} target="_blank" style={{ color: "#31c75a" }}>
                  Link
                </a>
              </span>
            </h3>
            {category && (
              <h3>
                Category:{" "}
                <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                  {category}
                </span>
              </h3>
            )}
            {problems?.length ? (
              <h3>
                Problems:{" "}
                <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                  {joinProblem}
                </span>
              </h3>
            ) : (
              ""
            )}

            <FormControl
              style={{
                // margin: "1rem",
                width: "100%",
              }}
            >
              <InputLabel
                id="demo-multiple-Specialization-label"
                style={{ opacity: counsellingType.length ? "0" : "1" }}
              >
                Counselling type
              </InputLabel>
              <Select
                labelId="demo-multiple-Specialization-label"
                id="demo-multiple-Specialization"
                multiple
                value={counsellingType}
                onChange={handleProblemChange}
                input={
                  <OutlinedInput
                    id="select-multiple-Specialization"
                    label="Specialization"
                  />
                }
                renderValue={(selected) =>
                  selected.map((obj) => counselling[obj - 1].value).join(", ")
                }
              >
                {counselling.map((name) => (
                  <MenuItem
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      display: "flex",
                      width: "100%",
                    }}
                    key={name.id}
                    value={name.id}
                  >
                    <Checkbox checked={counsellingType.indexOf(name.id) > -1} />
                    <ListItemText primary={name?.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <InputLabel
            id="demo-simple-select-label"
            for="amount"
            style={{ fontSize: "16px", fontWeight: "normal" }}
          >
            Set price
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="amount"
            // value={age}
            label="Set price"
            required
            // name="amount"
            style={{ width: "100%" }}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          >
            <MenuItem value="">Choose Price</MenuItem>
            {pricingList?.pricingList?.map((list) => (
              <MenuItem value={list?.price}>{list?.category}</MenuItem>
            ))}
          </Select> */}
          </Grid>
        )}
        <Button
          style={{
            backgroundColor: "#31C75A",
            color: "#fff",
            width: "60%",
            marginLeft: "37%",
          }}
          onClick={() => setEditState(!editState)}
        >
          {editState ? "Cancel" : "Edit"}
        </Button>
      </Grid>
      <Grid container alignItems="center">
        <Grid
          item
          md={6}
          className="scroollbar"
          style={{
            maxHeight: "450px",
            marginTop: "3rem",
            width: "100%",
            overflowY: "scroll",
          }}
        >
          <Box style={{ background: "#F5F5F5", padding: "2rem" }}>
            <Typography
              variant="h4"
              style={{ textAlign: "center" }}
              sx={{
                fontSize: {
                  xs: "5vw",
                  sm: "3vw",
                  md: "2vw",
                },
              }}
            >
              Schedule
            </Typography>

            {availableDateTimes?.length
              ? availableDateTimes?.map((dateTimes) =>
                  dateTimes?.date && dateTimes?.times?.length ? (
                    <>
                      <Box key={dateTimes.id}>
                        <p>
                          <span style={{ color: "#31C75A" }}>Day:</span>{" "}
                          {dateTimes.date}
                        </p>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography>Start Time</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>End Time</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid container>
                          {dateTimes.times.map((time) => (
                            <>
                              <Grid item xs={6}>
                                <Typography>{time.startTime}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography>{time.endTime}</Typography>
                              </Grid>
                            </>
                          ))}
                        </Grid>
                      </Box>
                    </>
                  ) : (
                    ""
                  )
                )
              : ""}
          </Box>
        </Grid>
        {!approved ? (
          <Grid item md={6} style={{ padding: "20px" }}>
            <h2>Psychologist approve process</h2>
            <p>
              In order to approved the psychologist admin should click on{" "}
              <span style={{ color: "#31C75A" }}>Approve</span> button.
            </p>
            <div>
              <Button
                style={{
                  backgroundColor: "#31C75A",
                  color: "#fff",
                  width: "100%",
                }}
                onClick={() => handleApproved(psychologistDetails)}
              >
                {" "}
                Approve
              </Button>
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </Grid>
        ) : (
          <Grid item md={6} style={{ padding: "20px" }}>
            <h2>currently working with psycure</h2>
            <Button
              style={{
                backgroundColor: "red",
                color: "#fff",
                width: "100%",
              }}
              onClick={() => handleDisApproved(psychologistDetails)}
            >
              {" "}
              Disapprove
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
