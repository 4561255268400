import { Button, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import axios from "axios";

export default function TopWorkshop() {
  const [workshopData, setWorkshopData] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.thepsycure.com/workshop")
      .then((data) => {
        setWorkshopData(data.data);
        console.log(data.data);
      })

      .catch((err) => console.log(err));
  }, []);
  return (
    <Container>
      <h2 className="headin">Top Workshops</h2>
      <hr
        style={{
          display: "block",
          width: "10%",
          height: "3px",
          backgroundColor: "#31C75A",
          border: 0,
        }}
      />
      {/* <Grid sx={{ pt: 5 }} container columns={{ xs: 4, sm: 8, md: 12 }} > */}
      <div className="workshop">
        {workshopData?.map((workshop) => {
          let workshopParams = workshop?.worskshopTitle?.split(" ").join("-");
          return (
            <div style={{ backgroundColor: "#E9FAED", borderRadius: "15px" }}>
              <img
                src={workshop?.workshopImage}
                alt=""
                style={{
                  width: "100%",
                  height: "180px",
                  borderRadius: "15px 15px 0 0",
                }}
              />

              <div style={{ padding: "1rem 1.5rem" }}>
                <div>
                  <div style={{ height: "110px" }}>
                    <h2 style={{ fontSize: "1.3rem" }}>
                      {workshop?.worskshopTitle}
                    </h2>

                    {workshop?.eventType == "online" ? (
                      <p
                        style={{
                          display: "inline",
                          padding: "2px 10px",
                          borderRadius: "100px",
                          color: "#fff",
                          backgroundColor: "#27583A",
                          fontSize: "18px",
                        }}
                      >
                        {workshop?.eventType}
                      </p>
                    ) : (
                      <p
                        style={{
                          display: "inline",
                          padding: "2px 10px",
                          borderRadius: "100px",
                          color: "#27583A",
                          fontSize: "18px",
                          border: "1px solid #27583A",
                        }}
                      >
                        {workshop?.eventType}
                      </p>
                    )}
                  </div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: workshop?.workshopContent.slice(0, 70) + "...",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    padding: "1rem 0",
                  }}
                >
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/workshop/${workshopParams}`}
                  >
                    <Button
                      fullWidth
                      style={{
                        backgroundColor: "#31C75A",
                        padding: "10px",
                        borderRadius: "15px",
                      }}
                      variant="contained"
                    >
                      KNOW MORE
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
}
