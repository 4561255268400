import React, { useState } from "react";
import AvailableDate from "./Component/AvailableDate";
import PsychologistDetails from "./Component/PsychologistDetails";

export default function PsychologistDetailsPage() {
  let [page, setPage] = useState(1);
  let [details, setDetails] = useState({
    name: "",
    degree: "",
    location: "",
    phone: "",
    email: "",
    yourself: "",
    whyPsycure: "",
    category: [],
    image: "",
    maxCharge: 10000,
    minCharge: 100,
    totalSessions: 0,
    paymentRequest: false,
    approved: false,
    problems: [],
    availableDateTimes: [],
    payment: [],
    reviews: [],
    experience: 0,
    age: "",
    gender: "",
    amount: "",
    counselingType: [],
    designation: "",
    createdAt: "",
  });
  return (
    <div>
      {page === 1 && (
        <PsychologistDetails
          setPage={setPage}
          setDetails={setDetails}
          details={details}
        />
      )}
      {page === 2 && (
        <AvailableDate
          setDetails={setDetails}
          details={details}
          setPage={setPage}
          method="POST"
        />
      )}
    </div>
  );
}
