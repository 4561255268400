import { Box, Button, Container, TextField } from "@material-ui/core";

import React, { useEffect } from "react";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const SignUpSecondPage = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Psychologist signup details page`,
    });
  }, []);
  const handleSubmit = (data) => {};

  return (
    <>
      <Header />
      <Box>
        <Container
          sx={{
            flexGrow: 1,
          }}
          style={{
            background: "#FAFAFA",
            boxShadow: " 0px 24px 48px rgba(0, 0, 0, 0.25)",
            borderRadius: "40px",
          }}
        >
          <Box style={{ padding: "5rem", marginTop: "8rem" }}>
            <Box>
              <Box>
                <h2
                  style={{ textAlign: "center" }}
                  variant="h4"
                  gutterBottom
                  component="div"
                >
                  Psychologist Details
                </h2>
                <hr
                  style={{
                    display: "block",
                    width: "10%",
                    height: "3px",
                    backgroundColor: "#31C75A",
                    border: 0,
                  }}
                />
              </Box>

              <Box style={{ padding: "3rem" }}>
                <form onSubmit={handleSubmit}>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <TextField
                      style={{ width: "100%", margin: "1rem" }}
                      id="standard-basic"
                      label="Your Name"
                      name="name"
                      type="text"
                      variant="outlined"
                    />
                  </Box>

                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <TextField
                      style={{ width: "50%", margin: "1rem" }}
                      id="standard-basic"
                      label="Your Name"
                      name="name"
                      type="text"
                      variant="outlined"
                    />

                    <TextField
                      style={{ width: "50%", margin: "1rem" }}
                      id="standard-basic"
                      label="Your Email"
                      name="email"
                      type="email"
                      variant="outlined"
                    />
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <TextField
                      style={{ width: "50%", margin: "1rem" }}
                      id="standard-basic"
                      label="Your Name"
                      name="name"
                      type="text"
                      variant="outlined"
                    />

                    <TextField
                      style={{ width: "50%", margin: "1rem" }}
                      id="standard-basic"
                      label="Your Email"
                      name="email"
                      type="email"
                      variant="outlined"
                    />
                  </Box>
                  <Box style={{ margin: "1rem" }}>
                    <h5>Why do you want to join Psycure</h5>
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                      <textarea
                        required
                        style={{ outlineColor: "green", width: "100%" }}
                        name="problem"
                        placeholder="whats in your mind"
                        rows="5"
                      ></textarea>
                    </Box>
                  </Box>

                  <Box style={{ margin: "1rem" }}>
                    <h5>Why do you want to join Psycure</h5>
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                      <textarea
                        required
                        style={{ outlineColor: "green", width: "100%" }}
                        name="problem"
                        placeholder="whats in your mind"
                        rows="5"
                      ></textarea>
                    </Box>
                  </Box>

                  <Box style={{ display: "flex" }}>
                    <TextField
                      style={{ width: "50%", margin: "1rem" }}
                      id="standard-basic"
                      name="email"
                      type="file"
                      variant="outlined"
                    />

                    <TextField
                      style={{ width: "50%", margin: "1rem" }}
                      id="standard-basic"
                      name="email"
                      type="file"
                      variant="outlined"
                    />
                  </Box>

                  <Button
                    onClick={handleSubmit}
                    style={{
                      backgroundColor: "#31C75A",
                      marginTop: "1rem",
                      padding: "20px 30px",
                      borderRadius: "15px",
                      color: "white",
                      float: "right",
                      marginRight: "20px",
                    }}
                  >
                    Continue
                  </Button>
                </form>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SignUpSecondPage;
