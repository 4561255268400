import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";

export default function UnwindMindKeyFeaturesCard() {
  return (
    <Grid
      container
      columns={{ xs: 1, md: 2, lg: 3, xl: 3 }}
      justifyContent="center"
      sx={{ margin: "1% 0", gap: "10px" }}
    >
      {/* first */}
      <Grid item>
        <Card
          sx={{
            maxWidth: 345,
            height: 600,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardActionArea sx={{ paddingBottom: "21px" }}>
            <CardMedia
              component="img"
              // height="300px"
              image="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Fphotography.jpg?alt=media"
              alt="photography"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Photography
              </Typography>
              <ul
                style={{
                  textAlign: "left",
                  lineHeight: "22px",
                  fontSize: "1rem",
                }}
              >
                <li>
                  Theme: <b>Mental health</b>
                </li>
                <li>Both mobile and DSLR photography is eligible </li>
                <li>The photographs should be of high resolution </li>
                <li>The photographs must be free from watermarks</li>
                <li>
                  There should not be significant editing made to the photo
                </li>
                <li>Violence or nudity is strictly prohibited </li>
                <li>
                  The participant must have complete ownership of the photo
                </li>
              </ul>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {/* second */}
      <Grid item>
        <Card
          sx={{
            maxWidth: 345,
            height: 600,
            textAlign: "center",
          }}
        >
          <CardActionArea sx={{ paddingBottom: "100px" }}>
            <CardMedia
              component="img"
              // height="220px"
              image="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Fdigital%20art.jpg?alt=media&token=df610a1f-3d89-428a-8c77-cc427811ba20"
              alt="digital art"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Digital Art
              </Typography>
              <ul
                style={{
                  textAlign: "left",
                  lineHeight: "22px",
                  fontSize: "1rem",
                }}
              >
                <li>
                  Theme: <b>Mental health</b>
                </li>
                <li>Any digital software can be used to prepare the art</li>
                <li>
                  Use high-resolution (300-350 dpi) jpeg/jpg/png for submission
                  and posting
                </li>
                <li>Violence or nudity is strictly prohibited</li>
                <li>
                  The participant must have complete ownership of the artwork.
                </li>
              </ul>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {/* third */}
      <Grid item>
        <Card
          sx={{
            maxWidth: 345,
            height: 600,
            textAlign: "center",
          }}
        >
          <CardActionArea sx={{ paddingBottom: "110px" }}>
            <CardMedia
              component="img"
              // height="288px"
              image="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Fpainting.jpg?alt=media"
              alt="painting"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Painting
              </Typography>
              <ul
                style={{
                  textAlign: "left",
                  lineHeight: "22px",
                  fontSize: "1rem",
                }}
              >
                <li>
                  Theme: <b>Mental health</b>
                </li>
                <li>
                  Take a photo of your artwork (no background or other elements
                  aside from original artwork) and upload it.
                </li>
                <li>No digital editing is allowed</li>
                <li>Violence or nudity is strictly prohibited</li>
                <li>
                  The participant must have complete ownership of the artwork.
                </li>
              </ul>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}
