import React from "react";

const UnWindMindAwards = () => {
  return (
    <div style={{ margin: "1% 0" }}>
      <div class="award-container">
        <div class="award-header">
          <h1 class="award-title">No Registration Fee!</h1>
        </div>

        <div class="award-details">
          <h2 class="award-subtitle">PRIZES & AWARDS:</h2>
          <p class="award-total">Total Prize Money: BDT. 45,000</p>
          <ul class="award-list">
            <li class="award-item">
              <strong>Winner of each category will receive:</strong> BDT.
              10,000, a crest, and a certificate.
            </li>
            <li class="award-item">
              <strong>Runners up of each category will receive:</strong> BDT.
              5,000, a crest, and a certificate.
            </li>
            <li class="award-item">
              <strong>Top 10 Artwork from each category:</strong> Displayed in
              an exhibition by EMK Center along with a certificate.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UnWindMindAwards;
