import { useEffect, useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import useAuth from "./useAuth";

export const useLogin = () => {
  let [isCancelled, setIsCancelled] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [error, setError] = useState(null);
  const { dispatch } = useAuth();

  const Login = (email, password, location, navigate, user, giftCardId) => {
    setError(null);
    setIsLoading(true);

    const auth = getAuth();

    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        dispatch({ type: "LOGIN", payload: res.user });
        if (!isCancelled) {
          setIsLoading(false);
          setError(null);
        }
        let destination;
        if (user === "admin") {
          destination = "/admin-signin";
        }
        if (user === "psychologist") {
          destination = "/psychologist-signin";
        }

        if (user === "patient" && !giftCardId) {
          destination = "/signin";
        }
        if (user === "patient" && giftCardId) {
          destination = `/gift-card/${giftCardId}`;
        }

        navigate(destination);
      })
      .catch((error) => {
        let errMessage = error?.code?.split("auth/")[1];
        if (errMessage == "wrong-password") {
          setError("Invalid Password");
        } else {
          setError(errMessage);
        }
        setIsLoading(false);
      });

    setError(null);
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { setError, error, Login, isLoading };
};
