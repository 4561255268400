import React, { useEffect, useLayoutEffect, useState } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useAuth from "../../../hooks/useAuth";
import { Container } from "@mui/system";
import "./editProfile.css";
import SuccessAlert from "../../../Shared/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../../Shared/ErrorAlert/ErrorAlert";
import spinner from "../../..//assets/images/spinner-loop.gif";

import Footer from "../../../Shared/Footer/Footer";

const EditPsychologistInfo = () => {
  const { user } = useAuth();
  const [psychologistDetails, setPsychologistDetails] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(false);

  let [userDetails, setUserDetails] = useState({
    name: psychologistDetails?.name,
    email: psychologistDetails?.email,
    location: psychologistDetails?.location,
    phone: psychologistDetails?.phone,
    age: psychologistDetails?.age,
    gender: psychologistDetails?.gender,
  });

  useLayoutEffect(() => {
    fetch("https://api.thepsycure.com/psychologists")
      .then((res) => res.json())
      .then((data) => {
        const foundPsychologist = data.filter(
          (detail) => detail.email === user.email
        );
        setPsychologistDetails(foundPsychologist[0]);
      });
  }, [user.email]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userProfile = {
      name: userDetails?.name,
      location: userDetails?.location,
      phone: userDetails?.phone,
      age: userDetails?.age,
      gender: userDetails?.gender,
    };

    fetch(`https://api.thepsycure.com/psychologistProfile/${user?.email}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(userProfile),
    })
      .then((res) => res.json())
      .then((data) => {
        setIsSuccess(true);
        setError(false);
      })
      .catch((err) => {
        setIsSuccess(false);
        setError(true);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(false);
      setError(false);
    }, 3000);
  }, [isSuccess, error]);

  return (
    <>
      <div style={{ minHeight: "75vh" }}>
        {psychologistDetails._id ? (
          <>
            <Container
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                textAlign: "center",
                borderRadius: "15px",
              }}
              sx={{ alignItems: "center" }}
            >
              <Box
                className="EditProfile"
                style={{
                  padding: "2rem 5rem  5rem 5rem",
                }}
              >
                <Box id="BookSession">
                  <h2
                    style={{ textAlign: "center", marginLeft: "1rem" }}
                    variant="h4"
                    gutterBottom
                    component="div"
                  >
                    Edit Your Information
                  </h2>
                  <hr
                    style={{
                      display: "block",
                      width: "10%",
                      height: "3px",
                      backgroundColor: "#31C75A",
                      border: 0,
                    }}
                  />
                </Box>
                <Box>
                  <form className="mode" onSubmit={handleSubmit}>
                    <Box>
                      <TextField
                        required
                        sx={{ width: "97.5%", m: 2 }}
                        id="outlined-required"
                        label="Full Name"
                        defaultValue={psychologistDetails?.name}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            name: e.target.value,
                          })
                        }
                      />
                    </Box>
                    {/* 
            <TextField
              required
              sx={{ width: '100%', m: 1 }}
              id="outlined-required"
              label="UserName"
              defaultValue="Oliver Queen"
            /> */}

                    <Box>
                      <TextField
                        required
                        sx={{ width: "97.5%", m: 2 }}
                        id="outlined-required"
                        label="Email"
                        defaultValue={psychologistDetails?.email}
                      />
                    </Box>

                    <Box
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                      }}
                    >
                      <TextField
                        required
                        sx={{ width: "95%", m: 2 }}
                        id="outlined-required"
                        label="Location"
                        defaultValue={psychologistDetails?.location}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            location: e.target.value,
                          })
                        }
                      />

                      <TextField
                        required
                        sx={{ width: "95%", m: 2 }}
                        id="outlined-required"
                        label="phone"
                        type="number"
                        defaultValue={psychologistDetails?.phone}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            phone: e.target.value,
                          })
                        }
                      />

                      <TextField
                        required
                        sx={{ width: "95%", m: 2 }}
                        id="outlined-required"
                        label="Age"
                        type="number"
                        defaultValue={psychologistDetails?.age}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            age: e.target.value,
                          })
                        }
                      />
                    </Box>

                    <Box
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                      }}
                    >
                      {/* 
                                            <TextField
                                                required
                                                sx={{ width: '95%', m: 2 }}
                                                id="outlined-required"
                                                label="Gender"
                                                defaultValue={psychologistDetails?.gender}
                                                onChange={e => setUserDetails({ ...userDetails, gender: e.target.value })}
                                            /> */}
                    </Box>

                    <Box className="btn_container">
                      <Button
                        style={{ backgroundColor: "#31C75A", width: "20%" }}
                        type="submit"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                  {isSuccess ? (
                    <SuccessAlert>Profile updated successfully.</SuccessAlert>
                  ) : (
                    ""
                  )}
                  {error ? (
                    <ErrorAlert>Could not update your profile</ErrorAlert>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Container>
          </>
        ) : (
          <div
            style={{
              height: "40vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={spinner}
              alt="spinner"
              style={{ width: "500px", height: "400px" }}
            />
          </div>
        )}
      </div>
      {/* <DashboardFooter /> */}
      <Footer />
    </>
  );
};

export default EditPsychologistInfo;
