import {
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Icon,
  Modal,
  Slide,
} from "@mui/material";
import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import { FacebookRounded } from "@mui/icons-material";
import { LinkedinIcon } from "react-share";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // height: "80%",
  p: 4,
  overflow: "scroll",
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Faculty = () => {
  const faculties = [
    {
      name: "Shaheen Islam",
      post: "Director Youth Mental Health Fellowship Program 2024",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fshaheen.jpg?alt=media&token=caf0bef8-e5e6-4d38-a935-de1bc158ba02",
      details:
        "Professor Dr. Shaheen Islam was the founder chairperson (2012-2016) of the Department of Educational and Counselling Psychology (DECP) at the University of Dhaka and continues her dedicated work for its growth. She served as the Director of the Student Counselling and Guidance Office for 9 years and has been a significant faculty member since 1987. As a core founding member of DECP, she was instrumental in establishing it as a separate entity. Awarded a PhD by Dhaka University in 1998, she has numerous publications and authored “Monosebar Dharapat” on basic helping skills. <br /> As a principal investigator of the Higher Education Quality Enhancement Project (HEQEP) from UGC, she contributed significantly to the professional skill development of psychology graduates and mental health personnel. Dr. Islam played a crucial role in initiating psychological services, play therapy, and family therapy training in the department. She has been pivotal in raising mental health awareness and providing psychological support during crises like the Rana Plaza collapse and the COVID-19 pandemic.<br /> A pioneer in EMDR therapy in Bangladesh, she received the GARE fund to prove its efficacy in trauma recovery. Dr. Islam is a registered member of the British Association of Counseling and Psychotherapy and other professional bodies. She has 56 national and international publications and continues to contribute to mental health services and professional training in Bangladesh.",
    },
    {
      name: "Dr. M. Tasdik Hasan",
      post: "PhD Fellow (Digital Mental Health), Monash University",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FTasdik%20(3)%20copy.jpg?alt=media&token=0cd7e39a-c6ef-4f19-89aa-ecd54d849570",
      details:
        "Dr. M. Tasdik Hasan is a multidisciplinary expert in mental health and digital health, with a diverse background in epidemiology, public health, and information technology. He currently serves as a PhD Fellow at Monash University and an Assistant Professor at the State University of Bangladesh. Additionally, Dr. Hasan is a Teaching Fellow at Monash University’s Faculty of Information Technology and Co-Convenor of the Mental Health Special Interest Group in the Public Health Association of Australia. <br />His global contributions include work as a Research Scientist at Moner Bondhu (Friends of Mind) in Dhaka, Bangladesh, and a faculty position in Life Sciences. Dr. Hasan is dedicated to advancing mental health research and practice, integrating digital solutions into healthcare, and educating future professionals in the field. His commitment to improving mental health outcomes and his innovative approach have made a significant impact both locally and internationally.",
    },
    {
      name: "Dr. Muntasir Maruf",
      post: "Associate Professor,  National Institute of Mental Health",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fmuntasir.jpg?alt=media&token=ccfb889d-9a2a-40cd-99a1-8667036bda40",
      details:
        "Dr. Muntasir Maruf is an Associate Professor of Psychiatry at the National Institute of Mental Health, Dhaka, Bangladesh. He completed his MBBS from Dhaka Medical College in 2004 and his FCPS in 2013. He received the Young Psychiatrists Fellowship Award from various prestigious organizations including the ASEAN Federation for Psychiatry and Mental Health (AFPMH) in 2014, World Psychiatric Association (WPA) in 2015 and others in 2016. He has presented at international conferences in the Philippines, China, Thailand, Sri Lanka and India. His research focuses on sexual dysfunctions and addiction psychiatry. He has published 43 articles in national and international scientific journals and over 300 articles on health-related topics in national dailies and weeklies.",
    },
    {
      name: "Umme Kawser",
      post: " Assistant Professor Educational & Counselling Psychology University of Dhaka",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fumme.jpg?alt=media&token=e07bb944-66b4-445c-b377-fed757bcc905",
      details:
        "Umme Kawser, Assistant Professor in Educational & Counselling Psychology at the University of Dhaka, is a distinguished member of the Psycure Mental Health Fellowship 2024. With extensive expertise in educational and counselling psychology, she is committed to fostering growth and resilience in individuals and communities. Umme Kawser has a profound dedication to mental health advocacy, striving to bridge the gap between theory and practice. Her work emphasizes the importance of psychological well-being and educational advancement, making her an invaluable asset to the fellowship and the broader mental health community.",
    },
    {
      name: "Andalib Mahmud",
      post: "Counseling Psychologist ",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FAndalib%20Mahmud%20-%20Andalib%20Mahmud.jpg?alt=media&token=ec15e80f-70a9-4228-82c8-b4688bcd77ca",
      details:
        "Andalib Mahmud, the esteemed first NLP International Ambassador for Bangladesh, is a vital part of the Psycure Mental Health Fellowship 2024. With over a decade of expertise in counseling psychology and NLP training, Andalib brings invaluable insights to our fellowship. She has crafted tailored NLP modules and provided crucial crisis support during events like the Rana Plaza collapse and COVID-19. Through personalized sessions and training, Andalib empowers individuals and professionals alike. Her mission is to foster global resilience and well-being, making her a significant contributor to our fellowship and a beacon of hope in the field of mental health.",
    },
    /*  {
      name: "Zohora Parveen",
      post: "Clinical Psychologist",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fzohora.jpg?alt=media&token=8483ce46-3131-46a5-9620-3044b2fef743",
    }, */
    {
      name: "Liza Akter",
      post: "Lecturer, Department of Clinical Psychology University of Dhaka",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fliza.jpg?alt=media&token=d5f0377a-6b7b-4252-bf15-fccb3df1be92",
      details:
        "Liza Akter, a dedicated lecturer at the Department of Clinical Psychology, University of Dhaka, brings a wealth of experience in both academic and practical settings. Alongside her role in academia, Liza has collaborated with esteemed organizations such as the UN, I/NGOs, and research institutions including IOM, IRC, and ICDDR,B. Specializing in Cognitive Behaviour Therapy (CBT), Liza's expertise extends to providing crucial mental health support in various contexts. With a commitment to bridging theory and practice, she continues to empower individuals and communities through her multidimensional approach to clinical psychology.",
    },
  ];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [faculty, setFaculty] = useState();
  return (
    <Container maxWidth="xl">
      <Typography style={{ textAlign: "center", margin: "2% 0" }} variant="h3">
        Our Faculties
      </Typography>
      <Grid
        container
        columns={{ xs: 1, md: 2, lg: 3, xl: 3 }}
        justifyContent="center"
        spacing={2}
      >
        {/* first */}

        {faculties?.map((faculty) => (
          <Grid item>
            <ButtonBase>
              <Card
                onClick={() => {
                  setFaculty(faculty);
                  handleOpen();
                }}
                sx={{
                  maxWidth: 345,
                  height: 450,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div style={{ padding: "15px" }}>
                  <CardMedia
                    component="img"
                    height="100%"
                    image={faculty?.image}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {faculty?.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {faculty?.post}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        // aria-labelledby="modal-modal-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{ padding: "20px" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={faculty?.image}
              alt=""
              style={{
                width: "30%",
                borderRadius: "100%",
              }}
            />

            <Typography id="modal-modal-title" variant="h6" component="h2">
              {faculty?.name}
            </Typography>
            {/*  <Box>
              <FacebookRounded fontSize="medium" />
              <Instagram fontSize="medium" />
              <LinkedIn fontSize="medium" />
              <Twitter fontSize="medium" />
            </Box> */}
            <Box>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: faculty?.details,
                }}
                id="modal-modal-description"
                sx={{ mt: 2 }}
                style={{ textAlign: "justify" }}
              >
                {/* {faculty?.details} */}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Container>
  );
};

export default Faculty;
