import { Box, Grid, Paper, Skeleton } from "@mui/material";
import { Button, Container} from "@material-ui/core";
import React, { useLayoutEffect, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import educationIcon from "../../assets/images/educationIcon.png";
import experienceIcon from "../../assets/images/experience-icon.png";

import "swiper/css/effect-coverflow";

import { Rating } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import "swiper/css/pagination";

import useAuth from "../../hooks/useAuth";
import PickersDay, {
 
  pickersDayClasses,
} from "@mui/lab/PickersDay";
import style from "./PsychologistProfile.module.css";
import Header from "../../Shared/Header/Header";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import verifiedImage from "../../assets/images/verified.png";
import "./PsychologistProfile.css";
import PaySecondPage from "../Payment/PaySecondPage/PaySecondPage";
import NotSignedIn from "../NotSignedIn/NotSignedIn";
import ReactGA from "react-ga4";

const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
  return (
    <PickersDay
      {...pickersDayProps}
      sx={{
        [`&&.${pickersDayClasses.selected}`]: {
          backgroundColor: "rgb(23, 216, 136)",
          borderRadius: "50%",
          border: "1px solid #fff",
          color: "#fff !important",
        },
      }}
    />
  );
};

const PsychologistProfile = () => {
  let { title } = useParams();
  const [psychologistDetails, setPsychologistDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allSession, setAllSession] = useState([]);
  const [date, setDate] = useState("");
  const [value, setValue] = React.useState("");
  const [selectedStartTime, setSelectedStartTime] = React.useState("");
  const [selectedEndTime, setSelectedEndTime] = React.useState("");
  const [userDetail, setUserDetail] = useState({});
  const [timeSelectionButton, setTimeSelectionButton] = useState(false);
  const [noUserStatus, setNoUserStatus] = useState();
  let selectedPsychologists = psychologistDetails[0];

  let navigate = useNavigate();
  const { user } = useAuth();

  const bookingEndRef = useRef(null);

  const { height, width } = useWindowDimensions();

  const scrollToBottom = () => {
    bookingEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedStartTime, value]);

  useLayoutEffect(() => {
    fetch("https://api.thepsycure.com/psychologists")
      .then((res) => res.json())
      .then((data) => {
        const foundPsychologist = data.filter(
          (detail) => detail.name?.split(" ").join("-") == title
        );
        setIsLoading(false);
        setPsychologistDetails(foundPsychologist);
      });
  }, [title]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Psychologist details : ${psychologistDetails[0]?.name}`,
    });
  }, [psychologistDetails]);
  const totalReview = psychologistDetails[0]?.reviews?.length;
  const likedReview = psychologistDetails[0]?.reviews?.filter(
    (review) => review.rating === "like"
  ).length;

  useEffect(() => {
    fetch(`https://api.thepsycure.com/users/${user?.email}`)
      .then((res) => res.json())
      .then((data) => setUserDetail(data));
  }, [user]);
  const handleValue = (newValue) => {
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const selectedDate = new Date(newValue.toISOString().slice(0, 10));
    console.log(new Date(newValue.toISOString().slice(0, 10)));
    setValue(weekday[selectedDate.getDay()]);
    setDate(newValue.toISOString());

    setSelectedStartTime("");
    setSelectedEndTime("");
  };

  const weekday = [0, 1, 2, 3, 4, 5, 6];
  const availableDate = selectedPsychologists?.availableDateTimes?.map(
    (times) => {
      if (times.date === "Sunday") {
        return 0;
      } else if (times.date === "Monday") {
        return 1;
      } else if (times.date === "Tuesday") {
        return 2;
      } else if (times.date === "Wednesday") {
        return 3;
      } else if (times.date === "Thursday") {
        return 4;
      } else if (times.date === "Friday") {
        return 5;
      } else if (times.date === "Saturday") {
        return 6;
      }
    }
  );
  const unAvailableDate = weekday.filter(
    (day) => !availableDate?.includes(day)
  );

  function isWeekendDay(date) {
    return unAvailableDate.includes(date.getDay());
  }

  const handleSelectedTime = (time) => {
    console.log(time);
    if (!user) {
      setNoUserStatus(true);
    }
    setSelectedStartTime(time.startTime);
    setSelectedEndTime(time.endTime);
    let sessionDetails = {
      patientEmail: user?.email,
      docEmail: selectedPsychologists?.email,
      startTime: time.startTime,
      endTime: time.endTime,
      date: date.slice(0, 10),
      day: value,
      status: "incomplete",
      selectedPsychologists,
      userDetails: userDetail,
    };

    localStorage.setItem("selectedDetails", JSON.stringify(sessionDetails));
    setParsedItem(JSON.parse(localStorage?.getItem("selectedDetails")));
  };

  const [item, setParsedItem] = useState();

  useEffect(() => {
    fetch("https://api.thepsycure.com/session")
      .then((res) => res.json())
      .then((data) => {
        const filteredByDate = data.filter((d) => d.date == date.slice(0, 10));

        setAllSession(filteredByDate);
      });
  }, [date]);

  const filteredSession = allSession.length
    ? allSession?.filter(
        (session) =>
          session?.selectedPsychologists?.email === selectedPsychologists?.email
      )
    : [];

  const {
    image,
    name,
    details,
    biodata,
    reviews,
    category,
    degree,
    yourself,
    expertiseArea,
    problems,
  } = psychologistDetails[0] || {};
  const { education, experience, specialization } = details || {};
  const { subject, university, program, institute } = education || {};
  const { designation, organization, issue, company } = experience || {};
  const { topic, concern } = specialization || {};
  const slicedCat = psychologistDetails[0]?.category.slice(2);
  console.log(slicedCat);
  const { pathname } = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const mediaQuery = window.matchMedia("(max-width:768px)");
  // Function to handle media query changes
  const handleMediaQueryChange = (event) => {
    setIsMobile(event.matches);
  };

  // Attach event listener for media query changes
  useEffect(() => {
    mediaQuery.addListener(handleMediaQueryChange);

    // Initial check for the media query
    setIsMobile(mediaQuery.matches);

    // Cleanup the event listener when the component unmounts
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, [mediaQuery]);

  return (
    <>
      {pathname === `/psychologists/details/${title}` ? <Header /> : null}
      <Container sx={{ my: 20 }} style={{ paddingTop: "20px" }}>
        <Grid
          container
          spacing={2}
          key={name}
          sx={{ flexGrow: 1 }}
          style={{ marginTop: "70px" }}
        >
          <Grid
            item
            sm={12}
            md={4}
            style={{ margin: "0 auto" }}
            className="frame-3"
          >
            <div
              style={{
                display: isMobile && "flex",
                justifyContent: isMobile && "center",
              }}
            >
              <img
                style={{
                  width: isMobile ? "151px" : "227.68px",
                  height: isMobile ? "151px" : "227.68px",
                  borderRadius: "100%",
                  position: isMobile && "relative",
                }}
                src={`${image}`}
                alt="psychologist"
              />
              {isMobile && (
                <img
                  className="verified-image"
                  src={verifiedImage}
                  style={{
                    height: "44px",
                    width: "44px",
                    position: "absolute",
                    top: "195px",
                    left: "247px",
                  }}
                  alt=""
                />
              )}
            </div>
            {name && isMobile ? (
              <h2
                className="psychologist-name-head"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {name}
              </h2>
            ) : (
              ""
            )}
            {isMobile && (
              <div className="review">
                <Rating
                  className="rating-system"
                  size="large"
                  name="read-only"
                  value={
                    (((likedReview / totalReview) * 100) / 100) * 5 == 5
                      ? 5
                      : 4.5
                  }
                  sx={{
                    color: "black",
                  }}
                  precision={0.5}
                  readOnly
                />
              </div>
            )}
            <div className="category-grid">
              <p
                className="category-grid-item"
                style={{
                  width:
                    psychologistDetails[0]?.category[0].length > 20 &&
                    "175.15px",
                }}
              >
                {psychologistDetails[0]?.category[0]}
              </p>
              {psychologistDetails[0]?.category[1] && (
                <p
                  className="category-grid-item"
                  style={{
                    width:
                      psychologistDetails[0]?.category[1].length > 20 &&
                      "175.15px",
                  }}
                >
                  {psychologistDetails[0]?.category[1]}
                </p>
              )}
            </div>
            {slicedCat?.map((slice, index) => (
              <p
                style={{
                  width: slice.length > 20 ? "175.15px" : "",
                  marginTop: index == slicedCat.length - 1 && "20px",
                  // marginBottom: index == slicedCat.length - 1 && "100px",
                }}
                className="category-grid-item"
              >
                {slice}
              </p>
            ))}
          </Grid>

          <Grid item sm={12} md={8}>
            {name && !isMobile ? (
              <h2
                className="psychologist-name-head"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {name}
                <img
                  src={verifiedImage}
                  height="44px"
                  width="44px"
                  alt=""
                  srcset=""
                />
              </h2>
            ) : (
              ""
            )}
            {!isMobile && (
              <div className="review">
                <Rating
                  className="rating-system"
                  size="large"
                  name="read-only"
                  value={
                    (((likedReview / totalReview) * 100) / 100) * 5 == 5
                      ? 5
                      : 4.5
                  }
                  precision={0.5}
                  sx={{
                    color: "black",
                  }}
                  readOnly
                />
              </div>
            )}
            {isMobile && (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  marginTop:
                    psychologistDetails[0]?.category.length > 2 ? "30%" : "1%",
                }}
              >
                <img
                  src={educationIcon}
                  alt="education Icon"
                  style={{ width: "31.55px", height: "auto" }}
                />

                {degree ? <p className="degree-name">{`${degree}`}</p> : ""}
              </div>
            )}
            {/* {category ? <h4>{category}</h4> : ""} */}
            {yourself ? (
              <p
                className="psychologist-yourself"
                style={{ textAlign: "justify" }}
              >
                {yourself}
              </p>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        {/* ===============three cards========== */}

        <Grid
          container
          spacing={2}
          key={name}
          sx={{
            flexGrow: 1,
            my: 5,
            justifyContent: "center",
            margin: "auto",
            width: "100%",
          }}
        >
          {!isMobile && (
            <Grid item sm={12} md={4} style={{ paddingLeft: "unset" }}>
              {isLoading ? (
                <Skeleton
                  elevation={6}
                  variant="rectangular"
                  width={250}
                  height={480}
                  sx={{ borderRadius: "25px" }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    margin: "15% 0",
                  }}
                >
                  <img
                    src={educationIcon}
                    alt="education Icon"
                    style={{ width: "31.55px", height: "auto" }}
                  />

                  {degree ? <p className="degree-name">{`${degree}`}</p> : ""}
                </div>
              )}
            </Grid>
          )}

          <Grid item sm={12} md={4} style={{ paddingLeft: "unset" }}>
            {isLoading ? (
              <Skeleton
                elevation={6}
                variant="rectangular"
                width={250}
                height={480}
                sx={{ borderRadius: "25px" }}
              />
            ) : (
              <div></div>
            )}
          </Grid>

          <Grid item sm={12} md={4} style={{ paddingLeft: "unset" }}>
            {isLoading ? (
              <Skeleton
                elevation={6}
                variant="rectangular"
                width={250}
                height={480}
                sx={{ borderRadius: "25px" }}
              />
            ) : expertiseArea ? (
              <Paper
                elevation={6}
                sx={{
                  textAlign: "center",
                  py: 5,
                  px: 2,
                  borderRadius: "20px",
                  minHeight: "15rem",
                  width: "20rem",
                  backgroundColor: "#FDFFBD",
                }}
              >
                <img src={experienceIcon} alt="experience Icon" />
                <h3>Experience</h3>
                {expertiseArea ? <p>{`${expertiseArea}`}</p> : ""}
              </Paper>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        {/* =======> REVIEW SECTION<======= */}

        {selectedPsychologists ? (
          <>
            <Box id="BookSession" sx={{ paddingTop: "5rem" }}>
              <h2
                style={{ textAlign: "center" }}
                variant="h4"
                gutterBottom
                component="div"
              >
                Book Session
              </h2>
              <hr
                style={{
                  display: "block",
                  width: "121.84px",
                  height: "5.44px",
                  backgroundColor: "#000000",
                  border: 0,
                  borderRadius: "8.16px",
                }}
              />
            </Box>

            <Grid
              container
              spacing={2}
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={12}
                md={4}
                style={{ margin: ".5rem" }}
                className={style.outlinedToday}
              >
                <Box
                  style={{
                    boxShadow:
                      "0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)",
                    borderRadius: "15px",
                    overflow: "hidden",
                  }}
                  sx={{
                    alignItems: "center",
                    borderRadius: "30px",
                    height: "100%",
                  }}
                  ref={
                    value && !selectedStartTime && width < 996
                      ? bookingEndRef
                      : null
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      openTo="day"
                      // disabled
                      minDate={new Date()}
                      shouldDisableDate={isWeekendDay}
                      value={value}
                      renderDay={renderWeekPickerDay}
                      onChange={handleValue}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>

              <Grid item xs={12} md={2} style={{ margin: ".5rem" }}>
                <Box
                  style={{ textAlign: "center", borderRadius: "15px" }}
                  sx={{ alignItems: "center" }}
                >
                  {value && (
                    <Box>
                      {/* {
                                            today > value && <Alert severity="warning">Check today's later dates!</Alert>
                                        } */}

                      {selectedPsychologists?.availableDateTimes?.map(
                        (pd) =>
                          pd.date == value && (
                            <>
                              <div className="time-button-parent">
                                <div
                                  className="time-button"
                                  onClick={() =>
                                    setTimeSelectionButton(!timeSelectionButton)
                                  }
                                >
                                  <button>Time</button>
                                  {timeSelectionButton ? (
                                    <span>⯆</span>
                                  ) : (
                                    <span>⯅</span>
                                  )}
                                </div>
                              </div>
                              <div className="custom-box-parent">
                                <div
                                  className="custom-box"
                                  style={{
                                    display: timeSelectionButton
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  {selectedPsychologists?.availableDateTimes.map(
                                    (adt) =>
                                      adt.date === value &&
                                      adt.times.map((time) =>
                                        // checking filtered session date and time matching or not
                                        // if matching this means already booked this session
                                        (filteredSession[0]?.startTime ==
                                          time?.startTime &&
                                          filteredSession[0]?.endTime ==
                                            time?.endTime) ||
                                        (filteredSession[1]?.startTime ==
                                          time?.startTime &&
                                          filteredSession[1]?.endTime ==
                                            time?.endTime) ||
                                        (filteredSession[2]?.startTime ==
                                          time?.startTime &&
                                          filteredSession[2]?.endTime ==
                                            time?.endTime) ||
                                        (filteredSession[3]?.startTime ==
                                          time?.startTime &&
                                          filteredSession[3]?.endTime ==
                                            time?.endTime) ? (
                                          <div style={{ padding: "10px 0" }}>
                                            <Button
                                              style={{
                                                background: "#31C75A",
                                                color: "#fff",
                                              }}
                                            >
                                              Already Booked
                                            </Button>
                                          </div>
                                        ) : (
                                          <Box>
                                            <Button
                                              onClick={() =>
                                                handleSelectedTime(time)
                                              }
                                              className="select-time"
                                              style={{
                                                width: "90%",
                                                margin: ".5rem",
                                                fontSize: "14px",
                                                background:
                                                  time.startTime ===
                                                  selectedStartTime
                                                    ? "#36CA5A"
                                                    : "transparent",
                                                color:
                                                  time.startTime ===
                                                  selectedStartTime
                                                    ? "#fff"
                                                    : "#F2F2F2",
                                              }}
                                              variant="contained"
                                              color="success"
                                            >
                                              {time.startTime} - {time.endTime}
                                            </Button>
                                          </Box>
                                        )
                                      )
                                  )}
                                </div>
                              </div>
                            </>
                          )
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>

              {value && selectedStartTime ? (
                <div ref={bookingEndRef}></div>
              ) : null}
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "2rem 0 5rem 0",
              }}
            >
              {selectedPsychologists?.availableDateTimes?.map(
                (qtm) =>
                  qtm.date == value && (
                    <>
                      {value && selectedStartTime && user && (
                        <PaySecondPage
                          selectedStartTime={selectedStartTime}
                          item={item}
                          isMobile={isMobile}
                        ></PaySecondPage>
                      )}
                    </>
                  )
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </Container>
      {noUserStatus && <NotSignedIn></NotSignedIn>}
    </>
  );
};

export default PsychologistProfile;
