import { Alert, AlertTitle, Box, Button, CircularProgress, Grid, Paper, TextField } from '@mui/material';
import { Container } from '@mui/system';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import PaySlide from './PaySlide/PaySlide';
import Header from '../../Shared/Header/Header';

const Payment = () => {

    const [loginData, setLoginData] = useState({});
    const { user, loginUser, error, isLoading } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();


    const handleOnChange = e => {
        const field = e.target.name;
        const value = e.target.value;
        const newLoginData = { ...loginData };
        newLoginData[field] = value;
        setLoginData(newLoginData);
    }

    const handleLoginSubmit = e => {
        loginUser(loginData.email, loginData.password, location, navigate);
        e.preventDefault();
    }

    return (
        <>
            <Header />
            <div style={{ backgroundColor: '#EBFCF0', paddingBottom: '30px' }}>
                <Container sx={{ mb: 5, mt: 20 }}>
                    <Box>
                        <Grid container spacing={12} sx={{ flexGrow: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} md={6}>
                                <Paper style={{ borderRadius: '25px', padding: '3rem' }} elevation={12}>
                                    <PaySlide />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <Paper style={{ borderRadius: '25px', textAlign: 'center', padding: '5.5rem' }} elevation={12}>
                                    {user.email ? <>
                                        <h2>Welcome to the <span style={{ color: 'green' }}>Payment</span> Process.</h2>
                                        <p>Please click on the next button.</p>
                                        <Link to="/payment-next" style={{ textDecoration: 'none' }}><Button className="signin-btn" type='submit' variant="contained">Next</Button></Link>
                                    </> :
                                        <>
                                            <h3>Please Login</h3>
                                            <form onSubmit={handleLoginSubmit}>
                                                <TextField
                                                    sx={{ width: '75%', m: 1 }}
                                                    id="standard-basic"
                                                    label="Your Email"
                                                    name='email'
                                                    type='email'
                                                    onChange={handleOnChange}
                                                    variant="outlined" />

                                                <TextField
                                                    sx={{ width: '75%', m: 1 }}
                                                    id="standard-password-input"
                                                    label="Your Password"
                                                    type="password"
                                                    name='password'
                                                    onChange={handleOnChange}
                                                    autoComplete="current-password"
                                                    variant="outlined"
                                                />
                                                <Button className="signin-btn" type='submit' variant="contained">Sign In</Button>
                                            </form>

                                            {isLoading && <CircularProgress />}
                                            {user?.email && <Alert severity="success">
                                                <AlertTitle>Congratulations</AlertTitle>
                                                User logged in — <strong>successfully!</strong>
                                            </Alert>}
                                            {error && <Alert severity="error">
                                                <AlertTitle>Error</AlertTitle>
                                                {error}
                                            </Alert>}
                                            <p>Don't have an account? <br />
                                                Please <Link to='/signup' style={{ color: '#31C75A', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }}>Register</Link>
                                            </p>
                                        </>}

                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </div>
        </>
    );
};

export default Payment;