import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Container, Rating } from "@mui/material";
import { Autoplay, Navigation } from "swiper";
import "swiper/css/navigation";
import "./Psychologist.css";

import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import verifiedImage from "../../../assets/images/verified.png";

const Psychologist = () => {
  const [psychologists, setPsychologists] = useState([]);
  let [dataLoaded, setDataLoaded] = useState(false);
  // useLayoutEffect(() => {
  //     function updateScreen(time) {
  //         // Make visual updates here.
  //         if (psychologists.length === 0) {
  //             fetch('https://api.thepsycure.com/psychologists')
  //                 .then(res => res.json())
  //                 .then(data => { setPsychologists(data); }
  //                 )
  //         }
  //     }

  //     requestAnimationFrame(updateScreen);
  // }, [psychologists]);

  useEffect(() => {
    fetch("https://api.thepsycure.com/psychologists")
      .then((res) => res.json())
      .then((data) => {
        setDataLoaded(true);
        setPsychologists(data);
      });
  }, []);

  let approvedPsychologist = psychologists
    ?.filter((psychologist) => psychologist?.approved === true)
    .sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));

  const values = approvedPsychologist?.map((psychologist) => {
    let totalReview, likedReview;
    totalReview = psychologist.reviews.length;
    likedReview = psychologist.reviews?.filter(
      (review) => review.rating === "like"
    ).length;

    return { totalReview, likedReview };
  });
  return (
    <Container>
      <h2 className="headin">Meet Our Psychologists</h2>
      <hr
        style={{
          display: "block",
          width: "10%",
          height: "3px",
          backgroundColor: "#31C75A",
          border: 0,
        }}
      />

      {dataLoaded ? (
        <Swiper
          className="psychologist-container"
          spaceBetween={30}
          grabCursor={false}
          centeredSlides={true}
          slidesPerView={"auto"}
          hashNavigation={{
            watchState: true,
          }}
          autoplay={{
            delay: 2200,
            disableOnInteraction: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
        >
          {approvedPsychologist?.map((psychologist, index) => (
            <SwiperSlide key={psychologist.name}>
              <div className="group-37417">
                <div
                  className="rectangle-59"
                  style={{
                    height: "440px",
                  }}
                >
                  <div
                    className="image-main"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="psychologist-image-square"
                      style={{
                        /*  display: "flex",
                      justifyContent: "center", */
                        marginTop: "10px",
                        height: "151px",
                        width: "151px",
                        borderRadius: "75.5px",

                        /* top: "230px",
                      left: "121px",
                      /* backgroundImage: `url(${psychologist?.image})`,
                      backgroundSize: "cover", */
                        // backgroundRepeat: "cover",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "75.5px",

                          // position: "relative",
                        }}
                        src={psychologist?.image}
                        alt=""
                        srcset=""
                      />
                      <img
                        className="verified-image"
                        src={verifiedImage}
                        style={{
                          height: "44px",
                          width: "44px",
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <p className="psychologist-name">{psychologist?.name}</p>
                  </div>
                  <div
                    className="review"
                    style={{
                      marginTop: "2px",
                    }}
                  >
                    <Rating
                      className="rating-system"
                      size="large"
                      name="read-only"
                      value={
                        (((values[index].likedReview /
                          values[index].totalReview) *
                          100) /
                          100) *
                          5 ==
                        5
                          ? 5
                          : 4.5
                      }
                      sx={{
                        color: "black",
                      }}
                      precision={0.5}
                      readOnly
                    />
                  </div>
                  <div className="pricing">
                    <p className="pricing-text">{psychologist?.amount}৳</p>
                  </div>
                  <div className="details">
                    <div className="details-text">
                      {psychologist?.yourself.slice(0, 10)}
                      <span className="faded-text">
                        {psychologist?.yourself.slice(10, 70)}
                      </span>
                    </div>
                  </div>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/psychologists/details/${psychologist?.name
                      ?.split(" ")
                      .join("-")}`}
                  >
                    <div className="booking-button-custom-home-parent">
                      <div className="booking-button-custom-home">
                        <p>Book Session</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <CircularProgress />
        </div>
      )}
    </Container>
  );
};

export default Psychologist;
