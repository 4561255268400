import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BookingModal = ({ open, handleClose, logedInUser }) => {
  const { user, logout } = useAuth();

  let [userDetails, setUserDetails] = useState({
    name: logedInUser?.name,
    emai: logedInUser?.email,
    location: logedInUser?.location,
    contact: logedInUser?.contact,
    age: logedInUser?.age,
    gender: logedInUser?.gender,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const userProfile = {
      name: userDetails?.name,
      location: userDetails?.location,
      contact: userDetails?.contact,
      age: userDetails?.age,
      gender: userDetails?.gender,
    };

    fetch(`https://api.thepsycure.com/userProfile/${user?.email}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(userProfile),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClose();
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <form className="mode" onSubmit={handleSubmit}>
            <Box>
              <TextField
                required
                sx={{ width: "95%", m: 2 }}
                id="outlined-required"
                label="Full Name"
                defaultValue={logedInUser?.name}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, name: e.target.value })
                }
              />
            </Box>
            {/* 
            <TextField
              required
              sx={{ width: '100%', m: 1 }}
              id="outlined-required"
              label="UserName"
              defaultValue="Oliver Queen"
            /> */}

            <Box>
              <TextField
                required
                sx={{ width: "95%", m: 2 }}
                id="outlined-required"
                label="Email"
                defaultValue={logedInUser?.email}
              />
            </Box>

            <Box
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <TextField
                required
                sx={{ width: "95%", m: 2 }}
                id="outlined-required"
                label="Location"
                defaultValue={logedInUser?.location}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, location: e.target.value })
                }
              />

              <TextField
                required
                sx={{ width: "95%", m: 2 }}
                id="outlined-required"
                label="Contact"
                type="number"
                defaultValue={logedInUser?.contact}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, contact: e.target.value })
                }
              />
            </Box>

            <Box
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <TextField
                required
                sx={{ width: "95%", m: 2 }}
                id="outlined-required"
                label="Age"
                type="number"
                defaultValue={logedInUser?.age}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, age: e.target.value })
                }
              />

              {/* <TextField
                required
                sx={{ width: '95%', m: 2 }}
                id="outlined-required"
                label="Gender"
                defaultValue={}
                onChange={e => setUserDetails({ ...userDetails, gender: e.target.value })}
              /> */}
              <p>{logedInUser?.gender}</p>
            </Box>

            <Box
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <Button
                style={{
                  backgroundColor: "#31C75A",
                  width: "60%",
                  marginLeft: "70px",
                }}
                type="submit"
                variant="contained"
              >
                Submit
              </Button>

              <Button
                style={{ backgroundColor: "#31C75A", width: "60%" }}
                onClick={handleClose}
                variant="contained"
              >
                Chancel
              </Button>
            </Box>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default BookingModal;
