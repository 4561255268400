import React, { useEffect } from "react";
import Header from "../../Shared/Header/Header";
import Footer from "../../Shared/Footer/Footer";
import workshopTop from "../../assets/images/workshop_top.png";
import { Box, Container, Grid } from "@mui/material";
import TopWorkshop from "./TopWorkshop/TopWorkshop";
import ReactGA from "react-ga4";

export default function Workshop() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: "Workshop",
    });
  }, []);
  return (
    <>
      <Header />
      <Container sx={{ my: 15 }}>
        <h2 className="headin" style={{ marginTop: "30px" }}>
          Workshops
        </h2>
        <hr
          style={{
            display: "block",
            width: "10%",
            height: "3px",
            backgroundColor: "#31C75A",
            border: 0,
          }}
        />
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <img
                style={{ width: "100%", height: "auto" }}
                src={workshopTop}
                alt="workshop image"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p sx={{ textAlign: "justify" }} variant="body1" gutterBottom>
                We are committed to promoting mental health and well-being. With
                over 110 workshops, both online and offline, we have trained
                over 11,000 individuals in various areas such as Emotional
                Intelligence,Emotional Regulation, Dealing with Depression,
                Anger Management , Suicide Prevention, Mindfulness & Meditation,
                and Research Methodology.
              </p>
            </Grid>
          </Grid>
        </Box>
        <TopWorkshop />
      </Container>
      <Footer />
    </>
  );
}
