import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
// import { Button } from '@mui/material';

import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import BookingModal from "../EditUserInfo/BookingModal";
// import DrawerComp from '../../Shared/Header/Drawer';
import logo from "../../assets/images/Psycure Limited logo.png";
import useAuth from "../../hooks/useAuth";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
// import AssessmentIcon from '@mui/icons-material/Assessment';
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AssistantIcon from "@mui/icons-material/Assistant";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useLogout } from "../../hooks/useLogout";
import { useTheme } from "@material-ui/core/styles";
import "./DashboardNavigation.css";
import { HomeRepairService } from "@mui/icons-material";
import ReactGA from "react-ga4";

const drawerWidth = 240;

const normalStyle = {
  textDecoration: "none",
  color: "black",
  display: "flex",
};
const activeStyle = {
  textDecoration: "none",
  color: "black",
  display: "flex",
  fontWeight: "700",
};

function DashboardNavigation(props) {
  const [getLink, setGetLink] = React.useState({});
  const [allUsers, setAllUsers] = React.useState([]);

  const theme = useTheme();

  React.useEffect(() => {
    fetch("https://api.thepsycure.com/meetLinks")
      .then((res) => res.json())
      .then((data) => {
        setGetLink(data);
      });

    fetch("https://api.thepsycure.com/users")
      .then((res) => res.json())
      .then((data) => {
        setAllUsers(data);
      });

    ReactGA.send({
      hitType: "pageview",
      page: `${window?.location?.pathname + window?.location?.search}`,
      title: `User Dashboard`,
    });
  }, []);

  /* =====> AUTHENTICATION<======= */
  const { user } = useAuth();
  const { Logout } = useLogout();
  const location = useLocation();

  const handleLogout = (e) => {
    Logout();
  };
  const [open, setOpen] = React.useState(false);
  const logedInUser = allUsers?.filter(
    (singleUser) => singleUser.email == user?.email
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Link
          style={{
            textDecoration: "none",
            color: "black",
            display: "flex",
            marginBottom: "0.4rem",
          }}
          to="/"
        >
          {" "}
          <img src={logo} alt="" />
        </Link>
      </Toolbar>

      <Divider />
      <List style={{ marginLeft: "1rem" }}>
        <ListItem>
          <NavLink
            style={
              location?.pathname == "/Dashboard" ? activeStyle : normalStyle
            }
            to="/Dashboard"
          >
            <span style={{ marginRight: "0.5rem" }}>
              <DashboardIcon></DashboardIcon>
            </span>{" "}
            Dashboard
          </NavLink>
        </ListItem>
        {/* <ListItem>
                    <Link style={{ textDecoration: 'none', color: 'black', display: 'flex' }} to='/Dashboard/homework'><span style={{ marginRight: '0.5rem' }}><MapsHomeWorkIcon></MapsHomeWorkIcon></span> Homeworks</Link>
                </ListItem> */}
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/Dashboard/Session-History`}
          >
            <span style={{ marginRight: "0.5rem" }}>
              <ManageSearchIcon></ManageSearchIcon>
            </span>{" "}
            Session History
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/Dashboard/registered-workshop`}
          >
            <span style={{ marginRight: "0.5rem" }}>
              <HomeRepairService></HomeRepairService>
            </span>{" "}
            Workshops
          </NavLink>
        </ListItem>
        {/* <ListItem>
                    <Link style={{ textDecoration: 'none', color: 'black', display: 'flex' }} to={`/Dashboard/reports`}><span style={{ marginRight: '0.5rem' }}><AssessmentIcon></AssessmentIcon></span>Reports</Link>
                </ListItem> */}
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/Dashboard/journal`}
          >
            <span style={{ marginRight: "0.5rem" }}>
              <MenuBookIcon></MenuBookIcon>
            </span>
            Mood Journals
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/Dashboard/recommend`}
          >
            <span style={{ marginRight: "0.5rem" }}>
              <AssistantIcon></AssistantIcon>
            </span>
            Recommendation
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/Dashboard/choose-your-psychologist`}
          >
            <span style={{ marginRight: "0.5rem" }}>
              <LocalHospitalIcon></LocalHospitalIcon>
            </span>
            Choose your Psychologist
          </NavLink>
        </ListItem>

        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/Dashboard/edit`}
          >
            {" "}
            <span style={{ marginRight: "0.5rem" }}>
              <EditIcon></EditIcon>
            </span>
            Edit Profile
          </NavLink>
        </ListItem>
        <ListItem>
          <p
            sx={{ color: "black", display: "flex" }}
            onClick={handleLogout}
            style={{ display: "flex", margin: "0", cursor: "pointer" }}
          >
            {" "}
            <span style={{ marginRight: "0.5rem" }}>
              <ExitToAppIcon></ExitToAppIcon>
            </span>
            <span>Logout</span>
          </p>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className="topbar"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          pb: 1.5,
          height: "4.8rem",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none !important" } }}
            style={{
              position: "absolute",
              right: "16px",
              padding: "16px",
              marginRight: "-12px",
              top: "1rem",
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {/* <Toolbar /> */}
        <>
          <Box sx={{ pt: 10 }}>
            <Outlet />

            {/* <Container sx={{ my: 20 }}> */}
            <BookingModal
              handleClose={handleClose}
              open={open}
              logedInUser={logedInUser[0]}
            ></BookingModal>
            {/* </Container> */}
          </Box>
        </>
      </Box>
    </Box>
  );
}

DashboardNavigation.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DashboardNavigation;
