import React, { useState, useLayoutEffect, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import like from "./../../../assets/images/like.png";
import dislike from "./../../../assets/images/dislike.png";
import { TextField } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import SuccessAlert from "../../../Shared/SuccessAlert/SuccessAlert";

import Header from "../../../Shared/Header/Header";
import ReactGA from "react-ga4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

const RatingVideoSession = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window?.location?.pathname + window?.location?.search}`,
      title: `Rate video session`,
    });
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let { id } = useParams();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [session, setSession] = useState([]);
  const [psychologist, setPsychologist] = useState({});
  const [review, setReview] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate("");

  useLayoutEffect(() => {
    fetch("https://api.thepsycure.com/session")
      .then((res) => res.json())
      .then((data) => {
        const foundSession = data.filter((detail) => detail._id === id);
        setIsLoading(false);
        setSession(foundSession);
      });
  }, [id]);

  const { selectedPsychologists, userDetails } = session[0] || {};
  const { name, category, image, email } = selectedPsychologists || {};
  // const { displayName: patientName, email: patientEmail } = userDetails || {}

  useEffect(() => {
    fetch(`https://api.thepsycure.com/psychologists/${email}`)
      .then((res) => res.json())
      .then((data) => {
        setPsychologist(data);
      });
  }, [session]);

  const handleLike = (rating) => {
    if (rating === "like") {
      setReview({ ...review, rating: "like", ...userDetails });
    }
    if (rating === "dislike") {
      setReview({ ...review, rating: "dislike", ...userDetails });
    }
  };

  const handleSubmit = (e) => {
    // let allReview = psychologist?.reviews.push(review)

    const provideReview = {
      ...psychologist,
      reviews: [...psychologist?.reviews, review],
    };

    fetch(`https://api.thepsycure.com/psychologists/${email}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(provideReview),
    })
      .then((res) => res.json())
      .then((data) => {
        // setError("")
        handleClose();
        setSuccess(true);
        setReview({});
        setTimeout(() => {
          navigate(
            `/psychologists/details/${psychologist?.name?.split(" ").join("-")}`
          );
        }, 3000);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess("");
      setError("");
    }, 3000);
  }, [success, error]);

  return (
    <>
      <Header />
      <div style={{ marginTop: "100px", textAlign: "center" }}>
        <h2>Please say something about {name}</h2>
        <Button
          style={{ backgroundColor: "#31C75A", color: "#fff" }}
          onClick={handleOpen}
        >
          Give a Review to this Psychologist
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form>
              <div style={{ textAlign: "center" }}>
                <div>
                  <img
                    style={{
                      width: "120px",
                      height: "120px",
                      objectFit: "cover",
                    }}
                    src={image}
                    alt={name}
                  />
                  <h3>{name}</h3>
                  <p> {category}</p>
                  <h4>Senior {category}</h4>
                  <h3 style={{ color: "#FFD06A" }}>Share Your Experience</h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => handleLike("like")}
                    style={{ border: "0", background: "none" }}
                  >
                    <img
                      style={{
                        opacity: review?.rating === "like" ? "1" : ".5",
                      }}
                      src={like}
                      alt=""
                    />
                  </div>
                  <div
                    onClick={() => handleLike("dislike")}
                    style={{ border: "0", background: "none" }}
                  >
                    <img
                      style={{
                        opacity: review?.rating === "dislike" ? "1" : ".5",
                      }}
                      src={dislike}
                      alt=""
                    />
                  </div>
                </div>

                <TextField
                  fullWidth
                  sx={{ mt: 2 }}
                  required
                  id="outlined-required"
                  multiline
                  rows={3}
                  label="Review"
                  placeholder="Write your Experience"
                  onChange={(e) =>
                    setReview({ ...review, opinion: e.target.value })
                  }
                />
                <Button
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: "#31C75A",
                    padding: "10px 20px ",
                    borderRadius: "15px",
                    marginTop: "1rem",
                  }}
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
        {success ? (
          <SuccessAlert>
            We appriciate your kindness for spending some time to review our
            psychologist.
          </SuccessAlert>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default RatingVideoSession;
