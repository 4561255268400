import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
  
} from "@mui/material";

import React from "react";
import RichTextEditor from "./RichTextEditor";
import placeHolderImage from "./../../../assets/images/blogImage.jpg";
import { useState } from "react";
import { projectStorage } from "../../../firebase.config";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import useAuth from "../../../hooks/useAuth";
import ErrorAlert from "../../../Shared/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../../Shared/SuccessAlert/SuccessAlert";

const AddBlog = () => {
  const { user } = useAuth();

  const [logoFileError, setlogoFileError] = useState("");
  const [logoProgress, setLogoProgress] = useState(0);

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [authorImage, setAuthorImage] = useState("");
  const [blogImage, setBlogImage] = useState("");
  const [blogImageCaption, setBlogImageCaption] = useState("");
  const [blogImageDescription, setBlogImageDescription] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [blogContent, setBlogContent] = useState();
  const [blogCategory, setBlogCategory] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");
  const types = ["image/png", "image/jpeg", "image/jpg"];

  const [categoryName, setCategoryName] = React.useState("");

  const handleAdd = (e) => {
    if (!blogCategory.includes(categoryName)) {
      setBlogCategory([...blogCategory, categoryName]);
      setCategoryName("");
    }
  };

  const handleImage = (e, imageFor) => {
    let selected = e.target.files[0];

    if (selected && !types.includes(selected.type)) {
      // setDetails({ ...details, image: "" })
      setlogoFileError(
        "Please select an image file (png , jpg Or JPeg) for Logo"
      );
      return;
    }
    const storageRef = ref(projectStorage, `files/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setLogoProgress(progress);
      },
      (error) => {
        setlogoFileError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (imageFor === "blogImage") {
            setBlogImage(downloadURL);
          } else {
            setAuthorImage(downloadURL);
          }
        });
      }
    );
  };
  const today = new Date().toISOString().slice(0, 10);
  const handlePostBlog = async (e) => {
    e.preventDefault();
    const randomNumber = Math.floor(Math.random() * (2000 - 1000 + 1)) + 1000;
    console.log(randomNumber);
    let details = {
      blogTitle,
      blogContent,
      blogImage,
      blogImageCaption,
      blogImageDescription,
      metaDescription,
      blogCategory,
      author: user?.displayName || "Admin",
      authorEmail: user?.email,
      authorImage: authorImage || user?.photoURL,
      date: today,
      readTime: randomNumber,
      ipList: [],
    };

    const response = await fetch("https://api.thepsycure.com/add-blog", {
      method: "POST",
      body: JSON.stringify(details),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      setError("Could not upload your blog. Please try again");
    }
    if (response.ok) {
      setBlogImage("");
      setBlogTitle("");
      setMetaDescription("");
      setBlogContent("");
      setBlogCategory([]);
      setSuccess("Congratulations, Blog Added");
    }
  };

  return (
    <div>
      <Box>
        <Container sx={{ flexGrow: 1 }}>
          <Box md={5} sx={12}>
            <Box>
              <h2
                style={{ textAlign: "center" }}
                variant="h4"
                gutterBottom
                component="div"
              >
                Add Blog
              </h2>
              <hr
                style={{
                  display: "block",
                  width: "10%",
                  height: "3px",
                  backgroundColor: "#31C75A",
                  border: 0,
                }}
              />
            </Box>

            <Box>
              {blogImage ? (
                <img
                  style={{ maxWidth: "500px", margin: "auto", display: "flex" }}
                  src={blogImage}
                  alt=""
                />
              ) : (
                <img
                  style={{ maxWidth: "500px", margin: "auto", display: "flex" }}
                  src={placeHolderImage}
                  alt=""
                />
              )}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <TextField
                    required
                    sx={{ width: "100%", marginRight: "1%" }}
                    id="outlined-required"
                    label="Blog Title"
                    onChange={(e) => setBlogTitle(e.target.value)}
                    value={blogTitle}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    style={{ width: "100%" }}
                    id="standard-basic"
                    onChange={(e) => handleImage(e, "blogImage")}
                    name="image"
                    type="file"
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{ mt: 2, mb: 2 }}
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    sx={{ width: "100%", marginRight: "1%" }}
                    id="outlined-required"
                    label="Alt text"
                    onChange={(e) => setBlogImageCaption(e.target.value)}
                    value={blogImageCaption}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    sx={{ width: "100%", marginRight: "1%" }}
                    id="outlined-required"
                    label="Image Description"
                    onChange={(e) => setBlogImageDescription(e.target.value)}
                    value={blogImageDescription}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    sx={{ width: "100%", marginRight: "1%" }}
                    id="outlined-required"
                    label="Meta Description"
                    onChange={(e) => setMetaDescription(e.target.value)}
                    value={metaDescription}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      required
                      sx={{ width: "100%", marginRight: "1%" }}
                      id="outlined-required"
                      label="Blog Category"
                      onChange={(e) => setCategoryName(e.target.value)}
                      value={categoryName}
                    />

                    <Button
                      style={{ backgroundColor: "#31C75A" }}
                      type="submit"
                      variant="contained"
                      onClick={handleAdd}
                    >
                      Add
                    </Button>
                  </div>

                  {/* <FormControl style={{ width: '100%'}}>
                                        <InputLabel id="demo-multiple-Specialization-label" style={{ opacity: categoryName.length ? "0" : "1" }}>Blog Category</InputLabel>
                                        <Select
                                            labelId="demo-multiple-Specialization-label"
                                            id="demo-multiple-Specialization"
                                            multiple
                                            value={categoryName}
                                            onChange={handleCategoryChange}
                                            input={<OutlinedInput id="select-multiple-Specialization" label="Specialization" />}
                                            renderValue={(selected) => selected.map(obj => categories[obj - 1].value).join(", ")}
                                        >
                                            {categories.map((name) => (
                                                <MenuItem style={{ display: "block", marginBottom: "10px", display: "flex" }} key={name.id} value={name.id}>
                                                    <Checkbox checked={categoryName.indexOf(name.id) > -1} />
                                                    <ListItemText primary={name.value} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> */}
                </Grid>
              </Grid>

              {blogCategory?.length ? (
                <div>
                  <h4 style={{ marginBottom: "0rem" }}>Selected Category</h4>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "1.5rem" }}
                  >
                    {blogCategory?.map((category, index) => (
                      <p
                        key={index}
                        style={{
                          backgroundColor: "rgba(49, 199, 90, .2)",
                          padding: "1rem",
                          borderRadius: ".4rem",
                        }}
                      >
                        {category}
                      </p>
                    ))}
                  </div>
                </div>
              ) : null}

              {!user?.photoURL && (
                <>
                  <p>Upload Author image</p>
                  <TextField
                    style={{ width: "100%", marginBottom: "1rem" }}
                    id="standard-basic"
                    onChange={(e) => handleImage(e, "authorImage")}
                    name="image"
                    type="file"
                    variant="outlined"
                  />
                  <img
                    style={{ width: "150px", margin: "auto", display: "flex" }}
                    src={authorImage}
                    alt=""
                  />
                </>
              )}

              <RichTextEditor setBlogContent={setBlogContent} />
              <div style={{ textAlign: "center", marginTop: "2rem" }}>
                <Button
                  style={{ backgroundColor: "#31C75A" }}
                  type="submit"
                  variant="contained"
                  onClick={handlePostBlog}
                >
                  Submit
                </Button>
              </div>
            </Box>
            <Box md={6} sx={12}></Box>
          </Box>
        </Container>
      </Box>
      {error && <ErrorAlert>{error}</ErrorAlert>}
      {success && <SuccessAlert>{success}</SuccessAlert>}
    </div>
  );
};

export default AddBlog;
