import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import backgroundImage from "./../../assets/images/signinBG.png";
// import signInImage from './../../assets/images/signin image.png'
import signInImage from "./../../assets/images/login.svg";

import { Container } from "@mui/system";
import { CardMedia, CircularProgress } from "@mui/material";
import "./BlogerSignin.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useLogin } from "../../hooks/useLogin";
import Footer from "../../Shared/Footer/Footer";
import SuccessfulSignup from "../SignUp/SuccessfulSignup";
import { useForgotPassword } from "../../hooks/useForgotPassword";
import SuccessAlert from "../../Shared/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../Shared/ErrorAlert/ErrorAlert";
import styled from "styled-components";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: #31c75a;
    border-color: #31c75a;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #31c75a;
    }
  }
`;

const SignIn = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Blogger Sign In`,
    });
  }, []);
  const [loginData, setLoginData] = useState({});
  let [issue, setIssue] = useState(null);
  const [emailValidate, setEmailValidate] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const { user } = useAuth();
  const [userObj, setUserObj] = useState({});
  const [isValid, setIsValid] = useState("");
  const { Login, setError, error, isLoading } = useLogin();
  const {
    sendPasswordReset,
    error: forgotError,
    success,
  } = useForgotPassword();

  const [forgotPage, setForgotPage] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://api.thepsycure.com/users/${loginData.email}`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setUserObj(data);
          setIsValid("");
        }
      })
      .catch((err) => {
        if (err) {
          setUserObj({});
        }
      });
  }, [loginData.email]);

  function CheckPassword(inputtxt) {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (inputtxt.match(passw)) {
      setIssue(false);
      setEmailVerified(true);
      setError(null);
      return true;
    } else {
      setEmailVerified(false);
      return false;
    }
  }

  function ValidateEmail(email) {
    if (
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailValidate(true);
      setEmailVerified(true);
      setError(null);
      setIssue(false);
      return true;
    }
    setEmailValidate(false);
    setEmailVerified(false);
    setIssue("Please enter a valid email address.");
    return false;
  }

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
    setError(null);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();

    if (userObj?.role === "bloger") {
      Login(loginData.email, loginData.password, location, navigate, "bloger");
    } else {
      setError("We could not find any patient with this email.");
    }
  };

  const handleForgot = (e) => {
    e.preventDefault();

    sendPasswordReset(forgotEmail);
  };

  return !user?.email ? (
    <>
      <Header />
      <Container
        className="main"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Grid
            container
            component="main"
            sx={{
              boxShadow: "0px 4px 48px rgba(0, 0, 0, 0.25)",
              borderRadius: "50px",
            }}
          >
            {/* ========> LEFT SIGH IN<=========== */}

            <Grid
              className="left-signIn"
              item
              xs={12}
              md={6}
              sx={{
                background: `url(${backgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: "#27583A",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  height: "70vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <CardMedia
                    component="img"
                    alt="sign in"
                    image={signInImage}
                  />
                </Box>
              </Box>
            </Grid>

            {/* ========> RIGHT SIGH IN<=========== */}

            {!forgotPage ? (
              <Grid className="right-signIn" item xs={12} md={6}>
                <form onSubmit={handleLoginSubmit}>
                  <h2>Blogger Sign In</h2>
                  <WhiteBorderTextField
                    sx={{ width: "75%", m: 1 }}
                    id="standard-basic"
                    label="Your Email"
                    name="email"
                    type="email"
                    required
                    onChange={(e) => {
                      handleOnChange(e);
                      ValidateEmail(e.target.value);
                    }}
                    variant="outlined"
                  />

                  <WhiteBorderTextField
                    sx={{ width: "75%", m: 1 }}
                    id="standard-password-input"
                    label="Your Password"
                    type="password"
                    name="password"
                    required
                    onChange={handleOnChange}
                    autoComplete="current-password"
                    variant="outlined"
                  />
                  <p
                    style={{
                      width: "75%",
                      margin: "0 auto",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                    onClick={() => setForgotPage(!forgotPage)}
                  >
                    Forgot Password?
                  </p>

                  {error && <small style={{ color: "red" }}>{error}</small>}
                  {isValid && <small style={{ color: "red" }}>{isValid}</small>}

                  {!emailVerified || issue ? (
                    <small style={{ color: "red" }}>{issue}</small>
                  ) : (
                    <></>
                  )}
                  <br />
                  {!isLoading && (
                    <Button
                      className="signin-btn"
                      type="submit"
                      variant="contained"
                      style={{ marginTop: "1rem" }}
                    >
                      Sign In
                    </Button>
                  )}
                  {isLoading && (
                    <div>
                      <CircularProgress />
                    </div>
                  )}
                  <br />

                  <div>
                    <p style={{ margin: "10px 0 " }}>New User?</p>

                    <Link
                      style={{ textDecoration: "none", color: "#000" }}
                      to="/bloger-signup"
                    >
                      {" "}
                      <Button
                        sx={{
                          color: "#000",
                          borderRadius: "50px",
                          padding: "8px 30px",
                        }}
                        color="inherit"
                        variant="outlined"
                      >
                        SIGN UP
                      </Button>
                    </Link>
                  </div>
                </form>
              </Grid>
            ) : (
              <Grid className="right-signIn" item xs={12} md={6}>
                <form onSubmit={handleForgot}>
                  <h2>Your Email</h2>
                  <WhiteBorderTextField
                    sx={{ width: "75%", m: 1 }}
                    id="standard-password-input"
                    label="Your Password"
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    variant="outlined"
                    style={{ visibility: "hidden" }}
                  />
                  <WhiteBorderTextField
                    sx={{ width: "75%", m: 1 }}
                    id="standard-basic"
                    label="Your Email"
                    name="email"
                    type="email"
                    required
                    onChange={(e) => setForgotEmail(e.target.value)}
                    variant="outlined"
                  />
                  <p
                    style={{
                      width: "75%",
                      margin: "0 auto",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                    onClick={() => setForgotPage(!forgotPage)}
                  >
                    Signin Page?
                  </p>
                  {success ? <SuccessAlert>{success}</SuccessAlert> : ""}
                  {forgotError ? <ErrorAlert>{forgotError}</ErrorAlert> : ""}
                  <br />
                  {
                    <Button
                      className="signin-btn"
                      type="submit"
                      variant="contained"
                      style={{ marginTop: "1rem" }}
                    >
                      Submit
                    </Button>
                  }
                  <br /> <br />
                </form>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  ) : (
    <SuccessfulSignup greet="login" />
  );
};

export default SignIn;
