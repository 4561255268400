import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Button } from "@mui/material";
import { Container } from "@mui/system";
import { Link, Outlet } from "react-router-dom";
import BookingModal from "../EditUserInfo/BookingModal";

import logo from "../../assets/images/Psycure Limited logo.png";
import useAuth from "../../hooks/useAuth";
import DashboardIcon from "@mui/icons-material/Dashboard";

import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useLogout } from "../../hooks/useLogout";
const drawerWidth = 240;

function BlogerDashboardNavigation(props) {
  const [getLink, setGetLink] = React.useState({});
  const [users, setUsers] = React.useState([]);

  /* =====> AUTHENTICATION<======= */
  const { user } = useAuth();
  const { Logout } = useLogout();

  React.useEffect(() => {
    fetch(`https://api.thepsycure.com/users/${user?.email}`)
      .then((res) => res.json())
      .then((data) => {
        setUsers(data);
      });
  }, []);

  const handleLogout = (e) => {
    Logout();
  };
  const [open, setOpen] = React.useState(false);
  const logedInUser = users?.email;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Link
          style={{
            textDecoration: "none",
            color: "black",
            display: "flex",
            marginBottom: "0.4rem",
          }}
          to="/"
        >
          {" "}
          <img src={logo} alt="" />
        </Link>
      </Toolbar>
      <Divider />
      <List style={{ marginLeft: "1rem" }}>
        <ListItem>
          <Link
            style={{ textDecoration: "none", color: "black", display: "flex" }}
            to="/bloger-dashboard"
          >
            <span style={{ marginRight: "0.5rem" }}>
              <DashboardIcon></DashboardIcon>
            </span>
            All Blogs
          </Link>
        </ListItem>
        <ListItem>
          <Link
            style={{ textDecoration: "none", color: "black", display: "flex" }}
            to={"/bloger-dashboard/addBlog"}
          >
            <span style={{ marginRight: "0.5rem" }}>
              <ManageSearchIcon></ManageSearchIcon>
            </span>{" "}
            Add Blog
          </Link>
        </ListItem>
        <ListItem>
          <Link
            style={{ textDecoration: "none", color: "black", display: "flex" }}
            to={"/bloger-dashboard/info"}
          >
            <span style={{ marginRight: "0.5rem" }}>
              <EditIcon></EditIcon>
            </span>
            About Bloger
          </Link>
        </ListItem>

        {/* 
                <ListItem>
                    <Link style={{ textDecoration: 'none', color: 'black', display: 'flex' }} to={`/Dashboard/edit`}> <span style={{ marginRight: '0.5rem' }}><EditIcon></EditIcon></span>Edit</Link>
                </ListItem> */}
        <ListItem>
          <Button
            sx={{ color: "black", display: "flex" }}
            onClick={handleLogout}
          >
            {" "}
            <span style={{ marginRight: "0.5rem" }}>
              <ExitToAppIcon></ExitToAppIcon>
            </span>
            Logout
          </Button>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: "white", color: "black" }}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          pb: 1.5,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <h3>
                        <NavLink style={{ textDecoration: 'none', color: 'green' }} to='/'>Psycure Limited</NavLink>
                    </h3>
                    <DrawerComp /> */}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <>
          <Box sx={{ py: 10 }}>
            <Outlet />

            <Container sx={{ my: 20 }}>
              <BookingModal
                handleClose={handleClose}
                open={open}
                logedInUser={logedInUser}
              ></BookingModal>
            </Container>
          </Box>
        </>
      </Box>
    </Box>
  );
}

BlogerDashboardNavigation.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default BlogerDashboardNavigation;
