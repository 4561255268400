import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import FAQ from './FAQ/FAQ';
import faqImg from '../../assets/images/faq-img.svg';
import Footer from '../../Shared/Footer/Footer';
import Header from '../../Shared/Header/Header';
import ReactGA from "react-ga4";

export default function FAQmain() {
    React.useEffect(() => {
        ReactGA.send({
          hitType: "pageview",
          page: `${window.location.pathname + window.location.search}`,
          title: "FAQ",
        });
      }, []);
    return (
        <>
        <Header />
            <Container style={{ marginTop: "10rem" }} sx={{ my: 5 }}>
                <h2 className='headin'>
                    Frequently Asked Questions
                </h2>
                <hr style={{ display: 'block', width: '10%', height: '3px', backgroundColor: '#31C75A', border: 0 }} />
                <Box sx={{ my: 5 }}>
                    <Grid container spacing={12} sx={{ flexGrow: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <img style={{ width: '100%', height: 'auto' }} src={faqImg} alt="FAQ-img" />

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FAQ />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer />
        </>
    );
}
