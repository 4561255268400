import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import aboutImg from "../../assets/images/about-img.png";
import Teams from "../Teams/Teams";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";
export default function AboutUs() {
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: "About Us",
    });
  }, []);

  return (
    <>
      <Header />
      <Container sx={{ my: 15 }}>
        <h2 className="headin" style={{ marginTop: "30px" }}>
          About Us
        </h2>
        <hr
          style={{
            display: "block",
            width: "10%",
            height: "3px",
            backgroundColor: "#31C75A",
            border: 0,
          }}
        />
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <img
                style={{ width: "100%", height: "auto" }}
                src={aboutImg}
                alt="Psycure logo"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p sx={{ textAlign: "justify" }} variant="body1" gutterBottom>
                Psycure organization is a one-stop digital solution platform for
                mental health and emotional well-being, working towards
                empowering Bangladesh through breaking the stigma and providing
                holistic services for mental health and well-being.
              </p>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Teams />
    </>
  );
}
