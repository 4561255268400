import React, { useEffect, useState } from "react";
import ticket from "../../../assets/images/ticket-discount.png";

import { useParams } from "react-router-dom";
import Header from "../../../Shared/Header/Header";
import ReactGA from "react-ga4";

import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import SignIn from "../../SignIn/SignIn.js";

const SingleGiftCard = () => {
  const { id } = useParams();
  const [packageName, setPackageName] = useState("");

  useEffect(() => {
    axios
      .get(`https://api.thepsycure.com/gift-card/${id}`)
      .then((data) => setPackageName(data.data))
      .catch((err) => console.log(err));
  }, [id]);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Gift card package name: ${packageName?.packageName}`,
    });
    
  }, [packageName]);
  const [purchaseFor, setPurchaseFor] = useState("");
  const { user } = useAuth();
  const [userDetails, setUserDetails] = useState();
  // promo code things
  let [promoCode, setPromoCode] = useState("");
  let [allPromo, setAllPromo] = useState([]);
  let [amount, setAmount] = useState(0);
  let [discountPrice, setDiscountPrice] = useState(0);
  let [error, setError] = useState("");
  let [success, setSuccess] = useState("");
  let [selectedPromo, setSelectedPromo] = useState({});
  const [promoState, setPromoState] = useState(false);
  useEffect(() => {
    fetch("https://api.thepsycure.com/gift-cards-promo")
      .then((res) => res.json())
      .then((data) => {
        setAllPromo(data);
      });
  }, []);
  useEffect(() => {
    setAmount(packageName?.packagePrice);
  }, [packageName]);
  const handlePromoAppy = () => {
    console.log(selectedPromo);
    if (promoCode) {
      let filteredPromo = allPromo?.filter(
        (promo) => promo?.name === promoCode
      );
      setSelectedPromo(filteredPromo[0]);
      if (filteredPromo[0]?.percentage) {
        setError("");
        let discountAmount =
          amount * (Number(filteredPromo[0]?.percentage) / 100);
        if (discountAmount > Number(filteredPromo[0]?.maxDiscount)) {
          setDiscountPrice(Number(filteredPromo[0]?.maxDiscount));
          setPromoState(true);
        } else {
          setDiscountPrice(discountAmount);
          setPromoState(true);
        }
        setSuccess(
          "Successfully applied your promo code. Now you can pay with discount price"
        );
      } else {
        setDiscountPrice(0);
        setError("The promo code is invalid");
        setSuccess("");
      }
    } else {
      setError("Please enter your promo code");
      setSuccess("");
    }
  };
  // promo-code end
  const handlePurchaseFor = (event) => {
    setPurchaseFor(event.target.value);
  };

  useEffect(() => {
    axios
      .get(`https://api.thepsycure.com/users/${user?.email}`)
      .then((data) => {
        setUserDetails(data.data);
      })
      .catch((err) => console.log(err));
  }, [user]);
  return (
    <>
      <Header></Header>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // gap: "1rem",
          }}
        >
          <div style={{ maxWidth: "500px" }}>
            <h2 style={{ margin: "0", color: "#31C75A" }}>
              {/* Help us finding  */}
              Well-being E-gift Card 2024
            </h2>
            <p style={{ margin: "0" }}>
              Looking for a way to improve your mental well-being or give
              special someone the gift card of self-care? Look no further than
              Psycure's e-gift card offer. Our e-gift card provides access to a
              professional counseling session with one of our certified
              therapists, as well as a guided meditation and stress management
              session.
            </p>
            <p>
              To purchase your e-gift card, simply fill out this form. Once you
              submit the form, we will send you the e-gift card and further
              details on how to avail our services
            </p>
            <p>
              Thank you for choosing Psycure to support your mental health
              journey.
            </p>
            <p>[All your information will be kept confidential]</p>
          </div>
        </div>
        <form
          action="https://api.thepsycure.com/gift-card-payment"
          method="post"
          className="payment_form"
          style={{ maxWidth: "500px", marginTop: "0px" }}
        >
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-label">
              Do you want to purchase this e-gift card for you / someone?
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              //   value={age}
              label="purchaseFor"
              name="purchaseFor"
              onChange={handlePurchaseFor}
            >
              <MenuItem value={"myself"}>For Myself</MenuItem>
              <MenuItem value={"someone"}>For Someone</MenuItem>
            </Select>
          </FormControl>
          {purchaseFor === "myself" && user && (
            <div>
              {/* name */}
              <div
                style={{ width: "100%", margin: "20px 0" }}
                className="input_container billing-info"
              >
                <label className="phone-label" for="name">
                  Name
                </label>
                <input
                  required
                  name="cus_name"
                  id="name"
                  defaultValue={user?.displayName}
                  className="phone-input"
                  style={{ width: "100%" }}
                />
              </div>
              {/* email */}
              <div
                className="input_container billing-info"
                style={{ width: "100%", margin: "20px 0" }}
              >
                <label className="phone-label" for="email">
                  Email
                </label>
                <input
                  type="email"
                  name="cus_email"
                  id="email"
                  readOnly
                  value={user?.email}
                  className="phone-input"
                  style={{ width: "100%" }}
                />
              </div>
              {/* contact number */}
              <div
                className="input_container billing-info"
                style={{ margin: "20px 0", width: "100%" }}
              >
                <label className="phone-label" for="phone">
                  Phone
                </label>
                <input
                  placeholder="Ex. 0184542125"
                  className="phone-input"
                  required
                  id="phone"
                  name="cus_phone"
                  defaultValue={
                    userDetails?.phone?.length > 11 &&
                    userDetails?.phone?.length <= 13
                      ? userDetails?.phone
                      : ""
                  }
                  minLength={11}
                  maxLength={13}
                />
              </div>
              {/* amount */}
              <div
                className="input_container billing-info"
                style={{ margin: "20px 0", width: "100%", display: "none" }}
              >
                <label className="phone-label" for="phone">
                  Amount
                </label>
                <input
                  placeholder="Ex. 0184542125"
                  className="phone-input"
                  required
                  value={
                    !promoState
                      ? packageName?.packagePrice
                      : amount - discountPrice
                  }
                  id="amount"
                  name="amount"
                />
              </div>
              {/* package id */}
              <div
                className="input_container billing-info"
                style={{ margin: "20px 0", width: "100%", display: "none" }}
              >
                <label className="phone-label" for="phone">
                  Package Id
                </label>
                <input
                  placeholder="Ex. 0184542125"
                  className="phone-input"
                  required
                  value={packageName?._id}
                  id="packageId"
                  name="packageId"
                />
              </div>
              {/* psychologist-preference */}
              <div>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Would you prefer a male or female therapist for the
                    counseling session ?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    //   value={age}
                    label="psychologistPreference"
                    name="psychologistPreference"
                    required
                  >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                    <MenuItem value={"not to say"}>Prefer not to say</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/* promo-code */}
              {packageName?.packageName != "bondhu" &&
                packageName?.packageName != "happiness" &&
                packageName?.packageName != "premium" && (
                  <div className="promo-code-things" style={{ margin: "1% 0" }}>
                    <label for="promo_code" className="promo-code-label">
                      Promo Code
                    </label>
                    <div class="input-container" style={{ width: "100%" }}>
                      <span class="promo-icon">
                        <img
                          src={ticket}
                          alt=""
                          height="24px"
                          width="24px"
                          srcset=""
                        />
                      </span>

                      <input
                        type="text"
                        name={promoState && "promo_code"}
                        id="promo_code"
                        placeholder="Promo Code"
                        class="promo-input"
                        onChange={(e) => setPromoCode(e.target.value)}
                      />

                      <div onClick={handlePromoAppy} class="apply-button">
                        Apply Promo
                      </div>
                    </div>
                  </div>
                )}
              {/* selected package */}
              <div style={{ margin: "20px 0" }}>
                Selected package:{" "}
                <span style={{ fontWeight: "700" }}>
                  {packageName?.packageName}
                </span>
              </div>
              {!promoState && (
                <button
                  type="submit"
                  className="pay-button"
                  style={{ width: "100%" }}
                >
                  Pay ৳{packageName?.packagePrice}
                </button>
              )}
              {promoState && (
                <div>
                  <button
                    type="submit"
                    className="pay-button"
                    style={{ width: "100%" }}
                  >
                    Pay ৳{amount - discountPrice}
                  </button>
                </div>
              )}
              {error && <p style={{ color: "red" }}>{error}</p>}
              {success && <p style={{ color: "green" }}>{success}</p>}
            </div>
          )}

          {/* someone */}
          {purchaseFor === "someone" && (
            <div>
              {/* name */}
              <div
                style={{ width: "100%", margin: "20px 0" }}
                className="input_container billing-info"
              >
                <label className="phone-label" for="name">
                  Gift recipient's Name
                </label>
                <input
                  required
                  name="cus_name"
                  id="name"
                  className="phone-input"
                  style={{ width: "100%" }}
                />
              </div>
              {/* email */}
              <div
                className="input_container billing-info"
                style={{ width: "100%", margin: "20px 0" }}
              >
                <label className="phone-label" for="email">
                  Gift recipient's email address
                </label>
                <input
                  type="email"
                  name="cus_email"
                  id="email"
                  className="phone-input"
                  style={{ width: "100%" }}
                />
              </div>
              {/* contact number */}
              <div
                className="input_container billing-info"
                style={{ margin: "20px 0", width: "100%" }}
              >
                <label className="phone-label" for="phone">
                  Gift recipient's Phone Number
                </label>
                <input
                  placeholder="Ex. 0184542125"
                  className="phone-input"
                  required
                  id="phone"
                  name="cus_phone"
                  minLength={11}
                  maxLength={13}
                />
              </div>
              {/* amount */}
              <div
                className="input_container billing-info"
                style={{ margin: "20px 0", width: "100%", display: "none" }}
              >
                <label className="phone-label" for="phone">
                  Amount
                </label>
                <input
                  placeholder="Ex. 0184542125"
                  className="phone-input"
                  required
                  value={
                    !promoState
                      ? packageName?.packagePrice
                      : amount - discountPrice
                  }
                  id="amount"
                  name="amount"
                />
              </div>
              {/* package id */}
              <div
                className="input_container billing-info"
                style={{ margin: "20px 0", width: "100%", display: "none" }}
              >
                <label className="phone-label" for="phone">
                  Package Id
                </label>
                <input
                  placeholder="Ex. 0184542125"
                  className="phone-input"
                  required
                  value={packageName?._id}
                  id="packageId"
                  name="packageId"
                />
              </div>
              {/* psychologist-preference */}
              <div>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Would you prefer a male or female therapist for the
                    counseling session ?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    //   value={age}
                    label="psychologistPreference"
                    name="psychologistPreference"
                    required
                  >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                    <MenuItem value={"not to say"}>Prefer not to say</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/* promo-code */}
              {packageName?.packageName != "bondhu" &&
                packageName?.packageName != "happiness" &&
                packageName?.packageName != "premium" && (
                  <div className="promo-code-things" style={{ margin: "1% 0" }}>
                    <label for="promo_code" className="promo-code-label">
                      Promo Code
                    </label>

                    <div class="input-container" style={{ width: "100%" }}>
                      <span class="promo-icon">
                        <img
                          src={ticket}
                          alt=""
                          height="24px"
                          width="24px"
                          srcset=""
                        />
                      </span>

                      <input
                        type="text"
                        name={promoState && "promo_code"}
                        id="promo_code"
                        placeholder="Promo Code"
                        class="promo-input"
                        onChange={(e) => setPromoCode(e.target.value)}
                      />

                      <div onClick={handlePromoAppy} class="apply-button">
                        Apply Promo
                      </div>
                    </div>
                  </div>
                )}
              {/* selected package */}
              <div style={{ margin: "20px 0" }}>
                Selected package:{" "}
                <span style={{ fontWeight: "700" }}>
                  {packageName?.packageName}
                </span>
              </div>
              {!promoState && (
                <button
                  type="submit"
                  className="pay-button"
                  style={{ width: "100%" }}
                >
                  Pay ৳{packageName?.packagePrice}
                </button>
              )}
              {promoState && (
                <button
                  type="submit"
                  className="pay-button"
                  style={{ width: "100%" }}
                >
                  Pay ৳{amount - discountPrice}
                </button>
              )}
              {error && <p style={{ color: "red" }}>{error}</p>}
              {success && <p style={{ color: "green" }}>{success}</p>}
            </div>
          )}
        </form>
        {purchaseFor === "myself" && !user && (
          <div style={{ width: "100%" }}>
            <SignIn giftCardId={packageName?._id} />
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleGiftCard;
