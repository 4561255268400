import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const UnwindMindRules = () => {
  return (
    <div style={{ margin: "1% 0" }}>
      <Container container maxWidth="xl">
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            columns={{ lg: 10, xl: 10, md: 10, xs: 10 }}
            justifyContent="center"
            alignItems="center"
            direction="row"
            width="100%"
            spacing={5}
          >
            <Grid item lg={5} xl={5} md={6} xs={10}>
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                <Typography variant="h3">
                  <b>Submission Rules:</b>
                </Typography>
              </Box>
              <p style={{ color: "#3cb669" }}>
                You must fulfill the following rules in order to make your
                submission eligible for judgment:
              </p>
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/* <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Funderstanding.png?alt=media&token=1ce06547-a87a-4297-a8d8-4c8b19bb9169"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>1.</b> Create an engaging caption that provides a
                  story/scenario behind your photo/digital art/ painting-
                  Maximum 250 words. Use the two hashtags at the end of your
                  caption. <b>#unwindmind2024</b> <br />
                  <b>#psycureorganization</b>
                </Typography>
              </Box>
              {/* second */}
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/*  <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fcopling.png?alt=media&token=5ba8ad40-d9e6-4a76-99c4-c4dc33b54194"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>2.</b> Post the artwork along with the caption (must be
                  within 250 words) on your timeline, and keep the privacy of
                  the post <b>“public”</b>.
                </Typography>
              </Box>
              {/* third */}
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/* <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Feffective%20communication.png?alt=media&token=1ce344a4-656c-4c3e-965f-fce62a9e4e72"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>3. </b>Lastly, submit your artwork/photograph using the
                  button above or by{" "}
                  <a
                    href="https://forms.gle/mkrV6BrD9mouoWMj6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    clicking here
                  </a>{" "}
                  to complete your submission:
                </Typography>
              </Box>

              {/* fourth */}
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/* <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fmental%20health%20advocacy.png?alt=media&token=48d69b0c-1812-4d67-b05b-96330dd9e570"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>4. </b>One participant can make a maximum of <b>2</b>{" "}
                  submissions per category. And one participant can participate
                  in all three categories. All latter excessive submissions will
                  be disqualified from the competition.
                </Typography>
              </Box>

              {/* fifth */}

              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/*   <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fdesigning.png?alt=media&token=0ef50766-1102-4f2f-aa87-91a393523c7b"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>5. </b>There are no marks for likes, shares, and comments
                  but your submission will not be considered if not posted on
                  your timeline.
                </Typography>
              </Box>

              {/* sixth */}

              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/* <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fsustainable.png?alt=media&token=b8d3b6fe-cfb9-4d66-ad0b-042e5a1e4492"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>
                    <span>*****</span>Psycure Organization reserves all rights
                    to modify the rules.<span>*****</span>
                  </b>
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={5} xl={3} md={4} xs={10}>
              <Box sx={{ width: "100%" }}>
                <img
                  style={{
                    borderRadius: "5%",
                    width: "100%",
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fpexels-vlada-karpovich-4050315.jpg?alt=media&token=f6369005-9d4c-49f7-9f0f-cf016a4871fe"
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default UnwindMindRules;
