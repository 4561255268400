import {
  
  Container,
  Paper,
  Table,
 
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

function createData(Patient, Age, Gender, Psychologist, Case, Date, Contact) {
  return { Patient, Age, Gender, Psychologist, Case, Date, Contact };
}

// const rows = [
//     createData('Petey Cruiser', 19, "Male", "Dr.Monty Carlo", "Anxiety", "20/05/2022", "+8801345555466", <Button variant="contained">Contained</Button>
//     ),
//     createData('Anna Sthesia', 21, "Female", "Dr.Pete Sariya", "Couple Issue", "23/06/2022", "+8801745555466", <Button variant="contained">Contained</Button>),
//     createData('Paul Molive', 26, "Female", "Dr.Brock Lee", "Depression", "10/08/2022", "+8801845555466", <Button variant="contained">Contained</Button>),
//     createData('Anna Mull', 35, "Male", "Dr.Barb Ackue", "Counselling", "21/05/2022", "+8801445555466", <Button variant="contained">Contained</Button>),

// ];

const AllPsychologists = () => {
  const [psychologists, setPsychologists] = useState({});
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `All psychologists page`,
    });
  }, []);
  useEffect(() => {
    fetch("https://api.thepsycure.com/psychologists")
      .then((res) => res.json())
      .then((data) => {
        setPsychologists(data);
      });
  }, [psychologists]);

  return (
    <Container sx={{ mt: 20, mb: 5 }}>
      <h2 style={{ textAlign: "center" }}>All Psychologists</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#E1FFD2" }}>
              <TableCell>Patient</TableCell>
              <TableCell align="right">Age</TableCell>
              <TableCell align="right">Gender</TableCell>
              <TableCell align="right">Psychologist</TableCell>
              <TableCell align="right">Case</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Contact</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          {/* <TableBody sx={{ backgroundColor: "#E1FFD2" }}>
                        {
                            psychologists?.map((psychologist) => (
                                <TableRow
                                    key={psychologist.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {psychologist.name}
                                    </TableCell>
                                    <TableCell align="right">{psychologist.title}</TableCell>
                                    <TableCell align="right">{psychologist.likes}</TableCell>
                                    <TableCell align="right">{psychologist.dislikes}</TableCell>
                                    <TableCell align="right">{psychologist.totalSessions}</TableCell>
                                    <TableCell align="right">{psychologist.specialization.topic}</TableCell>
                                    <TableCell align="right">{psychologist.availableDateTimes.day}</TableCell>
                                </TableRow>
                            ))}

                    </TableBody> */}
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AllPsychologists;
