import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

const CoreModules = () => {
  return (
    <div style={{ margin: "5% 0" }}>
      <Container container maxWidth="xl">
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            columns={{ lg: 10, xl: 10, md: 10, xs: 10 }}
            justifyContent="center"
            alignItems="center"
            direction="row"
            width="100%"
            spacing={5}
          >
            <Grid item lg={5} xl={5} md={6} xs={10}>
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                <Typography variant="h3">
                  <b>Course Modules:</b>
                </Typography>
              </Box>
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/* <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Funderstanding.png?alt=media&token=1ce06547-a87a-4297-a8d8-4c8b19bb9169"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>1. Understanding Mental Health:</b> Gain insights into mental
                  health concepts, explore common challenges faced by youth, and
                  learn strategies for destigmatizing mental health issues.
                </Typography>
              </Box>
              {/* second */}
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/*  <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fcopling.png?alt=media&token=5ba8ad40-d9e6-4a76-99c4-c4dc33b54194"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>2. Coping Strategies and Resilience: </b> Develop healthy
                  coping mechanisms, build resilience, and explore practical
                  exercises for stress management to foster emotional
                  well-being.
                </Typography>
              </Box>
              {/* third */}
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/* <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Feffective%20communication.png?alt=media&token=1ce344a4-656c-4c3e-965f-fce62a9e4e72"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>3. Effective Communication and Peer Support:</b> Enhance
                  communication skills, establish supportive peer networks, and
                  address mental health in interpersonal relationships.
                </Typography>
              </Box>

              {/* fourth */}
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/* <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fmental%20health%20advocacy.png?alt=media&token=48d69b0c-1812-4d67-b05b-96330dd9e570"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>4. Mental Health Advocacy and Awareness: </b>Learn advocacy
                  strategies, promote awareness within communities, and combat
                  stigma through effective communication.
                </Typography>
              </Box>

              {/* fifth */}

              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/*   <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fdesigning.png?alt=media&token=0ef50766-1102-4f2f-aa87-91a393523c7b"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>5. Designing Impactful Workshops:</b> Acquire skills in
                  workshop planning, facilitation techniques, and content
                  tailoring for diverse audiences to deliver impactful mental
                  health workshops.
                </Typography>
              </Box>

              {/* sixth */}

              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "3% 0",
                }}
              >
                {/* <img
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fsustainable.png?alt=media&token=b8d3b6fe-cfb9-4d66-ad0b-042e5a1e4492"
                  alt=""
                  style={{ width: "10%" }}
                /> */}
                <Typography>
                  <b>6. Sustainable Community Engagement: </b>Implement and assess
                  workshop impact, establish long-term community partnerships,
                  and create a plan for continued mental health advocacy beyond
                  the program.
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={5} xl={3} md={4} xs={10}>
              <Box sx={{ width: "100%" }}>
                <img
                  style={{
                    borderRadius: "5%",
                    width: "100%",
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fpexels-vlada-karpovich-4050315.jpg?alt=media&token=f6369005-9d4c-49f7-9f0f-cf016a4871fe"
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default CoreModules;
