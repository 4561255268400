import { useState, useEffect } from "react";
import { auth } from "../firebase.config";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import useAuth from "./useAuth";

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useAuth();

  const signUp = async (
    email,
    password,
    displayName,
    navigate,
    user,
    patientInfo
  ) => {
    setError(null);
    setIsLoading(true);
    try {
      // Create the user
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log(response);
      if (!auth) {
        throw new Error("Could not complete signup");
      }

      // Update the user profile
      await updateProfile(auth.currentUser, { displayName });

      // saving data to the server
      const saveUser = (obj, method) => {
        const user = {
          ...obj,
          createdAt: new Date().toISOString().slice(0, 10),
        };
        fetch("https://api.thepsycure.com/users", {
          method: method,
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(user),
        });
      };

      let userDetails = {
        name: displayName,
        email: email.toLowerCase(),
        patientInfo: user === "patient" ? patientInfo : patientInfo,
        // previously patient inffo was set to null
        role:
          user === "patient"
            ? "patient"
            : user === "psychologist"
            ? "psychologist"
            : "bloger",
      };

      if (response?.user?.email) {
        saveUser(userDetails, "POST");
      }
      if (user === "patient") {
        navigate("/signup");
      }
      if (user === "psychologist") {
        navigate("/psychologist-signup");
      }

      if (user === "bloger") {
        navigate("/bloger-signup");
      }

      // Dispatch login action
      dispatch({ type: "LOGIN", payload: auth.currentUser });

      if (!isCancelled) {
        setError("");
        setIsLoading(false);
      }
    } catch (error) {
      let errMessage = error?.code?.split("auth/")[1];
      if (errMessage.toLowerCase() == "email-already-in-use".toLowerCase()) {
        setError("You already have an account with this email");
      } else if (errMessage == "weak-password") {
        setError("Weak Password");
      } else {
        setError(error?.code?.split("auth/")[1]);
      }
      setIsLoading(false);
    }
    // setError(null)
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { signUp, error, isLoading };
};

// ===============with image uploading==========================

// import { useState, useEffect } from "react";
// import { auth, projectStorage } from "../firebase.config";
// import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import useAuth from "./useAuth";

// export const useSignup = () => {
//     const [isCancelled, setIsCancelled] = useState(false);
//     const [error, setError] = useState(null);
//     const [isLoading, setIsLoading] = useState(false);
//     const { dispatch } = useAuth();

//     const signUp = async (email, password, displayName, thumbnail, navigate, user) => {
//         setError(null);
//         setIsLoading(true);
//         try {
//             // Create the user
//             await createUserWithEmailAndPassword(auth, email, password);

//             if (!auth) {
//                 throw new Error("Could not complete signup");
//             }

//             // Upload the image
//             const uploadPath = `thumbnails/${auth.currentUser.uid}/${thumbnail.name}`;
//             const thumbnailRef = ref(projectStorage, uploadPath);

//             const img = await uploadBytes(thumbnailRef, thumbnail);

//             const photoURL = await getDownloadURL(thumbnailRef);

//             // Update the user profile
//             await updateProfile(auth.currentUser, { displayName, photoURL });

//             // saving data to the server
//             const saveUser = (obj, method) => {
//                 const user = obj;
//                 fetch('https://api.thepsycure.com/users', {
//                     method: method,
//                     headers: {
//                         'content-type': 'application/json'
//                     },
//                     body: JSON.stringify(user)
//                 })
//             }

//             let userDetails = {
//                 name: displayName,
//                 email,
//                 photoURL,
//                 role: user === "patient" ? "patient" : "psychologist"
//             }

//             saveUser(userDetails, "POST");
//             navigate(user === "patient" ? "/signup" : "/psychologist-signup");

//             // Dispatch login action
//             dispatch({ type: "LOGIN", payload: auth.currentUser });

//             if (!isCancelled) {
//                 setError("");
//                 setIsLoading(false);
//             }

//         } catch (err) {
//             if (!isCancelled) {
//                 setError(err.message);
//                 setIsLoading(false);
//             }
//         }
//     };

//     useEffect(() => {
//         return () => setIsCancelled(true);
//     }, []);

//     return { signUp, error, isLoading };
// };
