import React, { useLayoutEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditModal = ({ open, handleClose }) => {
  const { user } = useAuth();
  const [psychologistDetails, setPsychologistDetails] = useState([]);

  let [userDetails, setUserDetails] = useState({
    name: psychologistDetails?.name,
    email: psychologistDetails?.email,
    location: psychologistDetails?.location,
    phone: psychologistDetails?.phone,
    age: psychologistDetails?.age,
    gender: psychologistDetails?.gender,
  });

  useLayoutEffect(() => {
    fetch("https://api.thepsycure.com/psychologists")
      .then((res) => res.json())
      .then((data) => {
        const foundPsychologist = data.filter(
          (detail) => detail.email === user.email
        );
        setPsychologistDetails(foundPsychologist[0]);
      });
  }, [user.email]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userProfile = {
      name: userDetails?.name,
      location: userDetails?.location,
      phone: userDetails?.phone,
      age: userDetails?.age,
      gender: userDetails?.gender,
    };

    fetch(`https://api.thepsycure.com/psychologistProfile/${user?.email}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(userProfile),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClose();
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <form className="mode" onSubmit={handleSubmit}>
            <Box>
              <TextField
                required
                sx={{ width: "95%", m: 2 }}
                id="outlined-required"
                label="Full Name"
                defaultValue={psychologistDetails?.name}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, name: e.target.value })
                }
              />
            </Box>
            {/* 
            <TextField
              required
              sx={{ width: '100%', m: 1 }}
              id="outlined-required"
              label="UserName"
              defaultValue="Oliver Queen"
            /> */}

            <Box>
              <TextField
                required
                sx={{ width: "95%", m: 2 }}
                id="outlined-required"
                label="Email"
                defaultValue={psychologistDetails?.email}
              />
            </Box>

            <Box
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <TextField
                required
                sx={{ width: "95%", m: 2 }}
                id="outlined-required"
                label="Location"
                defaultValue={psychologistDetails?.location}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, location: e.target.value })
                }
              />

              <TextField
                required
                sx={{ width: "95%", m: 2 }}
                id="outlined-required"
                label="phone"
                type="number"
                defaultValue={psychologistDetails?.phone}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, phone: e.target.value })
                }
              />
            </Box>

            <Box
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <TextField
                required
                sx={{ width: "95%", m: 2 }}
                id="outlined-required"
                label="Age"
                type="number"
                defaultValue={psychologistDetails?.age}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, age: e.target.value })
                }
              />

              {/* <TextField
                                required
                                sx={{ width: '95%', m: 2 }}
                                id="outlined-required"
                                label="Gender"
                                defaultValue={}
                                onChange={e => setUserDetails({ ...userDetails, gender: e.target.value })}
                            /> */}
              <p>{psychologistDetails?.gender}</p>
            </Box>

            <Box
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <Button
                style={{
                  backgroundColor: "#31C75A",
                  width: "60%",
                  marginLeft: "70px",
                }}
                type="submit"
                variant="contained"
              >
                Submit
              </Button>

              <Button
                style={{ backgroundColor: "#31C75A", width: "60%" }}
                onClick={handleClose}
                variant="contained"
              >
                Chancel
              </Button>
            </Box>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EditModal;
