import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";

export default function KeyFeaturesCard() {
  return (
    <Grid
      container
      columns={{ xs: 1, md: 2, lg: 3, xl: 3 }}
      justifyContent="center"
      spacing={5}
    >
      {/* first */}
      <Grid item>
        <Card
          sx={{
            maxWidth: 345,
            height: 150,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardActionArea>
            {/* <CardMedia
              component="img"
              height="300px"
              image="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fonline-training.png?alt=media&token=28ffdd2e-11d3-43fb-a820-1f49a009e4fa"
              alt="green iguana"
            /> */}
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Online training
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Interactive modules, and industry expert-led sessions on various
                aspects of mental health advocacy.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {/* second */}
      <Grid item>
        <Card
          sx={{
            maxWidth: 345,
            height: 150,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardActionArea>
            {/* <CardMedia
              component="img"
              height="320px"
              image="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fguidance.png?alt=media&token=40532480-18fd-46ce-bf8e-7358bb8e0ff1"
              alt="green iguana"
            /> */}
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Guidance and mentorship
              </Typography>
              <Typography variant="body2" color="text.secondary">
                From seasoned professionals and leaders for valuable insights,
                and meaningful relationships.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {/* third */}
      <Grid item>
        <Card
          sx={{
            maxWidth: 345,
            height: 150,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardActionArea>
            {/* <CardMedia
              component="img"
              height="288px"
              image="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fconnect%20.png?alt=media&token=0e4de7fe-81bc-4391-a24a-c4408408849f"
              alt="green iguana"
            /> */}
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Connect with a community of professionals
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Build valuable relationships and expand your network for future
                opportunities.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {/* fourth */}
      <Grid item>
        <Card
          sx={{
            maxWidth: 345,
            height: 150,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CardActionArea>
            {/*  <CardMedia
              component="img"
              height="288px"
              image="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fcustomized%20learning.png?alt=media&token=3af55cd2-7ace-4191-9be1-2fd64ea397a3"
              alt="green iguana"
            /> */}
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Customized learning experience
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Designed to provide a holistic understanding of mental health
                and equip you with the right skills.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {/* 5th */}
      <Grid item>
        <Card
          sx={{
            maxWidth: 345,
            height: 150,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardActionArea>
            {/*  <CardMedia
              component="img"
              height="320px"
              image="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fhands%20on%20experience.png?alt=media&token=bf5e85b3-0cad-4d1b-b4aa-7c33e60d73a3"
              alt="green iguana"
            /> */}
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Gain hands-on experience
              </Typography>
              <Typography variant="body2" color="text.secondary">
                By participating in community workshops and put your skills and
                knowledge into action
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {/* 6th */}
      <Grid item>
        <Card
          sx={{
            maxWidth: 345,
            height: 150,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardActionArea>
            {/* <CardMedia
              component="img"
              height="320px"
              image="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fcertificate.png?alt=media&token=02a95366-581a-4b59-b19d-9eaaa97dd4db"
              alt="green iguana"
            /> */}
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Certificate of achievement
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Upon successful completion of the fellowship program,
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}
