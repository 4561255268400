import { Button, Grid } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

import { Link } from 'react-router-dom';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#E6F1EC",
        color: "#000",
        textAlign: "center"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#E6F1EC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const highlightGreen = { display: "inline", backgroundColor: "#31C75A", color: "#fff" , padding: "10px", borderRadius: ".5rem" };
const highlightYellow = { display: "inline", backgroundColor: "yellow", color: "#000", padding: "10px", borderRadius: ".5rem" };

export default function AppointmentTable({ foundResults }) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const decendingSorted = foundResults.sort((a, b) => new Date(b.date) - new Date(a.date))


    return (
        <>

            <Grid item xs={12} md={6} component={Paper}>

                <TableContainer className='table-container' sx={{ maxHeight: 440, backgroundColor: "#E6F1EC", }}>
                    <Table aria-label="customized table">
                        <TableHead style={{ marginBottom: "2rem" }}>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell><strong>Patient Name</strong></StyledTableCell>
                                <StyledTableCell><strong>Patient Email</strong></StyledTableCell>
                                <StyledTableCell><strong>Date</strong></StyledTableCell>
                                <StyledTableCell><strong>Time</strong></StyledTableCell>
                                <StyledTableCell><strong>Status</strong></StyledTableCell>
                                {/* <StyledTableCell><strong>More Details</strong></StyledTableCell> */}
                                <StyledTableCell><strong>Meet Link</strong></StyledTableCell>
                            </TableRow>
                        </TableHead>


                        <TableBody >
                            {decendingSorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <StyledTableRow key={row._id}>
                                    <StyledTableCell >

                                    </StyledTableCell>
                                    <StyledTableCell className="table_center">
                                        {row.userDetails.name}
                                    </StyledTableCell>
                                    <StyledTableCell className="table_center">{row.userDetails.email}</StyledTableCell>
                                    <StyledTableCell className="table_center">{row.date} {row.day}</StyledTableCell>
                                    <StyledTableCell className="table_center">{row.startTime}-{row.endTime}</StyledTableCell>
                                    <StyledTableCell className="table_center">{row?.status === "complete" ? <p style={highlightGreen}>Completed</p> : <p style={highlightYellow}>Upcoming</p>}</StyledTableCell>
                                    {/* <StyledTableCell >
                                        <Button variant='contained' style={{ backgroundColor: '#31C75A', }} sx={{ borderRadius: '25px', }}>
                                            <NavLink style={{ textDecoration: 'none', color: 'white' }} to={`/psychologist-dashboard/session/${row._id}`} >Details</NavLink>
                                        </Button>
                                    </StyledTableCell> */}
                                    <StyledTableCell className="table_center">
                                        <Link to={`/video-session/${row?._id}`} style={{ textDecoration: "none" }}>
                                            <Button variant='contained' style={{ backgroundColor: '#31C75A', }} sx={{ borderRadius: '25px', }} >Join</Button>

                                        </Link>
                                    </StyledTableCell>


                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={foundResults.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
        </>
    )
}