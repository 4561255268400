import React, { useEffect } from "react";
import { Container } from "@mui/system";
import Header from "../../Shared/Header/Header";
import Footer from "../../Shared/Footer/Footer";
import ReactGA from "react-ga4";

const TermsCondition = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Terms & Conditions`,
    });
  }, []);
  return (
    <>
      <Header />
      <Container sx={{ my: 15 }}>
        <h2 className="headin">Terms & Conditions</h2>
        <hr
          style={{
            display: "block",
            width: "10%",
            height: "3px",
            backgroundColor: "#31C75A",
            border: 0,
          }}
        />

        <p style={{ textAlign: "justify" }} variant="body1" gutterBottom>
          By downloading or using the app, these terms will automatically apply
          to you – you should make sure therefore that you read them carefully
          before using the app. You’re not allowed to copy or modify the app,
          any part of the app, or our trademarks in any way. You’re not allowed
          to attempt to extract the source code of the app, and you also
          shouldn’t try to translate the app into other languages or make
          derivative versions. The app itself, and all the trademarks,
          copyright, database rights, and other intellectual property rights
          related to it, still belong to{" "}
          <span style={{ color: "#31C75A" }}>Psycure</span>.
          <br />
          <br />
          <span style={{ color: "#31C75A" }}>Psycure</span> is committed to
          ensuring that the app is as useful and efficient as possible. For that
          reason, we reserve the right to make changes to the app or to charge
          for its services, at any time and for any reason. We will never charge
          you for the app or its services without making it very clear to you
          exactly what you’re paying for.
          <br />
          <br />
          The <span style={{ color: "#31C75A" }}>Psycure</span> app stores and
          processes personal data that you have provided to us, to provide our
          Service. It’s your responsibility to keep your phone and access to the
          app secure. We therefore recommend that you do not jailbreak or root
          your phone, which is the process of removing software restrictions and
          limitations imposed by the official operating system of your device.
          It could make your phone vulnerable to malware/viruses/malicious
          programs, compromise your phone’s security features and it could mean
          that the <span style={{ color: "#31C75A" }}>Psycure</span> app won’t
          work properly or at all.
          <br />
          <br />
          The app does use third-party services that declare their Terms and
          Conditions.
          <br />
          <br />
          Link to Terms and Conditions of third-party service providers used by
          the app
          <br />
          <ul>
            <li>Google Play Services</li>
            <li>Google Analytics for Firebase</li>
            <li>Firebase Crashlytics</li>
            <li>Facebook</li>
          </ul>
          <br />
          <br />
          You should be aware that there are certain things that{" "}
          <span style={{ color: "#31C75A" }}>Psycure</span> will not take
          responsibility for. Certain functions of the app will require the app
          to have an active internet connection. The connection can be Wi-Fi or
          provided by your mobile network provider, but{" "}
          <span style={{ color: "#31C75A" }}>Psycure</span> cannot take
          responsibility for the app not working at full functionality if you
          don’t have access to Wi-Fi, and you don’t have any of your data
          allowance left.
          <br /> <br />
          If you’re using the app outside of an area with Wi-Fi, you should
          remember that the terms of the agreement with your mobile network
          provider will still apply. As a result, you may be charged by your
          mobile provider for the cost of data for the duration of the
          connection while accessing the app, or other third-party charges. In
          using the app, you’re accepting responsibility for any such charges,
          including roaming data charges if you use the app outside of your home
          territory (i.e. region or country) without turning off data roaming.
          If you are not the bill payer for the device on which you’re using the
          app, please be aware that we assume that you have received permission
          from the bill payer for using the app.
          <br />
          <br />
          Along the same lines,{" "}
          <span style={{ color: "#31C75A" }}>Psycure</span> cannot always take
          responsibility for the way you use the app i.e. You need to make sure
          that your device stays charged – if it runs out of battery and you
          can’t turn it on to avail the Service,{" "}
          <span style={{ color: "#31C75A" }}>Psycure</span> cannot accept
          responsibility.
          <br />
          With respect to <span style={{ color: "#31C75A" }}>Psycure</span>{" "}
          responsibility for your use of the app, when you’re using the app,
          it’s important to bear in mind that although we endeavor to ensure
          that it is updated and correct at all times, we do rely on third
          parties to provide information to us so that we can make it available
          to you. <span style={{ color: "#31C75A" }}>Psycure</span> accepts no
          liability for any loss, direct or indirect, you experience as a result
          of relying wholly on this functionality of the app.
          <br />
          <br />
          <strong>Changes to This Terms and Conditions</strong>
          <br />
          <br />
          We may update our Terms and Conditions from time to time. Thus, you
          are advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Terms and Conditions on
          this page.
          <br />
          <br />
          These terms and conditions are effective as of <a>July 01, 2022</a>.
          <br />
          <br />
          <strong>Contact Us</strong>
          <br />
          If you have any questions or suggestions about our Terms and
          Conditions, do not hesitate to contact us at{" "}
          <a>(+880) 01722-318724</a>.
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default TermsCondition;
