import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import PaidIcon from "@mui/icons-material/Paid";
import DuoIcon from "@mui/icons-material/Duo";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

import "./admin.css";
export default function AdminDashboard() {
  const [allPsychologist, setPsychologist] = useState([]);
  const [approvedPsychologist, setApprovedPsychologist] = useState([]);
  const [paymentRequest, setPaymentRequest] = useState([]);
  const [totalSession, setTotalSession] = useState([]);
  const [completedSession, setCompletedSession] = useState([]);
  const [isSelectMonth, setIsSelectMonth] = useState(false);
  let [monthlyCompleted, setMonthlyCompleted] = useState([]);
  let [monthlyPending, setMonthlyPending] = useState([]);

  useEffect(() => {
    fetch(`https://api.thepsycure.com/session`)
      .then((res) => res.json())
      .then((data) => {
        setTotalSession(data);
        let finishedSession = data.filter(
          (session) => session?.status === "complete"
        );
        setCompletedSession(finishedSession);
      });

    fetch(`https://api.thepsycure.com/psychologists`)
      .then((res) => res.json())
      .then((data) => {
        setPsychologist(data);
        let workingPsychologist = data.filter(
          (psychologist) => psychologist?.approved
        );
        let requestForPayment = data.filter(
          (psychologist) => psychologist?.paymentRequest
        );
        setApprovedPsychologist(workingPsychologist);
        setPaymentRequest(requestForPayment);
      });
  }, []);

  useEffect(() => {
    let filteredByMonthComplete = totalSession.filter((session) => {
      return (
        session?.date?.split("-")[0] == new Date().getFullYear() &&
        +session?.date?.split("-")[1] - 1 == Number(new Date().getMonth()) &&
        session?.status == "complete"
      );
    });
    let filteredByMonthPending = totalSession.filter(
      (session) =>
        session?.date?.split("-")[0] == new Date().getFullYear() &&
        +session?.date?.split("-")[1] - 1 == Number(new Date().getMonth()) &&
        session?.status != "complete"
    );

    setMonthlyCompleted(filteredByMonthComplete);
    setMonthlyPending(filteredByMonthPending);
  }, [totalSession]);

  let totalEarnings = [
    ...new Set(
      allPsychologist?.map((psychologist) => psychologist?.payment)?.flat()
    ),
  ].reduce(
    (acc, cur) =>
      Number(cur?.ammount)
        ? Number(acc) + Number(cur?.ammount)
        : Number(acc) + 0,
    0
  );

  let totalPaid = [
    ...new Set(
      allPsychologist?.map((psychologist) => psychologist?.payment)?.flat()
    ),
  ].reduce(
    (acc, cur) =>
      cur?.isDisbursed
        ? Number(cur?.ammount)
          ? Number(acc) + Number(cur?.ammount)
          : Number(acc) + 0
        : Number(acc) + 0,
    0
  );

  const sessionHistory = [
    {
      name: "Month",
      completed: monthlyCompleted?.length,
      pending: monthlyPending?.length,
    },
  ];

  const paymentRequestHistory = [
    { name: "Payment request", request: paymentRequest.length },
  ];
  const EarningsHistory = [
    { name: "Earning History", earnings: totalEarnings, paid: totalPaid },
  ];

  const fullMonthData = totalSession
    .filter((session) => {
      return (
        session?.date?.split("-")[0] == new Date().getFullYear() &&
        +session?.date?.split("-")[1] - 1 == Number(new Date().getMonth())
      );
    })
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  let [fullMonthHistory, setFullMonthHistory] = useState([]);

  function unique(arr) {
    let arr1 = [];
    for (let i = 0; i < arr.length; i++) {
      let objIndex = arr1.findIndex((obj) => obj.date === arr[i].date);
      if (objIndex == -1) {
        arr1.push({
          date: arr[i].date,
          total: 1,
        });
      } else {
        arr1[objIndex].total = arr1[objIndex].total + 1;
      }
    }

    setFullMonthHistory(arr1);
  }

  useEffect(() => {
    unique(fullMonthData);
  }, [totalSession]);

  return (
    <>
      <div>
        <h2 style={{ textAlign: "center", color: "#31C75A" }}>
          Psycure Analytics Data
        </h2>

        <div className="enganement_container">
          <Grid container spacing={2}>
            <Grid item xs={4} md={3}>
              <div>
                <h3>Engagement</h3>
                <p>General statics of user engagement process</p>
              </div>
            </Grid>
            <Grid item xs={4} md={3}>
              <div className="box">
                <p>Total Session</p>
                <h3>{totalSession?.length ? totalSession?.length : 0}</h3>
                <DuoIcon />
              </div>
            </Grid>
            <Grid item xs={4} md={3}>
              <div className="box">
                <p>Completed Session</p>
                <h3>{monthlyCompleted?.length}</h3>
                <VideocamOffIcon />
              </div>
            </Grid>
            <Grid item xs={4} md={3}>
              <div className="box">
                <p>Total earnings</p>
                <h3>{totalEarnings}</h3>
                <LocalAtmIcon />
              </div>
            </Grid>
            <Grid item xs={4} md={3}>
              <div className="box">
                <p>paid ammount</p>
                <h3>{totalEarnings}</h3>
                <PaidIcon />
              </div>
            </Grid>
            <Grid item xs={4} md={3}>
              <div className="box">
                <p>Payment request</p>
                <h3>{paymentRequest.length}</h3>
                <PointOfSaleIcon />
              </div>
            </Grid>
          </Grid>
        </div>

        {/* <div>
          <div >
            <Button onClick={() => setIsSelectMonth(!isSelectMonth)} style={{ backgroundColor: '#31C75A', padding: '10px', borderRadius: '8px', }} variant="contained">{isSelectMonth ? "Hide Calender" : "Select Month"} </Button>
          </div>
          {
            isSelectMonth && <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MonthPicker
                date={date}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(newDate) => setDate(newDate)}
              />
            </LocalizationProvider>
          }
        </div> */}
        <Grid container>
          {/* <Grid item xs={12} >
            <h2 style={{ textAlign: "center" }}>Payment request history</h2>
            <ResponsiveContainer width="90%" height={400}>
              <BarChart
                width={800}
                height={400}
                data={fullMonthHistory}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="date" fill="#8884d8" />
                <Bar dataKey="startTime" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Grid> */}
          <Grid item xs={12}>
            <h2 style={{ textAlign: "center" }}>Monthly Session History</h2>
            <ResponsiveContainer width="90%" height={400}>
              <BarChart
                width={500}
                height={300}
                data={fullMonthHistory}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barSize={20}
              >
                <XAxis
                  dataKey="date"
                  scale="point"
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <CartesianGrid strokeDasharray="3 3" />
                <Bar
                  dataKey="total"
                  fill="#8884d8"
                  background={{ fill: "#fff" }}
                />
              </BarChart>

              {/* ==========================================
              =================Line Chart================
              ========================================== */}

              {/* <LineChart
                width={500}
                height={300}
                data={fullMonthHistory}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis dataKey="total" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="date" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="total" stroke="#82ca9d" />
              </LineChart> */}
            </ResponsiveContainer>
          </Grid>
          {/* <Grid item md={6} xs={12}>
            <h2 style={{ textAlign: "center" }}>Session History</h2>
            <BarChart
              width={500}
              height={400}
              data={sessionHistory}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="completed" fill="#8884d8" />
              <Bar dataKey="pending" fill="#82ca9d" />
            </BarChart>
          </Grid> */}

          {/* <Grid item md={6} xs={12} >
            <h2 style={{ textAlign: "center" }}>Total earnings history</h2>
            <BarChart
              width={500}
              height={400}
              data={EarningsHistory}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="earnings" fill="#8884d8" />
              <Bar dataKey="paid" fill="#82ca9d" />
            </BarChart>
          </Grid> */}
          {/* <Grid item md={6} xs={12}>
            <h2 >Session History</h2>
            <BarChart
              width={500}
              height={500}
              data={sessionHistory}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="completed" fill="#8884d8" />
              <Bar dataKey="pending" fill="#82ca9d" />
            </BarChart>
          </Grid> */}
        </Grid>
      </div>
      {/* <h4>Psychologist list</h4>
      <PsychologistList /> */}
      {/* <h4>Psychologist payment request</h4>
      <PsychologistPaymentRequest /> */}
    </>
  );
}

// let dates = [
//   {
//     date: '2022--9-12'
//   },
//   {
//     date: '2022--9-12'
//   },
//   {
//     date: '2022--9-13'
//   },
// ]

// let uniqueDateTotal = [
//   {
//     date: '2022--9-12',
//     total: 2
//   },
//   {
//     date: '2022--9-13',
//     total: 1
//   },
// ]
