import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./IndividualEvent.css";
const IndividualEvent = ({ selectedItems, setSelectedItems }) => {
  const firstPair = [
    {
      name: "The Healthy Way of Managing Anger",
      conduct: "Dr. Lipy Gloria Rozario, PhD",
      date: "22 November, 2024",
      time: "Morning",
      id: 1,
    },
    {
      name: "Beat Stress and Anxiety through Simple Yoga",
      conduct: "Dr. Lipy Gloria Rozario, PhD",
      date: "22 November, 2024",
      time: "Morning",
      id: 2,
    },
  ];
  const secondPair = [
    {
      name: "Who am I in My Romantic Relationship?",
      conduct: "Tasnuva Huque and Monzia Mushtaq",
      date: "22 November, 2024",
      time: "Afternoon",
      id: 3,
    },
    {
      name: "Cognitive Behavioral Therapy (CBT) for Everyday Life",
      conduct: "Dr Mohammad Salim Chowdhury ",
      date: "22 November, 2024",
      time: "Afternoon",
      id: 4,
    },
    {
      name: "Power of Metaphor for Healing and Transformation",
      conduct: "Andalib Mahmud",
      date: "22 November, 2024",
      time: "Afternoon",
      id: 5,
    },
  ];
  const thirdPair = [
    {
      name: "Building Emotional Intelligence at the Workplace",
      conduct: "Kamruzzaman Mozumdar, PhD",
      date: "23 November, 2024",
      time: "Morning",
      id: 6,
    },
    {
      name: "Discovering Anger through Body Movement: Explore, Experience, and Evolve",
      conduct: "Monzia Mushtaq and Tasnuva Huque",
      date: "23 November, 2024",
      time: "Morning",
      id: 7,
    },
  ];
  const fourthPair = [
    {
      name: "Healing deep wounds in romantic Relationship",
      conduct: "Mehtab Khanam, PhD",
      date: "23 November, 2024",
      time: "Afternoon",
      id: 8,
    },
    {
      name: "Storytelling for Children",
      conduct: "Farhana Mannan",
      date: "23 November, 2024",
      time: "Afternoon",
      id: 9,
    },
  ];

  const handleSelect = (pair, index, event, id) => {
    const finalEvent = { pair: pair, index: index, event: event, id: id };
    setSelectedItems((prev) => {
      const isAvailable = prev?.findIndex(
        (item) => item.pair === pair && item.id === id
      );
      const isAvailablePair = prev?.findIndex((item) => item.pair === pair);
      if (isAvailable === -1 && isAvailablePair === -1) {
        // console.log("first");
        return [...prev, finalEvent];
      }
      if (isAvailablePair !== -1) {
        // console.log("second");
        if (isAvailable !== -1) {
          // console.log("last");
          const newArray = prev.filter(
            (item) => !(item.pair === pair && item.id === id)
          );
          // console.log(newArray);
          return newArray;
        }
        const newArray = prev.filter((item) => !(item.pair === pair));
        return [...newArray, finalEvent];
      }
    });
  };
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedSecondEventId, setSelectedSecondEventId] = useState(null);
  const [selectedThirdEventId, setSelectedThirdEventId] = useState(null);
  const [selectedFourthEventId, setSelectedFourthEventId] = useState(null);
  // Check if an event exists based on id
  useEffect(() => {
    const eventExists = (id) => {
      return selectedItems.some((event) => event.id === id);
    };
  }, [selectedEventId]);

  const handleSelectEvent = (id) => {
    if (selectedEventId !== id) {
      setSelectedEventId(id);
    } else {
      setSelectedEventId(null);
    }
  };
  //   second
  useEffect(() => {
    const eventExists = (id) => {
      return selectedItems.some((event) => event.id === id);
    };
  }, [selectedSecondEventId]);

  const handleSecondSelectEvent = (id) => {
    if (selectedSecondEventId !== id) {
      setSelectedSecondEventId(id);
    } else {
      setSelectedSecondEventId(null);
    } // Set the selected event's id
  };
  //   third
  useEffect(() => {
    const eventExists = (id) => {
      return selectedItems.some((event) => event.id === id);
    };
  }, [selectedThirdEventId]);

  const handleThirdSelectEvent = (id) => {
    if (selectedThirdEventId !== id) {
      setSelectedThirdEventId(id);
    } else {
      setSelectedThirdEventId(null);
    } // Set the selected event's id
  };
  //   fourth
  useEffect(() => {
    const eventExists = (id) => {
      return selectedItems.some((event) => event.id === id);
    };
  }, [selectedThirdEventId]);

  const handleFourthSelectEvent = (id) => {
    if (selectedFourthEventId !== id) {
      setSelectedFourthEventId(id);
    } else {
      setSelectedFourthEventId(null);
    } // Set the selected event's id
  };
  // console.log(selectedItems);
  return (
    <Box>
      <hr />
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Typography
          textAlign="center"
          sx={{
            background: "#004d14",
            display: "inline-block",
            color: "white",
            padding: 2,
          }}
        >
          You can select up to 1 workshop from each pair.
        </Typography>
      </Box>
      <Container>
        {firstPair.map((item, index) => (
          <Box
            style={{
              textAlign: "center",
              margin: "10px 0",
              backgroundColor: selectedEventId === item.id ? "#31c75a" : "",
              border: selectedEventId === item.id ? "" : "1px solid #31c75a",
              borderRadius: "10px",
            }}
            className="event-box"
            onClick={() => {
              handleSelect("firstPair", index, item, item.id);
              handleSelectEvent(item.id);
            }}
          >
            <Typography variant="h6">{item?.name}</Typography>
            <Typography variant="body1">Conduct by: {item?.conduct}</Typography>
            <Typography variant="body2">Date: {item?.date}</Typography>
            <Typography variant="body2">Time: {item?.time}</Typography>
          </Box>
        ))}
        <hr />
      </Container>
      {/* second pair */}
      <Container sx={{ mt: 15 }}>
        <hr />
        {secondPair.map((item, index) => (
          <Box
            style={{
              textAlign: "center",
              margin: "10px 0",
              backgroundColor:
                selectedSecondEventId === item.id ? "#31c75a" : "",
              border:
                selectedSecondEventId === item.id ? "" : "1px solid #31c75a",
              borderRadius: "10px",
            }}
            className="event-box"
            onClick={() => {
              handleSelect("secondPair", index, item, item.id);
              handleSecondSelectEvent(item.id);
            }}
          >
            <Typography variant="h6">{item?.name}</Typography>
            <Typography variant="body1">Conduct by: {item?.conduct}</Typography>
            <Typography variant="body2">Date: {item?.date}</Typography>
            <Typography variant="body2">Time: {item?.time}</Typography>
          </Box>
        ))}
        <hr />
      </Container>
      {/* third pair */}
      <Container sx={{ mt: 15, padding: 2 }}>
        <hr />
        {thirdPair.map((item, index) => (
          <Box
            style={{
              textAlign: "center",
              margin: "10px 0",
              backgroundColor:
                selectedThirdEventId === item.id ? "#31c75a" : "",
              border:
                selectedThirdEventId === item.id ? "" : "1px solid #31c75a",
              borderRadius: "10px",
              //   width: "100%",
            }}
            className="event-box"
            onClick={() => {
              handleSelect("thirdPair", index, item, item.id);
              handleThirdSelectEvent(item.id);
            }}
          >
            <Typography variant="h6">{item?.name}</Typography>
            <Typography variant="body1">Conduct by: {item?.conduct}</Typography>
            <Typography variant="body2">Date: {item?.date}</Typography>
            <Typography variant="body2">Time: {item?.time}</Typography>
          </Box>
        ))}
        <hr />
      </Container>
      {/* fourth pair */}
      <Container sx={{ mt: 15, padding: 2 }}>
        <hr />
        {fourthPair.map((item, index) => (
          <Box
            style={{
              textAlign: "center",
              margin: "10px 0",
              backgroundColor:
                selectedFourthEventId === item.id ? "#31c75a" : "",
              border:
                selectedFourthEventId === item.id ? "" : "1px solid #31c75a",
              borderRadius: "10px",
              //   width: "100%",
            }}
            className="event-box"
            onClick={() => {
              handleSelect("fourthPair", index, item, item.id);
              handleFourthSelectEvent(item.id);
            }}
          >
            <Typography variant="h6">{item?.name}</Typography>
            <Typography variant="body1">Conduct by: {item?.conduct}</Typography>
            <Typography variant="body2">Date: {item?.date}</Typography>
            <Typography variant="body2">Time: {item?.time}</Typography>
          </Box>
        ))}
        <hr />
      </Container>
    </Box>
  );
};

export default IndividualEvent;
