import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import KeyFeaturesCard from "./UnwindMindKeyFeaturesCard";
import UnwindMindKeyFeaturesCard from "./UnwindMindKeyFeaturesCard";

const UnwindMindProgram = () => {
  return (
    <div>
      <Container maxWidth="xl">
        <Box sx={{ padding: "5%" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columns={{ lg: 10, xs: 10, md: 8 }}
            spacing={10}
          >
            <Grid item lg={5} xs={10} md={8}>
              <Box sx={{ width: "100%" }}>
                <img
                  style={{
                    borderRadius: "5%",
                    width: "100%",
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Funwind-mind.jpg?alt=media&token=34f9ec73-f90b-4b5e-bd54-2fcceb398f59"
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item lg={5} xs={10} md={8}>
              <div class="quote-container">
                <blockquote>
                  “Art washes away from the soul the dust of everyday life.”
                </blockquote>
                <cite>– Pablo Picasso</cite>
              </div>
              <Typography variant="h5" className="unwindmind-text" >
                Each brushstroke and whispered intention carries the weight of
                unspoken struggles, transforming pain into vivid canvases.
                Through the lens of the broken, awaits a luminous end to our
                story.
                <Typography
                  className="unwindmind-text"
                  variant="h5"
                  style={{ marginTop: "20px" }}
                >
                  The aim of the initiative is to create a platform where
                  individuals can express themselves in the form of different
                  types of art and photography.
                </Typography>
                <Typography
                  className="unwindmind-text"
                  variant="h5"
                  style={{ marginTop: "20px" }}
                >
                  Participants, enthusiasts and audience are all invited to
                  plunge into the thoughts of the society as they fill it with
                  colors, celebrating World Mental Health day. Do NOT miss out!
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={10} xs={10} md={8}>
              <Typography variant="h3" textAlign="center">
                <b>Categories</b>
              </Typography>
              <UnwindMindKeyFeaturesCard />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default UnwindMindProgram;
