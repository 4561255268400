import {    TwitterShareButton, TwitterIcon, } from 'react-share';
const Twitter = ({ url, quotes, hashtag }) => {
    return (
        <>

            <TwitterShareButton
                url={url}     //eg. https://www.example.com
                quotes={quotes}  //"Your Quotes"
                // hashtag={hashtag} // #hashTag
            >
                <TwitterIcon  style={{width: "40px", height: "40px"}}/>
            </TwitterShareButton>

        </>
    )
}

export default Twitter;