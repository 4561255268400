import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Button, Grid, Container } from "@material-ui/core";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import UserTableResonModal from "./UserTableResonModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6F1EC",
    color: "#000",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E6F1EC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function UsersTable() {
  let [allUsers, setAllUsers] = useState([]);

  const fetchUsers = async () => {
    const response = await fetch("https://api.thepsycure.com/users");
    const users = await response.json();

    let patient = users.filter((user) => user.role === "patient");

    if (response.ok) {
      let sortedData = patient?.sort(
        (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
      );
      setAllUsers(sortedData);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const columns = [
    { id: "name", label: "Name", minWidth: 170, align: "center" },
    { id: "email", label: "Email", minWidth: 150, align: "center" },
    { id: "createdAt", label: "Created", minWidth: 150, align: "center" },
    {
      id: "gender",
      label: "Gender",
      minWidth: 170,
      align: "center",
    },
    {
      id: "relation",
      label: "Relation",
      minWidth: 170,
      align: "center",
    },
    {
      id: "preferences",
      label: "Preferences",
      minWidth: 170,
      align: "center",
    },
    {
      id: "age",
      label: "Age",
      minWidth: 170,
      align: "center",
    },
    {
      id: "reason",
      label: "Reason",
      minWidth: 170,
      align: "center",
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [selectedData, setSelectedData] = useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function createData(
    id,
    name,
    email,
    gender,
    createdAt,
    relation,
    preferences,
    age,
    reason
  ) {
    return {
      id,
      name,
      email,
      gender,
      createdAt,
      relation,
      preferences,
      age,
      reason,
    };
  }

  const rows = [];
  allUsers.forEach((user) =>
    rows.push(
      createData(
        user?.id,
        user?.name,
        user?.email,
        user?.patientInfo?.gender,
        user?.createdAt,
        user?.patientInfo?.relationshipStatus,
        user?.patientInfo?.preferences,
        user?.patientInfo?.age,
        <Button
          style={{ backgroundColor: "green", color: "#fff" }}
          onClick={() => handleReason(user)}
        >
          Reason
        </Button>
      )
    )
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleReason = (data) => {
    handleOpen();
    setSelectedData(data);
  };

  return (
    <Container>
      <Grid item xs={12} md={12} component={Paper}>
        <TableContainer
          className="table-container"
          sx={{ maxHeight: 440, backgroundColor: "#E6F1EC" }}
        >
          <Table aria-label="customized table">
            <TableHead style={{ marginBottom: "2rem" }}>
              <TableRow style={{ textAlign: "center" }}>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 600 }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        const user = row["user"];
                        return (
                          <>
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          </>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <div className="container">
          <UserTableResonModal
            handleClose={handleClose}
            open={open}
            selectedData={selectedData}
          />
        </div>
      </Grid>
    </Container>
  );
}
