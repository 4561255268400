import React, { useState, useEffect } from "react";
import classes from "../Home/Message/Message.module.css";
import { createChannel, createClient } from "agora-rtm-react";
import chatIcon from "../../assets/images/chatIcon.png";
import { Button } from "@mui/material";
import Header from "../../Shared/Header/Header";

let patientmail = localStorage.getItem("patientEmailChat");
const useClient = createClient("699e8064f15244958ea8a401d6eac09c");
const useChannel = createChannel(localStorage.getItem("patientEmailChat"));

// const useChannel = createChannel(localStorage.getItem('patientEmailChat'));

export default function PsycureMessage({
  email,
  patient,
  isLoggedIn,
  setLoggedIn,
  setStartMessage,
  setShowMessage,
}) {
  let [chanelName, setChanelName] = useState(
    localStorage.getItem("patientEmailChat")
  );

  if (patientmail == null) {
    window.location.reload(true);
  }

  // const useClient = createClient("699e8064f15244958ea8a401d6eac09c");
  // const useChannel = createChannel(chanelName);
  const [texts, setTexts] = useState([]);
  const client = useClient();

  const testChannel = useChannel(client);

  // const [uid, setUid] = useState('')
  const uid = "Psycure";
  const [textInput, setTextInput] = useState("");
  let [firstMsg, setFirstMsg] = useState("");

  let [sent, setSent] = useState(false);

  let login = async () => {
    await client.login({ uid });
    await testChannel.join();
    if (!sent) {
      let message = client.createMessage({
        text: "MSGOLD-*",
        messageType: "TEXT",
      });
      await testChannel.sendMessage(message);
    }
    setSent(true);
    client.on("ConnectionStateChanged", async (state, reason) => {
      let loginState = state;
      let loginReason = reason;

      // if (state === 'CONNECTED') {
      //     setLoggedIn(true);
      // } else {
      //     setLoggedIn(false);
      // }
    });
    testChannel.on("ChannelMessage", (msg, uid) => {
      if (msg.text.includes("MSG-*")) {
        let splitMsg = msg.text.split("MSG-*")[1];
        let oldMsg = splitMsg.split("*x*");
        oldMsg.splice(oldMsg.length - 1, 1);

        for (let i = 0; i < oldMsg.length; i++) {
          setTexts((previous) => {
            return [...previous, { msg: { text: oldMsg[i] }, uid }];
          });
        }
      } else {
        setTexts((previous) => {
          return [...previous, { msg, uid }];
        });
      }
    });
    testChannel.on("MemberJoined", (memberId) => {
      let loginMemberId = memberId;
    });
    setLoggedIn(true);

    fetch(`https://api-laravel.thepsycure.com/ChatStart/${patient?.id}`, {
      method: "PUT",
      headers: {
        // authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "content-type": "application/json",
        authorization: `Bearer 1|hkbjFZ8MxcEIQVcqVMYoqx45Wnme8T2aZVVpolkm`,
      },
      body: JSON.stringify({
        time: "2022-12-15 11:00",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        let obj = data;
      })
      .catch((err) => {
        let error = err.message;
      });
  };

  let logout = async () => {
    await testChannel.leave();
    await client.logout();
    testChannel.removeAllListeners();
    client.removeAllListeners();
    setLoggedIn(false);
  };

  useEffect(() => {
    if (isLoggedIn === false) {
      setTimeout(() => {
        login();
      }, 2000);
    }
  }, [localStorage.getItem("patientEmailChat")]);

  const sendMsg = async (e) => {
    e.preventDefault();

    if (textInput) {
      let message = client.createMessage({
        text: textInput,
        messageType: "TEXT",
      });
      await testChannel.sendMessage(message);

      if (textInput !== "MSGOLD-*") {
        setTexts((previous) => {
          return [...previous, { msg: { text: textInput }, uid }];
        });
      }
      setTextInput("");
    } else {
      alert("nothing here");
    }
  };

  const handleComplete = (e) => {
    fetch(`https://api-laravel.thepsycure.com/ChatEnd/${patient?.id}`, {
      method: "PUT",
      headers: {
        // authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "content-type": "application/json",
        authorization: `Bearer 1|hkbjFZ8MxcEIQVcqVMYoqx45Wnme8T2aZVVpolkm`,
      },
      body: JSON.stringify({
        time: "2022-12-15 11:00",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        let obj = data;
      })
      .catch((err) => {
        let error = err.message;
      });

    logout();
    setStartMessage(false);
    setShowMessage(false);
    localStorage.removeItem("patientEmailChat");
    window.location.reload(true);
  };

  return (
    <>
      <Header />
      <div className={classes.message_container}>
        <div className={classes.chat_heading}>
          <img src={chatIcon} alt="chat icon" />
          <h2> Chat</h2>
        </div>
        <Button
          className="btn"
          style={{
            backgroundColor: "#31C75A",
            padding: "8px",
            fontSize: "14px",
            textTransform: "capitalize",
            borderRadius: "8px",
            width: "100%",
          }}
          variant="contained"
          onClick={handleComplete}
        >
          Complete
        </Button>
        {isLoggedIn ? (
          <div className={classes.messages}>
            {texts.map((text, i) => (
              <div
                key={i}
                className={text.uid === uid ? classes.sending : classes.comming}
              >
                {/* <div style={{ fontSize: 12, opacity: 0.5 }}>{text.uid}</div> */}
                <div className={classes.text}>{text.msg["text"]}</div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}

        {isLoggedIn ? (
          <form onSubmit={sendMsg} className={classes.form}>
            <input
              type="text"
              placeholder="message"
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
            />
          </form>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
