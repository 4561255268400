import { Box, Button, Container, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { projectStorage } from "../../../firebase.config";
import MultiRangeSlider from "multi-range-slider-react";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import logoUpload from "./../../../assets/images/logoUpload-icon.png";
import ImageUploadSuccess from "./../../../assets/images/imageUploadSuccess.png";

import { OutlinedInput } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Header from "../../../Shared/Header/Header";

const PsychologistDetails = ({ setPage, setDetails, details }) => {
  let [issue, setIssue] = useState(null);
  let [error, setError] = useState(null);
  let [selfError, setSelfError] = useState(null);
  let [detailsError, setDetalilsError] = useState(null);
  const [uploaderImage, setUploaderImage] = useState("");
  let [thumbnailError, setThumbnailError] = useState(null);
  let [thumbnailProgress, setThumbnailProgress] = useState(null);
  const [minValue, set_minValue] = useState(100);
  const [maxValue, set_maxValue] = useState(2499);
  const [problemName, setProblemName] = React.useState([]);
  const [categoryName, setCategoryName] = React.useState([]);
  const [counselingName, setCounselingName] = React.useState([]);

  const problems = [
    { id: "1", value: "Insomnia" },
    { id: "2", value: "Suicidal" },
    { id: "3", value: "Mental Issues" },
    { id: "4", value: "Over thinking" },
  ];
  const allCategories = [
    { id: "1", value: "Individual Counseling" },
    { id: "2", value: "Child & Adolescent" },
    { id: "3", value: "Family & Couple Therapy" },
    { id: "4", value: "Group Counseling" },
    { id: "5", value: "Neuropsychological Issues" },
    { id: "6", value: "Sleep Disorder" },
    { id: "7", value: "Couple Counseling" },
    { id: "8", value: "Corporate Mental Health" },
  ];
  const allCounseling = [
    { id: "1", value: "student" },
    { id: "2", value: "regular" },
    { id: "3", value: "premium" },
  ];

  const types = ["image/png", "image/jpeg", "image/jpg"];
  const cvTypes = ["application/pdf"];

  const handleImage = (e) => {
    let selected = e.target.files[0];
    setThumbnailError(null);
    if (!selected) {
      setThumbnailError("Please, Select A file");
      return;
    }

    if (!selected.type.includes("image")) {
      setThumbnailError("File should be Image");
      return;
    }
    if (selected.size > 3000000) {
      setThumbnailError("Image file size must be less than 300kb'");
      return;
    }

    setThumbnailError(null);
    if (selected && !types.includes(selected.type)) {
      setDetails({ ...details, image: "" });
      setThumbnailError(
        "Please select an image file (png , jpg Or JPeg) for Logo"
      );
      return;
    }
    setUploaderImage("file updated");
    const storageRef = ref(projectStorage, `files/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // setThumbnailError(progress);
      },
      (error) => {
        // setThumbnailError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setDetails({ ...details, image: downloadURL });
        });
      }
    );
  };

  // cv upload
  const handleCv = (e) => {
    let selected = e.target.files[0];
    setThumbnailError(null);
    if (!selected) {
      setThumbnailError("Please, Select A PDF file");
      return;
    }

    // if (!selected.type.includes("image")) {
    //     setThumbnailError("File should be Image")
    //     return
    // }
    // if (selected.size > 3000000) {
    //     setThumbnailError("Image file size must be less than 300kb'")
    //     return
    // }

    setThumbnailError(null);
    if (selected && !cvTypes.includes(selected.type)) {
      setDetails({ ...details, cv: "" });
      setThumbnailError("Please select an PDF file for CV");
      return;
    }
    // setUploaderImage("file updated");
    const storageRef = ref(projectStorage, `CV/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setThumbnailProgress(progress);
      },
      (error) => {
        // setThumbnailError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setDetails({ ...details, cv: downloadURL });
        });
      }
    );
  };

  function CheckName(inputtxt) {
    var name = /^([a-zA-Z0-9\s,'-*]).{0,100}$/;
    if (inputtxt.match(name)) {
      setIssue(false);
      return true;
    } else {
      setIssue("Name length cannot be less than 1 chracter");
      return false;
    }
  }

  function CheckAddress(inputtxt) {
    var address = /^([a-zA-Z0-9\s,'-*]).{0,250}$/;
    if (inputtxt.match(address)) {
      setIssue(false);
      return true;
    } else {
      setIssue("Address length cannot be less than 1 chracter");
      return false;
    }
  }

  function CheckDetails(inputtxt) {
    var details = /^([a-zA-Z0-9\s,'-*]).{0,500}$/;
    if (inputtxt.match(details)) {
      setDetalilsError(false);
      return true;
    } else {
      setDetalilsError("Length cannot be less than 1 chracter");
      return false;
    }
  }

  function CheckSelf(inputtxt) {
    var self = /^([a-zA-Z0-9\s,'-*]).{0,500}$/;
    if (inputtxt.match(self)) {
      setSelfError(false);
      return true;
    } else {
      setSelfError("Length cannot be less than 1 chracter");
      return false;
    }
  }
  const handleCategory = (e) => {
    setDetails({ ...details, category: e.target.value });
  };
  const handleGender = (e) => {
    setDetails({ ...details, gender: e.target.value });
  };

  const handleProblemChange = (event) => {
    const {
      target: { value },
    } = event;
    setProblemName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategoryName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleCounselingChange = (event) => {
    const {
      target: { value },
    } = event;
    setCounselingName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  let [clickNext, setClickNext] = useState(false);

  const handleNextPage = () => {
    setClickNext(true);
    if (
      details.name == "" ||
      details.degree == "" ||
      details.location == "" ||
      details.phone == "" ||
      details.expertiseArea == "" ||
      details.yourself == "" ||
      details.whyPsycure == "" ||
      details.image == ""
    ) {
      setError("Please fill all required input field");
      return;
    }

    setPage(2);
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
      setClickNext(false);
    }, 3000);
  }, [error, clickNext]);

  // all problems adding into details problem array
  useEffect(() => {
    setDetails({
      ...details,
      problems: problemName.map((obj) => problems[obj - 1].value),
    });
  }, [problemName]);

  useEffect(() => {
    setDetails({
      ...details,
      category: categoryName.map((obj) => allCategories[obj - 1].value),
    });
  }, [categoryName]);

  useEffect(() => {
    setDetails({
      ...details,
      counselingType: counselingName.map((obj) => allCounseling[obj - 1].value),
    });
  }, [counselingName]);

  return (
    <>
      <Header />
      <Box>
        <Container
          sx={{
            flexGrow: 1,
          }}
          style={{
            background: "#FAFAFA",
            boxShadow: " 0px 24px 48px rgba(0, 0, 0, 0.25)",
            borderRadius: "40px",
          }}
        >
          <Box className="details_container">
            <Box>
              <Box>
                <h2
                  style={{ textAlign: "center" }}
                  variant="h4"
                  gutterBottom
                  component="div"
                >
                  Psychologist Details
                </h2>
                <hr
                  style={{
                    display: "block",
                    width: "10%",
                    height: "3px",
                    backgroundColor: "#31C75A",
                    border: 0,
                  }}
                />
              </Box>

              <Box className="main_box">
                <form>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Box
                      style={{
                        border:
                          clickNext && details.image == ""
                            ? "1px solid red"
                            : "1px solid rgb(49, 199, 90)",
                        borderRadius: "50%",
                        display: "flex",
                        width: "6rem",
                        height: "6rem",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <input
                        class="custom-file__input"
                        id="photo"
                        accept="image/*"
                        type="file"
                        onChange={handleImage}
                        label={logoUpload}
                      />
                      <label class="custom-file__label" for="photo">
                        {uploaderImage.length ? (
                          <img
                            alt=""
                            class="thumb--lg rounded-circle"
                            src={ImageUploadSuccess}
                          />
                        ) : (
                          <img
                            alt=""
                            class="thumb--lg rounded-circle"
                            src={logoUpload}
                          />
                        )}
                      </label>
                    </Box>
                  </Box>
                  <div style={{ textAlign: "center" }}>
                    {issue ? (
                      <small style={{ color: "coral" }}>{issue}</small>
                    ) : (
                      <></>
                    )}
                  </div>
                  {thumbnailError ? (
                    <Alert
                      style={{
                        backgroundColor: "#D6E4D7",
                        textAlign: "center",
                      }}
                      severity="warning"
                    >
                      {thumbnailError}
                    </Alert>
                  ) : (
                    <></>
                  )}
                  {thumbnailProgress ? (
                    <Alert
                      style={{
                        backgroundColor: "#D6E4D7",
                        textAlign: "center",
                      }}
                      severity="warning"
                    >
                      Cv uploading {thumbnailProgress} %
                    </Alert>
                  ) : (
                    <></>
                  )}

                  <Box className="flex">
                    <TextField
                      id="standard-basic"
                      label="Your Name"
                      name="name"
                      type="text"
                      required
                      variant="outlined"
                      value={details.name}
                      inputProps={{ maxLength: 100 }}
                      style={{
                        margin: "1rem",
                        border:
                          clickNext && details?.name == ""
                            ? "2px solid red"
                            : "",
                      }}
                      onChange={(e) => {
                        setDetails({ ...details, name: e.target.value });
                        CheckName(e.target.value);
                        e.target.value.length > 99 &&
                          setIssue(
                            "Maximum length exceeded. Name length cannot be more than 100 chracters"
                          );
                      }}
                    />
                    <FormControl
                      style={{
                        margin: "1rem",
                        border:
                          clickNext && details.gender == ""
                            ? "2px solid red"
                            : "",
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ opacity: details.gender !== "" ? "0" : "1" }}
                      >
                        Gender
                      </InputLabel>
                      <Select
                        // style={{ display: 'flex' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={details.gender}
                        label="Gender"
                        onChange={handleGender}
                        input={<OutlinedInput label="Gender" />}
                      >
                        <MenuItem
                          style={{
                            display: "block",
                            marginBottom: "10px",
                            paddingLeft: "20px",
                          }}
                          value="male"
                        >
                          Male
                        </MenuItem>
                        <MenuItem
                          style={{
                            display: "block",
                            marginBottom: "10px",
                            paddingLeft: "20px",
                          }}
                          value="female"
                        >
                          Female
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="flex">
                    <TextField
                      style={{
                        margin: "1rem",
                        border:
                          clickNext && details.location == ""
                            ? "2px solid red"
                            : "",
                      }}
                      id="standard-basic"
                      label="Your Location"
                      name="name"
                      type="text"
                      required
                      variant="outlined"
                      value={details.location}
                      inputProps={{ maxLength: 250 }}
                      onChange={(e) => {
                        setDetails({ ...details, location: e.target.value });
                        CheckAddress(e.target.value);
                        e.target.value.length > 249 &&
                          setIssue(
                            "Maximum length exceeded. Address length cannot be more than 250 chracters"
                          );
                      }}
                    />

                    <TextField
                      style={{
                        margin: "1rem",
                        border:
                          clickNext && details.phone == ""
                            ? "2px solid red"
                            : "",
                      }}
                      id="standard-basic"
                      label="Your Phone Number"
                      name="name"
                      type="number"
                      required
                      variant="outlined"
                      value={details.phone}
                      onChange={(e) =>
                        setDetails({ ...details, phone: e.target.value })
                      }
                    />
                  </Box>

                  <Box className="flex">
                    <TextField
                      style={{
                        margin: "1rem",
                        border:
                          clickNext && details.degree == ""
                            ? "2px solid red"
                            : "",
                      }}
                      id="standard-basic"
                      label="Your last degree"
                      name="name"
                      type="text"
                      required
                      variant="outlined"
                      value={details.degree}
                      onChange={(e) =>
                        setDetails({ ...details, degree: e.target.value })
                      }
                    />
                    <TextField
                      style={{ margin: "1rem" }}
                      id="standard-basic"
                      label="Your Experience. eg: 6"
                      name="name"
                      type="number"
                      required
                      variant="outlined"
                      value={details.experience}
                      onChange={(e) =>
                        setDetails({ ...details, experience: e.target.value })
                      }
                    />
                  </Box>

                  <Box className="flex">
                    <FormControl
                      style={{
                        margin: "1rem",
                        border:
                          clickNext && !details.problems.length
                            ? "2px solid red"
                            : "",
                      }}
                    >
                      <InputLabel
                        id="demo-multiple-Specialization-label"
                        style={{ opacity: problemName.length ? "0" : "1" }}
                      >
                        Specialization
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-Specialization-label"
                        id="demo-multiple-Specialization"
                        multiple
                        value={problemName}
                        onChange={handleProblemChange}
                        input={
                          <OutlinedInput
                            id="select-multiple-Specialization"
                            label="Specialization"
                          />
                        }
                        renderValue={(selected) =>
                          selected
                            .map((obj) => problems[obj - 1].value)
                            .join(", ")
                        }
                      >
                        {problems.map((name) => (
                          <MenuItem
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              display: "flex",
                            }}
                            key={name.id}
                            value={name.id}
                          >
                            <Checkbox
                              checked={problemName.indexOf(name.id) > -1}
                            />
                            <ListItemText primary={name.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* category */}
                    <FormControl
                      style={{
                        margin: "1rem",
                        border:
                          clickNext && !details.category.length
                            ? "2px solid red"
                            : "",
                      }}
                    >
                      <InputLabel
                        id="demo-multiple-Specialization-label"
                        style={{ opacity: categoryName.length ? "0" : "1" }}
                      >
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-Specialization-label"
                        id="demo-multiple-Specialization"
                        multiple
                        value={categoryName}
                        onChange={handleCategoryChange}
                        input={
                          <OutlinedInput
                            id="select-multiple-Specialization"
                            label="Specialization"
                          />
                        }
                        renderValue={(selected) =>
                          selected
                            .map((obj) => allCategories[obj - 1].value)
                            .join(", ")
                        }
                      >
                        {allCategories.map((name) => (
                          <MenuItem
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              display: "flex",
                            }}
                            key={name.id}
                            value={name.id}
                          >
                            <Checkbox
                              checked={categoryName.indexOf(name.id) > -1}
                            />
                            <ListItemText primary={name.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  {/* <Box className="flex"> */}
                  <div
                    style={{
                      margin: "20px 0",
                    }}
                  >
                    <label style={{ fontWeight: "800" }}>
                      How much will you charge per session as a psychologist on
                      our platform?
                    </label>
                  </div>
                  <MultiRangeSlider
                    min={100}
                    max={10000}
                    minValue={minValue}
                    maxValue={maxValue}
                    step={5}
                    stepOnly={true}
                    label={true}
                    onInput={(e) => {
                      set_minValue(e.minValue);
                      set_maxValue(e.maxValue);
                    }}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        maxCharge: e.maxValue,
                        minCharge: e.minValue,
                      })
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <span>Minimum Charge per session: {minValue}</span>
                    <span>Maximum Charge per session: {maxValue}</span>
                  </div>
                  {/* </Box> */}

                  <Box style={{ margin: "1rem" }}>
                    <p>Upload your Cv/Resume</p>
                    <TextField
                      style={{ width: "100%" }}
                      id="standard-basic"
                      name="name"
                      accept="image/*"
                      type="file"
                      required
                      variant="outlined"
                      onChange={handleCv}
                    />
                  </Box>
                  <Box style={{ margin: "1rem" }}>
                    <p
                      style={{
                        color:
                          clickNext && details.whyPsycure == "" ? "red" : "",
                      }}
                    >
                      Why do you want to join Psycure
                    </p>
                    {detailsError ? (
                      <small style={{ color: "coral" }}>{detailsError}</small>
                    ) : (
                      <></>
                    )}
                    <Box className="flex">
                      <textarea
                        required
                        style={{
                          outlineColor: "green",
                          width: "100%",
                          padding: "17px",
                          resize: "none",
                          fontSize: "16px",
                        }}
                        name="problem"
                        placeholder="whats in your mind"
                        rows="5"
                        value={details.whyPsycure}
                        maxLength={700}
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            whyPsycure: e.target.value,
                          });
                          CheckDetails(e.target.value);
                          e.target.value.length > 699 &&
                            setDetalilsError(
                              "Maximum length exceeded. Length cannot be more than 700 chracters"
                            );
                        }}
                      ></textarea>
                    </Box>
                  </Box>

                  <Box style={{ margin: "1rem" }}>
                    <p
                      style={{
                        color: clickNext && details.yourself == "" ? "red" : "",
                      }}
                    >
                      Briefly talk about yourself *
                    </p>
                    {selfError ? (
                      <small style={{ color: "coral" }}>{selfError}</small>
                    ) : (
                      <></>
                    )}
                    <Box style={{ width: "100%" }}>
                      <textarea
                        required
                        style={{
                          outlineColor: "green",
                          width: "100%",
                          padding: "17px",
                          resize: "none",
                          fontSize: "16px",
                        }}
                        name="problem"
                        placeholder="Yourself"
                        rows="5"
                        value={details.yourself}
                        maxLength={1200}
                        onChange={(e) => {
                          setDetails({ ...details, yourself: e.target.value });
                          CheckSelf(e.target.value);
                          e.target.value.length > 1199 &&
                            setSelfError(
                              "Maximum length exceeded. Length cannot be more than 1200 chracters"
                            );
                        }}
                      ></textarea>
                    </Box>
                  </Box>
                  <Box style={{ textAlign: "right" }}>
                    <Button
                      onClick={handleNextPage}
                      style={{
                        backgroundColor: "#31C75A",
                        marginTop: "1rem",
                        padding: "20px 30px",
                        borderRadius: "15px",
                        color: "white",
                        marginRight: "20px",
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                </form>
                {/* {
                                error && <p style={{ color: "red" }}>{error}</p>
                            } */}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PsychologistDetails;
