// src/ImageTextBlock.js
import React from "react";
import { Grid, Box, Typography } from "@mui/material";

const ImageTextBlock = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{ alignItems: "center", mt: 2, width: "100%" }}
    >
      <Grid item xs={12} md={6}>
        <Box
          component="img"
          src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/summit%2Fimg.jpg?alt=media" // Replace with your image URL
          alt="Description"
          sx={{ width: "100%", height: "auto", borderRadius: 2 }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: "900" }}>
            The Mental Health Summit 2024, Bangladesh
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "justify",
              lineHeight: "1.9",
              fontFamily: "DMSans, sans-serif",
            }}
          >
            is a landmark event, uniting <b>500 passionate participants</b> from
            across the nation, alongside <b>25 organizations </b> committed to
            advancing mental health. This two-day summit will serve as a
            platform for knowledge exchange, innovation, and action to address
            pressing mental health challenges. <br />
            <b>With 9 immersive workshops and 5 impactful panel discussions</b>,
            the summit will feature renowned mental health leaders across the
            academia and mental health organizations. These experts will guide
            discussions that explore new strategies for mental health advocacy,
            policy reform, and community support. <br /> Additionally, the
            summit will offer a <b> 50,000 BDT research grant</b>, empowering
            attendees to further explore and contribute to the mental health
            landscape in Bangladesh. By fostering academic inquiry and
            innovative solutions, this grant will help deepen the impact of
            mental health initiatives across the country. <br /> As the summit
            concludes, participants will return to their communities not just as
            advocates, but as ambassadors of change—equipped with the knowledge,
            networks, and resources needed to create lasting, positive mental
            health outcomes for all. <br />
            <b>
              {" "}
              Join us as we come together to transform the future of mental
              health in Bangladesh!{" "}
            </b>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ImageTextBlock;
