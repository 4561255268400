import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";


export default function AllWorkShopPromo({ success }) {
  let [promoCodes, setPromoCodes] = useState([]);

  const fetchPromo = async () => {
    const response = await fetch("https://api.thepsycure.com/workshop-promo");
    const promos = await response.json();

    if (response.ok) {
      setPromoCodes(promos);
    }
  };

  useEffect(() => {
    fetchPromo();
  }, [success]);

  const columns = [
    { id: "name", label: "Name", minWidth: 170, align: "center" },
    { id: "percentage", label: "Percentage", minWidth: 150, align: "center" },
    {
      id: "maxDiscount",
      label: "Max Discount",
      minWidth: 150,
      align: "center",
    },
    {
      id: "validity",
      label: "Validity",
      minWidth: 170,
      align: "center",
    },
  ];

  function createData(id, name, percentage, maxDiscount, validity) {
    return { id, name, percentage, maxDiscount, validity };
  }

  const rows = [];
  promoCodes.forEach((promo) =>
    rows.push(
      createData(
        promo?._id,
        promo?.name,
        promo?.percentage,
        promo?.maxDiscount,
        promo?.validity
      )
    )
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div
      style={{
        boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.25)",
        padding: "2rem",
        borderRadius: ".8rem",
        marginTop: "4rem",
      }}
    >
      <h2 style={{ textAlign: "center" }}>All Promo</h2>
      <TableContainer className="table-container" sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: 600 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
