import { Grid } from '@mui/material';
import React, { useState } from 'react';
import "./Journal.css"
import angryImg from "../../../assets/images/MoodJournal/1angry.png"
import sadImg from "../../../assets/images/MoodJournal/2sad.png"
import notgoodImg from "../../../assets/images/MoodJournal/3notgood.png"
import happyImg from "../../../assets/images/MoodJournal/4happy.png"
import betterImg from "../../../assets/images/MoodJournal/5better.png"
import AllJournal from './AllJournal';
import JournalPosting from './JournalPosting';
import ReadingJournal from './ReadingJournal';
import { Container } from '@mui/system';

import { useEffect } from 'react';
import { journalPageEvent } from '../../../utils/FacebookPixelEvent';
import Footer from '../../../Shared/Footer/Footer';

const feelingEmoji = [
    {
        id: 1,
        img: angryImg,
        title: "angry"
    },
    {
        id: 2,
        img: sadImg,
        title: "sad"
    },
    {
        id: 3,
        img: notgoodImg,
        title: "notgood"
    },
    {
        id: 4,
        img: happyImg,
        title: "happy"
    },
    {
        id: 5,
        img: betterImg,
        title: "better"
    },
]

let toDate = new Date().toISOString().slice(0, 10)
let toTime = new Date().toLocaleString().slice(10)
let concatDateTime = `${toDate},${toTime}`

const Journal = () => {
    let [mood, setMood] = useState({
        feeling: "",
        mind: "",
        date: concatDateTime,
        email: "",
    });

    useEffect(() => {
        journalPageEvent();
    }, [])

    let [selectedJournal, setSelectedJournal] = useState("");
    let [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Container>
                <div className="journal">
                    <h1 style={{ textAlign: "center" }}>Mood Journal</h1>
                    <Grid container spacing={4}>
                        <JournalPosting setMood={setMood} mood={mood} feelingEmoji={feelingEmoji} />
                        <AllJournal setSelectedJournal={setSelectedJournal} setOpen={setOpen} mood={mood} />
                    </Grid>
                    {/* <div className='img_container'>
                        <img src={SessionImg} />
                    </div> */}
                    {
                        selectedJournal && <ReadingJournal selectedJournal={selectedJournal} handleClose={handleClose} open={open} />
                    }
                </div>
            </Container>
            {/* <DashboardFooter /> */}
            <Footer />
        </>
    );
};

export default Journal;