import {
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Icon,
  Modal,
  Slide,
} from "@mui/material";
import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import { FacebookRounded } from "@mui/icons-material";
import { LinkedinIcon } from "react-share";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // height: "80%",
  p: 4,
  overflow: "scroll",
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const UnwindMindJudges = () => {
  const faculties = [
    {
      name: "Morshed Mishu",
      post: "Cartoonist and Installation Artist",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Fjudges%2Fmorshed.jpg?alt=media",
    },
    {
      name: "Dr. M. Tasdik Hasan",
      post: "PhD Fellow, Monash University, Australia",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FTasdik%20(3)%20copy.jpg?alt=media&token=0cd7e39a-c6ef-4f19-89aa-ecd54d849570",
    },
    {
      name: "Priti Ali",
      post: "Artist",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Fjudges%2Fprity%20ali%20.jpg?alt=media",
    },
    {
      name: "M Aminur Rahman",
      post: "Chief Photographer and CEO Checkmate Events",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Fjudges%2Faminur.jpg?alt=media",
    },
    {
      name: "Syed Zakir Hossain",
      post: "Chief Photographer, The Business Standard",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Fjudges%2Fzakir.jpg?alt=media",
    },
    {
      name: "Rashad Imam Tanmoy",
      post: "Cartoonist, Founder- Cartoon People",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/unwind--mind%2Fjudges%2Frashad.jpg?alt=media",
    },
  ];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [faculty, setFaculty] = useState();
  return (
    <Container maxWidth="xl">
      <Typography style={{ textAlign: "center", margin: "2% 0" }} variant="h3">
        Our Judges of 2024
      </Typography>
      <Grid
        container
        columns={{ xs: 1, md: 2, lg: 3, xl: 3 }}
        justifyContent="center"
        spacing={2}
      >
        {/* first */}

        {faculties?.map((faculty) => (
          <Grid item>
            <ButtonBase>
              <Card
                onClick={() => {
                  setFaculty(faculty);
                  handleOpen();
                }}
                sx={{
                  maxWidth: 345,
                  height: 419,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div style={{ padding: "15px" }}>
                  <CardMedia
                    component="img"
                    height="100%"
                    image={faculty?.image}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {faculty?.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {faculty?.post}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default UnwindMindJudges;
