import React from "react";
import DonationPayment from "./DonationPayment";

const Donation = () => {
  return <>
  <DonationPayment />
  </>;
};

export default Donation;
