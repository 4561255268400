import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import useAuth from "../../../hooks/useAuth";
import HomeworkBuilder from "../HomeworkBuilder";
import { Container } from "@mui/system";
import homeworkImg from "../../../assets/images/dashboard/homework.png";

import Footer from "../../../Shared/Footer/Footer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6F1EC",
    color: "#000",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E6F1EC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function HomeworkTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { user } = useAuth();

  const [sessions, setSessions] = React.useState([]);
  const [patientSession, setPatientSession] = useState({});

  function findUnique(arr, predicate) {
    var found = {};
    arr.forEach((d) => {
      found[predicate(d)] = d;
    });
    return Object.keys(found).map((key) => found[key]);
  }

  useEffect(() => {
    const url = `https://api.thepsycure.com/session`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        const psychologistSession = data?.filter(
          (data) => data?.selectedPsychologists?.email === user?.email
        );
        const unique = findUnique(
          psychologistSession,
          (session) => session?.patientEmail
        ).sort((a, b) => a.selectedTime - b.selectedTime);

        setSessions(unique);
      });
  }, [user?.email]);

  const [foundResults, setFoundResults] = useState(sessions);

  const filter = (e) => {
    const keyword = e.target.value;

    const results = sessions?.filter((session) => {
      return session?.userDetails?.name
        ?.toLowerCase()
        .includes(keyword.toLowerCase());
      // Use the toLowerCase() method to make it case-insensitive
    });
    if (keyword) {
      setFoundResults(results);
    } else {
      setFoundResults(sessions);
    }
    // if (results.length) {
    // } else {
    //     setFoundResults(sessions);
    // }
  };

  useEffect(() => {
    setFoundResults(sessions);
  }, [sessions]);

  const handleGive = (session) => {
    setPatientSession(session);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Container>
        <Grid item xs={12} md={6} component={Paper}>
          <form
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            action=""
          >
            <input
              type="search"
              name="search"
              placeholder="Type here to search"
              id="data-filter"
              onChange={filter}
              style={{ margin: "1rem", width: "50%" }}
            />
          </form>
          <TableContainer
            className="table-container"
            sx={{ maxHeight: 440, backgroundColor: "#E6F1EC" }}
          >
            <Table aria-label="customized table">
              <TableHead style={{ marginBottom: "2rem" }}>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <strong>Patient Name</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Patient Email</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Give Homework</strong>
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {foundResults
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell className="table_center">
                        {row?.userDetails?.name}
                      </StyledTableCell>
                      <StyledTableCell className="table_center">
                        {row.userDetails.email}
                      </StyledTableCell>
                      <StyledTableCell className="table_center">
                        <Button
                          onClick={() => {
                            handleGive(row);
                          }}
                          variant="contained"
                          style={{ backgroundColor: "#31C75A" }}
                          sx={{ borderRadius: "25px" }}
                        >
                          Give
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={foundResults.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        <HomeworkBuilder
          patientSession={patientSession}
          setPatientSession={setPatientSession}
        />
        <div className="img_container">
          <img src={homeworkImg} />
        </div>
      </Container>
      {/* <DashboardFooter /> */}
      <Footer />
    </>
  );
}
