import styled from "@emotion/styled";
import {
  Box,
  Button,
  CardActions,
  Collapse,
  Container,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import homeworkImg1 from "../../assets/images/Homework/homework-1.png";
import homeworkImg2 from "../../assets/images/Homework/homework-2.png";
import recommendationImg from "../../assets/images/Homework/recommendation.png";
import axios from "axios";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(0deg)",
  marginLeft: "auto",
}));

const SelectDoctor = () => {
  const [problemData, setProblemData] = useState({});

  const [expanded1, setExpanded1] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);

  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };
  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };

  const handleChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newProblemData = { ...problemData };
    newProblemData[field] = value;
    setProblemData(newProblemData);
  };

  const handleSubmit = (e, user) => {
    e.preventDefault();

    axios
      .post("https://api.thepsycure.com/problems", {
        problemData,
      })
      .then((res) => {
        alert("Great! Your Info Submitted Successfully!");
        e.target.reset();
      });
  };
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: "Select Doctor",
    });
  }, []);
  return (
    <>
      <Header />
      <Container>
        <Box sx={{ mt: 20, mb: 5 }}>
          {/* first part */}
          <Grid
            container
            spacing={12}
            sx={{
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <img
                style={{ width: "90%", height: "auto" }}
                src={homeworkImg1}
                alt="Psycure logo"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <h2 style={{ textAlign: "left", margin: 0, padding: 0 }}>
                Psychologist
              </h2>

              <hr
                style={{
                  display: "inline-block",
                  width: "20%",
                  height: "3px",
                  backgroundColor: "#31C75A",
                  border: 0,
                }}
              />

              <p sx={{ textAlign: "justify" }} variant="body1" gutterBottom>
                Here, I focus on a range of items and features that we use in
                life without giving them a second thought such as Coca Cola,
                body muscles and holding ones own breath. Though, most of these
                notes are not fundamentally necessary, they are such that you
                can use them for a good laugh, at a drinks party or for picking
                up women or men. Here, I focus on a range of items and features
                that we use in life without giving them a second thought such as
                Coca Cola, body muscles and holding ones own breath. Though,
                most of these notes are not fundamentally necessary, they are
                such that you can use them for a good laugh, at a drinks party
                or for picking up women or men.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  placeContent: "flex-start",
                }}
              >
                <CardActions disableSpacing>
                  <ExpandMore
                    expand={expanded1}
                    onClick={handleExpandClick1}
                    aria-expanded={expanded1}
                    aria-label="show more"
                  >
                    <Button
                      style={{
                        backgroundColor: "#31C75A",
                        padding: "10px",
                        borderRadius: "15px",
                      }}
                      variant="contained"
                    >
                      {expanded1 ? "See Less" : "See More"} <ExpandMoreIcon />
                    </Button>
                  </ExpandMore>
                </CardActions>
              </div>
              <Collapse in={expanded1} timeout="auto" unmountOnExit>
                <p>
                  View the latest from the world of psychology: from behavioral
                  research to practical guidance on relationships, mental health
                  and addiction. I focus on a range of items and features that
                  we use in life without giving them a second thought such as
                  Coca Cola, body muscles and holding ones own breath. Though,
                  most of these notes are not fundamentally necessary, they are
                  such that you can use them for a good laugh, at a drinks party
                  or for picking up women or men.
                </p>
              </Collapse>
            </Grid>
          </Grid>

          {/* second part */}
          <Grid
            container
            spacing={12}
            sx={{
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <h2 style={{ textAlign: "left", margin: 0, padding: 0 }}>
                Psychiatrist
              </h2>

              <hr
                style={{
                  display: "inline-block",
                  width: "20%",
                  height: "3px",
                  backgroundColor: "#31C75A",
                  border: 0,
                }}
              />

              <p sx={{ textAlign: "justify" }} variant="body1" gutterBottom>
                Here, I focus on a range of items and features that we use in
                life without giving them a second thought such as Coca Cola,
                body muscles and holding ones own breath. Though, most of these
                notes are not fundamentally necessary, they are such that you
                can use them for a good laugh, at a drinks party or for picking
                up women or men. Here, I focus on a range of items and features
                that we use in life without giving them a second thought such as
                Coca Cola, body muscles and holding ones own breath. Though,
                most of these notes are not fundamentally necessary, they are
                such that you can use them for a good laugh, at a drinks party
                or for picking up women or men.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  placeContent: "flex-start",
                }}
              >
                <CardActions disableSpacing>
                  <ExpandMore
                    expand={expanded2}
                    onClick={handleExpandClick2}
                    aria-expanded={expanded2}
                    aria-label="show more"
                  >
                    <Button
                      style={{
                        backgroundColor: "#31C75A",
                        padding: "10px",
                        borderRadius: "15px",
                      }}
                      variant="contained"
                    >
                      {expanded2 ? "See Less" : "See More"} <ExpandMoreIcon />
                    </Button>
                  </ExpandMore>
                </CardActions>
              </div>
              <Collapse in={expanded2} timeout="auto" unmountOnExit>
                <p>
                  View the latest from the world of psychology: from behavioral
                  research to practical guidance on relationships, mental health
                  and addiction. I focus on a range of items and features that
                  we use in life without giving them a second thought such as
                  Coca Cola, body muscles and holding ones own breath. Though,
                  most of these notes are not fundamentally necessary, they are
                  such that you can use them for a good laugh, at a drinks party
                  or for picking up women or men.
                </p>
              </Collapse>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                style={{ width: "90%", height: "auto" }}
                src={homeworkImg2}
                alt="Psycure logo"
              />
            </Grid>
          </Grid>
          {/* third part */}
          <Grid
            container
            spacing={12}
            sx={{
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <img
                style={{ width: "90%", height: "auto" }}
                src={recommendationImg}
                alt="Psycure logo"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <h2 style={{ textAlign: "left", margin: 0, padding: 0 }}>
                Our Recommendation
              </h2>

              <hr
                style={{
                  display: "inline-block",
                  width: "30%",
                  height: "3px",
                  backgroundColor: "#31C75A",
                  border: 0,
                }}
              />

              <p sx={{ textAlign: "justify" }} variant="body1" gutterBottom>
                Here, I focus on a range of items and features that we use in
                life without giving them a second thought such as Coca Cola,
                body muscles and holding ones own breath. Though, most of these
                notes.
              </p>
              <h3 style={{ margin: 0, padding: 0 }}>Describe Your Problem</h3>

              <br />
              <br />

              <form onSubmit={handleSubmit}>
                <input
                  style={{
                    width: "100%",
                    padding: "15px",
                    outlineColor: "green",
                  }}
                  id="standard-basic"
                  name="patientName"
                  type="text"
                  placeholder="Type Your Name"
                  onChange={handleChange}
                  required
                  variant="outlined"
                />

                <br />
                <br />

                <textarea
                  onChange={handleChange}
                  required
                  style={{ outlineColor: "green", width: "100%" }}
                  name="problem"
                  placeholder="whats in your mind"
                  cols="79"
                  rows="10"
                ></textarea>

                <br />
                <br />

                <Button
                  style={{
                    backgroundColor: "#31C75A",
                    padding: "10px 20px",
                    borderRadius: "15px",
                  }}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default SelectDoctor;
