import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useTheme } from '@material-ui/core/styles';

export default function Impact() {
    const theme = useTheme();
    return (
        <Container sx={{ my: 10,
            [theme.breakpoints.down('xs')]: {
              my: 4,
            }, }}>
            <h2 className='headin'>
                Our Impact
            </h2>
            <hr style={{ display: 'block', width: '10%', height: '3px', backgroundColor: '#31C75A', border: 0 }} />

            <Grid sx={{ pt: 5 }} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} sm={12} md={4}>
                    <div style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "3.5rem", color: "#31C75A", margin: "0px", fontWeight: "700" }}>
                            <CountUp end={80} redraw={true} duration={1}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>,
                            <CountUp end={431} redraw={true} duration={1}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                        </p>
                        <Typography variant="p" style={{ textAlign: "center", marginTop: "0px", fontSize: "1.4rem" }}>
                            People reached through our services
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <div style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "3.5rem", color: "#31C75A", margin: "0px", fontWeight: "700" }}>
                            <CountUp end={2} redraw={true}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>,
                            <CountUp end={719} redraw={true}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                        </p>
                        <Typography variant="p" style={{ textAlign: "center", fontSize: "1.4rem" }}>
                            Counseling provided to clients
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <div style={{ textAlign: "center" }}>

                        <p style={{ fontSize: "3.5rem", color: "#31C75A", margin: "0px", fontWeight: "700" }}>
                            <CountUp end={11} redraw={true}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>,
                            <CountUp end={560} redraw={true}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                        </p>
                        <Typography variant="p" style={{ textAlign: "center", fontSize: "1.4rem" }}>
                            Youths trained through our programs
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}
