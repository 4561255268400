import { Box, Button, Container, Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import VideoHeroSection from './../../assets/images/video-meet.png'
import Header from '../../Shared/Header/Header';


const VideoSessionHeroSection = () => {
    return (
        <>
            <Header />
            <Container style={{ marginTop: '10rem' }}>
                {/* <h2 style={{ textAlign: 'center' }} variant="h4" gutterBottom component="div">
                About Us
            </h2>
            <hr style={{ display: 'block', width: '10%', height: '3px', backgroundColor: '#31C75A', border: 0 }} /> */}
                <Box>
                    <Grid container spacing={2} sx={{ flexGrow: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
                        <Grid item xs={12} md={6}>
                            <h2>Psycure </h2>
                            <h2>Video Session</h2>

                            <p sx={{ textAlign: 'justify' }} variant="body1" gutterBottom>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, sit sequi! Repellat accusantium aut dolor veniam aliquam explicabo fugit hic similique dolores! Atque mollitia eveniet exercitationem provident ullam voluptatibus neque doloremque? Deleniti quas id necessitatibus atque perferendis maxime, officiis, fugit, veritatis architecto eligendi sequi deserunt? Repudiandae consectetur pariatur harum voluptas?Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nobis cumque quam ad sequi consectetur fugiat, blanditiis necessitatibus optio quae deleniti ea quidem dolorum voluptatum exercitationem reiciendis maiores tenetur mollitia eveniet!
                            </p>

                            <Button style={{ backgroundColor: '#31C75A', padding: '15px', borderRadius: '15px' }} variant='contained'><Link to='*' style={{ textDecoration: 'none', color: 'white' }}>Join Meeting</Link></Button>



                        </Grid>


                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Box >
                                <img style={{ width: '100%', height: 'auto' }} src={VideoHeroSection} alt="Psycure logo" />
                            </Box>

                        </Grid>

                    </Grid>
                </Box>
            </Container>
        </>
    );
};

export default VideoSessionHeroSection;