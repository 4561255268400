import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
} from "@mui/material";

import React from "react";
import RichTextEditor from "./RichTextEditor";

import { useState } from "react";
import { projectStorage } from "../../firebase.config";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import useAuth from "../../hooks/useAuth";
import ErrorAlert from "../../Shared/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../Shared/SuccessAlert/SuccessAlert";

const AddWorkShop = () => {
  const { user } = useAuth();

  const [logoFileError, setlogoFileError] = useState("");
  const [logoProgress, setLogoProgress] = useState(0);

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [workshopImage, setWorkshopImage] = useState("");
  const [blogImage, setBlogImage] = useState("");
  const [workshopLocation, setWorkshopLocation] = useState("");

  const [worskshopTitle, setWorkshopTitle] = useState("");
  const [workshopContent, setWorkshopContent] = useState();
  const [workshopFee, setWorkshopFee] = useState();
  const types = ["image/png", "image/jpeg", "image/jpg"];

  const [eventType, setEventType] = React.useState("");
  const handleEventType = (event) => {
    setEventType(event.target.value);
  };

  const handleImage = (e, imageFor) => {
    let selected = e.target.files[0];

    if (selected && !types.includes(selected.type)) {
      // setDetails({ ...details, image: "" })
      setlogoFileError(
        "Please select an image file (png , jpg Or JPeg) for Logo"
      );
      return;
    }
    const storageRef = ref(projectStorage, `files/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setLogoProgress(progress);
      },
      (error) => {
        setlogoFileError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (imageFor === "blogImage") {
            setBlogImage(downloadURL);
          } else {
            setWorkshopImage(downloadURL);
          }
        });
      }
    );
  };

  const handleWorkshop = async (e) => {
    e.preventDefault();
    const randomNumber = Math.floor(Math.random() * (2000 - 1000 + 1)) + 1000;
    console.log(randomNumber);
    let details = {
      worskshopTitle,
      workshopContent,
      workshopLocation,
      workshopImage: workshopImage,
      workshopFee: parseInt(workshopFee),
      eventType,
    };
    console.log(details);
    const response = await fetch("https://api.thepsycure.com/add-workshop", {
      method: "POST",
      body: JSON.stringify(details),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      setError("Could not publish your workshop. Please try again");
    }
    if (response.ok) {
      setWorkshopImage("");
      setWorkshopTitle("");
      setWorkshopLocation("");
      setWorkshopContent("");
      setEventType("");
      setWorkshopFee("");
      setLogoProgress(0);
      setSuccess("Congratulations, Workshop Added");
    }
  };
  return (
    <div>
      <Box>
        <Container sx={{ flexGrow: 1 }}>
          <Box md={5} sx={12}>
            <Box>
              <h2
                style={{ textAlign: "center" }}
                variant="h4"
                gutterBottom
                component="div"
              >
                Add Workshop
              </h2>
              <hr
                style={{
                  display: "block",
                  width: "10%",
                  height: "3px",
                  backgroundColor: "#31C75A",
                  border: 0,
                }}
              />
            </Box>

            <Box>
              {/* {blogImage ? (
                <img
                  style={{ maxWidth: "500px", margin: "auto", display: "flex" }}
                  src={blogImage}
                  alt=""
                />
              ) : (
                <img
                  style={{ maxWidth: "500px", margin: "auto", display: "flex" }}
                  src={placeHolderImage}
                  alt=""
                />
              )} */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={12}>
                  <TextField
                    required
                    sx={{ width: "100%", marginRight: "1%" }}
                    id="outlined-required"
                    label="Workshop Title"
                    onChange={(e) => setWorkshopTitle(e.target.value)}
                    value={worskshopTitle}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{ mt: 2, mb: 2 }}
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{ width: "100%", marginRight: "1%" }}
                    id="outlined-required"
                    label="Location"
                    onChange={(e) => setWorkshopLocation(e.target.value)}
                    value={workshopLocation}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <InputLabel id="demo-simple-select-label">
                    Event Type*
                  </InputLabel>
                  <Select
                    required
                    style={{ width: "100%", marginBottom: "1rem" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={eventType}
                    label="Event Type"
                    onChange={handleEventType}
                  >
                    <MenuItem value="online">Online</MenuItem>
                    <MenuItem value="offline">Offline</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              {!user?.photoURL && (
                <>
                  <p>Upload Workshop Cover image</p>
                  <TextField
                    required
                    style={{ width: "100%", marginBottom: "1rem" }}
                    id="standard-basic"
                    onChange={(e) => handleImage(e, "workshopImage")}
                    name="image"
                    type="file"
                    variant="outlined"
                  />
                  {logoProgress == 100 && (
                    <img
                      style={{
                        width: "150px",
                        margin: "auto",
                        display: "flex",
                      }}
                      src={workshopImage}
                      alt=""
                    />
                  )}

                  <div
                    style={{
                      margin: "2% 0",
                    }}
                  >
                    <LinearProgress
                      variant="determinate"
                      value={logoProgress}
                    />
                  </div>
                </>
              )}

              <RichTextEditor setBlogContent={setWorkshopContent} />
              <TextField
                required
                type="number"
                sx={{ width: "100%", margin: "2% 0" }}
                id="outlined-required"
                label="Fee"
                onChange={(e) => setWorkshopFee(e.target.value)}
                value={workshopFee}
              />
              <div style={{ textAlign: "center", marginTop: "2rem" }}>
                <Button
                  style={{ backgroundColor: "#31C75A" }}
                  type="submit"
                  variant="contained"
                  onClick={handleWorkshop}
                >
                  Submit
                </Button>
              </div>
            </Box>
            <Box md={6} sx={12}></Box>
          </Box>
        </Container>
      </Box>
      {error && <ErrorAlert>{error}</ErrorAlert>}
      {success && <SuccessAlert>{success}</SuccessAlert>}
    </div>
  );
};

export default AddWorkShop;
