import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";

import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { NavLink } from "react-router-dom";
import Footer from "../../Shared/Footer/Footer";
import spinner from "../../assets/images/spinner-loop.gif";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const Blogs = () => {
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "green",
      background: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
      background: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#67523D",
        borderRadius: "50px",
        background: "none",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
        background: "none",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
        background: "none",
      },
    },
  });

  const [blogs, setBlogs] = useState([]);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Blogs`,
    });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    const dataFetch = async () => {
      // setIsLoading(true);

      await fetch(
        `https://api.thepsycure.com/blogs?page=${currentPage}&pageSize=${pageSize}`
      )
        .then((res) => res.json())
        .then((data) => {
          setBlogs(data.blogs);
          setTotalPages(data.totalPages);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    };
    dataFetch();
  }, [currentPage, pageSize]);
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  const [foundResults, setFoundResults] = useState(blogs);
  useEffect(() => {
    setFoundResults(blogs);
  }, [blogs]);

  const filter = (e) => {
    setIsLoading(true);
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = blogs?.filter((blog) => {
        return blog.blogTitle.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setIsLoading(false);
      setFoundResults(results);
    } else {
      setIsLoading(false);
      setFoundResults(blogs);
      // If the text field is empty, show all users
    }

    setName(keyword);
  };

  return (
    <>
      <Header />
      <Box sx={{}}>
        <Container sx={{ flexGrow: 1 }}>
          <Box>
            <h2
              style={{
                textAlign: "center",
                marginTop: "8rem",
                fontSize: "38px",
              }}
              variant="h4"
              gutterBottom
              className="headin"
            >
              Blogs
            </h2>
            <hr
              style={{
                display: "block",
                width: "10%",
                height: "3px",
                backgroundColor: "#31C75A",
                border: 0,
              }}
            />
          </Box>
          <Box style={{ textAlign: "center" }}>
            <input
              value={name}
              onChange={filter}
              type="Search"
              placeholder="Type for search"
              style={{
                width: "50%",
                borderRadius: "25px",
                padding: "10px 15px",
                outline: "none",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            />
          </Box>

          <Grid container spacing={2} sx={{ mt: 10 }} alignItems="center">
            {foundResults?.length ? (
              foundResults?.map((blog) => {
                let blogParams = blog?._id;

                return (
                  <Grid item xs={12} md={4}>
                    <NavLink
                      to={`/${blogParams}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Card sx={{ maxWidth: 345 }}>
                        {/* <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                            R
                                        </Avatar>
                                    }
                                    // action={
                                    //     <IconButton aria-label="settings">
                                    //         <MoreVertIcon />
                                    //     </IconButton>
                                    // }
                                    title={blog?.author}
                                    subheader={blog?.date}
                                /> */}
                        <CardMedia
                          component="img"
                          height="150"
                          image={blog?.blogImage}
                          alt="green iguana"
                          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {blog?.blogTitle}
                          </Typography>
                        </CardContent>
                        <CardActions
                          style={{
                            marginBottom: "1rem",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <NavLink
                            to={`/${blogParams}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              size="small"
                              style={{
                                backgroundColor: "#31C75A",
                                padding: "5px 15px",
                                fontSize: "14px",
                                textTransform: "capitalize",
                                borderRadius: "8px",
                                color: "#fff",
                              }}
                              contain
                            >
                              {" "}
                              Read More
                            </Button>
                          </NavLink>
                          {blog?.authorImage ? (
                            <CardMedia
                              component="img"
                              height="50"
                              width="50"
                              image={blog?.authorImage}
                              alt={blog?.author}
                              style={{
                                width: "80px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "50%",
                                paddingLeft: "1rem",
                                paddingRight: "1rem",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </CardActions>
                        {blog?.metaDescription && (
                          <div
                            style={{
                              borderTop: "1px solid #ddd",
                              padding: "1rem",
                            }}
                          >
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              style={{ fontWeight: "600" }}
                            >
                              {blog?.metaDescription?.slice(0, 80)}..
                            </Typography>
                          </div>
                        )}
                      </Card>
                    </NavLink>
                  </Grid>
                );
              })
            ) : isLoading ? (
              <div
                style={{
                  height: "40vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={spinner}
                  alt="spinner"
                  style={{ width: "500px", height: "400px" }}
                />
              </div>
            ) : (
              <div
                style={{
                  height: "40vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ textAlign: "center" }}>No content avaialble</p>
              </div>
            )}
          </Grid>
          <div className="flex">
            {totalPages > 0 && (
              <Pagination
                count={totalPages}
                // color="secondary"

                onChange={handleChange}
                page={currentPage}
                variant="outlined"
                hidePrevButton
                hideNextButtons
                sx={{
                  margin: "5% 0",
                }}
              />
            )}
          </div>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Blogs;
