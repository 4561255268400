import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import backgroundImage from './../../assets/images/signinBG.png'
// import signInImage from './../../assets/images/signin image.png'
import signInImage from './../../assets/images/login.svg'
import { Container } from '@mui/system';
import { Box, CardMedia, CircularProgress, } from '@mui/material';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import './SignUp.css';
import { useSignup } from '../../hooks/useSignup';
import useToken from '../../hooks/useToken';
import SuccessfulSignup from './SuccessfulSignup';

import googleIcon from './../../assets/images/googleIcon.png';
import Footer from '../../Shared/Footer/Footer';

import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import ScrollToTop from '../../Shared/ScrollToTop/ScrollToTop';
import { useSignInWithGooglePatient } from '../../hooks/useSignInWithGooglePatient';

import styled from "styled-components";
import Header from '../../Shared/Header/Header';


const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: #31c75a;
    border-color: #31c75a;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #31c75a;
    }
  }
`;

const GreenRadio = withStyles({
    root: {
        color: green[400],
        borderRadius: "0px",
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);
const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        borderRadius: "0px",
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const SignUpChat = () => {
    const [loginData, setLoginData] = useState({});
    let [issue, setIssue] = useState(null);
    let [nextError, setNextError] = useState(null)
    let [page, setPage] = useState(1)
    let [nextPageOne, setNextPageOne] = useState(false);
    let [nextPageTwo, setNextPageTwo] = useState(false);
    let [info, setInfo] = useState({
        gender: "",
        age: "",
        relationshipStatus: "",
        therapyBefore: "",
        preferences: "",
        preferences: "",
        reason: [],
    })


    const [emailValidate, setEmailValidate] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    // const [token, setToken] = useState("");
    const navigate = useNavigate("/");
    const { signUp, error, isLoading } = useSignup();
    const { user } = useAuth();
    const [token] = useToken(user);
    const { handleSignInWithGoogle, handleSignOutWithGoogle, error: googleError } = useSignInWithGooglePatient('patient')

    const handlePageOne = () => {
        if (info.gender !== "" && info.age !== "" && info.preferences !== "" && info.relationshipStatus !== "" && info.therapyBefore !== "") {
            setPage(2);
            setNextError("");
        } else {
            setNextError("Please fill all required field")
            setNextPageOne(true);
        }
    }

    const handlePageTwo = () => {
        if (info.gender !== "" && info.age !== "" && info.reason.length > 0 && info.preferences !== "" && info.relationshipStatus !== "" && info.therapyBefore !== "") {
            setPage(3)
            setNextError("")
        } else {
            setNextError("Please fill the all required field");
            setNextPageTwo(true);
        }
    }


    function CheckName(inputtxt) {
        var name = /^([a-zA-Z0-9\s,'-*]).{0,100}$/;
        if (inputtxt.match(name)) {
            setIssue(false)
            setEmailVerified(true)
            return true;
        }
        else {
            setIssue('Name length cannot be less than 1 chracter')
            setEmailVerified(false)
            return false;
        }
    }


    function CheckPassword(inputtxt) {
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if (inputtxt.match(passw)) {
            setIssue(false)
            setEmailVerified(true)
            return true;
        }
        else {
            setIssue('Please set a strong password(atleast 7 characters, Uppercase, Lowercase, Symbols, Numbers)')
            setEmailVerified(false)
            return false;
        }
    }


    function ValidateEmail(email) {
        if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            setEmailValidate(true)
            setEmailVerified(true)
            setIssue(false)
            return (true)
        }
        setEmailValidate(false)
        setEmailVerified(false)
        setIssue('Please enter a valid email address')
        return (false)
    }

    const handleOnBlur = e => {
        const field = e.target.name;
        const value = e.target.value;
        const newLoginData = { ...loginData };
        newLoginData[field] = value;
        setLoginData(newLoginData);
    }

    const handleLoginSubmit = e => {
        e.preventDefault();
        if (loginData.password !== loginData.password2) {
            setIssue('Your password did not match');
            return;
        }
        setIssue(null)
        signUp(loginData.email, loginData.password, loginData.name, navigate, "patient", info);
    }

    const handleCheckbox = e => {
        if (!info?.reason.includes(e.target.value)) {
            setInfo({ ...info, reason: [...info.reason, e.target.value] });
        }
    }


    return (
        !user?.email ? <>
            <Header />
            <Container className="main">
                <Container>
                    <Container>
                        <Container>

                            <Container
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>

                                {
                                    page === 1 && <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
                                        <div style={{ maxWidth: "500px" }}>
                                            <h2 style={{ margin: "0", color: "#31C75A", textAlign: "center", marginBottom: "3rem" }}>Please sign up before chatting with us.</h2>
                                            <h2 style={{ margin: "0", color: "#31C75A" }}>Help us finding the right therapist for you</h2>
                                            <p style={{ margin: "0" }}>Please complete the short questionnaire to give some background information about you and the issues you'd like to address in therapy. It would assist  to match you with the most suitable therapist</p>
                                        </div>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" style={{ fontSize: "20px", color: "#31C75A" }}>What is your gender identity? {(nextPageOne && info.gender == "") ? <span style={{ color: "red" }}>*</span> : ""}</FormLabel>
                                            <RadioGroup aria-label="gender" name="gender1" onChange={e => setInfo({ ...info, gender: e.target.value })} >
                                                <FormControlLabel value="female" control={<GreenRadio />} label="Female" />
                                                <FormControlLabel value="male" control={<GreenRadio />} label="Male" />
                                            </RadioGroup>
                                        </FormControl>

                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" style={{ fontSize: "20px", color: "#31C75A" }}>How old are you? {(nextPageOne && info.age == "") ? <span style={{ color: "red" }}>*</span> : ""}</FormLabel>
                                            <TextField onChange={e => setInfo({ ...info, age: e.target.value })} type="number" variant="standard" />
                                        </FormControl>

                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" style={{ fontSize: "20px", color: "#31C75A" }}>What is your relationship status? {(nextPageOne && info.relationshipStatus == "") ? <span style={{ color: "red" }}>*</span> : ""}</FormLabel>
                                            <RadioGroup aria-label="relation" name="relation" onChange={e => setInfo({ ...info, relationshipStatus: e.target.value })} >
                                                <FormControlLabel value="Single" control={<GreenRadio />} label="Single" />
                                                <FormControlLabel value="In a relationship" control={<GreenRadio />} label="In a relationship" />
                                                <FormControlLabel value="Married" control={<GreenRadio />} label="Married" />
                                                <FormControlLabel value="Divorced" control={<GreenRadio />} label="Divorced" />
                                                <FormControlLabel value="Widowed" control={<GreenRadio />} label="Widowed" />
                                                <FormControlLabel value="Other" control={<GreenRadio />} label="Other" />
                                            </RadioGroup>
                                        </FormControl>

                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" style={{ fontSize: "20px", color: "#31C75A" }}>Have you ever been in therapy before? {(nextPageOne && info.therapyBefore == "") ? <span style={{ color: "red" }}>*</span> : ""}</FormLabel>
                                            <RadioGroup aria-label="therapi" name="therapi" onChange={e => setInfo({ ...info, therapyBefore: e.target.value })} >
                                                <FormControlLabel value="No" control={<GreenRadio />} label="No" />
                                                <FormControlLabel value="Yes" control={<GreenRadio />} label="Yes" />
                                            </RadioGroup>
                                        </FormControl>

                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" style={{ fontSize: "20px", color: "#31C75A" }}>Are there any specific preferences for your therapist? {(nextPageOne && info.preferences == "") ? <span style={{ color: "red" }}>*</span> : ""}</FormLabel>
                                            <RadioGroup aria-label="preference" name="preference" onChange={e => setInfo({ ...info, preferences: e.target.value })} >
                                                <FormControlLabel value="Male Therapist" control={<GreenRadio />} label="Male Therapist" />
                                                <FormControlLabel value="Female Therapist" control={<GreenRadio />} label="Female Therapist" />
                                            </RadioGroup>
                                        </FormControl>

                                        {
                                            <div style={{ textAlign: "center" }}><Button onClick={handlePageOne} variant="contained" style={{ backgroundColor: "#31C75A", color: "#fff", width: "150px", padding: "10px", borderRadius: "8px" }} >Next</Button></div>
                                        }
                                        {
                                            nextError && <p style={{ color: "red", textAlign: "center" }}>{nextError}</p>
                                        }
                                    </div>
                                }

                                {
                                    page === 2 &&
                                    <ScrollToTop>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
                                            <FormControl component="fieldset" multiple>
                                                <FormLabel component="legend" style={{ fontSize: "20px", color: "#31C75A" }}>Tell us why you're looking for help today. {(nextPageTwo && info.reason.length < 1) ? <span style={{ color: "red" }}>*</span> : ""}</FormLabel>
                                                <FormGroup aria-label="help" name="gender1" >
                                                    <FormControlLabel control={<GreenCheckbox checked={info?.reason.includes("I am feeling down or depressed")} value="I am feeling down or depressed" onChange={handleCheckbox} />} label="I am feeling down or depressed" />
                                                </FormGroup>
                                                <FormGroup aria-label="help" name="gender1" >
                                                    <FormControlLabel control={<GreenCheckbox checked={info?.reason.includes("I am dealing with stress at school/ work")} value="I am dealing with stress at school/ work" onChange={handleCheckbox} />} label="I am dealing with stress at school/ work" />
                                                </FormGroup>
                                                <FormGroup aria-label="help" name="gender1" >
                                                    <FormControlLabel control={<GreenCheckbox checked={info?.reason.includes("I have been trouble having difficulty sleeping")} value="I have been trouble having difficulty sleeping" onChange={handleCheckbox} />} label="I am feeling down or depressed" />
                                                </FormGroup>
                                                <FormGroup aria-label="help" name="gender1" >
                                                    <FormControlLabel control={<GreenCheckbox checked={info?.reason.includes("I have experienced truma")} value="I have experienced truma" onChange={handleCheckbox} />} label="I have experienced truma" />
                                                </FormGroup>
                                                <FormGroup aria-label="help" name="gender1" >
                                                    <FormControlLabel control={<GreenCheckbox checked={info?.reason.includes("I am navigation addiction or difficulty with substance abuse")} value="I am navigation addiction or difficulty with substance abuse" onChange={handleCheckbox} />} label="I am navigation addiction or difficulty with substance abuse" />
                                                </FormGroup>
                                                <FormGroup aria-label="help" name="gender1" >
                                                    <FormControlLabel control={<GreenCheckbox checked={info?.reason.includes("I want to gain self confidence")} value="I want to gain self confidence" onChange={handleCheckbox} />} label="I want to gain self confidence" />
                                                </FormGroup>
                                                <FormGroup aria-label="help" name="gender1" >
                                                    <FormControlLabel control={<GreenCheckbox checked={info?.reason.includes("I cannot find purpose and meaning in my life")} value="I cannot find purpose and meaning in my life" onChange={handleCheckbox} />} label="I cannot find purpose and meaning in my life" />
                                                </FormGroup>
                                                <FormGroup aria-label="help" name="gender1" >
                                                    <FormControlLabel control={<GreenCheckbox checked={info?.reason.includes("I feel anxious and overwhelmed")} value="I feel anxious and overwhelmed" onChange={handleCheckbox} />} label="I feel anxious and overwhelmed" />
                                                </FormGroup>
                                                <FormGroup aria-label="help" name="gender1" >
                                                    <FormControlLabel control={<GreenCheckbox checked={info?.reason.includes("I am having difficulty in my relationship")} value="I am having difficulty in my relationship" onChange={handleCheckbox} />} label="I am having difficulty in my relationship" />
                                                </FormGroup>
                                                <FormGroup aria-label="help" name="gender1" >
                                                    <FormControlLabel control={<GreenCheckbox checked={info?.reason.includes("Just exploring")} value="Just exploring" onChange={handleCheckbox} />} label="Just exploring" />
                                                </FormGroup>
                                                <FormGroup aria-label="help" name="gender1" >
                                                    <FormControlLabel control={<GreenCheckbox checked={info?.reason.includes("Something else")} value="Something else" onChange={handleCheckbox} />} label="Something else" />
                                                </FormGroup>

                                            </FormControl>

                                            {
                                                <div style={{ textAlign: "center" }}><Button onClick={handlePageTwo} variant="contained" style={{ backgroundColor: "#31C75A", color: "#fff", width: "150px", padding: "10px", borderRadius: "8px" }} >Next</Button></div>
                                            }
                                            {
                                                nextError && <p style={{ color: "red", textAlign: "center" }}>{nextError}</p>
                                            }
                                        </div>
                                    </ScrollToTop>
                                }

                                {
                                    page === 3 ? <Box>
                                        <Grid container component="main" sx={{
                                            boxShadow: '0px 4px 48px rgba(0, 0, 0, 0.25)',
                                            borderRadius: '50px',
                                        }}>
                                            {/* ======>FIRST PART<======== */}
                                            <Grid className='left-signIn'

                                                item
                                                xs={12} md={6}
                                                sx={{
                                                    background: `url(${backgroundImage})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundColor: '#27583A',
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                <Box
                                                    sx={{

                                                        textAlign: 'center',
                                                        height: '70vh',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'

                                                    }}
                                                >
                                                    <Box>
                                                        <CardMedia
                                                            component="img"
                                                            alt="sign in"
                                                            image={signInImage}
                                                        />


                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ======>SECOND PART<======== */}
                                            <Grid className='right-signIn' item xs={12} md={6}>



                                                <form style={{ marginBottom: 0 }} onSubmit={handleLoginSubmit} >
                                                    <h2>Sign Up</h2>

                                                    <WhiteBorderTextField
                                                        sx={{ width: '75%', m: 1 }}
                                                        id="standard-basic"
                                                        label="Your Name"
                                                        name='name'
                                                        type='text'
                                                        required
                                                        onChange={e => { CheckName(e.target.value); (e.target.value).length > 99 && setIssue("Maximum length exceeded. Name length cannot be more than 100 chracters") }}
                                                        onBlur={handleOnBlur}
                                                        inputProps={{ maxLength: 100 }}
                                                        variant="outlined"
                                                    />

                                                    <WhiteBorderTextField
                                                        sx={{ width: '75%', m: 1 }}
                                                        id="standard-basic"
                                                        label="Your Email"
                                                        name='email'
                                                        type='email'
                                                        required
                                                        onChange={(e) => ValidateEmail(e.target.value)}
                                                        onBlur={handleOnBlur}
                                                        variant="outlined" />
                                                    <WhiteBorderTextField
                                                        sx={{ width: '75%', m: 1 }}
                                                        id="standard-password-input"
                                                        label="Your Password"
                                                        type="password"
                                                        name='password'
                                                        required
                                                        onChange={(e) => CheckPassword(e.target.value)}
                                                        onBlur={handleOnBlur}
                                                        autoComplete="current-password"
                                                        variant="outlined"
                                                    />
                                                    <WhiteBorderTextField
                                                        sx={{ width: '75%', m: 1 }}
                                                        id="standard-password-input"
                                                        label="Re-type Your Password"
                                                        type="password"
                                                        name='password2'
                                                        required
                                                        onBlur={handleOnBlur}
                                                        autoComplete="current-password"
                                                        variant="outlined"
                                                    />
                                                    {
                                                        isLoading && <div>
                                                            <CircularProgress />
                                                        </div>
                                                    }
                                                    <br />

                                                    {!emailVerified || issue ? <small style={{ color: 'red' }}>{issue}</small> : <></>}
                                                    <br />

                                                    {error && <small style={{ color: 'red' }}> {error} </small>}
                                                    <br />
                                                    {
                                                        !isLoading && <Button className='signin-btn' type='submit' variant="contained" style={{ marginTop: '1rem' }}>Sign Up</Button>
                                                    }
                                                    <div>
                                                        <p style={{ margin: "10px 0 " }}>Already Registered? Please <Link to="/signin" style={{ color: '#31c75a' }}>signin</Link></p>

                                                        <Link style={{ textDecoration: 'none', color: 'white' }} to='/signin'> <Button sx={{ color: '#000', borderRadius: '50px', padding: "8px 30px" }} color="inherit" variant='outlined'>SIGN IN</Button></Link>
                                                    </div>
                                                    {
                                                        !user?.email ? <div className='google_login' onClick={() => handleSignInWithGoogle("patient")}>
                                                            <img src={googleIcon} alt="icon" />
                                                            <p>Sign In With Google</p>
                                                        </div> : <div className='google_login' onClick={() => handleSignOutWithGoogle()}>
                                                            <img src={googleIcon} alt="icon" />
                                                            <p>Sign Out From Google</p>
                                                        </div>
                                                    }

                                                </form>
                                            </Grid>
                                        </Grid>
                                    </Box> : null
                                }

                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container >
            <Footer />
        </>

            : <SuccessfulSignup greet="created" />

    );
};

export default SignUpChat;