import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import googleIcon from "./../../assets/images/googleIcon.png";

import { Container } from "@mui/system";
import { CircularProgress } from "@mui/material";
import "../SignIn/SignIn.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useLogin } from "../../hooks/useLogin";
import Footer from "../../Shared/Footer/Footer";
import SuccessfulLoginPsychologist from "./SuccessfulLoginPsychologist";
import { useForgotPassword } from "../../hooks/useForgotPassword";
import ErrorAlert from "../../Shared/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../Shared/SuccessAlert/SuccessAlert";
import { useSignInWithGoogle } from "../../hooks/useSignInWithGoogle";
import LoginImg from "../../assets/images/sign_in_girl.svg";
import Paper from "@mui/material/Paper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styled from "styled-components";
import { psychologistSigninPageEvent } from "../../utils/FacebookPixelEvent";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: #31c75a;
    border-color: #31c75a;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #31c75a;
    }
  }
`;

export default function PsychologistSignIn() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Psychologist sign in`,
    });
  }, []);
  const [loginData, setLoginData] = useState({});
  let [issue, setIssue] = useState(null);
  const [emailValidate, setEmailValidate] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const { user } = useAuth();
  const [userObj, setUserObj] = useState({});
  const [isValid, setIsValid] = useState("");
  const { Login, setError, error, isLoading } = useLogin();
  const {
    sendPasswordReset,
    error: forgotError,
    success,
  } = useForgotPassword();
  // let [isProvidedDetail, setIsProvidedDetail] = useState(false);

  const [forgotPage, setForgotPage] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");

  const [showPass, setShowPass] = useState(false);

  const {
    handleSignInWithGoogle,
    handleSignOutWithGoogle,
    error: googleError,
  } = useSignInWithGoogle("psychologist");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://api.thepsycure.com/users/${loginData.email}`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setUserObj(data);
          setIsValid("");
        }
      })
      .catch((err) => {
        if (err) {
          setUserObj({});
        }
      });
  }, [loginData.email]);

  useEffect(() => {
    psychologistSigninPageEvent();
  }, []);

  function ValidateEmail(email) {
    if (
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailValidate(true);
      setEmailVerified(true);
      setError(null);
      setIssue(false);
      return true;
    }
    setEmailValidate(false);
    setEmailVerified(false);
    setIssue("Please enter a valid email address.");
    return false;
  }

  const handleOnBlur = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (userObj?.role === "psychologist") {
      Login(
        loginData.email,
        loginData.password,
        location,
        navigate,
        "psychologist"
      );
    } else {
      setError("We could not find any psychologist with this email.");
    }
  };

  const handleForgot = (e) => {
    e.preventDefault();
    sendPasswordReset(forgotEmail);
  };

  return !user?.email ? (
    <>
      <Header />
      <Container sx={{ my: 15 }}>
        <h2
          style={{ textAlign: "center" }}
          variant="h4"
          gutterBottom
          component="div"
        >
          Sign In as a Psychologist
        </h2>
        <hr
          style={{
            display: "block",
            width: "10%",
            height: "3px",
            backgroundColor: "#31C75A",
            border: 0,
          }}
        />
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* ========> LEFT SIGH IN<=========== */}

            <Grid item xs={12} md={6}>
              <img
                style={{ width: "100%", height: "auto" }}
                src={LoginImg}
                alt="Contact-img"
              />
            </Grid>

            {/* ========> RIGHT SIGH IN<=========== */}
            <Grid item xs={12} md={6}>
              <Paper
                elevation={12}
                style={{ backgroundColor: "#F4F8F5" }}
                sx={{ textAlign: "center", py: 5, borderRadius: 5 }}
              >
                {
                  !forgotPage ? (
                    // <Grid className='right-signIn' item xs={12} md={6}>

                    <form onSubmit={handleLoginSubmit}>
                      <h2>Sign In as a Psychologist</h2>
                      <WhiteBorderTextField
                        sx={{ width: "75%", m: 1 }}
                        id="standard-basic"
                        label="Your Email"
                        name="email"
                        type="email"
                        required
                        onChange={(e) => {
                          setError(null);
                          ValidateEmail(e.target.value);
                        }}
                        onBlur={handleOnBlur}
                        variant="outlined"
                      />

                      <div className="pass_div">
                        <WhiteBorderTextField
                          sx={{ width: "75%", m: 1 }}
                          id="standard-password-input"
                          label="Your Password"
                          type={showPass ? "text" : "password"}
                          name="password"
                          required
                          onChange={(e) => setError(null)}
                          onBlur={handleOnBlur}
                          autoComplete="current-password"
                          variant="outlined"
                        />
                        {showPass ? (
                          <RemoveRedEyeIcon
                            onClick={() => setShowPass(!showPass)}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => setShowPass(!showPass)}
                          />
                        )}
                      </div>

                      <p
                        style={{
                          width: "75%",
                          margin: "0 auto",
                          textAlign: "left",
                          cursor: "pointer",
                        }}
                        onClick={() => setForgotPage(!forgotPage)}
                      >
                        Forgot Password?
                      </p>

                      {isValid && (
                        <small style={{ color: "coral" }}>{isValid}</small>
                      )}
                      {!emailVerified || issue ? (
                        <small style={{ color: "coral" }}>{issue}</small>
                      ) : (
                        <></>
                      )}
                      {error && (
                        <small style={{ color: "coral" }}>{error}</small>
                      )}
                      <br />

                      {isLoading && (
                        <div>
                          <CircularProgress />
                        </div>
                      )}

                      {!isLoading && (
                        <Button
                          style={{ marginTop: "1rem" }}
                          className="signin-btn"
                          type="submit"
                          variant="contained"
                        >
                          Sign In
                        </Button>
                      )}

                      <div>
                        <p style={{ margin: "15px 0" }}>
                          New User? &nbsp;
                          <Link
                            style={{
                              color: "#31c75a",
                              textTransform: "uppercase",
                            }}
                            to="/psychologist-signup"
                          >
                            Sign Up
                          </Link>
                        </p>
                      </div>

                      <div
                        className="google_login"
                        onClick={() => handleSignInWithGoogle("psychologist")}
                      >
                        <img src={googleIcon} alt="icon" />
                        <p>Sign In With Google</p>
                      </div>
                    </form>
                  ) : (
                    // </Grid >
                    // <Grid className='right-signIn' item xs={12} md={6}>
                    <form onSubmit={handleForgot}>
                      <h2>Your Email</h2>
                      <WhiteBorderTextField
                        sx={{ width: "75%", m: 1 }}
                        variant="outlined"
                        style={{ visibility: "hidden" }}
                      />
                      <WhiteBorderTextField
                        sx={{ width: "75%", m: 1 }}
                        id="standard-basic"
                        label="Your Email"
                        name="email"
                        type="email"
                        required
                        onChange={(e) => setForgotEmail(e.target.value)}
                        variant="outlined"
                      />

                      <p
                        style={{
                          width: "75%",
                          margin: "0 auto",
                          textAlign: "left",
                          cursor: "pointer",
                        }}
                        onClick={() => setForgotPage(!forgotPage)}
                      >
                        Signin Page?
                      </p>

                      {success ? <SuccessAlert>{success}</SuccessAlert> : ""}
                      {forgotError ? (
                        <ErrorAlert>{forgotError}</ErrorAlert>
                      ) : (
                        ""
                      )}
                      {
                        <Button
                          className="signin-btn"
                          type="submit"
                          variant="contained"
                          style={{ marginTop: "1rem" }}
                        >
                          Submit
                        </Button>
                      }
                    </form>
                  )
                  // </Grid >
                }
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  ) : (
    <SuccessfulLoginPsychologist greet="login" />
  );
}
