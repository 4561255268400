import React, { useEffect, useState } from "react";

import ReactGA from "react-ga4";

import { Box, Container, Grid, Typography } from "@mui/material";
import "./DonationPayment.css";
import useAuth from "../../hooks/useAuth.js";
import Header from "../../Shared/Header/Header.js";
import axios from "axios";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
const DonationPayment = () => {
  const [donation, setDonation] = useState(null);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Donation`,
    });
    axios
      .get("https://api.thepsycure.com/total-donation")
      .then((data) => setDonation(data.data))
      .catch((err) => console.log(err));
  }, []);
  const { user } = useAuth();
  // console.log(donation);
  const [userDetails, setUserDetails] = useState();
  const presetPrice = [325, 650, 975];
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [others, setOthers] = useState(false);
  const [otherAmount, setOtherAmount] = useState();
  const [error, setError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <Header></Header>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // gap: "1rem",
          }}
        >
          <div style={{ maxWidth: "500px" }}>
            <h1 style={{ margin: "0", color: "#31C75A", textAlign: "center" }}>
              {/* Help us finding  */}
              Total Donated:
              <CountUp
                end={donation}
                redraw={true}
                duration={1}
                className={`countup-number ${
                  isVisible ? "countup-visible" : ""
                }`}
                useEasing={true}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor
                    onChange={(visible) => {
                      if (visible) {
                        start();
                        setIsVisible(true);
                      }
                    }}
                    delayedCall
                  >
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </h1>
            <h2 style={{ margin: "0", color: "#31C75A" }}>
              {/* Help us finding  */}
              Art of Resilience : MH for Flood Affected
            </h2>
            <p style={{ margin: "0" }}>
              Looking for a way to improve your mental well-being or give
              special someone the gift card of self-care? Look no further than
              Psycure's e-gift card offer. Our e-gift card provides access to a
              professional counseling session with one of our certified
              therapists, as well as a guided meditation and stress management
              session.
            </p>
            <p>
              To purchase your e-gift card, simply fill out this form. Once you
              submit the form, we will send you the e-gift card and further
              details on how to avail our services
            </p>
            <p>
              Thank you for choosing Psycure to support your mental health
              journey.
            </p>
            <p>[All your information will be kept confidential]</p>
          </div>
        </div>
        <form
          action="https://api.thepsycure.com/donation-payment"
          method="post"
          className="payment_form"
          style={{ maxWidth: "500px", marginTop: "0px" }}
        >
          <div>
            {/* name */}
            <div
              style={{ width: "100%", margin: "20px 0" }}
              className="input_container billing-info"
            >
              <label className="phone-label" for="name">
                Name
              </label>
              <input
                // required
                name="cus_name"
                id="name"
                defaultValue={user?.displayName}
                className="phone-input"
                style={{ width: "100%" }}
              />
            </div>
            {/* email */}
            <div
              className="input_container billing-info"
              style={{ width: "100%", margin: "20px 0" }}
            >
              <label className="phone-label" for="email">
                Email*
              </label>
              <input
                type="email"
                name="cus_email"
                id="email"
                className="phone-input"
                required
                style={{ width: "100%" }}
              />
            </div>
            {/* contact number */}
            <div
              className="input_container billing-info"
              style={{ margin: "20px 0", width: "100%" }}
            >
              <label className="phone-label" for="phone">
                Phone
              </label>
              <input
                placeholder="Ex. 0184542125"
                className="phone-input"
                // required
                id="phone"
                name="cus_phone"
                minLength={11}
                maxLength={13}
              />
            </div>
            <div
              className="input_container billing-info"
              style={{ margin: "20px 0", width: "100%" }}
            >
              <label className="phone-label" for="phone">
                Amount*
              </label>
              <Box container component="div" sx={{ display: "flex" }}>
                <Box sx={{ display: "flex" }}>
                  {presetPrice?.map((preset) => (
                    <Box
                      className="preset"
                      onClick={() => {
                        if (others) {
                          setOthers(false);
                        }
                        setSelectedPrice(preset);
                      }}
                      item
                      sx={{
                        width: "100px",
                        border: "1px solid #d9d9d9",
                        textAlign: "center",
                        height: "40px",
                        borderRadius: "7px",
                        marginRight: "10px",
                        background: selectedPrice == preset && "#36ca5a",
                        transition: "all",
                        transitionDuration: "0.3s",
                      }}
                    >
                      <Typography
                        sx={{
                          lineHeight: "40px",
                          // color: selectedPrice == preset && "white",
                        }}
                        className="preset-text"
                      >
                        {preset}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box
                  onClick={() => {
                    if (selectedPrice) {
                      setSelectedPrice(null);
                    }
                    setOthers(true);
                  }}
                  sx={{
                    width: "100px",
                    border: "1px solid #d9d9d9",
                    textAlign: "center",
                    height: "40px",
                    borderRadius: "7px",
                    marginRight: "10px",
                    background: others && "#36ca5a",
                    transition: "all",
                    transitionDuration: "0.3s",
                  }}
                  className="preset"
                >
                  <Typography
                    className="preset-text"
                    sx={{ lineHeight: "40px" }}
                  >
                    Others
                  </Typography>
                </Box>
              </Box>
            </div>
            {/* amount if price is selected */}
            {selectedPrice && (
              <div
                className="input_container billing-info"
                style={{ margin: "20px 0", width: "100%", display: "none" }}
              >
                <label className="phone-label" for="phone">
                  Amount
                </label>
                <input
                  placeholder="Ex. 0184542125"
                  className="phone-input"
                  required
                  id="amount"
                  name="amount"
                  value={selectedPrice}
                />
              </div>
            )}

            {/* amount if price is not selected */}
            {others && (
              <div
                className="input_container billing-info"
                style={{ margin: "20px 0", width: "100%", display: "" }}
              >
                <input
                  type="number"
                  placeholder="Ex. 100"
                  className="phone-input"
                  required
                  id="amount"
                  name="amount"
                  onChange={(e) => setOtherAmount(e.target.value)}
                  value={otherAmount}
                />
              </div>
            )}
            {/*  <div>
              {error && (
                <Typography color="red" sx={{ marginBottom: "10px" }}>
                  {error}
                </Typography>
              )}
            </div> */}
            <div>
              <button
                type="submit"
                className="pay-button"
                style={{ width: "100%" }}
                /*  onClick={() => {
                  if (!others && !selectedPrice) {
                    setError("Please donate an amount");
                  } else {
                    setError(false);
                  }
                }} */
                disabled={!selectedPrice && !others && true}
              >
                Pay
              </button>
            </div>
          </div>

          {/* someone */}
        </form>
      </Container>
    </>
  );
};

export default DonationPayment;
