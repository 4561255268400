import { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import useAuth from "../../../hooks/useAuth";
import ErrorAlert from "../../../Shared/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../../Shared/SuccessAlert/SuccessAlert";
import { Container } from "@mui/system";
import paymentImg from "../../../assets/images/dashboard/payment.png";
import Footer from "../../../Shared/Footer/Footer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6F1EC",
    color: "#000",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E6F1EC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PsychologistPayment() {
  let [psychologist, setPsychologist] = useState({});
  let [success, setSuccess] = useState("");
  let [error, setError] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { user } = useAuth();
  useEffect(() => {
    fetch(`https://api.thepsycure.com/psychologists/${user?.email}`)
      .then((res) => res.json())
      .then((data) => setPsychologist(data));
  }, [success]);

  const handlePaymentRequest = async (e) => {
    let updatedObj = { ...psychologist, paymentRequest: true };

    if (
      !psychologist?.payment?.some(
        (paymentDetail) => paymentDetail?.isDisbursed
      )
    ) {
      setError("you have nothing to dispatch");
      return;
    }

    fetch(`https://api.thepsycure.com/psychologist/${user?.email}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ updatedObj }),
    })
      .then((res) => res.json())
      .then((result) => {
        const data = result;
        setSuccess("Payment request sent");
        setError("");
      })
      .catch((err) => {
        setSuccess("");
        setError("Could not send payment request.");
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess("");
      setError("");
    }, 3000);
  }, [success, error]);

  let totalPaymnet = psychologist?.payment?.reduce(
    (acc, cur) => (!cur?.isDisbursed ? acc + Number(cur?.ammount) : 0),
    0
  );
  return (
    <>
      <Container>
        <div style={{ textAlign: "center" }}>
          <h2>{psychologist?.name} - Payment Details Page</h2>
          <div>
            <h2>All Sessions</h2>
            <Grid item xs={12} md={6} component={Paper}>
              <TableContainer
                className="table-container"
                sx={{ maxHeight: 440, backgroundColor: "#E6F1EC" }}
              >
                <Table aria-label="customized table">
                  <TableHead style={{ marginBottom: "2rem" }}>
                    <TableRow>
                      <StyledTableCell>Session Id</StyledTableCell>
                      <StyledTableCell>
                        <strong>Session Date</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <strong>Patient Email</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <strong>Doctor Email</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <strong>Amount</strong>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {psychologist?.payment
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) =>
                        !row?.isDisbursed ? (
                          <StyledTableRow>
                            <StyledTableCell className="table_center">
                              {row?.id}
                            </StyledTableCell>
                            <StyledTableCell className="table_center">
                              {row?.date}
                            </StyledTableCell>
                            <StyledTableCell className="table_center">
                              {row?.patientEmail}
                            </StyledTableCell>
                            <StyledTableCell className="table_center">
                              {row?.doctorEmail}
                            </StyledTableCell>
                            <StyledTableCell className="table_center">
                              {row?.ammount}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          ""
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={psychologist?.payment?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
            <p>Total payment: {totalPaymnet}</p>
            {!psychologist?.paymentRequest ? (
              <Button
                onClick={handlePaymentRequest}
                style={{ backgroundColor: "#31C75A", color: "#fff" }}
              >
                Request Payment
              </Button>
            ) : (
              <p>
                we are analyzing your payment request. You will get your payment
                within 5 working days. Thanks
              </p>
            )}
          </div>
          <div className="img_container">
            <img src={paymentImg} />
          </div>
          {success && <SuccessAlert>{success}</SuccessAlert>}
          {error && <ErrorAlert>{error}</ErrorAlert>}
        </div>
      </Container>
      <Footer />
    </>
  );
}
