import React, { useEffect } from "react";

import BookSession from "../BookSession/BookSession";
import "./SelectionOfDoctors.css";

import ReactGA from "react-ga4";

const SelectionOfDoctors = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Choose psychologist from user dashboard`,
    });
  }, []);
  return (
    <>
      <div className="SelectionOfDoctors">
        <BookSession></BookSession>
      </div>
    </>
  );
};

export default SelectionOfDoctors;
