import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import { Container } from "@mui/system";
import { Box, CircularProgress } from "@mui/material";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";

import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import "./SignUp.css";
import { useSignup } from "../../hooks/useSignup";
import useToken from "../../hooks/useToken";
import SuccessfulSignup from "./SuccessfulSignup";
// import logoUpload from './../../assets/images/logoUpload-icon.png'
// import ImageUploadSuccess from './../../assets/images/imageUploadSuccess.png';
import googleIcon from "./../../assets/images/googleIcon.png";
import Footer from "../../Shared/Footer/Footer";
// import { useSignInWithGoogle } from '../../hooks/useSignInWithGoogle';
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ScrollToTop from "../../Shared/ScrollToTop/ScrollToTop";
import { useSignInWithGooglePatient } from "../../hooks/useSignInWithGooglePatient";
import LoginImg from "../../assets/images/sign_up_boy.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Paper from "@mui/material/Paper";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

import styled from "styled-components";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: #31c75a;
    border-color: #31c75a;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #31c75a;
    }
  }
`;

const GreenRadio = withStyles({
  root: {
    color: green[400],
    borderRadius: "0px",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    borderRadius: "0px",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SignUp = () => {
  const [loginData, setLoginData] = useState({});
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Sign Up Page`,
    });
  }, []);
  let [issue, setIssue] = useState(null);
  let [nextError, setNextError] = useState(null);
  let [page, setPage] = useState(1);
  let [nextPageOne, setNextPageOne] = useState(false);
  let [nextPageTwo, setNextPageTwo] = useState(false);
  let [info, setInfo] = useState({
    gender: "",
    age: "",
    relationshipStatus: "",
    therapyBefore: "",
    preferences: "",
    reason: [],
  });

  let [pageOneAsterisk, setPageOneAsterisk] = useState(true);
  let [pageTwoAsterisk, setPageTwoAsterisk] = useState(true);

  const [emailValidate, setEmailValidate] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  // const [token, setToken] = useState("");
  const navigate = useNavigate("/");
  const { signUp, error, isLoading } = useSignup();
  const { user } = useAuth();
  const [token] = useToken(user);
  const {
    handleSignInWithGoogle,
    handleSignOutWithGoogle,
    error: googleError,
  } = useSignInWithGooglePatient("patient");

  const location = useLocation();

  const handlePopState = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
  };

  const handlePageOne = () => {
    if (
      info.gender !== "" &&
      info.age !== "" &&
      info.preferences !== "" &&
      info.relationshipStatus !== "" &&
      info.therapyBefore !== ""
    ) {
      if (isNaN(info.age)) {
        setNextError("Your Age should be only in digits (eg. 24)");
        setNextPageOne(true);
      } else if (parseInt(info.age) <= 0) {
        setNextError("Age must be greater than Zero!");
        setNextPageOne(true);
      } else {
        setPage(2);
        setNextError("");
      }
    } else {
      setNextError("Please fill all required field");
      setPageOneAsterisk(false);
      setNextPageOne(true);
      return false;
    }
  };

  const handlePageTwo = () => {
    if (
      info.gender !== "" &&
      info.age !== "" &&
      info.reason.length > 0 &&
      info.preferences !== "" &&
      info.relationshipStatus !== "" &&
      info.therapyBefore !== ""
    ) {
      setPage(3);
      setNextError("");
    } else {
      setNextError("Please fill the all required field");
      setPageTwoAsterisk(false);
      setNextPageTwo(true);
    }
  };

  function CheckName(inputtxt) {
    var name = /^([a-zA-Z0-9\s,'-*]).{0,100}$/;
    if (inputtxt.match(name)) {
      setIssue(false);
      setEmailVerified(true);
      return true;
    } else {
      setIssue("Name length cannot be less than 1 chracter");
      setEmailVerified(false);
      return false;
    }
  }
  const [passwordCheckingIssue, setPasswordCheckingIssue] = useState(null);
  function CheckPassword(inputtxt) {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,20}$/;
    if (inputtxt.match(passw)) {
      setPasswordCheckingIssue(false);
      setEmailVerified(true);
      return true;
    } else {
      setPasswordCheckingIssue(true);
      setIssue(
        ' "Please set a strong password(atleast 7 characters, Uppercase, Lowercase, Symbols, Numbers)"'
      );
      setEmailVerified(false);
      return false;
    }
  }

  function ValidateEmail(email) {
    if (
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailValidate(true);
      setEmailVerified(true);
      setIssue(false);
      return true;
    }
    setEmailValidate(false);
    setEmailVerified(false);
    setIssue("Please enter a valid email address");
    return false;
  }

  const handleOnBlur = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (loginData.password !== loginData.password2) {
      setIssue("Your password did not match");
      return;
    }
    if (passwordCheckingIssue) {
      setIssue(
        ' "Please set a strong password(atleast 7 characters, Uppercase, Lowercase, Symbols, Numbers)"'
      );
      return;
    }
    // this passwordchecking was not there
    setIssue(null);
    signUp(
      loginData.email,
      loginData.password,
      loginData.name,
      navigate,
      "patient",
      info
    );
  };

  const handleCheckbox = (e) => {
    if (!info?.reason.includes(e.target.value)) {
      setInfo({ ...info, reason: [...info.reason, e.target.value] });
    }
  };

  return !user?.email ? (
    <>
      <Header />
      <Container sx={{ my: 15 }}>
        {/* <Container>
                    <Container>
                        <Container> */}
        {page === 1 || page === 2 ? (
          <div className="back_container">
            <KeyboardDoubleArrowLeftIcon />
            <span onClick={() => handlePopState()}>Go Back</span>
          </div>
        ) : null}
        {page === 1 || page === 2 ? (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {page === 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                <div style={{ maxWidth: "500px" }}>
                  <h2 style={{ margin: "0", color: "#31C75A" }}>
                    {/* Help us finding  */}
                    Find the right therapist for you
                  </h2>
                  <p style={{ margin: "0" }}>
                    Please complete the short questionnaire to give some
                    background information about you and the issues you'd like
                    to address in therapy. It would assist to match you with the
                    most suitable therapist
                  </p>
                </div>
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    style={{ fontSize: "20px", color: "#31C75A" }}
                  >
                    What is your gender identity? {pageOneAsterisk ? "*" : ""}{" "}
                    {nextPageOne && info.gender == "" ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : (
                      ""
                    )}
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    onChange={(e) =>
                      setInfo({ ...info, gender: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="female"
                      control={<GreenRadio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="male"
                      control={<GreenRadio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="others"
                      control={<GreenRadio />}
                      label="Others"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    style={{ fontSize: "20px", color: "#31C75A" }}
                  >
                    How old are you? {pageOneAsterisk ? "*" : ""}{" "}
                    {nextPageOne && info.age == "" ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : (
                      ""
                    )}
                  </FormLabel>
                  <TextField
                    placeholder="(Only Numbers)"
                    onChange={(e) => setInfo({ ...info, age: e.target.value })}
                    type="text"
                    variant="standard"
                  />
                </FormControl>

                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    style={{ fontSize: "20px", color: "#31C75A" }}
                  >
                    What is your relationship status?{" "}
                    {pageOneAsterisk ? "*" : ""}{" "}
                    {nextPageOne && info.relationshipStatus == "" ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : (
                      ""
                    )}
                  </FormLabel>
                  <RadioGroup
                    aria-label="relation"
                    name="relation"
                    onChange={(e) =>
                      setInfo({ ...info, relationshipStatus: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="Single"
                      control={<GreenRadio />}
                      label="Single"
                    />
                    <FormControlLabel
                      value="In a relationship"
                      control={<GreenRadio />}
                      label="In a relationship"
                    />
                    <FormControlLabel
                      value="Married"
                      control={<GreenRadio />}
                      label="Married"
                    />
                    <FormControlLabel
                      value="Divorced"
                      control={<GreenRadio />}
                      label="Divorced"
                    />
                    <FormControlLabel
                      value="Widowed"
                      control={<GreenRadio />}
                      label="Widowed"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<GreenRadio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    style={{ fontSize: "20px", color: "#31C75A" }}
                  >
                    Have you ever been in therapy before?{" "}
                    {pageOneAsterisk ? "*" : ""}{" "}
                    {nextPageOne && info.therapyBefore == "" ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : (
                      ""
                    )}
                  </FormLabel>
                  <RadioGroup
                    aria-label="therapi"
                    name="therapi"
                    onChange={(e) =>
                      setInfo({ ...info, therapyBefore: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="No"
                      control={<GreenRadio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="Yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    style={{ fontSize: "20px", color: "#31C75A" }}
                  >
                    Are there any specific preferences for your therapist?{" "}
                    {pageOneAsterisk ? "*" : ""}{" "}
                    {nextPageOne && info.preferences == "" ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : (
                      ""
                    )}
                  </FormLabel>
                  <RadioGroup
                    aria-label="preference"
                    name="preference"
                    onChange={(e) =>
                      setInfo({ ...info, preferences: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="Male Therapist"
                      control={<GreenRadio />}
                      label="Male Therapist"
                    />
                    <FormControlLabel
                      value="Female Therapist"
                      control={<GreenRadio />}
                      label="Female Therapist"
                    />
                    <FormControlLabel
                      value="No preference"
                      control={<GreenRadio />}
                      label="No preference"
                    />
                  </RadioGroup>
                </FormControl>

                {
                  <div style={{ textAlign: "center" }}>
                    <Button
                      onClick={handlePageOne}
                      variant="contained"
                      style={{
                        backgroundColor: "#31C75A",
                        color: "#fff",
                        width: "150px",
                        padding: "10px",
                        borderRadius: "8px",
                      }}
                    >
                      Next
                    </Button>
                  </div>
                }
                {nextError && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {nextError}
                  </p>
                )}
              </div>
            )}

            {page === 2 && (
              <ScrollToTop>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                  }}
                >
                  <FormControl component="fieldset" multiple>
                    <FormLabel
                      component="legend"
                      style={{ fontSize: "20px", color: "#31C75A" }}
                    >
                      Tell us why you're looking for help today.{" "}
                      {pageTwoAsterisk ? "*" : ""}{" "}
                      {nextPageTwo && info.reason.length < 1 ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        ""
                      )}
                    </FormLabel>
                    <FormGroup aria-label="help" name="gender1">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={info?.reason.includes(
                              "I am feeling down or depressed"
                            )}
                            value="I am feeling down or depressed"
                            onChange={handleCheckbox}
                          />
                        }
                        label="I am feeling down or depressed"
                      />
                    </FormGroup>
                    <FormGroup aria-label="help" name="gender1">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={info?.reason.includes(
                              "I am dealing with stress at school/ work"
                            )}
                            value="I am dealing with stress at school/ work"
                            onChange={handleCheckbox}
                          />
                        }
                        label="I am dealing with stress at school/ work"
                      />
                    </FormGroup>
                    <FormGroup aria-label="help" name="gender1">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={info?.reason.includes(
                              "I have been trouble having difficulty sleeping"
                            )}
                            value="I have been trouble having difficulty sleeping"
                            onChange={handleCheckbox}
                          />
                        }
                        label="I am feeling down or depressed"
                      />
                    </FormGroup>
                    <FormGroup aria-label="help" name="gender1">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={info?.reason.includes(
                              "I have experienced truma"
                            )}
                            value="I have experienced truma"
                            onChange={handleCheckbox}
                          />
                        }
                        label="I have experienced truma"
                      />
                    </FormGroup>
                    <FormGroup aria-label="help" name="gender1">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={info?.reason.includes(
                              "I am navigation addiction or difficulty with substance abuse"
                            )}
                            value="I am navigation addiction or difficulty with substance abuse"
                            onChange={handleCheckbox}
                          />
                        }
                        label="I am navigation addiction or difficulty with substance abuse"
                      />
                    </FormGroup>
                    <FormGroup aria-label="help" name="gender1">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={info?.reason.includes(
                              "I want to gain self confidence"
                            )}
                            value="I want to gain self confidence"
                            onChange={handleCheckbox}
                          />
                        }
                        label="I want to gain self confidence"
                      />
                    </FormGroup>
                    <FormGroup aria-label="help" name="gender1">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={info?.reason.includes(
                              "I cannot find purpose and meaning in my life"
                            )}
                            value="I cannot find purpose and meaning in my life"
                            onChange={handleCheckbox}
                          />
                        }
                        label="I cannot find purpose and meaning in my life"
                      />
                    </FormGroup>
                    <FormGroup aria-label="help" name="gender1">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={info?.reason.includes(
                              "I feel anxious and overwhelmed"
                            )}
                            value="I feel anxious and overwhelmed"
                            onChange={handleCheckbox}
                          />
                        }
                        label="I feel anxious and overwhelmed"
                      />
                    </FormGroup>
                    <FormGroup aria-label="help" name="gender1">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={info?.reason.includes(
                              "I am having difficulty in my relationship"
                            )}
                            value="I am having difficulty in my relationship"
                            onChange={handleCheckbox}
                          />
                        }
                        label="I am having difficulty in my relationship"
                      />
                    </FormGroup>
                    <FormGroup aria-label="help" name="gender1">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={info?.reason.includes("Just exploring")}
                            value="Just exploring"
                            onChange={handleCheckbox}
                          />
                        }
                        label="Just exploring"
                      />
                    </FormGroup>
                    <FormGroup aria-label="help" name="gender1">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={info?.reason.includes("Something else")}
                            value="Something else"
                            onChange={handleCheckbox}
                          />
                        }
                        label="Something else"
                      />
                    </FormGroup>
                  </FormControl>

                  {
                    <div style={{ textAlign: "center" }}>
                      <Button
                        onClick={handlePageTwo}
                        variant="contained"
                        style={{
                          backgroundColor: "#31C75A",
                          color: "#fff",
                          width: "150px",
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  }
                  {nextError && (
                    <p style={{ color: "red", textAlign: "center" }}>
                      {nextError}
                    </p>
                  )}
                </div>
              </ScrollToTop>
            )}
          </Container>
        ) : null}

        {page === 3 ? (
          <>
            <h2 style={{ textAlign: "center" }} className="headin">
              Sign Up
            </h2>
            <hr
              style={{
                display: "block",
                width: "10%",
                height: "3px",
                backgroundColor: "#31C75A",
                border: 0,
              }}
            />
            <Box style={{ marginTop: "2rem" }}>
              <Grid
                container
                spacing={2}
                sx={{
                  flexGrow: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* ======>FIRST PART<======== */}
                <Grid item xs={12} md={6}>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={LoginImg}
                    alt="Contact-img"
                  />
                </Grid>
                {/* ======>SECOND PART<======== */}
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={12}
                    style={{ backgroundColor: "#F4F8F5" }}
                    sx={{ textAlign: "center", py: 5, borderRadius: 5 }}
                  >
                    <form
                      style={{ marginBottom: 0 }}
                      onSubmit={handleLoginSubmit}
                    >
                      <h2>Sign Up</h2>

                      <WhiteBorderTextField
                        sx={{ width: "75%", m: 1 }}
                        id="standard-basic"
                        label="Your Name"
                        name="name"
                        type="text"
                        required
                        onChange={(e) => {
                          CheckName(e.target.value);
                          e.target.value.length > 99 &&
                            setIssue(
                              "Maximum length exceeded. Name length cannot be more than 100 chracters"
                            );
                        }}
                        onBlur={handleOnBlur}
                        inputProps={{ maxLength: 100 }}
                        variant="outlined"
                      />

                      <WhiteBorderTextField
                        sx={{ width: "75%", m: 1 }}
                        id="standard-basic"
                        label="Your Email"
                        name="email"
                        type="email"
                        required
                        onChange={(e) => ValidateEmail(e.target.value)}
                        onBlur={handleOnBlur}
                        variant="outlined"
                      />

                      <div className="pass_div">
                        <WhiteBorderTextField
                          sx={{ width: "75%", m: 1 }}
                          id="standard-password-input"
                          label="Your Password"
                          type={showPass ? "text" : "password"}
                          name="password"
                          required
                          onChange={(e) => CheckPassword(e.target.value)}
                          onBlur={handleOnBlur}
                          autoComplete="current-password"
                          variant="outlined"
                        />
                        {showPass ? (
                          <RemoveRedEyeIcon
                            onClick={() => setShowPass(!showPass)}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => setShowPass(!showPass)}
                          />
                        )}
                      </div>

                      <div className="pass_div">
                        <WhiteBorderTextField
                          sx={{ width: "75%", m: 1 }}
                          id="standard-password-input"
                          label="Re-type Your Password"
                          type={showConfirmPass ? "text" : "password"}
                          name="password2"
                          required
                          onBlur={handleOnBlur}
                          autoComplete="current-password"
                          variant="outlined"
                        />
                        {showConfirmPass ? (
                          <RemoveRedEyeIcon
                            onClick={() => setShowConfirmPass(!showConfirmPass)}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => setShowConfirmPass(!showConfirmPass)}
                          />
                        )}
                      </div>
                      {isLoading && (
                        <div>
                          <CircularProgress />
                        </div>
                      )}
                      <br />

                      {!emailVerified || issue ? (
                        <div>
                          <small style={{ color: "red" }}>{issue}</small>
                          <br />
                        </div>
                      ) : (
                        <></>
                      )}

                      {error && (
                        <div>
                          <small style={{ color: "red" }}> {error} </small>
                          <br />
                        </div>
                      )}
                      {!isLoading && (
                        <Button
                          className="signin-btn"
                          type="submit"
                          variant="contained"
                          style={{ marginTop: "0" }}
                        >
                          Sign Up
                        </Button>
                      )}
                      <div>
                        <p style={{ margin: "10px 0 " }}>
                          Already Registered? Please{" "}
                          <Link
                            to="/signin"
                            style={{
                              color: "#31c75a",
                              textTransform: "uppercase",
                            }}
                          >
                            sign in
                          </Link>
                        </p>

                        {/* <Link style={{ textDecoration: 'none', color: 'white' }} to='/signin'> <Button sx={{ color: '#000', borderRadius: '50px', padding: "8px 30px" }} color="inherit" variant='outlined'>SIGN IN</Button></Link> */}
                      </div>
                      {!user?.email ? (
                        <div
                          className="google_login"
                          onClick={() => handleSignInWithGoogle("patient")}
                        >
                          <img src={googleIcon} alt="icon" />
                          <p>Sign In With Google</p>
                        </div>
                      ) : (
                        <div
                          className="google_login"
                          onClick={() => handleSignOutWithGoogle()}
                        >
                          <img src={googleIcon} alt="icon" />
                          <p>Sign Out From Google</p>
                        </div>
                      )}
                    </form>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : null}

        {/* </Container>
                        </Container>
                    </Container> */}
      </Container>
      <Footer />
    </>
  ) : (
    <SuccessfulSignup greet="created" />
  );
};

export default SignUp;
