import React, { useEffect } from "react";
import { useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@mui/material/TextField";
import { Container } from "@mui/system";
import Button from "@mui/material/Button";
import ScrollToTop from "../../Shared/ScrollToTop/ScrollToTop";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    borderRadius: "0px",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    borderRadius: "0px",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function UsersJourney() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `User journey before signup procedure`,
    });
  }, []);
  let [page, setPage] = useState(1);
  let [nextError, setNextError] = useState(null);
  let [nextPageOne, setNextPageOne] = useState(false);
  let [nextPageTwo, setNextPageTwo] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate("");

  let [info, setInfo] = useState({
    gender: "",
    age: "",
    relationshipStatus: "",
    therapyBefore: "",
    preferences: "",
    reason: [],
  });

  const handlePageOne = () => {
    if (
      info.gender !== "" &&
      info.age !== "" &&
      info.preferences !== "" &&
      info.relationshipStatus !== "" &&
      info.therapyBefore !== ""
    ) {
      setPage(2);
      setNextError("");
    } else {
      setNextError("Please fill all required field");
      setNextPageOne(true);
    }
  };

  const handlePageTwo = () => {
    if (
      info.gender !== "" &&
      info.age !== "" &&
      info.reason.length > 0 &&
      info.preferences !== "" &&
      info.relationshipStatus !== "" &&
      info.therapyBefore !== ""
    ) {
      let userDetails = {
        name: user?.displayName,
        email: user?.email,
        patientInfo: info,
        role: "patient",
        createdAt: new Date().toISOString().slice(0, 10),
      };

      fetch(`https://api.thepsycure.com/users`, {
        method: "POST",
        body: JSON.stringify(userDetails),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          let d = data;
        });
      setNextError("");
      navigate("/dashboard");
    } else {
      setNextError("Please fill the all required field");
      setNextPageTwo(true);
    }
  };

  const handleCheckbox = (e) => {
    if (!info?.reason.includes(e.target.value)) {
      setInfo({ ...info, reason: [...info.reason, e.target.value] });
    }
  };
  return (
    <>
      <Header />
      <Container style={{ maxWidth: "700px", margin: "10rem auto 2rem" }}>
        {page === 1 && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
          >
            <div style={{ maxWidth: "500px" }}>
              <h2 style={{ margin: "0", color: "#31C75A" }}>
                Help us finding the right therapist for you
              </h2>
              <p style={{ margin: "0" }}>
                Please complete the short questionnaire to give some background
                information about you and the issues you'd like to address in
                therapy. It would assist to match you with the most suitable
                therapist
              </p>
            </div>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: "20px", color: "#31C75A" }}
              >
                What is your gender identity?{" "}
                {nextPageOne && info.gender == "" ? (
                  <span style={{ color: "red" }}>*</span>
                ) : (
                  ""
                )}
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                onChange={(e) => setInfo({ ...info, gender: e.target.value })}
              >
                <FormControlLabel
                  value="female"
                  control={<GreenRadio />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<GreenRadio />}
                  label="Male"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: "20px", color: "#31C75A" }}
              >
                How old are you?{" "}
                {nextPageOne && info.age == "" ? (
                  <span style={{ color: "red" }}>*</span>
                ) : (
                  ""
                )}
              </FormLabel>
              <TextField
                onChange={(e) => setInfo({ ...info, age: e.target.value })}
                type="number"
                variant="standard"
              />
            </FormControl>

            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: "20px", color: "#31C75A" }}
              >
                What is your relationship status?{" "}
                {nextPageOne && info.relationshipStatus == "" ? (
                  <span style={{ color: "red" }}>*</span>
                ) : (
                  ""
                )}
              </FormLabel>
              <RadioGroup
                aria-label="relation"
                name="relation"
                onChange={(e) =>
                  setInfo({ ...info, relationshipStatus: e.target.value })
                }
              >
                <FormControlLabel
                  value="Single"
                  control={<GreenRadio />}
                  label="Single"
                />
                <FormControlLabel
                  value="In a relationship"
                  control={<GreenRadio />}
                  label="In a relationship"
                />
                <FormControlLabel
                  value="Married"
                  control={<GreenRadio />}
                  label="Married"
                />
                <FormControlLabel
                  value="Divorced"
                  control={<GreenRadio />}
                  label="Divorced"
                />
                <FormControlLabel
                  value="Widowed"
                  control={<GreenRadio />}
                  label="Widowed"
                />
                <FormControlLabel
                  value="Other"
                  control={<GreenRadio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: "20px", color: "#31C75A" }}
              >
                Have you ever been in therapy before?{" "}
                {nextPageOne && info.therapyBefore == "" ? (
                  <span style={{ color: "red" }}>*</span>
                ) : (
                  ""
                )}
              </FormLabel>
              <RadioGroup
                aria-label="therapi"
                name="therapi"
                onChange={(e) =>
                  setInfo({ ...info, therapyBefore: e.target.value })
                }
              >
                <FormControlLabel
                  value="No"
                  control={<GreenRadio />}
                  label="No"
                />
                <FormControlLabel
                  value="Yes"
                  control={<GreenRadio />}
                  label="Yes"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: "20px", color: "#31C75A" }}
              >
                Are there any specific preferences for your therapist?{" "}
                {nextPageOne && info.preferences == "" ? (
                  <span style={{ color: "red" }}>*</span>
                ) : (
                  ""
                )}
              </FormLabel>
              <RadioGroup
                aria-label="preference"
                name="preference"
                onChange={(e) =>
                  setInfo({ ...info, preferences: e.target.value })
                }
              >
                <FormControlLabel
                  value="Male Therapist"
                  control={<GreenRadio />}
                  label="Male Therapist"
                />
                <FormControlLabel
                  value="Female Therapist"
                  control={<GreenRadio />}
                  label="Female Therapist"
                />
              </RadioGroup>
            </FormControl>

            {
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={handlePageOne}
                  variant="contained"
                  style={{
                    backgroundColor: "#31C75A",
                    color: "#fff",
                    width: "150px",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  Next
                </Button>
              </div>
            }
            {nextError && (
              <p style={{ color: "red", textAlign: "center" }}>{nextError}</p>
            )}
          </div>
        )}

        {page === 2 && (
          <ScrollToTop>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
            >
              <FormControl component="fieldset" multiple>
                <FormLabel
                  component="legend"
                  style={{ fontSize: "20px", color: "#31C75A" }}
                >
                  Tell us why you're looking for help today.{" "}
                  {nextPageTwo && info.reason.length < 1 ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </FormLabel>
                <FormGroup aria-label="help" name="gender1">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={info?.reason.includes(
                          "I am feeling down or depressed"
                        )}
                        value="I am feeling down or depressed"
                        onChange={handleCheckbox}
                      />
                    }
                    label="I am feeling down or depressed"
                  />
                </FormGroup>
                <FormGroup aria-label="help" name="gender1">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={info?.reason.includes(
                          "I am dealing with stress at school/ work"
                        )}
                        value="I am dealing with stress at school/ work"
                        onChange={handleCheckbox}
                      />
                    }
                    label="I am dealing with stress at school/ work"
                  />
                </FormGroup>
                <FormGroup aria-label="help" name="gender1">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={info?.reason.includes(
                          "I have been trouble having difficulty sleeping"
                        )}
                        value="I have been trouble having difficulty sleeping"
                        onChange={handleCheckbox}
                      />
                    }
                    label="I am feeling down or depressed"
                  />
                </FormGroup>
                <FormGroup aria-label="help" name="gender1">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={info?.reason.includes(
                          "I have experienced truma"
                        )}
                        value="I have experienced truma"
                        onChange={handleCheckbox}
                      />
                    }
                    label="I have experienced truma"
                  />
                </FormGroup>
                <FormGroup aria-label="help" name="gender1">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={info?.reason.includes(
                          "I am navigation addiction or difficulty with substance abuse"
                        )}
                        value="I am navigation addiction or difficulty with substance abuse"
                        onChange={handleCheckbox}
                      />
                    }
                    label="I am navigation addiction or difficulty with substance abuse"
                  />
                </FormGroup>
                <FormGroup aria-label="help" name="gender1">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={info?.reason.includes(
                          "I want to gain self confidence"
                        )}
                        value="I want to gain self confidence"
                        onChange={handleCheckbox}
                      />
                    }
                    label="I want to gain self confidence"
                  />
                </FormGroup>
                <FormGroup aria-label="help" name="gender1">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={info?.reason.includes(
                          "I cannot find purpose and meaning in my life"
                        )}
                        value="I cannot find purpose and meaning in my life"
                        onChange={handleCheckbox}
                      />
                    }
                    label="I cannot find purpose and meaning in my life"
                  />
                </FormGroup>
                <FormGroup aria-label="help" name="gender1">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={info?.reason.includes(
                          "I feel anxious and overwhelmed"
                        )}
                        value="I feel anxious and overwhelmed"
                        onChange={handleCheckbox}
                      />
                    }
                    label="I feel anxious and overwhelmed"
                  />
                </FormGroup>
                <FormGroup aria-label="help" name="gender1">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={info?.reason.includes(
                          "I am having difficulty in my relationship"
                        )}
                        value="I am having difficulty in my relationship"
                        onChange={handleCheckbox}
                      />
                    }
                    label="I am having difficulty in my relationship"
                  />
                </FormGroup>
                <FormGroup aria-label="help" name="gender1">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={info?.reason.includes("Just exploring")}
                        value="Just exploring"
                        onChange={handleCheckbox}
                      />
                    }
                    label="Just exploring"
                  />
                </FormGroup>
                <FormGroup aria-label="help" name="gender1">
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={info?.reason.includes("Something else")}
                        value="Something else"
                        onChange={handleCheckbox}
                      />
                    }
                    label="Something else"
                  />
                </FormGroup>
              </FormControl>

              {
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={handlePageTwo}
                    variant="contained"
                    style={{
                      backgroundColor: "#31C75A",
                      color: "#fff",
                      width: "150px",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              }
              {nextError && (
                <p style={{ color: "red", textAlign: "center" }}>{nextError}</p>
              )}
            </div>
          </ScrollToTop>
        )}
      </Container>
    </>
  );
}
