import { auth } from '../firebase.config'
import { signOut } from "@firebase/auth"
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth'
import { useState } from 'react'

export const useLogout = () => {
    const [error, setError] = useState(null);
    const { dispatch } = useAuth();
    const navigate = useNavigate("");

    const Logout = () => {
        signOut(auth)
            .then(() => {
                dispatch({ type: "LOGOUT" });
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userDetails");
                localStorage.removeItem("patientEmail");
                navigate("/")
            })
            .catch(error => {
                setError(error?.code?.split('auth/',)[1])
            })
            setError(null)
    }

    return { Logout }
}