import { Button } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AllMoods from "./AllMoods";

export default function SessionDetails() {
  let { id } = useParams();
  const [session, setSession] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sessionMoods, setSessionMoods] = useState([]);

  useLayoutEffect(() => {
    fetch("https://api.thepsycure.com/session")
      .then((res) => res.json())
      .then((data) => {
        const foundSession = data.filter((detail) => detail._id === id);
        setIsLoading(false);
        setSession(foundSession[0]);
      });
  }, [id]);

  const handleMoods = async (email) => {
    const response = await fetch("https://api.thepsycure.com/moods");
    const json = await response.json();
    const filteredMoods = json.filter((data) => data.email === email);
    let sorted = filteredMoods.slice().sort(function (a, b) {
      let firstPortion = a.date.split(",")[0];
      let lastPortion = b.date.split(",")[0];
      var dateA = new Date(firstPortion),
        dateB = new Date(lastPortion);
      return dateB - dateA;
    });

    if (response.ok) {
      setSessionMoods(sorted);
    }
  };
  return (
    <div>
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <p>Name: {session?.userDetails?.displayName}</p>
        <Button
          onClick={() => handleMoods(session?.userDetails?.email)}
          variant="contained"
          style={{ backgroundColor: "#31C75A" }}
          sx={{ borderRadius: "25px" }}
        >
          Moods
        </Button>
      </div>

      {sessionMoods.length ? (
        <AllMoods
          moods={sessionMoods}
          name={session?.userDetails?.displayName}
        />
      ) : (
        ""
      )}
    </div>
  );
}
