import React, { useEffect } from "react";
import Header from "../../Shared/Header/Header";
import Footer from "../../Shared/Footer/Footer";
import { Button, Container } from "@mui/material";
import { Link } from "react-router-dom";
import { campaignData } from "./data";
import ReactGA from "react-ga4";

export default function Campaign() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Campaign`,
    });
  }, []);
  return (
    <>
      <Header />
      <Container sx={{ pt: 10 }}>
        <h2 className="headin">Campaigns</h2>
        <hr
          style={{
            display: "block",
            width: "10%",
            height: "3px",
            backgroundColor: "#31C75A",
            border: 0,
          }}
        />
        {/* <Grid sx={{ pt: 5 }} container columns={{ xs: 4, sm: 8, md: 12 }} > */}
        <div className="workshop">
          {campaignData?.map((campaign) => (
            // <Grid item xs={12} sm={12} md={4} style={{ backgroundColor: "#E9FAED", borderRadius: "15px" }}>
            <div style={{ backgroundColor: "#E9FAED", borderRadius: "15px" }}>
              <img
                src={campaign?.campaignImage}
                alt=""
                style={{
                  width: "100%",
                  height: "180px",
                  borderRadius: "15px 15px 0 0",
                }}
              />

              <div style={{ padding: "1rem" }}>
                <div>
                  <div
                    style={{
                      height:
                        campaign?.id === 1 ||
                        campaign?.id === 2 ||
                        campaign?.id === 3
                          ? "250px"
                          : "240px",
                    }}
                  >
                    <h2 style={{ fontSize: "1.2rem" }}>
                      {campaign?.campaignTitle}
                    </h2>
                    <div>
                      <p style={{ fontWeight: "bold" }}>Partners:</p>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "5px",
                        }}
                      >
                        {campaign?.corePartners?.map((partner) => (
                          <p
                            style={{
                              padding: "5px 15px",
                              margin: "0",
                              borderRadius: "100px",
                              color: "#fff",
                              backgroundColor: "#27583A",
                            }}
                          >
                            {partner}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{ fontWeight: "bold" }}>
                      Participants: {campaign?.totalPerticipant}
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      Event Reach: {campaign?.eventReact}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    padding: "1rem 0",
                  }}
                >
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/campaign/${campaign?.id}`}
                  >
                    <Button
                      fullWidth
                      style={{
                        backgroundColor: "#31C75A",
                        padding: "10px",
                        borderRadius: "15px",
                      }}
                      variant="contained"
                    >
                      KNOW MORE
                    </Button>
                  </Link>

                  <a href={campaign?.campaignLink} target="_blank">
                    <Button
                      fullWidth
                      style={{
                        backgroundColor: "#31C75A",
                        padding: "10px",
                        borderRadius: "15px",
                      }}
                      variant="contained"
                    >
                      EVENT LINK
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
      <Footer />
    </>
  );
}
