import { Button } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './PageNotFound.css'

const PageNoteFound = () => {
    return (
        <div>
            <div className='PageNotFound'>
                <div id="notfound">
                    <div class="notfound">
                        <div class="notfound-404">
                            <h3>Oops! Page not found</h3>
                            <h1><span>4</span><span>0</span><span>4</span></h1>
                        </div>
                        <h2>we are sorry, but the page you requested was not found</h2>
                        <NavLink to='/' style={{ textDecoration: 'none', }} ><Button variant='contained' style={{ backgroundColor: '#31C75A', }} sx={{ borderRadius: '25px', px: 3 }} >Go Back</Button></NavLink>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNoteFound;