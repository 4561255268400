import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import Footer from "../../../Shared/Footer/Footer";
import Header from "../../../Shared/Header/Header";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import WorkshopPayment from "./WorkshopPayment";
import useAuth from "../../../hooks/useAuth";
import NotSignedIn from "../../NotSignedIn/NotSignedIn";
import ReactGA from "react-ga4";

export default function WorkshopDetails() {
  const { id } = useParams();
  let [currentWorkshop, setCurrentWorkshop] = useState({});
  let [topWorkshop, setTopWorkshop] = useState([]);
  

  useEffect(() => {
    axios
      .get(`https://api.thepsycure.com/workshop/${id}`)
      .then((data) => setCurrentWorkshop(data.data[0]))
      .catch((err) => console.log(err));
  }, [id]);
  useEffect(() => {
    axios
      .get(`https://api.thepsycure.com/workshop/`)
      .then((data) => setTopWorkshop(data.data))
      .catch((err) => console.log(err));
  }, []);
  const [payment, setPayment] = useState(false);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `${currentWorkshop?.worskshopTitle}`,
    });
  }, [currentWorkshop]);
  return (
    <>
      <Header />
      <Container>
        <Grid sx={{ pt: 15 }} container spacing={8}>
          {/* item grid size: xs={12} md={7} */}
          <Grid item>
            <h2
              className="workshop_details_title"
              style={{ textAlign: "center" }}
            >
              {currentWorkshop?.worskshopTitle}
            </h2>
            <img
              src={currentWorkshop?.workshopImage}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            <p
              style={{
                display: "inline",
                padding: "2px 10px",
                borderRadius: "100px",
                color: "#fff",
                backgroundColor: "#27583A",
                fontSize: "18px",
              }}
            >
              {currentWorkshop?.eventType}
            </p>
            <div>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                Workshop Details:{" "}
              </p>
              <Typography variant="body2" color="text.secondary">
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentWorkshop?.workshopContent,
                  }}
                />
              </Typography>

              <Box sx={{ textAlign: "center", margin: "2% 0" }}>
                <a href="#payment">
                  <Button
                    style={{
                      backgroundColor: "#31C75A",
                      padding: "10px 20px",
                      borderRadius: "100px",
                    }}
                    onClick={() => setPayment(true)}
                    variant="contained"
                  >
                    Register Now
                  </Button>
                </a>
              </Box>
            </div>
          </Grid>
          {/* top workshop gird */}
          {/* <Grid item xs={12} md={5}>
            <h2 style={{ marginTop: "7px", marginBottom: 0 }}>Top Workshops</h2>
            <hr
              style={{
                display: "inline-block",
                width: "30%",
                height: "3px",
                backgroundColor: "#31C75A",
                border: 0,
                textAlign: "left",
              }}
            />
            {topWorkshop?.slice(0, 4)?.map((workshop) => (
              <Grid container spacing={2} sx={{ mt: 5, alignItems: "center" }}>
                <Grid item xs={6}>
                  <img
                    src={workshop?.workshopImage}
                    style={{
                      width: "100%",
    
                    }}
                    alt=""
                  />
                </Grid>
                <Grid item xs={6}>
                  {workshop?.eventType == "online" ? (
                    <p
                      style={{
                        display: "inline-block",
                        padding: "2px 10px",
                        borderRadius: "100px",
                        color: "#fff",
                        backgroundColor: "#27583A",
                        fontSize: "14px",
                        margin: "0",
                      }}
                    >
                      {workshop?.eventType}
                    </p>
                  ) : (
                    <p
                      style={{
                        display: "inline-block",
                        padding: "2px 10px",
                        borderRadius: "100px",
                        color: "#27583A",
                        backgroundColor: "#fff",
                        fontSize: "14px",
                        border: "1px solid #27583A",
                        margin: "0",
                      }}
                    >
                      {workshop?.eventType}
                    </p>
                  )}

                  <p className="worshop_heading">{workshop?.worskshopTitle}</p>
                  <p className="worshop_description">
                    {workshop?.workshopContent?.substring(3, 40)}...
                  </p>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/workshop/${workshop?._id}`}
                  >
                    <button className="workshop_btn">KNOW MORE</button>
                  </Link>
                </Grid>
              </Grid>
            ))}
          </Grid> */}
        </Grid>
        <div
          style={{
            // display: "flex",
            justifyContent: "center",
            margin: "2rem 0 5rem 0",
          }}
        >
          {payment && (
            <div id="payment">
              <WorkshopPayment currentWorkshop={currentWorkshop} />
            </div>
          )}
        </div>
      </Container>
      <Footer />
    </>
  );
}
