
import campaign1 from "../../assets/images/campaign1.jpg";
import campaign2 from "../../assets/images/campaign2.jpg";
import campaign3 from "../../assets/images/campaign3.jpg";
import campaign4 from "../../assets/images/campaign4.jpg";
import violence from "../../assets/images/violence.jpg";
import mentalHealth from "../../assets/images/mentalHealth.jpg";

export const campaignData = [
  {
    id: 1,
    campaignTitle: "Unwind Mind 2022: Photography and Art Competition",
    totalPerticipant: 1000,
    eventReact: ".6M",
    corePartners: [
      "EMK Center",
      "One Denim",
      "Youth Co-Lab supported by UNDP",
      "Citi FOundation",
    ],
    campaignImage: campaign1,
    campaignLink: "https://www.facebook.com/events/700852757936982",
    campaignDescription:
      "Unwind Mind 2022: Photography and Art Competition was as successful as the first event. This was sponsored by EMK Center, One Denim, Youth Co-Lab supported by UNDP & Citi Foundation. Around 1000 people from different parts of Bangladesh participated in the competition and shared their take on mental health through art. Along with huge participation the online reach of this event was .6M which shows how much the subject impacted and resonated with the people.",
  },
  {
    id: 2,
    campaignTitle: "Unwind Mind 2021: Photography and Art Competition",
    totalPerticipant: 700,
    eventReact: ".5M",
    corePartners: ["EMK Center", "Pathao"],
    campaignImage: campaign2,
    campaignLink: "https://www.facebook.com/events/709983319685426",
    campaignDescription:
      "Our first ever campaign emphasising mental health was Unwind Mind 2021: Photography and Art Competition which was organized along with EMK Center and Pathao. The reach of this competition was .5M and around 700 ignited minds participated. This event opened doors for people suffering through mental health issues to come forward and expressed themselves through art without any fear of judgment.",
  },

  {
    id: 3,
    campaignTitle: "Spreading Mental Health Awareness",
    totalPerticipant: 700,
    eventReact: "70K",
    corePartners: ["10MS", "Public Health Foundation"],
    campaignImage: campaign3,
    campaignLink:
      "https://www.facebook.com/events/281509632870389/281509652870387/",
    campaignDescription:
      "Spreading Mental Health Awareness campaign was organised to help the youth know about mental health diseases and as such workshops were conducted in 50 university campus by experts who shared various hacks on how to tackle these diseases in real life. Our partners were 10 Minute School and Public Health Foundation. 1800 students participated in the workshops and the reach was around 70 thousand. These workshops were a need of the hour during the covid.",
  },
  {
    id: 9,
    campaignTitle:
      "World Mental Health Day 2021: Mental Health in an Unequal World",
    totalPerticipant: "140",
    eventReact: "",
    corePartners: ["BYLC", "Rokomari", "The Daily Star"],
    campaignImage: mentalHealth,
    campaignLink:
      "https://www.facebook.com/events/411766797007401/?acontext=%7B%22event_action_history%22%3A[%7B%22surface%22%3A%22page%22%7D]%7D",
    campaignDescription:
      "World Mental Health Day is marked every year on October 10 to raise awareness about mental health around the world and to mobilize efforts to support those experiencing mental health issues. Every year since 2013, the World Health Organization (WHO) has organized a global campaign for World Mental Health Day. The World Federation for Mental Health (WFMH) announced the theme for World Mental Health Day 2022, which is ‘Mental Health in an Unequal World'.  Psycure, in collaboration with Bangladesh Youth Leadership Center ( BYLC) hosted an event on this day which was moderated by Fabia Firoze, Chairperson of Psycure Organization.  The panel was chaired by prominent Mental health figures including  Azharul Islam, PhD, Associate Professor, Department of Counseling Psychology, University of Rajshahi,  Monira Rahman, Country Lead, Mental Health First-Aid Bangladesh & Dr. M. Tasdik Hasa,  Global Mental Health Researcher. Many issues were addressed through the session and current situation of mental health profession and service in Bangladesh was also discussed. The event was covered by The Daily Star.",
  },
  {
    id: 4,
    campaignTitle: `National Mother's Campaign - "মায়ের সাথে, মায়ের পাশে"`,
    totalPerticipant: 300,
    eventReact: ".5M",
    corePartners: ["square"],
    campaignImage: campaign4,
    campaignLink: "https://www.facebook.com/events/1100133903697150",
    campaignDescription:
      "“মায়ের সাথে, মায়ের পাশে” was a campaign by Psycure Organization and Square which was held during the Covid-19 pandemic. The motive of this event to create a platform for people to celebrate their mother and share stories and pictures with their mother’s. The number of participants of this event was 300 and about .5M was the campaign reach. This event was triumphant as it made people comprehend the hard work a mother does and made them appreciate their Moms.",
  },

  {
    id: 8,
    campaignTitle:
      "How to deal with Postpartum Depression and Domestic Violence",
    totalPerticipant: "46",
    eventReact: "",
    corePartners: ["Foundation For Law and Development (FLAD)", "Mom for Mom"],
    campaignImage: violence,
    campaignLink:
      "https://www.facebook.com/events/764298581435497/?acontext=%7B%22event_action_history%22%3A[%7B%22surface%22%3A%22page%22%7D]%7D",
    campaignDescription:
      "In today's world, postpartum mental health is becoming a concern for new mothers and families. After giving birth to a child, the physical and mental changes that a mother faces can be stressful and can cause mental health deterioration. Another concerning issue that harms the well-being of the members of a family is domestic violence. If someone faces these problems, then dealing with these two issues are extremely necessary. Psycure felt the necessity for a workshop on postpartum depression and domestic violence and arranged an offline session on “ How to Deal with Postpartum Depression and Domestic Violence “ on August 6,2022. The workshop was directed by Fawzia Firoze, Advocate, Supreme Court Bangladesh,  Chairperson of FLAD, Mahmuda Mahmuda, Mental Health Professional & Naseem Mahmud,  Gynecologist, United Hospital.  The session was attended by 50 participants.  The participants had a good experience and they were hopeful to use the techniques to reduce their real life problems. ",
  },
];
