import { Box,Container, Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import successIcon from "./../../../assets/images/successIcon.png";


import "./PaymentSuccess.css";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const PaySuccess = () => {
  let [error, setError] = useState("");
  let [success, setSuccess] = useState("");
  let [isUpload, setIsUpload] = useState(false);
  const navigate = useNavigate("");

  let localStoragedata = JSON.parse(localStorage.getItem("selectedDetails"));
  let paymentAmount = JSON.parse(localStorage.getItem("paymentAmount"));

  async function handleSubmit(e) {
    let updatedObj = {
      ...localStoragedata?.selectedPsychologists,
      totalSessions:
        Number(localStoragedata?.selectedPsychologists?.totalSessions) + 1,
    };
    let psychologistEmail = localStoragedata?.selectedPsychologists?.email;

    let sessionObj = {
      ...localStoragedata,
      amount: paymentAmount,
    };

    if (localStoragedata?.selectedPsychologists?.name && !isUpload) {
      const response = await fetch("https://api.thepsycure.com/session", {
        method: "POST",
        body: JSON.stringify(sessionObj),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();

      if (!response.ok) {
        setError("Something Went Wrong");
        setSuccess(true);
      }
      if (response.ok) {
        setSuccess(true);
        setError(null);
        setIsUpload(true);

        fetch(`https://api.thepsycure.com/psychologist/${psychologistEmail}`, {
          method: "PUT",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({ updatedObj }),
        })
          .then((res) => res.json())
          .then((result) => {
            const data = result;
            localStorage.removeItem("selectedDetails");
            setTimeout(function () {
              navigate("/Dashboard");
            }, 5000);
          });
      }

      // Sending Message to psychologist and patient
      let date = new Date();
      let dateOfTime =
        date.toISOString().slice(0, 10) +
        " " +
        date.toLocaleString().split(" ")[1];
      const res = await fetch("https://170.187.237.140/api/MailSend", {
        method: "POST",
        body: JSON.stringify({
          name: localStoragedata?.selectedPsychologists?.name,
          pemail: localStoragedata?.patientEmail,
          semail: psychologistEmail,
          date: dateOfTime,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();

      if (!res.ok) {
        setError("Something Went Wrong");
        setSuccess(true);
      }
    }
  }

  useEffect(() => {
    handleSubmit();
  }, []);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: `Book Session Payment Successful`,
    });
  }, []);
  return (
    <div
      style={{
        backgroundColor: "#EBFCF0",
        paddingBottom: "30px",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container sx={{ mb: 5, mt: 20 }}>
        <Box>
          <Grid
            container
            spacing={12}
            sx={{
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <Paper
                style={{
                  borderRadius: "25px",
                  padding: "3rem",
                  border: "1px solid #31C75A",
                  textAlign: "center",
                }}
                elevation={12}
              >
                <div>
                  <img src={successIcon} alt="" />
                  <h3
                    style={{
                      color: "#65503A",
                      fontWeight: 600,
                      marginTop: "2rem",
                    }}
                  >
                    Your payment was completed successfully!
                  </h3>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper
                style={{
                  borderRadius: "25px",
                  padding: "1rem",
                  border: "1px solid #31C75A",
                }}
                elevation={12}
                className="paymentDetails"
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    className="psy_img"
                    src={localStoragedata?.selectedPsychologists?.image}
                    alt=""
                  />
                  {/* <h2 style={{ fontWeight: 600 }} >Invoice ID 1242</h2> */}
                </div>
                <div style={{ padding: "0 2rem 0 2rem" }}>
                  {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h4>Date Purchased:</h4>
                                        <h4>{new Date().now()}</h4>
                                    </div> */}

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4>Booking Date:</h4>
                    <h4>{localStoragedata?.date}</h4>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4>Psychologist Name:</h4>
                    <h4>{localStoragedata?.selectedPsychologists.name}</h4>
                  </div>
                  <hr />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4>Total Amount:</h4>
                    <h4>৳{paymentAmount}</h4>
                  </div>
                  {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h4>Confirm Your Booking:</h4>
                                        <Button onClick={handleSubmit} style={{
                                            backgroundColor: '#31C75A', marginTop: '1rem', padding: '10px 20px', borderRadius: '15px', color: 'white',

                                            width: '40%'
                                        }}>Confirm</Button>
                                    </div> */}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default PaySuccess;
