import React, { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import Paper from "@mui/material/Paper";
import contactImg from "../../assets/images/callCenter.png";
import "./ContactUs.css";
import Footer from "../../Shared/Footer/Footer";
import Header from "../../Shared/Header/Header";
import ReactGA from "react-ga4";

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "psycure_id",
        "psycure_template",
        form.current,
        "user_7GVvbZLAOGCj7ZfEWszwy"
      )
      .then(
        (result) => {
          alert("Message send successfully!");
        },
        (error) => {
          alert(error.message);
        }
      );
    e.target.reset();
  };
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: "Contact us",
    });
  }, []);
  return (
    <>
      <Header />
      <Container sx={{ my: 15 }}>
        <h2 style={{ textAlign: "center" }} className="headin">
          Get In Touch
        </h2>
        <hr
          style={{
            display: "block",
            width: "10%",
            height: "3px",
            backgroundColor: "#31C75A",
            border: 0,
          }}
        />
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <img
                style={{ width: "100%", height: "auto" }}
                src={contactImg}
                alt="Contact-img"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper
                elevation={12}
                style={{ backgroundColor: "#F4F8F5" }}
                sx={{ textAlign: "center", py: 5, borderRadius: 5 }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    color: "#31C75A",
                    fontWeight: "bold",
                  }}
                  variant="h5"
                  gutterBottom
                  component="div"
                >
                  Send a Direct Message
                </h3>

                {/* ========> CONTACT FORM <========= */}

                <form ref={form} onSubmit={sendEmail}>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Your Name"
                    name="user_name"
                    required
                  />

                  <br />
                  <br />
                  <input
                    className="input-field"
                    type="email"
                    placeholder="Your Email"
                    name="user_email"
                    required
                  />

                  <br />
                  <br />

                  <textarea
                    className="input-field"
                    placeholder="Type Your message here"
                    cols="30"
                    rows="5"
                    name="message"
                    required
                  ></textarea>

                  <br />
                  <br />

                  <input className="send-btn" type="submit" value="Send" />
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default ContactUs;
