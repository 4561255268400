import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SuccessAlert from "../../Shared/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../Shared/ErrorAlert/ErrorAlert";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function PsychologistPaymentRequest() {
  let [requestedPayment, setRequestedPayment] = useState([]);
  let [success, setSuccess] = useState("");
  let [error, setError] = useState("");

  const fetchPsychologist = async () => {
    const response = await fetch("https://api.thepsycure.com/psychologists");
    const allPsychologist = await response.json();

    if (response.ok) {
      let requestPayment = allPsychologist.filter(
        (psychologist) => psychologist?.paymentRequest
      );
      setRequestedPayment(requestPayment);
    }
  };

  useEffect(() => {
    fetchPsychologist();
  }, [success]);

  const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "email", label: "Email", minWidth: 150 },
    {
      id: "payment",
      label: "Payment Request",
      minWidth: 170,
      align: "right",
    },
    {
      id: "preview",
      label: "Payment Request",
      minWidth: 170,
      align: "right",
    },
    {
      id: "dispatch",
      label: "Dispatch",
      minWidth: 170,
      align: "right",
    },
  ];

  function createData(name, email, payment, preview, dispatch) {
    return { name, email, payment, preview, dispatch };
  }

  const handleDispatch = (psychologist) => {
    let arr = psychologist?.payment.map((detail) => {
      return { ...detail, isDisbursed: true };
    });
    let updatedObj = { ...psychologist, payment: arr, paymentRequest: false };

    fetch(`https://api.thepsycure.com/psychologist/${psychologist?.email}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ updatedObj }),
    })
      .then((res) => res.json())
      .then((result) => {
        setSuccess("Payment dispatch successfully.");
        setError("");
      })
      .catch((err) => {
        setSuccess("");
        setError("Something Went Wrong");
      });
  };

  const rows = [];
  requestedPayment.forEach((psychologist) =>
    rows.push(
      createData(
        psychologist.name,
        psychologist.email,
        psychologist?.payment?.reduce(
          (acc, cur) => (!cur?.isDisbursed ? acc + cur?.ammount : 0),
          0
        ),
        <Button style={{ backgroundColor: "green", color: "#fff" }}>
          <NavLink
            style={{ textDecoration: "none", color: "white" }}
            to={`/admin-dashboard/payment-review/${psychologist._id}`}
          >
            Payment Preview
          </NavLink>{" "}
        </Button>,
        <Button
          onClick={() => handleDispatch(psychologist)}
          style={{ backgroundColor: "green", color: "#fff" }}
        >
          dispatch
        </Button>
      )
    )
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess("");
      setError("");
    }, 3000);
  }, [success, error]);

  return (
    <div
      style={{
        boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.25)",
        padding: "2rem",
        borderRadius: ".8rem",
      }}
    >
      {rows?.length ? (
        <>
          <TableContainer className="table-container" sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: 600 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <p style={{ textAlign: "center" }}>No Payment request is pending.</p>
      )}

      {success && <SuccessAlert>{success}</SuccessAlert>}
      {error && <ErrorAlert>{error}</ErrorAlert>}
    </div>
  );
}
