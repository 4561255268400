import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { LinearProgress, MenuItem, TextField, styled } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { projectStorage } from "../../firebase.config";
import axios from "axios";
import Header from "../../Shared/Header/Header";
import "./fellowshipModal.css";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export default function ApplyFellowShipModal({ handleClose, open }) {
  const [name, setName] = useState("");
  const [age, setAge] = useState(null);
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState(null);
  const [insitute, setInstitute] = useState("");
  const [biography, setBiography] = useState("");
  const [brief, setBrief] = useState("");
  const [prevExperience, setPrevExperience] = useState("");
  const [prevExperienceBrief, setPrevExperienceBrief] = useState("");
  const [plan, setPlan] = useState("");
  /*  const [resume, setResume] = useState("");
  const types = ["application/pdf"];
  const [resumeFileError, setlResumeFileError] = useState("");
  const [resumeProgress, setResumeProgress] = useState(0); */
  const biographyInputLength = 251;
  const briefInputLength = 301;
  const prevExperienceInputLength = 251;
  const planLength = 251;
  const [success, setSuccess] = useState();
  const [biographyError, setBiographyError] = useState(null);
  const [briefError, setBriefError] = useState(null);
  const [prevExperienceError, setPrevExperienceError] = useState(null);
  const [planError, setPlanError] = useState(null);

  /* const handleResume = (e) => {
    let selected = e.target.files[0];
    setlResumeFileError("");
    if (selected && !types.includes(selected.type)) {
      setlResumeFileError("Please select an pdf file for resume");
      return;
    }
    const storageRef = ref(projectStorage, `files/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setResumeProgress(progress);
      },
      (error) => {
        setlResumeFileError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setResume(downloadURL);
        });
      }
    );
  }; */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess("");
    // biography error
    if (
      biography?.split(" ").length > 251 ||
      biography?.split(" ").length < 101
    ) {
      return setBiographyError("Minimum 100 words maximum 250 words");
    } else {
      setBiographyError(null);
    }
    // brief error
    if (brief?.split(" ").length > 301 || brief?.split(" ").length < 101) {
      return setBriefError("Minimum 100 words maximum 300 words");
    } else {
      setBriefError(null);
    }
    // prev experience error
    if (
      (prevExperience === "Yes" &&
        prevExperienceBrief?.split(" ").length > 251) ||
      (prevExperience === "Yes" && prevExperienceBrief?.split(" ").length < 101)
    ) {
      return setPrevExperienceError("Minimum 100 words maximum 250 words");
    } else {
      setPrevExperienceError(null);
    }
    // plan
    if (plan?.split(" ").length > 251 || plan?.split(" ").length < 101) {
      return setPlanError("Minimum 100 words maximum 250 words");
    } else {
      setPlanError(null);
    }
    const body = {
      name: name,
      age: age,
      gender: gender,
      email: email,
      number: number,
      institute: insitute,
      brief: brief,
      prevExperience: prevExperience,
      prevExperienceBrief: prevExperienceBrief,
      plan: plan,
      biography: biography,
    };

    await axios
      .post("https://api.thepsycure.com/fellowship", body)
      .then((data) => setSuccess(data.data))
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <Header />
      <Box
        component="section"
        display="flex"
        justifyContent="center"
        marginTop="5%"
        className="fellowship-form-submission"
      >
        <Box component="form" onSubmit={handleSubmit} width="90%">
          <Typography variant="h5">Fellowship Submission Form</Typography>
          {/* name */}
          <TextField
            id="filled-basic"
            label="Full Name"
            variant="filled"
            fullWidth
            required
            type="text"
            onChange={(e) => setName(e.target.value)}
            style={{ margin: "2% 0" }}
          />
          {/* age */}
          <TextField
            id="filled-basic"
            label="Age"
            variant="filled"
            fullWidth
            required
            type="number"
            onChange={(e) => setAge(e.target.value)}
            style={{ margin: "2% 0" }}
          />
          {/* gender */}
          <TextField
            id="filled-basic"
            label="Gender"
            select
            variant="filled"
            fullWidth
            required
            onChange={(e) => setGender(e.target.value)}
            style={{ margin: "2% 0" }}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="N/A">Prefer not to say</MenuItem>
          </TextField>
          {/* email */}
          <TextField
            id="filled-basic"
            label="Email"
            variant="filled"
            fullWidth
            required
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            style={{ margin: "2% 0" }}
          />
          {/* contact number */}
          <TextField
            id="filled-basic"
            label="Contact Number"
            variant="filled"
            fullWidth
            required
            type="number"
            onChange={(e) => setNumber(e.target.value)}
            style={{ margin: "2% 0" }}
          />
          {/* insitute */}
          <TextField
            id="filled-basic"
            label="Institute/Organization"
            variant="filled"
            fullWidth
            required
            type="text"
            onChange={(e) => setInstitute(e.target.value)}
            style={{ margin: "2% 0" }}
          />
          {/* biography */}
          <div style={{ margin: "2% 0" }}>
            <label htmlFor="biography" style={{ margin: "2% 0" }}>
              Please provide a short description of yourself, including your
              current role (if applicable), achievements and aspirations.*
            </label>
            <TextField
              id="biography"
              //   label="Please briefly describe your experience"
              variant="filled"
              fullWidth
              required
              type="text"
              onChange={(e) => setBiography(e.target.value)}
              error={biographyError && true}
              helperText={biographyError && `${biographyError}`}
            />
            <span>
              Remaining {biographyInputLength - biography?.split(" ").length}{" "}
              words
            </span>
          </div>
          {/* brief */}
          <div style={{ margin: "2% 0" }}>
            <label htmlFor="brief">
              Briefly describe why and what interested you in participating in
              the Youth Mental Health Fellowship Program 2024?*
            </label>
            <TextField
              id="brief"
              //   label="Please briefly describe your experience"
              variant="filled"
              fullWidth
              required
              type="text"
              onChange={(e) => setBrief(e.target.value)}
              error={briefError && true}
              helperText={briefError && `${briefError}`}
            />
            <span>
              Remaining {briefInputLength - brief?.split(" ").length} words
            </span>
          </div>
          {/* experience */}
          <div style={{ margin: "2% 0" }}>
            <label htmlFor="experience">
              Any previous experience or involvement in mental health advocacy
              or related activities?*
            </label>
            <TextField
              id="experience"
              select
              variant="filled"
              fullWidth
              required
              onChange={(e) => setPrevExperience(e.target.value)}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </TextField>
            {/* prev experience brief */}
            {prevExperience === "Yes" && (
              <div>
                <TextField
                  id="filled-basic"
                  label="Please briefly describe your experience"
                  variant="filled"
                  fullWidth
                  required
                  type="text"
                  error={prevExperienceError && true}
                  helperText={prevExperienceError && `${prevExperienceError}`}
                  onChange={(e) => setPrevExperienceBrief(e.target.value)}
                />
                <span>
                  Remaining{" "}
                  {prevExperienceInputLength -
                    prevExperienceBrief?.split(" ").length}{" "}
                  words
                </span>
              </div>
            )}
          </div>
          {/* plan */}
          <div style={{ margin: "2% 0" }}>
            <label htmlFor="plan">
              How do you plan to advocate for youth mental health?
            </label>
            <TextField
              id="plan"
              //   label="Please briefly describe your experience"
              variant="filled"
              fullWidth
              required
              type="text"
              onChange={(e) => setPlan(e.target.value)}
              error={planError && true}
              helperText={planError && `${planError}`}
            />
            <span>Remaining {planLength - plan?.split(" ").length} words</span>
          </div>
          {/* cv upload */}
          {/*  <Box style={{ margin: "2% 0", textAlign: "center" }}>
            <Button
              component="label"
              variant="contained"
              sx={{
                background: "#3cb669",
              }}
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload resume/CV
              <VisuallyHiddenInput
                onChange={(e) => handleResume(e)}
                required
                type="file"
              />
            </Button>
          </Box> */}
          {/*  {resumeFileError && (
            <Typography style={{ color: "red", margin: "2% 0" }}>
              {resumeFileError}
            </Typography>
          )} */}
          {/*  {<LinearProgress variant="determinate" value={resumeProgress} />} */}
          {success && <p style={{ color: "green" }}>{success}</p>}
          <Box style={{ textAlign: "center", margin: "2% 0" }}>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
