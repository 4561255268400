import React, { useState } from 'react'
import { Button, Paper } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const btnStyles = {
    backgroundColor: "#31C75A",
    color: "#fff",
    marginTop: "20px"
}


export default function WrittenElement({ selectElement, setHomeWork, homework, handleFile, setPage, fileUpload, progress, setFileUpload, setProgress }) {
    const [details, setDetails] = useState("");

    let elementShowing

    if (selectElement === "Title") {
        elementShowing = <input required type="text" onChange={e => setDetails(e.target.value)} />
    } else if (selectElement === "Description") {
        elementShowing = <textarea required onChange={e => setDetails(e.target.value)} />
    } else if (selectElement === "Image") {
        elementShowing = <input required type="file" onChange={handleFile} />
    } else if (selectElement === "Checkbox") {
        elementShowing = <input required type="checkbox" onChange={e => setHomeWork({ ...homework, checkbox: e.target.value })} />
    }

    const handleSave = () => {
        setPage(3);
        if (selectElement === "Title") {
            setHomeWork({ ...homework, title: details })
        } else if (selectElement === "Description") {
            setHomeWork({ ...homework, description: details })
        } else if (selectElement === "Image" && fileUpload.file) {
            setHomeWork({ ...homework, image: fileUpload.file })
        }
        setFileUpload({});
        setProgress(0)
    }

    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Paper elevation={12} sx={{ p: 5, pt: 2, borderRadius: '1.5rem' }} style={{ height: "25rem", display: "flex", flexDirection: "column", justifyContent: "center", }}>
            <h4>Task Elements/instructions</h4>
            <div className="written_container">
                <p>Add {selectElement}</p>
                {elementShowing}
                {
                    progress > 0 ? <Box sx={{ width: '100%' }}>
                        <LinearProgressWithLabel value={progress} />
                    </Box> : ""
                }

                {
                    selectElement === "Title" || selectElement === "Description" || selectElement === "Checkbox" ? <Button style={btnStyles} className="btn" onClick={handleSave}>Save</Button> : ""
                }
                {
                    fileUpload.file ? <Button style={btnStyles} onClick={handleSave}>Save</Button> : ""
                }

            </div>
        </Paper>
    )
}
