import { Button, Container, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import useAuth from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import SessionImg from "../../../assets/images/dashboard/session.png";

import Footer from "../../../Shared/Footer/Footer";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6F1EC",
    color: "#000",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E6F1EC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(doctor, Category, date, startTime, endTime, status, link) {
  return { doctor, Category, date, startTime, endTime, status, link };
}

const highlightGreen = {
  display: "inline",
  backgroundColor: "#31C75A",
  color: "#fff",
  padding: "10px",
  borderRadius: ".5rem",
};
const highlightYellow = {
  display: "inline",
  backgroundColor: "yellow",
  color: "#000",
  padding: "10px",
  borderRadius: ".5rem",
};

export default function SessionHistory() {
  const { user } = useAuth();
  const [userSessionHistory, setUserSessionHistory] = useState([]);
  const [link, setLink] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  let today = new Date().toISOString().slice(0, 10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetch(`https://api.thepsycure.com/session/${user?.email}`)
      .then((res) => res.json())
      .then((data) => {
        let sortedData = data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setUserSessionHistory(sortedData);
      });
  }, [user?.email]);

  useEffect(() => {
    userSessionHistory?.forEach((session) => {
      if (
        new Date(session?.date) < new Date(today) &&
        session?.status !== "complete"
      ) {
        let obj = {
          ...session,
          status: "complete",
        };

        fetch(`https://api.thepsycure.com/session/${session?._id}`, {
          method: "PUT",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify(obj),
        })
          .then((res) => res.json())
          .then((result) => {
            let data = result;
          })
          .catch((err) => {
            let error = err.message;
          });

        fetch(
          `https://api.thepsycure.com/psychologists/${session?.selectedPsychologists?.email}`
        )
          .then((res) => res.json())
          .then((selectPsychologist) => {
            let paymentSession = {
              id: session?._id,
              date: session?.date,
              patientEmail: session?.patientEmail,
              doctorEmail: session?.docEmail,
              ammount: 1200,
            };
            let updatedObj = {
              ...selectPsychologist,
              payment: [...selectPsychologist?.payment, paymentSession],
            };
            fetch(
              `https://api.thepsycure.com/psychologist/${selectPsychologist?.email}`,
              {
                method: "PUT",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({ updatedObj }),
              }
            )
              .then((res) => res.json())
              .then((result) => {
                const data = result;
              });
          });
      }
    });
  }, [userSessionHistory]);

  const rows = [];

  userSessionHistory.forEach((History) =>
    rows.push(
      createData(
        History,
        History.selectedPsychologists.category,
        History.date,
        History.startTime,
        History.endTime,
        History?.status,
        History.selectedPsychologists.link
      )
    )
  );

  return (
    <>
      <Container>
        <h1 className="title">Session History</h1>
        <Grid item xs={12} className="table-container" component={Paper}>
          <TableContainer sx={{ maxHeight: 440, backgroundColor: "#E6F1EC" }}>
            <Table aria-label="customized table">
              <TableHead style={{ marginBottom: "2rem" }}>
                <TableRow style={{ textAlign: "center" }}>
                  {/* <StyledTableCell></StyledTableCell> */}
                  <StyledTableCell>
                    <strong>Psychologist's Name</strong>
                  </StyledTableCell>
                  {/* <StyledTableCell><strong>Category</strong></StyledTableCell> */}
                  <StyledTableCell>
                    <strong>Date</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Time</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Status</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Meet Link</strong>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <>
                      <StyledTableRow key={row.name}>
                        {/* <StyledTableCell >

                                        </StyledTableCell> */}
                        <StyledTableCell className="table_center">
                          {
                            row?.doctor?.selectedPsychologists?.name?.split(
                              ","
                            )[0]
                          }
                        </StyledTableCell>
                        {/* <StyledTableCell className='table_center'>{row?.Category?.split(",")[0]}</StyledTableCell> */}
                        <StyledTableCell className="table_center">
                          {row?.date?.split(",")[0]} / {row.doctor?.day}
                        </StyledTableCell>
                        <StyledTableCell className="table_center">
                          {row.startTime} - {row.endTime}
                        </StyledTableCell>

                        <StyledTableCell className="table_center">
                          {row?.status === "complete" ? (
                            <p style={highlightGreen}>Completed</p>
                          ) : (
                            <p style={highlightYellow}>Upcoming</p>
                          )}
                        </StyledTableCell>
                        <StyledTableCell className="table_center">
                          {" "}
                          <Link
                            to={`/video-session/${row.doctor._id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "#31C75A" }}
                              sx={{ borderRadius: "25px" }}
                            >
                              Join
                            </Button>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        <div className="img_container">
          <img src={SessionImg} />
        </div>
      </Container>
      {/* <DashboardFooter /> */}
      <Footer />
    </>
  );
}
