import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import backgroundImage from "./../../assets/images/signinBG.png";
import signInImage from "./../../assets/images/signin image.png";

import { Container } from "@mui/system";
import { CardMedia, CircularProgress } from "@mui/material";
import "../SignIn/SignIn.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useLogin } from "../../hooks/useLogin";
import Footer from "../../Shared/Footer/Footer";
import SuccessfulAdminSignup from "./SuccessfulAdminSignup";
import styled from "styled-components";
import Header from "../../Shared/Header/Header";

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: #31c75a;
    border-color: #31c75a;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #31c75a;
    }
  }
`;

export default function AdminSignin() {
  const [loginData, setLoginData] = useState({});
  const { user } = useAuth();
  const [userObj, setUserObj] = useState({});
  const { Login, setError, error, isLoading } = useLogin();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://api.thepsycure.com/users/${loginData.email}`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setUserObj(data);
        }
      })
      .catch((err) => {
        if (err) {
          setUserObj({});
        }
      });
  }, [loginData.email]);

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
    setError(null);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();

    if (userObj?.role === "admin") {
      Login(loginData.email, loginData.password, location, navigate, "admin");
    } else {
      setError("We could not find any admin with this email");
    }
  };

  return !user?.email ? (
    <>
      <Header />
      <Container
        sx={{
          my: 24,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Grid
            container
            component="main"
            sx={{
              boxShadow: "0px 4px 48px rgba(0, 0, 0, 0.25)",
              borderRadius: "50px",
            }}
          >
            {/* ========> LEFT SIGH IN<=========== */}

            <Grid
              className="left-signIn"
              item
              xs={12}
              md={6}
              sx={{
                background: `url(${backgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: "#27583A",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  height: "70vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <CardMedia
                    component="img"
                    alt="sign in"
                    image={signInImage}
                  />

                  <Button
                    sx={{ color: "#fff", marginTop: "30px" }}
                    color="inherit"
                    variant="text"
                  >
                    New User?
                  </Button>
                  <br />

                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to="/signup"
                  >
                    {" "}
                    <Button
                      sx={{
                        color: "#fff",
                        borderRadius: "50px",
                        padding: "14px 80px",
                      }}
                      color="inherit"
                      variant="outlined"
                    >
                      SIGN UP
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Grid>

            {/* ========> RIGHT SIGH IN<=========== */}

            <Grid className="right-signIn" item xs={12} md={6}>
              <form onSubmit={handleLoginSubmit}>
                <h2>Sign In as a Admin</h2>
                <WhiteBorderTextField
                  sx={{ width: "75%", m: 1 }}
                  id="standard-basic"
                  label="Your Email"
                  name="email"
                  type="email"
                  required
                  onChange={handleOnChange}
                  variant="outlined"
                />

                <WhiteBorderTextField
                  sx={{ width: "75%", m: 1 }}
                  id="standard-password-input"
                  label="Your Password"
                  type="password"
                  name="password"
                  required
                  onChange={handleOnChange}
                  autoComplete="current-password"
                  variant="outlined"
                />
                <br />
                {error && <small style={{ color: "coral" }}>{error}</small>}
                <br />
                {!isLoading && (
                  <Button
                    className="signin-btn"
                    type="submit"
                    variant="contained"
                    style={{ marginTop: "1rem" }}
                  >
                    Sign In
                  </Button>
                )}
                {isLoading && (
                  <div>
                    <CircularProgress />
                  </div>
                )}

                {/* {error && <small style={{ color: 'coral' }}>{error}</small>}
                                {isValid && <small style={{ color: 'coral' }}>{isValid}</small>}

                                {!emailVerified || issue ? <small style={{ color: 'coral' }}>{issue}</small> : <></>} */}
              </form>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  ) : (
    <SuccessfulAdminSignup />
  );
}
