
import { Button, Container, Grid } from '@mui/material';
import { useState, useEffect } from 'react'
import PsycureMessage from './PsycureMessage';
import { useNavigate } from 'react-router-dom';

import Header from '../../Shared/Header/Header';

export default function ChatList() {
    // { email, patient , isLoggedIn, setLoggedIn}
    let [isLoggedIn, setLoggedIn] = useState(false);
    let [showMessage, setShowMessage] = useState(false);
    let [startMessage, setStartMessage] = useState(false);
    let [allChat, setAllChat] = useState([]);
    let [chat, setChat] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchchat = async () => {
            const response = await fetch('https://api-laravel.thepsycure.com/Chat'
                , {
                    method: "GET",
                    headers: {
                        // authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        authorization: `Bearer 1|hkbjFZ8MxcEIQVcqVMYoqx45Wnme8T2aZVVpolkm`,
                    },
                }
            )
            const json = await response.json()

            if (response.ok) {
                setAllChat(json.data);
            }
        }

        setInterval(() => {
            fetchchat()
        }, 3000)

        // if (!showMessage) {

        //     localStorage.setItem("patientEmail", "A")
        // }
    }, [])


    const handleChat = chatObj => {
        setChat(chatObj);
        localStorage.setItem('chatObj', JSON.stringify(chatObj))
        if (chatObj?.patient_email) {
            localStorage.setItem("patientEmailChat", chatObj?.patient_email)
            setTimeout(() => {
                setStartMessage(true);
            }, 1000)

        }
    }

    // let channelObj = new ChannelNames();
    const handleYes = () => {
        // navigate("/chat-list/user")

        // channelObj.channelName = "ABC";

        setTimeout(() => {
            setStartMessage(true)
        }, 2000)
    }

    return (
        <>
            <Header />
            <Container sx={{ my: 20 }} style={{ position: "relative" }}>
                <h2 style={{ textAlign: "center" }}>Waiting for response</h2>
                <hr style={{ display: 'block', width: '10%', height: '3px', backgroundColor: '#31C75A', border: 0 }} />
                <Grid sx={{ pt: 5 }} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {
                        allChat?.map(chat => (

                            chat?.status == "0" && <Grid item xs={12} sm={12} md={4} style={{ backgroundColor: 'rgba(49, 199, 90, .2)', border: "3px solid #fff", padding: "1.5rem" }}>
                                <div>
                                    <h2>{chat?.patient_name}</h2>
                                    {/* <p>{chat?.patient_email}</p> */}
                                    <p>{chat?.message_time}</p>
                                </div>
                                {
                                    chat?.status == "0" ? <Button className="btn" style={{ backgroundColor: "#31C75A", padding: "8px", fontSize: "14px", textTransform: "capitalize", borderRadius: "8px", width: "100%" }} variant='contained' onClick={() => handleChat(chat)}>Join</Button> : null
                                }

                            </Grid>
                        ))
                    }
                </Grid>
                {/* {
                    (showMessage && !startMessage) ? <div className={classes.message_container} style={{ display: "felx", justifyContent: "center", alignItems: "center" }}>
                        <h2>Are you sure you want to join?</h2>
                        <Button className="btn" style={{ backgroundColor: "#31C75A", padding: "8px", fontSize: "14px", textTransform: "capitalize", borderRadius: "8px", width: "100%" }} variant='contained' onClick={handleYes}>Yes</Button>
                        <Button className="btn" style={{ backgroundColor: "#EA5044", padding: "8px", fontSize: "14px", textTransform: "capitalize", borderRadius: "8px", width: "100%", marginTop: "10px" }} variant='contained' onClick={() => setShowMessage(false)}>No</Button>
                    </div> : null
                } */}

            </Container>
            {
                startMessage && localStorage.getItem('patientEmailChat') == chat.patient_email ? <PsycureMessage email={chat.patient_email} patient={chat} isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} setShowMessage={setShowMessage} setStartMessage={setStartMessage} /> : null
            }
        </>
    )
}
