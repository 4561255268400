import React from "react";
import Header from "../../Shared/Header/Header";
import Footer from "../../Shared/Footer/Footer";
import Banner from "./Banner";
import OverView from "./OverView";
import FellowshipProgram from "./FellowshipProgram";
import CoreModules from "./CoreModules";
import Faculty from "./Faculty";
import OverViewFull from "./OverViewFull";
import FellowShipFaq from "./FellowShipFaq";
// import AboutUs from "../../kit/pages/LandingPages/AboutUs";
import "./fellowship.css";
import FellowCarousel from "./FellowCarousel";
const FellowShipLandingPage = () => {
  return (
    <div>
      <Header />
      <div className="fellowship-banner">
        <Banner />
      </div>
      {/* <Quote /> */}
      <OverViewFull />
      <OverView />
      <FellowshipProgram />
      <CoreModules />
      <Faculty />
      <FellowCarousel />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FellowShipFaq />
      </div>
      <Footer />
    </div>
  );
};

export default FellowShipLandingPage;
