import { Box, Fade, Modal, Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop';
import React from 'react'
import angryImg from "../../../assets/images/MoodJournal/1angry.png"
import sadImg from "../../../assets/images/MoodJournal/2sad.png"
import notgoodImg from "../../../assets/images/MoodJournal/3notgood.png"
import happyImg from "../../../assets/images/MoodJournal/4happy.png"
import betterImg from "../../../assets/images/MoodJournal/5better.png"
import ClearIcon from '@mui/icons-material/Clear';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    bgcolor: 'background.paper',
    borderRadius: "12px",
    boxShadow: 24,
    outline: "none",
    p: 4,

    '@media (max-width: 500px)': {
        width: 320,
    },
};

const deleteImg = {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer"
}

export default function ReadingJournal({ selectedJournal, open, handleClose }) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style} >
                    <ClearIcon onClick={() => handleClose()} style={deleteImg} />
                    <Typography variant="h4" style={{ textAlign: "center" }}>Personal Diary</Typography>
                    <p style={{ textAlign: "center", color: "#31C75A" }}>{selectedJournal?.date?.split(",")[0]}</p>
                    <div style={{ textAlign: "center" }}>
                        <img src={selectedJournal.feeling === "angry" && angryImg || selectedJournal.feeling === "sad" && sadImg || selectedJournal.feeling === "notgood" && notgoodImg || selectedJournal.feeling === "happy" && happyImg || selectedJournal.feeling === "better" && betterImg} alt={selectedJournal.feeling} />
                    </div>
                    <p>{selectedJournal?.mind ? selectedJournal?.mind : "nothing to say"}</p>
                </Box>
            </Fade >
        </Modal>

    )
}
