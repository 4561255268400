// src/SlidingButton.js
import React, { useState } from "react";
import { Button, Box, Modal, Typography } from "@mui/material";
import { color, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Send } from "@material-ui/icons";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const RegisterButtonSummit = () => {
  const animation = {
    initial: { x: "-100%", y: "100%", color: "black" },
    animate: { x: 0, y: 0, color: "white" },
  };
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box
      sx={{ position: "relative", display: "inline-block", cursor: "pointer" }}
    >
      <motion.div
        // variant="contained"
        style={{
          position: "relative",

          zIndex: 1,
          padding: "10px 20px",
          backgroundColor: "transparent",
          overflow: "hidden",
          width: "200px",
          border: "2px solid #36ca5a",
          textAlign: "center",
          fontSize: "20px",
          color: isHovered ? "white" : "black",
          transition: "all",
          transitionDuration: "0.1s",
          borderRadius: 32,
        }}
        whileHover="animate"
        initial="initial"
        animate="initial"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleOpen}

        // color="white"
      >
        Register Now!
        <motion.div
          variants={animation}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#36ca5a", // Change to your desired hover color
            zIndex: -1,
            borderRadius: 32,
          }}
        />
      </motion.div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            How do you want to register?
          </Typography>
          <Box
            id="modal-modal-description"
            sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
          >
            {/* student */}
            <Box>
              <Link to="/summit-2024-student">
                <Button variant="contained" endIcon={<Send />}>
                  Student
                </Button>
              </Link>
            </Box>
            {/* professional */}
            <Box>
              <Link to="/summit-2024-professional">
                <Button variant="contained" endIcon={<Send />}>
                  Professional
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default RegisterButtonSummit;
