import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Button, CircularProgress } from "@mui/material";

import { Link, NavLink, Outlet, useLocation } from "react-router-dom";

import logo from "../../assets/images/Psycure Limited logo.png";
import useAuth from "../../hooks/useAuth";
import EditModal from "../EditPsychologist/EditModal";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MoneyIcon from "@mui/icons-material/Money";
import { useLogout } from "../../hooks/useLogout";
import WaitForApproval from "../PsychologistDashboard/WaitForApproval/WaitForApproval";
import { useTheme } from "@material-ui/core/styles";
import "./PsychologistDashboardNavigation.css";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const drawerWidth = 240;

const normalStyle = {
  textDecoration: "none",
  color: "black",
  display: "flex",
};
const activeStyle = {
  textDecoration: "none",
  color: "black",
  display: "flex",
  fontWeight: "700",
};

function PsychologistDashboardNavigation(props) {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window?.location?.pathname + window?.location?.search}`,
      title: `Psychologist dashboard`,
    });
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [psychologist, setPsychologist] = React.useState([]);

  const theme = useTheme();

  /* =====> AUTHENTICATION<======= */
  const { user } = useAuth();
  const { Logout } = useLogout();
  const location = useLocation();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = (e) => {
    Logout();
  };
  React.useEffect(() => {
    fetch(`https://api.thepsycure.com/psychologists/${user?.email}`)
      .then((res) => res.json())
      .then((data) => setPsychologist(data));
  }, [user?.email]);

  const drawer = (
    <div>
      <Toolbar>
        <Link
          style={{
            textDecoration: "none",
            color: "black",
            display: "flex",
            marginBottom: ".4rem",
          }}
          to="/"
        >
          {" "}
          <img src={logo} alt="" />
        </Link>
      </Toolbar>
      <Divider />
      <div>
        {psychologist?._id ? (
          <>
            {psychologist.approved === true ? (
              <List style={{ marginLeft: "1rem" }}>
                <ListItem>
                  <NavLink
                    style={
                      location?.pathname == "/psychologist-dashboard"
                        ? activeStyle
                        : normalStyle
                    }
                    to="/psychologist-dashboard"
                  >
                    <span style={{ marginRight: "0.5rem" }}>
                      <DashboardIcon></DashboardIcon>
                    </span>{" "}
                    Dashboard
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    style={({ isActive }) =>
                      isActive ? activeStyle : normalStyle
                    }
                    to={`/psychologist-dashboard/homework-builder`}
                  >
                    <span style={{ marginRight: "0.5rem" }}>
                      <AssignmentIcon></AssignmentIcon>
                    </span>{" "}
                    Homework Builder
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    style={({ isActive }) =>
                      isActive ? activeStyle : normalStyle
                    }
                    to={`/psychologist-dashboard/calender-view`}
                  >
                    <span style={{ marginRight: "0.5rem" }}>
                      <CalendarMonthIcon></CalendarMonthIcon>
                    </span>
                    My Schedules
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    style={({ isActive }) =>
                      isActive ? activeStyle : normalStyle
                    }
                    to={`/psychologist-dashboard/payment-details`}
                  >
                    <span style={{ marginRight: "0.5rem" }}>
                      <MoneyIcon></MoneyIcon>
                    </span>
                    Payment Details
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    style={({ isActive }) =>
                      isActive ? activeStyle : normalStyle
                    }
                    to={`/psychologist-dashboard/edit`}
                  >
                    {" "}
                    <span style={{ marginRight: "0.5rem" }}>
                      <EditIcon></EditIcon>
                    </span>
                    Edit Profile
                  </NavLink>
                  {/* <Button sx={{ color: 'black', display: 'flex' }} onClick={handleOpen}> <span style={{ marginRight: '0.5rem' }}><EditIcon></EditIcon></span>Edit</Button> */}
                </ListItem>
                <ListItem>
                  <p
                    sx={{ color: "black", display: "flex" }}
                    onClick={handleLogout}
                    style={{ margin: "0", display: "flex", cursor: "pointer" }}
                  >
                    {" "}
                    <span style={{ marginRight: "0.5rem" }}>
                      <ExitToAppIcon></ExitToAppIcon>
                    </span>
                    Logout
                  </p>
                </ListItem>
              </List>
            ) : (
              <List style={{ marginLeft: "1rem" }}>
                <ListItem>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                    }}
                    to="/psychologist-dashboard"
                  >
                    <span style={{ marginRight: "0.5rem" }}>
                      <DashboardIcon></DashboardIcon>
                    </span>{" "}
                    Dashboard
                  </Link>
                </ListItem>
                <ListItem>
                  <Button
                    sx={{ color: "black", display: "flex" }}
                    onClick={handleLogout}
                  >
                    {" "}
                    <span style={{ marginRight: "0.5rem" }}>
                      <ExitToAppIcon></ExitToAppIcon>
                    </span>
                    Logout
                  </Button>
                </ListItem>
              </List>
            )}
          </>
        ) : (
          <>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </>
        )}
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="topbar"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          pb: 1.5,
          [theme.breakpoints.down("xs")]: {
            pb: 4,
          },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { sm: "none !important" },
              [theme.breakpoints.down("xs")]: {
                mt: 4,
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <h3>
                        <NavLink style={{ textDecoration: 'none', color: 'green' }} to='/'>Psycure Limited</NavLink>
                    </h3>
                    <DrawerComp /> */}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      {/* Dashboard Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {psychologist?.approved === false ? (
          <>
            <WaitForApproval></WaitForApproval>
          </>
        ) : (
          <>
            <Box sx={{ pt: 10 }}>
              <Outlet />
              {/* <Container sx={{ my: 10 }}> */}
              <EditModal handleClose={handleClose} open={open}></EditModal>
              {/* </Container> */}
            </Box>{" "}
          </>
        )}
      </Box>
    </Box>
  );
}

PsychologistDashboardNavigation.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default PsychologistDashboardNavigation;
