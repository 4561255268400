import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import googleIcon from "./../../assets/images/googleIcon.png";
import ReactGA from "react-ga4";

import { Container } from "@mui/system";
import { CircularProgress } from "@mui/material";
import "./SignIn.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useLogin } from "../../hooks/useLogin";
import Footer from "../../Shared/Footer/Footer";
import SuccessfulSignup from "../SignUp/SuccessfulSignup";
import { useForgotPassword } from "../../hooks/useForgotPassword";
import SuccessAlert from "../../Shared/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../Shared/ErrorAlert/ErrorAlert";
// import { useSignInWithGoogle } from '../../hooks/useSignInWithGoogle';
import { useSignInWithGooglePatient } from "../../hooks/useSignInWithGooglePatient";
import styled from "styled-components";
import LoginImg from "../../assets/images/sign_in_girl.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Paper from "@mui/material/Paper";
import { signinPageEvent } from "../../utils/FacebookPixelEvent";
import Header from "../../Shared/Header/Header";

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: #31c75a;
    border-color: #31c75a;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #31c75a;
    }
  }
`;
const SignIn = ({ giftCardId }) => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: "Sign In Page",
    });
  }, []);
  const [loginData, setLoginData] = useState({});

  let [issue, setIssue] = useState(null);
  const [emailValidate, setEmailValidate] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const { user } = useAuth();
  const [userObj, setUserObj] = useState({});
  const [isValid, setIsValid] = useState("");
  const { Login, setError, error, isLoading } = useLogin();
  const {
    sendPasswordReset,
    error: forgotError,
    success,
  } = useForgotPassword();

  const [forgotPage, setForgotPage] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");

  const [showPass, setShowPass] = useState(false);

  const {
    handleSignInWithGoogle,
    handleSignOutWithGoogle,
    error: googleError,
  } = useSignInWithGooglePatient("patient");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    signinPageEvent();
  }, []);

  useEffect(() => {
    fetch(`https://api.thepsycure.com/users/${loginData.email}`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setUserObj(data);
          setIsValid("");
        }
      })
      .catch((err) => {
        if (err) {
          setUserObj({});
        }
      });
  }, [loginData.email]);

  function CheckPassword(inputtxt) {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (inputtxt.match(passw)) {
      setIssue(false);
      setEmailVerified(true);
      setError(null);
      return true;
    } else {
      setEmailVerified(false);
      return false;
    }
  }

  function ValidateEmail(email) {
    if (
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailValidate(true);
      setEmailVerified(true);
      setError(null);
      setIssue(false);
      return true;
    }
    setEmailValidate(false);
    setEmailVerified(false);
    setIssue("Please enter a valid email address.");
    return false;
  }

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
    setError(null);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();

    if (userObj?.role === "patient") {
      Login(loginData.email, loginData.password, location, navigate, "patient", giftCardId);
    } else {
      setError("We could not find any patient with this email.");
    }
  };

  const handleForgot = (e) => {
    e.preventDefault();

    sendPasswordReset(forgotEmail);
  };

  return !user?.email ? (
    <>
      <Header />
      <Container sx={{ my: 15 }}>
        <h2 style={{ textAlign: "center" }} className="headin">
          Sign In
        </h2>
        <hr
          style={{
            display: "block",
            width: "10%",
            height: "3px",
            backgroundColor: "#31C75A",
            border: 0,
          }}
        />
        <Box style={{ marginTop: "2rem" }}>
          <Grid
            container
            spacing={2}
            sx={{
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <img
                style={{ width: "100%", height: "auto" }}
                src={LoginImg}
                alt="Contact-img"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper
                elevation={12}
                style={{ backgroundColor: "#F4F8F5" }}
                sx={{ textAlign: "center", py: 5, borderRadius: 5 }}
              >
                {
                  !forgotPage ? (
                    // <Grid className='right-signIn' item xs={12} md={6}>

                    <form onSubmit={handleLoginSubmit}>
                      <h2>Sign In</h2>
                      <WhiteBorderTextField
                        style={{ backgroundColor: "white" }}
                        sx={{ width: "75%", m: 1 }}
                        id="standard-basic"
                        label="Your Email"
                        name="email"
                        type="email"
                        required
                        onChange={(e) => {
                          handleOnChange(e);
                          ValidateEmail(e.target.value);
                        }}
                        variant="outlined"
                      />

                      <div className="pass_div">
                        <WhiteBorderTextField
                          style={{ backgroundColor: "white" }}
                          sx={{ width: "75%", m: 1 }}
                          id="standard-password-input"
                          label="Your Password"
                          type={showPass ? "text" : "password"}
                          name="password"
                          required
                          onChange={handleOnChange}
                          autoComplete="current-password"
                          variant="outlined"
                        />
                        {showPass ? (
                          <RemoveRedEyeIcon
                            onClick={() => setShowPass(!showPass)}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => setShowPass(!showPass)}
                          />
                        )}
                      </div>
                      <p
                        style={{
                          width: "75%",
                          margin: "0 auto",
                          textAlign: "left",
                          cursor: "pointer",
                        }}
                        onClick={() => setForgotPage(!forgotPage)}
                      >
                        Forgot Password?
                      </p>
                      {error && <small style={{ color: "red" }}>{error}</small>}
                      {isValid && (
                        <small style={{ color: "red" }}>{isValid}</small>
                      )}

                      {!emailVerified || issue ? (
                        <small style={{ color: "red" }}>{issue}</small>
                      ) : (
                        <></>
                      )}
                      <br />
                      {!isLoading && (
                        <Button
                          className="signin-btn"
                          type="submit"
                          variant="contained"
                          style={{ marginTop: "1rem" }}
                        >
                          Sign In
                        </Button>
                      )}
                      {isLoading && (
                        <div>
                          <CircularProgress />
                        </div>
                      )}
                      <br />

                      <div>
                        <p style={{ margin: "15px 0" }}>
                          New User? &nbsp;
                          <Link
                            style={{
                              color: "#31c75a",
                              textTransform: "uppercase",
                            }}
                            to="/signup"
                          >
                            Sign Up
                          </Link>
                        </p>
                      </div>

                      {/* Login with google */}
                      <div
                        className="google_login"
                        onClick={() => handleSignInWithGoogle("patient")}
                      >
                        <img src={googleIcon} alt="icon" />
                        <p>Sign In With Google</p>
                      </div>
                    </form>
                  ) : (
                    // {/* </Grid >  */}
                    // <Grid className='right-signIn' item xs={12} md={6}>

                    <form onSubmit={handleForgot}>
                      <h2>Your Email</h2>
                      <WhiteBorderTextField
                        sx={{ width: "75%", m: 1 }}
                        id="standard-password-input"
                        label="Your Password"
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        variant="outlined"
                        style={{ visibility: "hidden" }}
                      />
                      <WhiteBorderTextField
                        style={{ backgroundColor: "white" }}
                        sx={{ width: "75%", m: 1 }}
                        id="standard-basic"
                        label="Your Email"
                        name="email"
                        type="email"
                        required
                        onChange={(e) => setForgotEmail(e.target.value)}
                        variant="outlined"
                      />
                      <p
                        style={{
                          width: "75%",
                          margin: "0.5rem auto",
                          textAlign: "left",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => setForgotPage(!forgotPage)}
                      >
                        Signin Page
                      </p>
                      {
                        <Button
                          className="signin-btn"
                          type="submit"
                          variant="contained"
                          style={{ marginTop: "1rem" }}
                        >
                          Submit
                        </Button>
                      }
                      {success ? <SuccessAlert>{success}</SuccessAlert> : ""}
                      {forgotError ? (
                        <ErrorAlert>{forgotError}</ErrorAlert>
                      ) : (
                        ""
                      )}
                      <br /> <br />
                    </form>
                  )
                  // </Grid >
                }
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {!giftCardId && <Footer />}
    </>
  ) : (
    <SuccessfulSignup greet="login" />
  );
};

export default SignIn;
