import React, { useEffect, useState } from "react";
import Header from "../../../Shared/Header/Header";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
const SuccessfulWorkshopPayment = () => {
  const [countDown, setCountdown] = useState(5);
  const navigate = useNavigate();
  useEffect(() => {
    if (countDown == 0) {
      navigate("/");
    }

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clear the timeout when the component unmounts to avoid memory leaks
    return () => {
      clearInterval(countdownInterval);
    };
  }, [countDown]);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: "Workshop payment successful",
    });
  }, []);
  return (
    <div>
      <Header></Header>
      <p
        style={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Hurrah! Workshop payment successful. Please check your email for the
        invoice and further details. Redirecting to home page in {countDown}{" "}
        seconds..
      </p>
    </div>
  );
};

export default SuccessfulWorkshopPayment;
