import React, { useState } from 'react'
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { CardMedia } from '@mui/material';
import { Navigate } from 'react-router-dom';
import backgroundImage from './../../assets/images/signinBG.png'
import signInImage from './../../assets/images/login.svg'
import Header from '../../Shared/Header/Header';

export default function ChatLogin() {
    let [email, setEmail] = useState("");

    let [user, setUser] = useState(false);

    const handleLoginSubmit = e => {
        e.preventDefault();

        fetch(`https://api-laravel.thepsycure.com/allPyslogist`)
            .then(res => res.json())
            .then(data => {
                if (data.error === false) {
                    let logedInUser = data.data?.filter(user => user?.email === email);
                    localStorage.setItem('loggedIn', 'true');
                    setUser(true);
                }
            })
    }

    return (
        <>
            {
                user ? <Navigate to='/chat-list' /> :
                    <>
                        <Header />
                        <Container
                            className="main"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box>
                                <Grid container component="main" sx={{
                                    boxShadow: '0px 4px 48px rgba(0, 0, 0, 0.25)',
                                    borderRadius: '50px',
                                }}>

                                    {/* ========> LEFT SIGH IN<=========== */}

                                    <Grid
                                        className='left-signIn'

                                        item
                                        xs={12} md={6}
                                        sx={{
                                            background: `url(${backgroundImage})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundColor: '#27583A',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{

                                                textAlign: 'center',
                                                height: '70vh',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'

                                            }}
                                        >
                                            <Box>
                                                <CardMedia
                                                    component="img"
                                                    alt="sign in"
                                                    image={signInImage}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid className='right-signIn' item xs={12} md={6}>

                                        <form onSubmit={handleLoginSubmit}>

                                            <h2>Chat Sign In</h2>
                                            <TextField
                                                sx={{ width: '75%', m: 1 }}
                                                id="standard-basic"
                                                label="Your Email"
                                                name='email'
                                                type='email'
                                                required
                                                onChange={e => setEmail(e.target.value)}
                                                variant="outlined" />
                                            <Button className="signin-btn" type='submit' variant="contained" style={{ marginTop: '1rem' }}>Sign In</Button>
                                        </form>
                                    </Grid>
                                </Grid >
                            </Box >
                        </Container >
                    </>
            }

        </>
    )
}
