import React, { useState, useLayoutEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-coverflow";
import { Container } from "@mui/material";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper";
import "./Review.css";
import reviewMan from "../../../assets/images/reviewMan.png";
import reviewFemale from "../../../assets/images/reviewFemale.png";
import { CircularProgress } from "@mui/material";

const Review = () => {
  const [reviews, setReviews] = useState([]);
  let [dataLoaded, setDataLoaded] = useState(false);

  useLayoutEffect(() => {
    function updateScreen(time) {
      // Make visual updates here.
      if (reviews.length === 0) {
        fetch("https://api.thepsycure.com/psychologists")
          .then((res) => res.json())
          .then((data) => {
            let allReview = data
              .map((psychologist) => psychologist?.reviews)
              .flatMap((rev) => rev);
            setReviews(allReview);
            setDataLoaded(true);
          });
      }
    }

    requestAnimationFrame(updateScreen);
  }, [reviews]);

  return (
    <Container>
      <h2 className="headin">Client Reviews</h2>
      <hr
        style={{
          display: "block",
          width: "10%",
          height: "3px",
          backgroundColor: "#31C75A",
          border: 0,
        }}
      />
      {/* <Swiper
                className='review-container'
                spaceBetween={30}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,

                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                pagination={{
                    el: ".swiper-pagination",
                    clickable: true,
                }}
                modules={[EffectCoverflow, Autoplay, Pagination]}
            > */}

      {dataLoaded ? (
        <Swiper
          className="psychologist-container"
          spaceBetween={30}
          grabCursor={false}
          // centeredSlides={true}
          slidesPerView={"auto"}
          hashNavigation={{
            watchState: true,
          }}
          autoplay={{
            delay: 2200,
            disableOnInteraction: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
        >
          {reviews.length ? (
            <>
              {reviews?.map(
                (review) =>
                  review?.opinion?.length > 0 && (
                    <SwiperSlide key={review?.name}>
                      <div className="review-box">
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={
                              review?.patientInfo?.gender === "male"
                                ? reviewMan
                                : reviewFemale
                            }
                            id="profile-img"
                            alt={review?.patientName}
                          />
                        </div>
                        {/* <div>
                                                <h2 style={{ fontSize: '18px', fontWeight: 600 }}>{review?.patientName}</h2>
                                            </div> */}
                        <div style={{ my: 5 }}>
                          <p style={{ fontSize: "15px", textAlign: "center" }}>
                            {review?.opinion}
                          </p>{" "}
                        </div>
                      </div>
                    </SwiperSlide>
                  )
              )}
            </>
          ) : (
            <></>
          )}
        </Swiper>
      ) : (
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <CircularProgress />
        </div>
      )}
    </Container>
  );
};

export default Review;

// Review star part
{
  /* <div className="flex justify-center gap-4">
                                                <p>{review.ratings} stars</p>
                                                <Rating name="half-rating-read" defaultValue={Number(review.ratings)} precision={0.5} readOnly />
                                            </div> */
}
