import { Box, Container, Typography } from "@mui/material";
import React from "react";

const OverView = () => {
  return (
    <div>
      <Container maxWidth="xl">
        <Box
          sx={{
            height: "600px",
            width: "100%",
            backgroundImage:
              "url(https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fbackground.jpg?alt=media&token=856c978c-bf72-41a6-aad0-9df23cb8c8bd), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.7))",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundBlendMode: "overlay",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "350px",
              border: "10px solid white",
              padding: "10px",
              borderRadius: "40px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: "white", textAlign: "center" }}
            >
              16 fellows leading a total of 80 workshops directly empowering
              4000 adolescents and youth
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default OverView;
