import { LocalizationProvider, StaticDatePicker } from '@mui/lab'
import { Paper } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react'

export default function LastDate({setLastDate, setPage}) {
    const handleValue = (newValue) => {
        const selectedDate = newValue.toISOString().slice(0, 10);
        setLastDate(selectedDate);
        setPage(3)
    }
    return (
        <Paper elevation={12} sx={{ p: 3, borderRadius: '1.5rem' }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignContent: 'center', gap: '0.5rem' }}>
            <h3>Last date for this homework</h3>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    // disabled
                    minDate={new Date()}
                    // renderDay={renderDayInPicker}
                    onChange={handleValue}

                />
            </LocalizationProvider>
        </Paper>
    )
}
