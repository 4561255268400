import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>How do I make an appointment?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Welcome here! You need to click on “Book Appointment” option on our website/ App and fill up the form with all necessary information. Soon after our representative will reach you out to schedule a session.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Is offline session possible?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    We take online sessions with our clients so far. Availing therapy was effective and well conducted. We will start taking offline sessions soon.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Can I get free service?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    We are excited to let you know that Psycure has initiated some projects and campaigns where students and others with financial instability will be advantaged to get our service at minimal cost.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>How many sessions will it take?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    After the confirmation of the schedule with your preferred therapist approximately 8-10 sessions are needed on average to end the therapy procedure.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography>How will Psycure ensure my privacy?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Psycure ensures 100% of their client’s privacy. Our Platform is  end-to-end encrypted.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography>Can I choose who will be my therapist?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Yes, you can choose your preferred therapist from our panel and we will confirm after the procedure of booking.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
