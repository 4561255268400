import { Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import angryImg from "../../../assets/images/MoodJournal/1angry.png";
import sadImg from "../../../assets/images/MoodJournal/2sad.png";
import notgoodImg from "../../../assets/images/MoodJournal/3notgood.png";
import happyImg from "../../../assets/images/MoodJournal/4happy.png";
import betterImg from "../../../assets/images/MoodJournal/5better.png";
import useAuth from "../../../hooks/useAuth";
import "./Journal.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6F1EC",
    color: "#000",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E6F1EC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(date, time, mind, feeling) {
  return { date, time, mind, feeling };
}

export default function AllJournal({ setSelectedJournal, setOpen, mood }) {
  let [allMoods, setAllMoods] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { user } = useAuth();

  const handleRead = (data) => {
    setSelectedJournal(data);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchMoods = async () => {
    const response = await fetch("https://api.thepsycure.com/moods");
    const json = await response.json();
    const filteredMoods = json.filter((data) => data.email === user?.email);
    let sorted = filteredMoods.slice().sort(function (a, b) {
      let firstPortion = a.date.split(",")[0];
      let lastPortion = b.date.split(",")[0];
      var dateA = new Date(firstPortion),
        dateB = new Date(lastPortion);
      return dateB - dateA;
    });

    if (response.ok) {
      setAllMoods(sorted);
    }
  };

  useEffect(() => {
    fetchMoods();
  }, [mood]);

  const rows = [];

  allMoods.forEach((moods) =>
    rows.push(createData(moods.date, moods.date, moods.mind, moods.feeling))
  );

  return (
    <Grid item xs={12} md={6} className="pad_left">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead style={{ marginBottom: "2rem" }}>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="center">Time</StyledTableCell>
              <StyledTableCell align="right">Mood</StyledTableCell>
              <StyledTableCell align="right">Journal</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell>{row.date.split(",")[0]}</StyledTableCell>
                  <StyledTableCell align="right" className="table_center">
                    {row.date.split(",")[1]}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="table_center">
                    <img
                      src={
                        (row.feeling === "angry" && angryImg) ||
                        (row.feeling === "sad" && sadImg) ||
                        (row.feeling === "notgood" && notgoodImg) ||
                        (row.feeling === "happy" && happyImg) ||
                        (row.feeling === "better" && betterImg)
                      }
                      alt={row.feeling}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right" className="table_center">
                    <Button
                      onClick={() => handleRead(row)}
                      style={{
                        background: "#31C75A",
                        color: "#fff",
                        borderRadius: "100px",
                      }}
                    >
                      Read
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
}
