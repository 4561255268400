import { Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import "./NotSignedIn.css";

const NotSignedIn = () => {
  return (
    <div>
      <div className="PageNotFound">
        <div id="notfound">
          <div class="notfound">
            <div class="notfound-404">
              <h3>Oops! You are not logged in</h3>
              <h1>
                <span>4</span>
                <span>0</span>
                <span>1</span>
              </h1>
            </div>
            <h2>You are unauthorized</h2>
            <NavLink to="/signin" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#31C75A" }}
                sx={{ borderRadius: "25px", px: 3 }}
              >
                Sign In
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotSignedIn;
