import { Typography } from "@material-ui/core";
import { Box, Button, Container, Grid } from "@mui/material";

import React from "react";
import SendIcon from "@mui/icons-material/Send";
import ApplyFellowShipModal from "./ApplyFellowShipModal";
import "./fellowship.css";
import { Link } from "react-router-dom";
import "./banner.css";
import { DateTime } from "luxon";

const Banner = () => {
  const [open, setOpen] = React.useState(false);
  const submissionDate = DateTime.fromISO(new Date().toISOString());
  const currentDate = DateTime.fromISO("2024-05-13");

  return (
    <div>
      <Container maxWidth="xl">
        <Box>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            columns={{ lg: 10, md: 10, xl: 10, xs: 10, sm: 10 }}
            spacing={1}
          >
            <Grid
              item
              xs={10}
              lg={10}
              sm={10}
              md={10}
              xl={10}
              sx={{ textAlign: "center" }}
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Flogo.png?alt=media&token=77107bf0-5c2a-4c0f-a73a-229fd65e8d4d"
                alt=""
                width="60%"
                className="banner-logo"
              />
            </Grid>
            <Grid
              item
              xs={10}
              lg={10}
              sm={10}
              md={10}
              xl={10}
              sx={{ textAlign: "center" }}
            >
              <Typography
                variant="h6"
                style={{
                  textTransform: "uppercase",
                }}
                className="deadline"
              >
                Application Deadline: May 12, 2024
                <br />
                <div style={{ margin: "3% 0" }}>
                  {submissionDate < currentDate ? (
                    <Link to="/fellowship/submission">
                      <Button
                        // variant="contained"
                        sx={{
                          width: "300px",
                          height: "50px",
                          background: "#3cb669",
                          borderRadius: "20px",
                          color: "white",
                        }}
                        endIcon={<SendIcon />}
                        className="fellowship-button"
                      >
                        Apply Now!
                      </Button>
                    </Link>
                  ) : (
                    <Typography variant="h5" style={{ color: "red" }}>
                      {/* Registration deadline passed */}
                    </Typography>
                  )}
                </div>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Banner;
