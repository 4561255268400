import * as React from 'react';

import { Container } from '@mui/system';
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import diana from '../../../assets/images/diana.png';
import award1 from '../../../assets/images/award-1.png';
import award2 from '../../../assets/images/award-2.png';
import award3 from '../../../assets/images/award-3.png';
import award4 from '../../../assets/images/award-4.png';
import { useTheme } from '@material-ui/core/styles';


export default function Awards() {
    const theme = useTheme();
    return (
        <Container sx={{ my: 5,
            [theme.breakpoints.down('xs')]: {
              my: 8,
            }, }}>
            <h2  className='headin'>
            Achievements
            </h2>
            <hr style={{ display: 'block', width: '10%', height: '3px', backgroundColor: '#31C75A', border: 0 }} />

            <Swiper
                className='psychologist-container'
                spaceBetween={30}
                grabCursor={false}
                centeredSlides={true}
                slidesPerView={"auto"}
                hashNavigation={{
                    watchState: true,
                }}
                autoplay={{
                    delay: 2200,
                    disableOnInteraction: true,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,

                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
            >

                <SwiperSlide >
                    <div>
                        <img style={{ width: '100%', height: 'auto', padding: '1rem' }} src={diana} alt="Psycure logo" />
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div>
                        <img style={{ width: '100%', height: 'auto', padding: '1rem' }} src={award2} alt="Psycure logo" />
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div>
                        <img style={{ width: '100%', height: 'auto', padding: '1rem' }} src={award3} alt="Psycure logo" />
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div>
                        <img style={{ width: '100%', height: 'auto', padding: '1rem' }} src={award4} alt="Psycure logo" />
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div>
                        <img style={{ width: 'auto', height: '230px', padding: '1rem' }} src={award1} alt="Psycure logo" />
                    </div>
                </SwiperSlide>

            </Swiper>

            {/* <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Grid container spacing={0} sx={{ flexGrow: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                    <Grid item xs={3} md={3}>
                        <img style={{ width: '100%', height: 'auto', padding: '1rem' }} src={award2} alt="Psycure logo" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <img style={{ width: '100%', height: 'auto', padding: '1rem' }} src={award3} alt="Psycure logo" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <img style={{ width: '100%', height: 'auto', padding: '1rem' }} src={award4} alt="Psycure logo" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <img style={{ width: '100%', height: 'auto', padding: '1rem' }} src={award1} alt="Psycure logo" />
                    </Grid>
                </Grid>
            </Box> */}
        </Container>
    );
}