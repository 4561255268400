import React, { useState } from "react";

import { signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import useAuth from "./useAuth";
import { auth } from "../firebase.config";
import { useNavigate } from "react-router-dom";

export const useSignInWithGooglePatient = (user, patientInfo) => {
  let [error, setError] = useState(false);
  const { dispatch } = useAuth();
  const navigate = useNavigate("");

  const handleSignInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    setError(false);
    signInWithPopup(auth, provider)
      .then((result) => {
        if (user === "patient") {
          fetch(`https://api.thepsycure.com/users`)
            .then((res) => res.json())
            .then((data) => {
              let haveUser = data.filter(
                (user) => user?.email === result?.user?.email
              );

              if (haveUser?.length <= 0) {
                dispatch({
                  type: "LOGIN",
                  payload: { ...result.user, type: "patient" },
                });
                navigate("/users-journey");
              } else {
                dispatch({
                  type: "LOGIN",
                  payload: { ...result.user, type: "patient" },
                });
                navigate("/dashboard");
              }
            });
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const handleSignOutWithGoogle = () => {
    signOut(auth)
      .then(() => {
        dispatch({ type: "LOGOUT" });
      })
      .catch((err) => {
        let error = "Your email or password was incorrect";
      });
  };

  return { error, handleSignInWithGoogle, handleSignOutWithGoogle };
};
