import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import KeyFeaturesCard from "./KeyFeaturesCard";

const FellowshipProgram = () => {
  return (
    <div>
      <Container maxWidth="xl">
        <Box sx={{ padding: "5%" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columns={{ lg: 10, xs: 10, md: 8 }}
            spacing={10}
          >
            <Grid item lg={5} xs={10} md={8}>
              <Box sx={{ width: "100%" }}>
                <img
                  style={{
                    borderRadius: "5%",
                    width: "100%",
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2Fallison-griffith-Q76DPRQ3Ix0-unsplash.jpg?alt=media&token=ad8d0e42-b196-496c-a8aa-ce1d2c3d8c04"
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item lg={5} xs={10} md={8}>
              <Typography variant="h5">
                The <b>Youth Mental Health Fellowship Program</b> is a
                comprehensive <b>6–month</b> long fellowship program that equips
                aspiring mental health advocates with the tools, skills, and
                resources they need to make a meaningful impact in their
                communities. Through a combination of interactive workshops,
                mentorship opportunities, and real-world experiences, fellows
                will develop innovative approaches to address mental health
                challenges and contribute to creating a more supportive and
                inclusive society.
                <Typography variant="h5" style={{ marginTop: "20px" }}>
                  Our fellowship program is committed to fostering a global
                  community of mental health advocates who are passionate about
                  driving change and breaking down barriers to mental health
                  care. Following the program, the fellows would be conducting
                  community workshops to enhance awareness about mental health,
                  identify common problems among youth, and equip participants
                  with practical strategies to support mental well-being.
                </Typography>
                {/* <Typography
                  variant="h2"
                  style={{
                    fontWeight: 900,
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  Customized learning experience
                </Typography> */}
              </Typography>
            </Grid>
            <Grid item lg={10} xs={10} md={8}>
              <KeyFeaturesCard />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default FellowshipProgram;
