import { Alert, AlertTitle, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

export default function SuccessfulSignupPsychologist({ greet }) {
  const navigate = useNavigate("");
  const { user } = useAuth();

  useEffect(() => {
    fetch("https://api.thepsycure.com/psychologists")
      .then((res) => res.json())
      .then((data) => {
        const isProvidedDetails = data.filter(
          (psychologist) => psychologist?.email === user?.email
        );
        if (isProvidedDetails?.length) {
          navigate("/psychologist-dashboard");
        } else {
          navigate("/psychologist-details");
        }
      });
  }, []);
  return (
    <Container>
      <Alert style={{ marginTop: "5rem" }} severity="success">
        <AlertTitle>Congratulations</AlertTitle>
        User created — <strong>successfully!</strong>
      </Alert>
    </Container>
  );
}
