import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./PaySlide.css";
import slideImg1 from '../../../assets/images/Payment/payment.png';
import slideImg2 from '../../../assets/images/Payment/paymentImg.png';
import slideImg3 from '../../../assets/images/Payment/payImg3.png';
import slideImg4 from '../../../assets/images/Payment/payImg4.png';


// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";


export default function App() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide><img style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }} src={slideImg1} alt="" /></SwiperSlide>

                <SwiperSlide><img style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }} src={slideImg2} alt="" /></SwiperSlide>

                <SwiperSlide><img style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }} src={slideImg3} alt="" /></SwiperSlide>

                <SwiperSlide><img style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }} src={slideImg4} alt="" /></SwiperSlide>

            </Swiper>
        </>
    );
}
