import { Container } from "@mui/material";
import React from "react";

const OverViewFull = () => {
  return (
    <Container maxWidth="xl">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FYouth%20Mental%20Health%20Fellowship%20Program.png?alt=media&token=207119a2-3fbc-4e5c-9b43-838b79ab0f50"
        alt=""
        srcset=""
        height="100%"
        width="100%"
      />
    </Container>
  );
};

export default OverViewFull;
