import * as React from "react";
import Grid from "@mui/material/Grid";

import {

  Button,
  Container,

  Paper,
 
} from "@mui/material";
import "./HomeworkBuilder.css";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { projectStorage } from "../../firebase.config";
import useAuth from "../../hooks/useAuth";

import FreequencyPage from "./TaskComponent/FreequencyPage";
import TaskElement from "./TaskComponent/TaskElement";
import WrittenElement from "./TaskComponent/WrittenElement";
import ErrorAlert from "../../Shared/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../Shared/SuccessAlert/SuccessAlert";
import LastDate from "./LastDate/LastDate";

export default function HomeworkBuilder({ patientSession, setPatientSession }) {
  const { user } = useAuth();
  const [homework, setHomeWork] = React.useState({
    frequency: "",
    title: "",
    description: "",
    image: "",
  });
  const [page, setPage] = React.useState(1);
  const [selectElement, setSelectElement] = React.useState("");
  const [lastDate, setLastDate] = React.useState("");

  const [fileUpload, setFileUpload] = React.useState({});

  // const [saveData, setSaveData] = React.useState([]);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  // const [defaultValue, setDefaultValue] = React.useState('')
  // const [time, setTime] = React.useState('');

  const types = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];

  const handleFile = (e) => {
    let selected = e.target.files[0];

    if (selected && !types.includes(selected.type)) {
      setFileUpload({ file: "" });
      setError("Please select a file (png , jpg, jpeg or pdf)");
      return;
    }
    const storageRef = ref(projectStorage, `hwFiles/${selected.name}`);
    const uploadFile = uploadBytesResumable(storageRef, selected);

    uploadFile.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        setError(error);
        setSuccess("");
      },
      () => {
        getDownloadURL(uploadFile.snapshot.ref).then((downloadURL) => {
          setFileUpload({ file: downloadURL });
          setHomeWork({ ...homework, file: downloadURL });
          setError("");
        });
      }
    );
  };

  const handleSubmit = async () => {
    const personalHomework = {
      userEmail: patientSession?.userDetails?.email || "",
      date: new Date().toISOString().slice(0, 10),
      lastDate,
      docEmail: `${user?.email}`,
      docName: `${user?.displayName}`,
      status: "incomplete",
      homework,
      completed: [],
      incomplete: [],
    };

    if (personalHomework.userEmail !== "") {
      fetch(`https://api.thepsycure.com/homeworks`, {
        method: "POST",
        body: JSON.stringify(personalHomework),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response1) => response1.json())
        .then((data) => {
          setHomeWork({
            frequency: "",
            title: "",
            description: "",
            image: "",
          });
          setSuccess("Homework Sent");
          setError("");
          setPatientSession({});
        })
        .catch((err) => {
          setError(err.message);
        });
    } else {
      setSuccess("");
      setError("Please select Patient's email");
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setError("");
      setSuccess("");
    }, 3000);
  }, [error, success]);

  return (
    <>
      <Container>
        {patientSession?.userDetails?.email ? (
          <p style={{ textAlign: "center" }}>
            Giving Homework to{" "}
            <span style={{ color: "#31C75A" }}>
              {patientSession?.userDetails?.name}
            </span>
          </p>
        ) : (
          ""
        )}

        {patientSession?.userDetails?.email ? (
          <Grid
            container
            spacing={2}
            justifyContent="space-around"
            alignItems="center"
            style={{ marginTop: "20px" }}
          >
            <Grid item xs={12} md={6}>
              <Paper
                elevation={12}
                sx={{ p: 5, borderRadius: "1.5rem", minHeight: "25rem" }}
              >
                {homework.frequency && (
                  <h4 style={{ textAlign: "center" }}>
                    {homework.frequency} Homework
                  </h4>
                )}
                {homework.title && <h2>{homework.title}</h2>}
                {homework.description && <p>{homework.description}</p>}
                {homework.image && (
                  <a href={homework?.image} style={{}} target="_blank">
                    <p style={{ color: "#31c75a" }}>Link</p>
                  </a>
                  //   <img
                  //     src={homework.image}
                  //     style={{ width: "200px", height: "auto" }}
                  //     alt="homework img"
                  //   />
                )}
                <br />

                {homework.title === "" &&
                homework.description === "" &&
                homework.image === "" ? (
                  ""
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#31C75A",
                      borderRadius: "0.5rem",
                    }}
                    onClick={handleSubmit}
                  >
                    Submit Homework
                  </Button>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              {page === 1 && (
                <FreequencyPage
                  setPage={setPage}
                  setHomeWork={setHomeWork}
                  homework={homework}
                />
              )}
              {page === 2 && (
                <LastDate setLastDate={setLastDate} setPage={setPage} />
              )}
              {page === 3 && (
                <TaskElement
                  setPage={setPage}
                  setSelectElement={setSelectElement}
                  homework={homework}
                />
              )}
              {page === 4 && (
                <WrittenElement
                  selectElement={selectElement}
                  setHomeWork={setHomeWork}
                  homework={homework}
                  handleFile={handleFile}
                  setPage={setPage}
                  fileUpload={fileUpload}
                  progress={progress}
                  setFileUpload={setFileUpload}
                  setProgress={setProgress}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        {error && <ErrorAlert>{error}</ErrorAlert>}
        {success && <SuccessAlert>{success}</SuccessAlert>}
      </Container>
    </>
  );
}
